.bottom-wrapper{
    position: absolute;
    bottom: 0.1px;
    left: 0;
    right: 0;
}


@media only screen and (max-width: 575px) {
    .bottom-wrapper {
        position: absolute;
        /* margin-bottom: 13.5%; */
        margin-bottom: 0.1px;
    }
}