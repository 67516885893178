.Caps {
  width: 100vw;
  height: 100vh;
  background: #fff;
  color: #212121;
  font-family: "Open Sans", sans-serif;
  display: grid;
  place-items: center;
}

body {
  margin: 0%;
  padding: 0%;
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700");

.joinChatContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.joinChatContainer h3 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

/* .joinChatContainer input {
  width: 210px;
  height: 40px;
  margin: 7px;
  border: 2px solid #43a047;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
} */

/* .joinChatContainer button {
  width: 225px;
  height: 50px;
  margin: 7px;
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  background: #43a047;
  color: #fff;
  cursor: pointer;
}

.joinChatContainer button:hover {
  background: #2e7d32;
} */

.chat-window {
  width: 100%;
  height: 420px;
}

.chat-window p {
  margin: 0;
  font-family: "Roboto Slab", serif;
  font-weight: 500;
}

.chat-window .chat-header {
  height: 45px;
  border-radius: 6px;
  background: #263238;
  position: relative;
  cursor: pointer;
}

.chat-window .chat-header p {
  display: block;
  padding: 0 1em 0 2em;
  color: #fff;
  font-weight: 700;
  line-height: 45px;
}

.chat-window .chat-body {
  height: calc(450px - (45px + 70px));
  border: 1px solid #263238;
  background: #fff;

  position: relative;
}

.chat-window .chat-body .message-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chat-window .chat-body .message-container::-webkit-scrollbar {
  display: none;
}

.chat-window .chat-body .message {
  height: auto;
  padding: 10px;
  display: flex;
}

.chat-window .chat-body .message .message-content {
  width: auto;
  height: auto;
  min-height: 40px;
  max-width: 120px;
  background-color: #43a047;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-left: 5px;
  padding-right: 5px;
  padding-left: 5px;
  overflow-wrap: break-word;
  word-break: break-word;
}

#you {
  justify-content: flex-start;
}

#you .message-content {
  justify-content: flex-start;
}

#you .message-meta {
  justify-content: flex-start;
  margin-left: 5px;
}

#other {
  justify-content: flex-end;
}

#other .message-content {
  justify-content: flex-end;
  background-color: cornflowerblue;
}

#other .message-meta {
  justify-content: flex-end;
  margin-right: 5px;
}

.message-meta #author {
  margin-left: 10px;
  font-weight: bold;
}

.chat-window .chat-body .message .message-meta {
  display: flex;
  font-size: 12px;
}

.chat-window .chat-footer {
  height: 40px;
  border: 1px solid #263238;
  border-top: none;
  display: flex;
}

.chat-window .chat-footer input {
  height: 100%;
  flex: 85%;
  border: 0;
  padding: 0 0.7em;
  font-size: 1em;
  border-right: 1px dotted #607d8b;

  outline: none;
  font-family: "Open Sans", sans-serif;
}

.chat-window .chat-footer button {
  border: 0;
  display: grid;
  place-items: center;
  cursor: pointer;
  flex: 15%;
  height: 100%;
  background: transparent;
  outline: none;
  font-size: 25px;
  color: lightgray;
}

.chat-window .chat-footer button:hover {
  color: #43a047;
}

.hide {
  opacity: 0 !important;
}

/* chat new css 10-05-2023 */
.modal-dialog.chat_modal {
  flex-direction: column;
  gap: 20px;
  justify-content: center;
}

.chat-user-list-bg {
  position: relative;
  border-radius: 5px;
  padding: 0px;
  display: block;
  margin: 20px 20px;
}

.chat-user-list-bg:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  margin: -8px;
  border-radius: 5px;
  background: linear-gradient(180deg,
      #ffe570,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #421800 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%);
}

.chat-user-list {
  gap: 10px;
  width: 100%;
  padding: 3px 2px 15px;
  display: flex;
}

.chat-scroll {
  overflow-x: scroll !important;
  overflow: hidden;
}

.chat-user-list a img {
  height: 70px;
  max-width: 70px !important;
}

.chat-content-view {
  background: #d39339;
  background: linear-gradient(90deg,
      #d39339 0,
      #ecca70 15%,
      #faf8bd 25%,
      #daa74c 51%,
      #cf8c31 65%,
      #da8b2c 75%,
      #d48b2c 90%,
      #ce842b);
  z-index: 11;
  position: relative;
  padding: 10px;
}

.chat-content-view11 {
  background: #000;
  z-index: 11;
  position: relative;
  padding: 0px;
  color: #fff;
}

.card-start-list .cardBorder-list .chat_titles {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.cardparent-list.chat-modul {
  max-height: 282px !important;
}

.chat-user-list a {
  padding: 7px;
  width: 100px;
  height: 100%;
}

.chat-user-list a.active {
  border: solid 2px #ecca70;
}

.form-group.input-custom-field.inputbg {
  padding: 3px !important;
  width: 100%;
  float: left;
  margin: 10px 0 0;
}

.form-group.input-custom-field.inputbg .form-control {
  width: 100%;
  margin: 0px;
}

.user-chat-list {
  background: #f1e5cb;
  padding: 10px 15px 10px 0px;
  display: grid;
  grid-template-columns: 110px 1fr;
  width: 100%;
  align-items: center;
}

.chatlistava img {
  height: 40px;
  width: 40px;
}

.chatlistava span {
  width: 100%;
  float: left;
  text-transform: uppercase;
  font-weight: 500;
  margin: 5px 0 0;
  font-size: 14px;
}

.chat-info p {
  font-size: 14px;
  margin: 0px;
  text-align: left;
}

.chat-info {
  max-height: 44px;
  overflow: hidden;
  overflow-y: scroll;
  padding: 0 20px;
  padding-right: 100px;
}

.chatlistava {
  border-right: solid 1px #ccc;
}

.user-chat-list .close-button-style-write {
  top: 50%;
  right: 40px;
  transform: translateY(-50%) !important;
  padding: 4.2px;
}

.user-chat-list .close-button-style-write span:before {
  height: 16px;
  width: 16px;
}

.chat-info::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
  background-color: #f5f5f5;
}

.chat-info::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.chat-info::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}

.chat-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
  background-color: #f5f5f5;
}

.chat-scroll::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.chat-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}

.chat-categorylist .nav li {
  border-right: solid 2px #954813;
  padding: 8px;
  font-size: 13px;
  font-weight: 500;
  color: #000;
  line-height: 20px;
}

img.close_icon_icon {
  position: absolute;
  min-width: 85px !important;
  height: 110px !important;
  top: -12px;
}

.chat-categorylist .nav {
  display: flex;
  flex-wrap: inherit;
}

.chat-user-list .cardparent-list {
  background: none;
  position: relative;
}

/* story list */

.cardBorder-list .chat-user-list-bg:before {
  margin: -2px;
}

.cardBorder-list .chat-content-view {
  padding: 0 0;
  font-size: 14px;
  font-weight: 500;
}

.cardBorder-list .chat-content-view h6 {
  padding: 0 0;
  font-size: 12px;
  margin: 0px;
}

.cardBorder-list .chat-user-list-bg {
  margin: 10px 20px 0 !important;
}

.cardTitle-list.cardbottomtitle .cardTitleImage-list {
  background-position: top;
  padding: 20px 10px 0px;
  height: auto;
}

.cardTitle-list.cardbottomtitle .cardTitles {
  font-size: 14px;
}

.storylist-list.cardstorylistviews {
  height: 170px;
}

@media only screen and (max-width: 767px) {
  .chat-categorylist .nav {
    flex-wrap: wrap-reverse;
  }

  .chat-user-list .cardparent-list {
    background: none;
    max-width: initial;
    min-width: min-content;
  }

  .user-chat-list {
    grid-template-columns: 60px 1fr;
  }

  .chatlistava span {
    font-size: 12px;
  }

  .chat-info {
    padding: 0 10px;
    padding-right: 70px;
  }

  .chat-info p {
    font-size: 13px;
  }

  .chatlistava img {
    height: 30px;
    width: 30px;
  }
}

.storylist-list.chat-modal-boxes {
  display: flex;
  flex-wrap: wrap;
  height: 400px;
  justify-content: space-evenly;
  margin-top: 1%;
}

button.create-group-btn {
  position: absolute;
  left: -70px;
  top: 50%;
  border: solid 2px #ffe672;
  background: #404040;
  padding: 0;
  border-radius: 100px;
  width: 50px;
  height: 50px;
}

button.create-group-btn {
  position: absolute;
  left: -70px;
  top: 50%;
  background: none;
  border: none;
  box-shadow: none;
  outline: none;
}

button.create-group-btn img {
  width: 50px;
  height: 50px;
}

/* chat new list */

.avtar-icon img {
  height: 70px;
  width: 70px;
  border: solid 2px #f9e37e;
  padding: 6px;
  border-radius: 100px;
}

.chat-listusers {
  width: 100%;
  gap: 20px;
  padding: 20px;
}

.chat-listusers .message {
  width: 100%;
  display: inline-block !important;
  position: relative;
  font-family: "Roboto Slab", serif;
}

.chat-listusers .message p {
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-weight: 500;
}

.chat-listusers .message-content {
  width: 100%;
  max-width: 100% !important;
  text-align: left;
  padding: 10px !important;
  background: #feeed4 !important;
  color: #000 !important;
  border: solid 3px #f98d2d;
}

.chat-listusers .close-button-style span:before {
  height: 20px;
  width: 20px;
}

.chat-listusers .close-button-style {
  right: 0px;
  top: -18px;
  width: 40px;
  height: 40px;
}

.chat-listusers .close-button-style span {
  height: 33px;
  width: 33px;
}

.avtar-icon {
  width: 100px;
}

.avtar-icon #author {
  font-size: 16px;
  margin-top: 5px;
  font-family: "Roboto Slab", serif;
  white-space: revert-layer;
  line-break: anywhere;
}

.chat-listusers .message-content p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.downarrow01 img {
  width: 25px;
  position: relative;
  bottom: 33px;
  right: 0;
}

.single-msg-content {
  overflow-y: scroll;
  max-height: 200px;
}

.single-msg-content::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.message-content2 {
  text-align: center;
  width: 100%;

}

.message-content2 p {
  background: #fff;
  width: 100%;
  padding: 0 10px;
  margin: 0 auto;
}

button.mick_icon {
  background: #000 !important;
  flex: none !important;
  border-radius: 100%;
  border-width: 5px !important;
  border-style: solid !important;
  border: solid 2px #ffe773 !important;
  width: 35px;
  height: 35px !important;
  margin: 2px;
}

button.mick_icon img {
  height: 20px;
  margin: -10px 0 0;
  width: 15px;
}

.long_user_names h6 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.cardTitles.card_eyes {
  display: grid;
}

.cardTitles.card_eyes img {
  width: 30px;
}

.chat-user-list-bg.sshh {
  margin: 0px !important;
}

.chat-user-list-bg.sshh #FullOrEmpty276 {
  padding: 3px 11px;
  font-weight: bold;
  text-transform: uppercase;
}