.card-image{

    /* width: calc(100%-180px); */
    margin: 0 20px;
    padding: 0;
    width: 250px;
   
    transition: 0.5s;
    /* z-index: 11; */
    display: flex;
    align-items: center;
    justify-content: center;

}
.card-image:hover {
    animation: shake 1.5s;
    animation-iteration-count:infinite;
  }
  @media screen and (min-width: 1600px) {
    .container {
      width: 1570px;
    }
  }
  @media screen and (min-width: 1900px) {
    .card-image {
      width: 19%;
    }
  } 
  @media only screen and (max-width: 767px){
.card-image {
    padding-left: 8px;
    padding-right: 8px;
 
}
  }
  @media only screen and (max-width : 991.5px) {
    .card-image {
      width: 30% !important;
      margin: 0;
      padding: 0;
  } 
  }
  @media only screen and (max-width: 1400px){
    .card-image {
        width: 20%;
        margin: 0 5px;
        padding: 0;
    } 
  }




  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }
.card-container{
    display: flex;
    justify-content: center;
    margin-top: 3%;
    position: relative;
}