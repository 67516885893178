.custom-file-upload {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.container-read {
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0;
  margin: 0;
  padding-left: 0rem;
  padding-right: 0rem;
  overflow: hidden;
  /* background      : url('../../assets/images/read-a-story-bg.png'); */
  background: url("../../assets/images/background_images//purpupl_background.jpeg");
  background-size: cover;
  background-position: center !important;
}

#readstorybookadd {
  /* background      : url('../../assets/images/background_images/yellow_baclground.jpeg'); */
  background-size: cover;
  background-position: center !important;
}

.main-rsb {
  width: 100%;
  height: 100%;
  display: block;
}

.main_container_home_readstory {
  background-image: url("../../assets/images/read-a-story-bg.png"),
    linear-gradient(89.94deg,
      #4b4251 1.56%,
      #5e6174 7.01%,
      #5f6276 12.37%,
      #707378 18.07%,
      #85afc4 26.74%,
      #9fd0e7 39.42%,
      #6a757f 49.94%,
      #5a5961 60.29%,
      #626a7f 64.62%,
      #59525a 74.69%,
      #5a5d66 81.31%,
      #6d9ba9 90.15%,
      #666669 95.71%,
      #55505c 99.14%);
  min-height: 100%;
}

.page-rsb {
  height: 500px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #fdfaf7;
  color: #785e3a;
  border: solid 1px #c2b5a3;
  overflow: hidden;
}

.page-rsb .page-content-rsb {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.page-rsb .page-content-rsb .page-header-rsb {
  height: 30px;
  font-size: 100%;
  text-transform: uppercase;
  text-align: center;
}

.page-rsb .page-content-rsb .page-image-rsb {
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.page-rsb .page-content-rsb .page-text-rsb {
  height: 100%;
  flex-grow: 1;
  font-size: 80%;
  text-align: justify;
  margin-top: 10px;
  padding-top: 10px;
  box-sizing: border-box;
  border-top: solid 1px #f4e8d7;
}

.page-rsb .page-content-rsb .page-footer-rsb {
  height: 30px;
  border-top: solid 1px #f4e8d7;
  font-size: 80%;
  color: #998466;
}

.page-rsb.--left {
  border-right: 0;
  box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, 0.4);
  top: 0 !important;
  height: 100% !important;
}

.page-rsb.--right {
  border-left: 0;
  box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4);
  top: 0 !important;
  height: 100% !important;
}

.page-rsb.--right .page-footer-rsb {
  text-align: right;
}

.page-rsb.hard {
  background-color: #f2e8d9;
  border: solid 1px #998466;
}

.page-rsb.page-cover-rsb {
  background-color: #e3d0b5;
  color: #785e3a;
  border: solid 1px #998466;
}

.page-rsb.page-cover-rsb h2 {
  text-align: center;
  padding-top: 50%;
  font-size: 210%;
}

.page-rsb.page-cover-rsb.page-cover-top-rsb {
  box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5),
    -2px 0 5px 2px rgba(0, 0, 0, 0.4);
}

.page-rsb.page-cover-rsb.page-cover-bottom-rsb {
  box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5),
    10px 0 8px 0px rgba(0, 0, 0, 0.4);
}

.pagedatachild-rsb {
  height: 90%;
  width: 100%;
  overflow: hidden;
}

.manualpage-rsb {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #fdfaf7;
  color: #785e3a;
  border: solid 1px #c2b5a3;
  overflow: hidden;
}

.manualpage-rsb .page-content-rsb {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.manualpage-rsb .page-content-rsb .page-header-rsb {
  height: 30px;
  font-size: 100%;
  text-transform: uppercase;
  text-align: center;
}

.manualpage-rsb .page-content-rsb .page-image-rsb {
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.manualpage-rsb .page-content-rsb .page-text-rsb {
  height: 100%;
  flex-grow: 1;
  font-size: 80%;
  text-align: justify;
  margin-top: 10px;
  padding-top: 10px;
  box-sizing: border-box;
  border-top: solid 1px #f4e8d7;
}

.readastory-modal-dialog-rsb.modal-dialog-rsb {
  max-width: 940px;
}

.manualpage-rsb .page-content-rsb .page-footer-rsb {
  height: 30px;
  border-top: solid 1px #f4e8d7;
  font-size: 80%;
  color: #998466;
}

.manualpage-rsb.--left {
  border-right: 0;
  box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, 0.4);
}

.manualpage-rsb.--right {
  border-left: 0;
  box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4);
}

.manualpage-rsb.--right .page-footer-rsb {
  text-align: right;
}

.manualpage-rsb.hard {
  background-color: #f2e8d9;
  border: solid 1px #998466;
}

.manualpage-rsb.page-cover-rsb {
  background-color: #e3d0b5;
  color: #785e3a;
  border: solid 1px #998466;
  top: 0 !important;
  min-width: 50%;
}

.manualpage-rsb.page-cover-rsb h2 {
  text-align: center;
  padding-top: 30%;
  font-size: 36px;
}

.manualpage-rsb.page-cover-rsb.page-cover-top-rsb {
  box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5),
    -2px 0 5px 2px rgba(0, 0, 0, 0.4);
}

.manualpage-rsb.page-cover-rsb.page-cover-bottom-rsb {
  box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5),
    10px 0 8px 0px rgba(0, 0, 0, 0.4);
}

.Instruction-page-rsb.page-cover-rsb {
  background-color: #e3d0b5;
  color: #785e3a;
}

.Instruction-page-rsb.page-cover-rsb .page-content-rsb {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_container_home_rsb {
  background-image: url("../../assets/images/read-a-story-bg.png"),
    linear-gradient(89.94deg,
      #b56a29 1.56%,
      #ca872e 7.01%,
      #d08f33 12.37%,
      #d39339 18.07%,
      #ecca70 26.74%,
      #faf8bd 39.42%,
      #daa74c 49.94%,
      #cf8c31 60.29%,
      #da8b2c 64.62%,
      #d48b2c 74.69%,
      #ce842b 81.31%,
      #f0b348 90.15%,
      #d58e34 94.33%,
      #c1752a 99.14%);
  min-height: 100%;
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
  background-position: top !important;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.welcome-body-wrapper-rsb {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100%;
  background-color: #000;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.bg-read-a-story-rsb {
  background-size: 100% !important;
  background-repeat: no-repeat !important;
  background-position: top !important;
}

.bg-read-a-story-rsb .home-middle-top-icon-rsb a span {
  background: linear-gradient(180deg, #192d64, #0d1734),
    linear-gradient(180deg, #3f4556, #242833);
}

.bg-read-a-story-rsb .home-middle-top-icon-rsb a:hover span {
  background: linear-gradient(0deg, #192d64, #0d1734),
    linear-gradient(0deg, #3f4556, #242833);
}

.bg-read-a-story-rsb .input-custom-field-2-rsb .form-control {
  height: 44px;
}

.home-top-box-rsb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 20px 5px;
  width: 100%;
  margin: 0 auto;
}

.bg-welcome-rsb .home-top-box-rsb {
  padding-top: 10px;
}

.home-logo-bx-rsb a {
  display: inline-block;
}

.home-logo-bx-rsb a .small-logo-rsb {
  display: none;
}

.home-logo-bx-rsb a .large-logo-rsb {
  display: block;
  height: 90px;
}

.starting-logo-rsb img.img-responsive-rsb {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;

  -ms-interpolation-mode: nearest-neighbor;
}

.home-middle-top-icon-rsb,
.home-middle-top-icon-rsb a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-middle-top-icon-rsb a {
  margin: 0 15px;
  width: 60px;
  height: 60px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/header-icons-frame.svg");
  background-size: contain;
  position: relative;
}

.home-middle-top-icon-rsb a:active {
  top: 3px;
}

.home-middle-top-icon-rsb a span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
}

.bg-read-a-story-rsb .home-middle-top-icon-rsb a span {
  background: linear-gradient(180deg, #192d64, #0d1734),
    linear-gradient(180deg, #3f4556, #242833);
}

.bg-read-a-story-rsb .home-middle-top-icon-rsb a:hover span {
  background: linear-gradient(0deg, #192d64, #0d1734),
    linear-gradient(0deg, #3f4556, #242833);
}

.home-middle-top-icon-rsb a span i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.home-middle-top-icon-rsb a span i.settings-icon-rsb {
  background-image: url("../../assets/images/header-setting-icon.svg");
}

.home-middle-top-icon-rsb a:not(:hover) span i.settings-icon-rsb {
  -webkit-animation: none;
  animation: none;
}

.home-middle-top-icon-rsb a span i.pay-per-click-icon-rsb {
  background-image: url("../../assets/images/header-ppc-icon.svg");
  -webkit-animation: zoom-in-zoom-out 1s ease-out infinite;
  animation: zoom-in-zoom-out 1s ease-out infinite;
}

.home-middle-top-icon-rsb a:not(:hover) span i.pay-per-click-icon-rsb {
  -webkit-animation: none;
  animation: none;
}

.home-middle-top-icon-rsb a span i.questions-icon-rsb {
  background-image: url("../../assets/images/header-questions-icon.svg");
}

.home-middle-top-icon-rsb a span.questions-span-rsb {
  position: relative;
  overflow: hidden;
}

.home-middle-top-icon-rsb a span.questions-span-rsb:after,
.home-middle-top-icon-rsb a span.questions-span-rsb:before {
  content: "";
  height: 35px;
  width: 35px;
  display: inline-block;
  background-image: url("../../assets/images/header-questions-icon.svg");
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: 0.5s;
}

.home-middle-top-icon-rsb a span.questions-span-rsb:before {
  left: 8px;
  opacity: 1;
}

.home-middle-top-icon-rsb a:hover span.questions-span-rsb:before {
  opacity: 0;
  left: -44px;
}

.home-middle-top-icon-rsb a span.questions-span-rsb:after {
  background-image: url("../../assets/images/header-questions-icon.svg");
  opacity: 0;
  right: -44px;
}

.home-middle-top-icon-rsb a:hover span.questions-span-rsb:after {
  opacity: 1;
  right: 8px;
}

.modal-dialog-rsb {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.gallary-modal-dialog-rsb.modal-dialog-rsb .modal-content-rsb {
  border: 0;
  position: relative;
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.25);
}

.gallary-modal-dialog.modal-dialog-rsb .close-button-style-rsb {
  right: -18px;
}

.gallary-modal-dialog-rsb .close-button-style-rsb:active {
  margin-top: 2px;
  background-color: transparent;
}

.gallary-modal-dialog-rsb.modal-dialog-rsb .modal-body-rsb {
  padding: 25px;
  background: #fff;
  text-align: center;
}

.modal-dialog-centered-rsb {
  display: flex;
  align-items: center;
  min-height: calc(100% - 12.5rem) !important;
}

.readastory-modal-dialog-rsb.modal-dialog-rsb .modal-body-rsb {
  padding: 15px 50px 15px;
}

.readastory-modal-dialog-rsb.modal-dialog-rsb .modal-content-rsb {
  background: linear-gradient(90deg,
      #d39339 0,
      #ecca70 15%,
      #faf8bd 25%,
      #daa74c 51%,
      #cf8c31 65%,
      #da8b2c 75%,
      #d48b2c 90%,
      #ce842b);
  border: 0;
  height: 100% !important;
  border-radius: 0;
  position: relative;
  box-shadow: 0 8px 17px rgb(0, 0, 0, 25%);
}

.readastory-modal-dialog-rsb.modal-dialog-rsb.write-a-story-modal-rsb .modal-body-rsb {
  padding-top: 15px;
}

.readastory-modal-dialog-rsb.modal-dialog-rsb.write-a-story-modal-rsb .modal-body-rsb .custom-field-heading-rsb {
  margin-bottom: 15px;
}

.bg-chatroom-rsb .readastory-modal-dialog-rsb.modal-dialog-rsb {
  margin-top: 10px;
}

.readastory-modal-dialog-rsb .close-button-style-rsb:active {
  margin-top: 2px;
  background-color: transparent;
}

.readastory-modal-dialog-rsb.modal-dialog-rsb .modal-body-rsb::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 2px rgb(255, 255, 0, 30%);
  background: linear-gradient(180deg,
      #ffe570,
      #fffe99 9.18%,
      #bb9432 25.31%,
      #efd269 48.35%,
      #c09937 69.22%,
      #9e781a 83.69%,
      #f1d361);
}

.readastory-modal-dialog-rsb.modal-dialog-rsb .modal-body-rsb::-webkit-scrollbar-track {
  background: linear-gradient(180deg, #4e4e4e, #000);
  border-radius: 5px;
}

.readastory-modal-dialog-rsb.modal-dialog-rsb .modal-body-rsb::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 2px rgb(255, 255, 0, 30%);
  background: linear-gradient(180deg,
      #ffe570,
      #fffe99 9.18%,
      #bb9432 25.31%,
      #efd269 48.35%,
      #c09937 69.22%,
      #9e781a 83.69%,
      #f1d361);
}

.border-style-8-rsb {
  border-radius: 5px;
  padding: 4px;
  background: #431900;
}

.modal-content-rsb {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  background: url("../../assets/images/back1-1.webp");
  -webkit-background-clip: border-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.storyPara-rsb {
  word-break: break-all;
  font-size: 14px;
  color: #000;
}

.bookAd-rsb {
  height: 600px;
  width: 800px;
  overflow-y: hidden;
}

.continue-btn-group-rsb .btn-continue-rsb {
  width: 50px;
  height: 50px;
}

.continue-btn-group-rsb {
  text-align: center;
  margin-bottom: 15px;
  margin-top: 15px;
}

.continue-btn-group-rsb .btn-continue-rsb {
  height: 50px;
  width: 50px;
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  display: inline-block;
  position: relative;
}

.continue-btn-group-rsb .btn-continue-rsb:focus {
  outline: 0;
}

.continue-btn-group-rsb .btn-continue-rsb i {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 40px;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.ads-add-btn-back-arrw.continue-btn-group-rsb .btn-continue-rsb {
  height: 50px;
  width: 50px;
}

.continue-btn-group-rsb {
  margin-top: 50px;
}

.arrow-bounce-right-rsb {
  -webkit-animation: bounceRight 2s infinite;
  animation: bounceRight 2s infinite;
}

.arrow-bounce-right-rsb {
  -webkit-animation: bounceRight 2s infinite;
  animation: bounceRight 2s infinite;
}

.help-icon.emailDiv {
  position: relative;
  z-index: 1;
}

.closeBtn {
  right: -20px;
  top: -15px;
}

.searchDiv .welcome-item {
  flex-direction: column;
  padding: 0;
  width: 80px;
}

.emailDiv .welcome-item {
  flex-direction: column;
  padding: 0px;
  width: 80px;
}

.searchDiv a img {
  width: 65px;
}

.emailDiv a img {
  width: 41px;
  /* margin: 23px 0 0; */
}

.help-icon.emailDiv.position-absolute.top-50.start-0.translate-middle {
  left: 47% !important;
  top: 70% !important;
}

.help-icon.emailDiv.shareStroyIcon.position-absolute.top-50.start-0.translate-middle {
  left: 30px !important;
  top: 70% !important;
}

.A1.logoposition {
  float: none;
  justify-content: center;
  /* translate(-50%, -50%); */
  /* transform: translateX(15%); */
}

.AB {
  display: flex;
  align-items: center;
}

.header.header-tops {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
}

.A1.logoposition a img {
  width: 210px !important;
}

.AB {
  display: flex;
  align-items: center;
  justify-content: end;
}

span.sLSearch {
  font-size: 13px !important;
  bottom: auto;
  /* font-family: inherit !important; */
  color: #fde9b3;
  top: -13px;
}

span.sLReport {
  font-family: inherit !important;
  font-size: 13px !important;
  color: #fde9b3;
  bottom: -17px;
}

span.sLShare {
  /* font-family: inherit !important; */
  font-size: 13px !important;
  color: #fde9b3;
  bottom: -18px;
}

.headertop121 {
  width: 100%;
  word-break: break-word;
}

.headertop121 h4 {
  text-align: center;
  padding: 17px 0 0;
  font-weight: 600;
  font-size: 22px;
  color: rgb(171 25 0);
  font-family: sanitarium_bbregular;
  letter-spacing: 2px;
}

.headertop121 {
  width: 100%;
  word-break: break-word;
  padding: 0 90px;
  /* overflow-y: scroll; */
  height: 190px;
}

.deteil_htmlparse {
  display: -webkit-box;
  /* -webkit-line-clamp: 6; */
  -webkit-box-orient: vertical;
  /* overflow: hidden; */
  font-size: 16px;
}

.help-icon.content_Boxes1 {
  overflow-y: scroll;
}

.upload__image-wrapper button {
  height: 40px;
  border-color: #e4d36e;
  background: #070707;
  color: #e3b55b;
  padding: 0 10px;
  border-radius: 5px;
  margin: 14px 0;
  font-size: 14px;
}

button.remove {
  background: #a30202;
}

@media only screen and (max-width: 575px) {
  .readastory-modal-dialog-rsb.modal-dialog-rsb .modal-body-rsb {
    padding: 20px;
  }
}

@media (min-width: 576px) {
  .modal-dialog-rsb {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-centered-rsb {
    min-height: calc(100% - 12.5rem) !important;
  }
}

@media screen and(max-width:767px) {
  .page-rsb {
    width: 100% !important;
    height: 100% !important;

    overflow-y: auto;
  }

  .home-top-box-rsb {
    display: block;
    margin-bottom: 10px;
  }

  .bg-welcome-rsb .home-top-box-rsb {
    display: flex;
  }

  .home-logo-bx-rsb {
    text-align: center;
    margin-bottom: 20px;
  }

  .home-middle-top-icon-rsb a {
    margin-left: 8px;
    margin-right: 8px;
    width: 52px;
    height: 52px;
  }

  .home-middle-top-icon-rsb a span {
    width: 41px;
    height: 41px;
  }

  .home-middle-top-icon-rsb a span.questions-span-rsb:after,
  .home-middle-top-icon-rsb a span.questions-span-rsb:before,
  .home-middle-top-icon-rsb a span i {
    width: 30px;
    height: 30px;
  }

  .home-middle-top-icon-rsb a span.questions-span-rsb:before {
    left: 5px;
  }

  .home-middle-top-icon-rsb a:hover span.questions-span-rsb:after {
    right: 5px;
  }

  .readastory-modal-dialog-rsb.modal-dialog-rsb .modal-body-rsb {
    padding: 40px 25px 25px;
  }

  .readastory-modal-dialog-rsb.modal-dialog-rsb.chat-modal-rsb .modal-body-rsb {
    padding-top: 40px;
  }

  .readastory-modal-dialog-rsb.modal-dialog-rsb.write-a-story-modal-rsb .modal-body-rsb .custom-field-heading-rsb {
    margin-bottom: 0;
  }

  .continue-btn-group-rsb .btn-continue-rsb {
    height: 50px;
    width: 50px;
    margin-top: 0;
  }
}

/* @media only screen and (max-width:991px) {
        .readastory-modal-dialog-rsb.modal-dialog-rsb .modal-body-rsb {
        padding-left: 35px;
        padding-right: 35px
      }
} */
/* @media only screen and (max-width : 1000px) {
    .readastory-modal-dialog-rsb.modal-dialog-rsb .modal-content-rsb {
      margin-top: 0px;
    }
  
  
  } */

@media screen and (max-width: 1050px) and (min-width: 601px) {
  .readastory-modal-dialog-rsb.modal-dialog-rsb .modal-content-rsb {
    /* margin-top: 0px; */
  }
}

@media screen and (min-width: 1024px) and (max-height: 768px) {
  .readastory-modal-dialog-rsb.modal-dialog-rsb .modal-body-rsb {
    max-height: 450px;
    overflow: auto;
  }

  .readastory-modal-dialog-rsb.modal-dialog-rsb .modal-body-rsb::-webkit-scrollbar {
    width: 5px;
  }

  .readastory-modal-dialog-rsb.modal-dialog-rsb .modal-body-rsb::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 2px rgb(255, 255, 0, 30%);
    background: linear-gradient(180deg,
        #ffe570,
        #fffe99 9.18%,
        #bb9432 25.31%,
        #efd269 48.35%,
        #c09937 69.22%,
        #9e781a 83.69%,
        #f1d361);
  }

  .readastory-modal-dialog-rsb.modal-dialog-rsb .modal-body-rsb::-webkit-scrollbar-track {
    background: linear-gradient(180deg, #4e4e4e, #000);
    border-radius: 5px;
  }

  .continue-btn-group-rsb {
    margin-bottom: 0px;
    margin-top: 0.5%;
  }

  .continue-btn-group-rsb .btn-continue-rsb {
    height: 40px !important;
    width: 40px !important;
  }
}

@media screen and (max-width: 1200px) {
  .bg-read-a-story-rsb {
    background-size: 1200px;
  }

  .readastory-modal-dialog-rsb.modal-dialog-rsb {
    max-width: 940px;
  }
}

@media screen and (max-width: 1400px) {
  .home-logo-bx-rsb a .large-logo-rsb {
    display: none;
    max-height: 90px;
  }

  .home-logo-bx-rsb a .small-logo-rsb {
    display: block;
    max-height: 90px;
  }

  .readastory-modal-dialog-rsb .form-group-rsb {
    margin-bottom: 8px;
  }

  .readastory-modal-dialog-rsb.modal-dialog-rsb .modal-body-rsb {
    max-height: 350px !important;
    overflow: auto;
  }
}

@media screen and (min-width: 1401px) {
  .readastory-modal-dialog-rsb.modal-dialog-rsb .modal-body-rsb {
    max-height: 350px !important;
    height: 500px;
    overflow: auto;
  }
}

@media screen and (max-width: 1680px) {
  .home-logo-bx-rsb a {
    max-width: 250px;
    max-height: 90px;
  }

  .continue-btn-group-rsb .btn-continue {
    width: 50px !important;
    height: 50px !important;
  }

  .continue-btn-group-rsb .btn-continue {
    height: 50px;
    width: 50px;
  }

  .continue-btn-group-rsb {
    margin-bottom: 10px;
    margin-top: 0;
  }
}

@media screen and (max-width: 2000px) {
  .bg-read-a-story-rsb .input-custom-field-2-rsb .form-control {
    height: 68px;
  }
}

.overflow-box {
  overflow: inherit !important;
}

.help-icon.emailDiv.shareStroyIcon {
  bottom: 0 !important;
  margin: 0px !important;
}

/* .storyPara-rsb p img{
  height: 110px;
  width: auto !important;
  bottom: 6px !important;
  left: -51%;
  transform: translate(-50%, 0px);
  position: absolute;
} */

.img-post-view {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.help-icon.searchIcon {
  top: -55px !important;
  left: -7px !important;
}

.help-icon.shareIcons {
  bottom: -52px !important;
  left: -18px !important;
}

.help-icon.ReportIcons {
  right: -23px !important;
  bottom: -50px !important;
}

.closeBtn.closeBtns1 {
  right: -27px;
  top: -27px;
}

/* img.myclass {
  position: absolute;
  left: -62%;
  width: 100px;
} */

/* .postdetails.pus-titles {
  padding: 90px 0 0;
} */

/* .postdetails.pus-titles {
  padding: 20px 0 0;
  text-align: center;
  width: 100%;
} */

.coverpage_readstory img {
  height: 100px !important;
  width: auto !important;
  top: 20px !important;
  left: 50%;
  transform: translate(-50%, 0px);
  position: absolute;
}

.the_end_cover {
  text-align: center;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  p.fixed_foot_text {
    font-size: 11px;
    text-align: center;
  }

  .E1 .E1S {
    margin-bottom: 17px !important;
  }

  .E1.E1S {
    justify-content: center;
    gap: 24px;
    margin-bottom: 19px;
  }

  .headertop121 {
    padding: 0 10px;
  }

  .E1.E1S {
    justify-content: space-around !important;
    gap: 50px;
    padding: 0 20px;
  }

  .modal-content-rsb.bookstory_book {
    width: calc(100% - 80px) !important;
  }

  img.position-absolute.top-0.start-50.translate-middle-x.top_preve_story {
    position: absolute;
    left: -40px !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

  img.position-absolute.bottom-0.start-50.translate-middle-x {
    right: -40px !important;
    position: absolute !important;
    bottom: auto !important;
    left: auto !important;
    transform: translateY(-50%) !important;
    top: 50% !important;
  }

  .emailDiv a img {
    width: 34px;
  }

  .help-icon.searchIcon {
    top: -48px !important;
    left: -6px !important;
  }

  .help-icon.ReportIcons.emailDiv {
    right: -26px !important;
    bottom: -125px !important;
  }

  .help-icon.shareIcons.emailDiv {
    bottom: -125px !important;
    left: -26px !important;
  }

  .postdetails.pus-titles .storydetails {
    display: block;
  }

  .D3.footer_icons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100vw;
    margin: 10px 0 0;
    height: inherit;
  }

  /* .D2.read {
    height: calc(100vh - 430px);
} */

  .stf__item.--right.--hard.--simple {
    height: 100% !important;
    top: 0 !important;
    bottom: 0 !important;
    width: 100% !important;
    left: 0 !important;
  }

  .D1.DD2 {
    margin: 0 !important;
  }

  .D1.DD2 .E1.E1S {
    margin: 0;
    margin-bottom: 22px !important;
  }

  .demo-book.stf__parent {
    overflow: hidden;
  }

  .help-icon .like-icon-border {
    width: 35px !important;
    height: 35px !important;
  }

  .like-icon-border {
    height: 35px;
    width: 35px;
  }

  .close-button-style-read {
    height: 40px;
    width: 40px;
  }

  .close-button-style-read span {
    height: 32px;
    width: 32px;
  }

  .D2.readstorybook2 {
    height: calc(100vh - 430px) !important;
  }

  .demo-book.read_storybook.stf__parent .postdetails.pus-titles {
    padding: 0 0 0;
    text-align: center;
    width: 100%;
  }

  .demo-book.read_storybook.stf__parent .postdetails.pus-titles .postcount {
    font-size: 22px;
    margin: 0 0 8px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 575px) {
  p.fixed_foot_text {
    font-size: 11px;
    text-align: center;
  }

  .E1 .E1S {
    margin-bottom: 22px !important;
  }

  .E1.E1S {
    justify-content: center;
    gap: 24px;
    margin-bottom: 19px;
  }

  .headertop121 {
    padding: 0 10px;
  }

  .E1.E1S {
    justify-content: space-around !important;
    gap: 50px;
    padding: 0 20px;
  }

  .modal-content-rsb.bookstory_book {
    width: calc(100% - 80px) !important;
  }

  img.position-absolute.top-0.start-50.translate-middle-x.top_preve_story {
    position: absolute;
    left: -40px !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

  img.position-absolute.bottom-0.start-50.translate-middle-x {
    right: -40px !important;
    position: absolute !important;
    bottom: auto !important;
    left: auto !important;
    transform: translateY(-50%) !important;
    top: 50% !important;
  }

  .emailDiv a img {
    width: 34px;
  }

  .help-icon.searchIcon {
    top: -48px !important;
    left: -6px !important;
  }

  .help-icon.ReportIcons.emailDiv {
    right: 4px !important;
    bottom: -125px !important;
  }

  .help-icon.shareIcons.emailDiv {
    bottom: -125px !important;
    left: -1px !important;
  }

  .postdetails.pus-titles .storydetails {
    display: block;
  }

  .D3.footer_icons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100vw;
    margin: 10px 0 0;
    height: inherit;
  }

  /* .D2.read {
    height: calc(100vh - 430px);
} */

  .stf__item.--right.--hard.--simple {
    height: 100% !important;
    top: 0 !important;
    bottom: 0 !important;
    width: 100% !important;
    left: 0 !important;
  }

  span.sLReport {
    font-size: 11px !important;
  }

  .D1.DD2 {
    margin: 0 !important;
  }

  .D1.DD2 .E1.E1S {
    margin: 0;
    height: auto;
  }

  .demo-book.stf__parent {
    overflow: hidden;
  }

  .help-icon .like-icon-border {
    width: 35px !important;
    height: 35px !important;
  }

  .like-icon-border {
    height: 35px;
    width: 35px;
  }

  .close-button-style-read {
    height: 40px;
    width: 40px;
  }

  .close-button-style-read span {
    height: 32px;
    width: 32px;
  }

  .D2.readstorybook2 {
    height: calc(100vh - 370px) !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  p.fixed_foot_text {
    font-size: 11px;
    text-align: center;
  }

  .E1 .E1S {
    margin-bottom: 11px !important;
  }

  .E1.E1S {
    justify-content: center;
    gap: 24px;
    margin-bottom: 19px;
  }

  .headertop121 {
    padding: 0 10px;
  }

  .E1.E1S {
    justify-content: space-around !important;
    gap: 50px;
    padding: 0 20px;
  }

  .modal-content-rsb.bookstory_book {
    width: calc(100% - 80px) !important;
  }

  img.position-absolute.top-0.start-50.translate-middle-x.top_preve_story {
    position: absolute;
    left: -40px !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

  img.position-absolute.bottom-0.start-50.translate-middle-x {
    right: -40px !important;
    position: absolute !important;
    bottom: auto !important;
    left: auto !important;
    transform: translateY(-50%) !important;
    top: 50% !important;
  }

  .emailDiv a img {
    width: 34px;
  }

  .help-icon.searchIcon {
    top: -48px !important;
    left: -6px !important;
  }

  .help-icon.ReportIcons.emailDiv {
    right: 4px !important;
    bottom: -125px !important;
  }

  .help-icon.shareIcons.emailDiv {
    bottom: -125px !important;
    left: -1px !important;
  }

  .postdetails.pus-titles .storydetails {
    display: block;
  }

  .D3.footer_icons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100vw;
    margin: 10px 0 0;
    height: inherit;
  }

  /* .D2.read {
    height: calc(100vh - 430px);
} */

  /* .stf__item.--right.--hard.--simple {
  height: 100% !important;
  top: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  left: 0 !important;
} */
  .postdetails.pus-titles {
    padding: 0 0 0;
  }

  span.sLReport {
    font-size: 11px !important;
  }

  .D1.DD2 {
    margin: 0 !important;
  }

  .D1.DD2 .E1.E1S {
    margin: 0;
    height: auto;
  }

  .demo-book.stf__parent {
    overflow: hidden;
  }

  .help-icon .like-icon-border {
    width: 35px !important;
    height: 35px !important;
  }

  .like-icon-border {
    height: 35px;
    width: 35px;
  }

  .close-button-style-read {
    height: 40px;
    width: 40px;
  }

  .close-button-style-read span {
    height: 32px;
    width: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 800px) {
  .readastory-modal-dialog-rsb.modal-dialog-rsb .modal-body-rsb {
    max-height: 450px;
    overflow: auto;
  }

  .readastory-modal-dialog-rsb.modal-dialog-rsb .modal-body-rsb::-webkit-scrollbar {
    width: 5px;
  }

  .readastory-modal-dialog-rsb.modal-dialog-rsb .modal-body-rsb::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 2px rgb(255, 255, 0, 30%);
    background: linear-gradient(180deg,
        #ffe570,
        #fffe99 9.18%,
        #bb9432 25.31%,
        #efd269 48.35%,
        #c09937 69.22%,
        #9e781a 83.69%,
        #f1d361);
  }

  .readastory-modal-dialog-rsb.modal-dialog-rsb .modal-body-rsb::-webkit-scrollbar-track {
    background: linear-gradient(180deg, #4e4e4e, #000);
    border-radius: 5px;
  }

  .continue-btn-group-rsb {
    margin-bottom: 0px;
    margin-top: 0.5%;
  }

  .continue-btn-group-rsb .btn-continue-rsb {
    height: 40px !important;
    width: 40px !important;
  }

  .B2.C.readstorybook_secone_div .C2 {
    flex-direction: column;
  }

  .B2.C.readstorybook_secone_div .E1.E1S {
    flex-direction: row;
    height: auto;
    align-items: center;
    justify-content: space-around;
    width: 100vw;
  }

  .D3.footer_icons {
    display: block;
    height: auto !important;
    width: 100%;
  }

  .B2.C.readstorybook_secone_div .D1.DD2 {
    width: 100%;
  }

  .like-icon-border {
    height: 42px !important;
    width: 42px !important;
  }

  .modal-content-rsb.bookstory_book {
    width: calc(100vw - 90px);
  }

  .help-icon.shareIcons.emailDiv {
    bottom: -100px !important;
    left: -1px !important;
  }

  .help-icon.ReportIcons.emailDiv {
    right: 4px !important;
    bottom: -100px !important;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1024px) {
  .help-icon.shareIcons.emailDiv {
    bottom: -60px !important;
    left: -1px !important;
  }

  .help-icon.ReportIcons.emailDiv {
    right: 4px !important;
    bottom: -60px !important;
  }
}

.page .page-content {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: 85% !important;
  /* justify-content: space-between; */
  width: 100% !important;
  word-break: break-all;
}

.manualpage-rsb.page-cover-rsb {
  overflow: hidden !important;
}

.readstory_loader {
  margin-top: 20%;
  margin-left: 50%;
}

.page-content.page_text {
  word-break: break-word;
  text-align: justify;
  /* text-align: left; */
  font-size: 14px;
}

.position-absolute.bottom-0.end-0.right_arrow_new {
  right: -15px !important;
  bottom: -20px !important;
}

img.position-absolute.bottom-0.start-0.translate-middle.left_prev_btns {
  z-index: 3;
  left: 24px !important;
  bottom: -20px !important;
}

.top_preve_story {
  z-index: 1;
}

.demo-book {
  width: 100% !important;
}

.manualpage-rsb.page-cover-rsb.overflow-box.stf__item.--hard.--simple.--left {
  left: 0px !important;
}

.page.page-cover {
  height: 100% !important;
  top: 74px;
  /* bottom: 32px !important; */
}

/* @media screen and (max-width: 1050px) and (min-width: 601px) {
  .readastory-modal-dialog-rsb.modal-dialog-rsb .modal-content-rsb {
   
  }

  .manualpage-rsb .page-cover-rsb .overflow-box .stf__item {
    top: 0px !important;
  
  }
} */

@media only screen and (max-width: 1024px) {
  p.fixed_foot_text {
    font-size: 11px;
    text-align: center;
  }

  .headertop121 {
    padding: 0 10px;
  }

  .E1.E1S {
    justify-content: center;
    gap: 24px;
  }

  .emailDiv a img {
    width: 34px;
  }

  .help-icon.searchIcon {
    top: -48px !important;
    left: -6px !important;
  }

  .help-icon.shareIcons {
    bottom: -39px !important;
    left: -1px !important;
  }

  .help-icon.ReportIcons {
    right: 4px !important;
    bottom: -38px !important;
  }

  .postdetails.pus-titles .storydetails {
    display: block;
  }

  .stf__block {
    position: absolute;
    width: 100%;
    /* height: 77%; */
    height: 100%;
    box-sizing: border-box;
    perspective: 2000px;
  }
}

.book_end_page {
  vertical-align: middle !important;
}

.modal-dialog.modal-dialog-centered.contact-modal-dialog.modal_open_share {
  height: calc(100vh - 0px);
  margin: auto !important;
  /* display: inherit; */
}

.modalbody {
  width: 100%;
}

.modalbody .type-btngroup {
  margin: 20px 0 0 !important;
}

.modalbody .contact-body-in {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 180px;
}

form.share_submit input {
  height: 45px;
  border: solid 1px #e3cf6b;
  padding: 0 20px;
  border-radius: 5px;
  margin: 0 10px 0 0;
  width: 100%;
}

.modalbody .contact-checkbox-group {
  width: 100%;
}

form.share_submit button {
  height: 45px;
  padding: 0 20px;
  border: none;
  border-radius: 5px;
  color: #fff;
  background: #000;
}

form.share_submit {
  width: 100%;
  display: flex;
}

@media only screen and (max-width: 460px) {
  .container-read {
    /* background-color: yellow; */
    display: flex;
    flex-direction: column;
    height: calc(100vh - 50px);
    padding: 0;
    margin: 0;
    padding-left: 0rem;
    padding-right: 0rem;
    overflow: hidden;
    /* background      : url('../../assets/images/read-a-story-bg.png'); */
    background: url("../../assets/images/background_images/blue-web.webp") !important;
    background-size: cover;
    background-repeat: no-repeat;
  }

  #readstorybookadd {
    overflow: hidden;
    /* background      : url('../../assets/images/read-a-story-bg.png'); */
    /* background      : url('../../assets/images/background_images//gold-web.webp') !important; */
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.demo-book.read_storybook {
  min-height: auto !important;
  width: 100% !important;
}

.middle.middle_readstory.readstorybookwbs .B2.C.readstorybook_secone_div {
  justify-content: flex-start;
}

.text_content_pages .page-content.page_text {
  height: 100% !important;
  /* display: inline-block; */
  margin: 0;
  align-items: center;
}

.text_content_pages .page.page-cover.page-borders {
  padding: 0 25px 0 10px;
  height: 100%;
  top: 80px !important;
}

.text_content_pages .page-content.page_text p {
  margin-top: 0px;
}

.ShowingID {
  position: absolute;
  bottom: 15px;
  margin: 0;
  color: #000;
  font-weight: 600;
}

.add__banner {
  height: 100%;
  float: left;
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
}

.add__banner p {
  margin: 0px;
  text-align: center;
  width: 100%;
}

.add__banner p img {
  height: auto;
  width: calc(100% - 20%) !important;
  margin: 0 auto;
  max-height: 200px;
  /* align-self: center !important; */
  /* text-align: center !important; */
  height: 150px;
}

.A1.logoposition {
  z-index: 1;
}

.deform_img {
  text-align: center;
  width: 100%;
  display: inline-block;
  margin-top: 10px;
}

.deform_img img {
  object-fit: cover;
}