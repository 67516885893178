.cardparent-list.chatmodal1 {
      flex-direction: column;
}

.cardparent-list.chat-modul {
      flex-direction: column;
}

.chat-modul {
      max-height: 197px !important;
}

button.cardTitles2 {
      background: #000;
      color: #fff;
      border: solid 2px #766d44;
      font-size: 14px;
      padding: 4px 0;
      background-image: linear-gradient(to right, #9f8746, #40321e);
      position: relative;
      z-index: 333;
}

.languageOnModal {}