.main-list {
  width: 100%;
  height: 100%;
}

.welcome-body-wrapper-list {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100%;
  background-color: #000;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top
}

.home-body-container-list h3 {
  -webkit-text-stroke: 1px #00000070;
  /* font-family: Poppins, sans-serif !important; */
}

.bg-read-a-story-list {
  background-size: 100% !important;
  background-repeat: no-repeat !important;
  background-position: top !important
}

.bg-read-a-story-list .home-middle-top-icon-list a span {
  background: linear-gradient(180deg, #192d64, #0d1734), linear-gradient(180deg, #3f4556, #242833)
}

.bg-read-a-story-list .home-middle-top-icon-list a:hover span {
  background: linear-gradient(0deg, #192d64, #0d1734), linear-gradient(0deg, #3f4556, #242833)
}

.bg-read-a-story-list .input-custom-field-2-list .form-control {
  height: 44px
}

.home-top-box-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 20px 5px;
  width: 100%;
  margin: 0 auto
}

.bg-welcome-list .home-top-box-list {
  padding-top: 10px
}

.home-logo-bx-list a {
  display: inline-block
}

.home-logo-bx-list a .small-logo-list {
  display: none
}

.home-logo-bx-list a .large-logo-list {
  display: block;
  height: 90px;
}

.img-responsive-list {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;


  -ms-interpolation-mode: nearest-neighbor
}

.home-middle-top-icon-list a {
  display: flex;
  justify-content: center;
  align-items: center
}

.home-middle-top-icon-list a {
  margin: 0 15px;
  width: 60px;
  height: 60px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/header-icons-frame.svg");
  background-size: contain;
  position: relative
}

.home-middle-top-icon-list a:active {
  top: 3px
}

.home-middle-top-icon-list a span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px
}

.home-middle-top-icon-list a span i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain
}

.home-middle-top-icon-list a span i.settings-icon-list {
  background-image: url("../../assets/images/header-setting-icon.svg")
}

.home-middle-top-icon-list a:not(:hover) span i.settings-icon-list {
  -webkit-animation: none;
  animation: none
}

.home-middle-top-icon-list a span i.pay-per-click-icon-list {
  background-image: url("../../assets/images/header-ppc-icon.svg");
  -webkit-animation: zoom-in-zoom-out 1s ease-out infinite;
  animation: zoom-in-zoom-out 1s ease-out infinite
}

.home-middle-top-icon-list a:not(:hover) span i.pay-per-click-icon-list {
  -webkit-animation: none;
  animation: none
}

.home-middle-top-icon-list a span i.questions-icon-list {
  background-image: url("../../assets/images/header-questions-icon.svg")
}

.home-middle-top-icon-list a span.questions-span-list {
  position: relative;
  overflow: hidden
}

.home-middle-top-icon-list a span.questions-span-list:after,
.home-middle-top-icon-list a span.questions-span-list:before {
  content: "";
  height: 35px;
  width: 35px;
  display: inline-block;
  background-image: url("../../assets/images/header-questions-icon.svg");
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: .5s
}

.home-middle-top-icon-list a span.questions-span-list:before {
  left: 8px;
  opacity: 1
}

.home-middle-top-icon-list a:hover span.questions-span-list:before {
  opacity: 0;
  left: -44px
}

.home-middle-top-icon-list a span.questions-span-list:after {
  background-image: url("../../assets/images/header-questions-icon.svg");
  opacity: 0;
  right: -44px
}

.home-middle-top-icon-list a:hover span.questions-span-list:after {
  opacity: 1;
  right: 8px
}

.modal-dialog-list {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: visible !important;
}

.modal-list.show-list .modal-dialog-list {
  transform: translate(0)
}

.modal-list.fade-list .modal-dialog-list {
  transition: transform .3s ease-out;
  transform: translateY(-25%)
}

.modal-dialog-list {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none
}

.modal-dialog-centered-list {
  display: flex;
  align-items: center;
  min-height: calc(100% - 12.5rem) !important;
}

.readastory-modal-dialog-list.modal-dialog-list .modal-body-list {
  padding: 15px 50px 15px
}

.readastory-modal-dialog-list.modal-dialog-list .modal-content-list {
  background: linear-gradient(90deg, #d39339 0, #ecca70 15%, #faf8bd 25%, #daa74c 51%, #cf8c31 65%, #da8b2c 75%, #d48b2c 90%, #ce842b);
  border: 0;
  height: 45vh !important;
  width: 100%;
  border-radius: 0;
  position: relative;
  box-shadow: 0 8px 17px rgb(0, 0, 0, 25%);
  padding-bottom: 100px;
}

.readastory-modal-dialog-list.modal-dialog-list.write-a-story-modal-list .modal-body-list {
  padding-top: 15px
}

.readastory-modal-dialog-list.modal-dialog-list.write-a-story-modal-list .modal-body-list .custom-field-heading-list {
  margin-bottom: 15px
}

.bg-chatroom-list .readastory-modal-dialog-list.modal-dialog-list {
  margin-top: 10px
}

.readastory-modal-dialog-list .close-button-style-list:active {
  margin-top: 2px;
  background-color: transparent
}

.readastory-modal-dialog-list.modal-dialog-list .modal-body-list::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 2px rgb(255, 255, 0, 30%);
  background: linear-gradient(180deg, #ffe570, #fffe99 9.18%, #bb9432 25.31%, #efd269 48.35%, #c09937 69.22%, #9e781a 83.69%, #f1d361);
}

.readastory-modal-dialog-list.modal-dialog-list .modal-body-list::-webkit-scrollbar-track {
  background: linear-gradient(180deg, #4e4e4e, #000);
  border-radius: 5px;
}

.readastory-modal-dialog-list.modal-dialog-list .modal-body-list::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 2px rgb(255, 255, 0, 30%);
  background: linear-gradient(180deg, #ffe570, #fffe99 9.18%, #bb9432 25.31%, #efd269 48.35%, #c09937 69.22%, #9e781a 83.69%, #f1d361);
}

.border-style-8-list {
  border-radius: 5px;
  padding: 8px !important;
  background: linear-gradient(180deg, #ffe570, #fffb95 13.74%, #be9a38 26.34%, #ebce65 47.87%, #b38a29 67.57%, #421800 78.56%, #b48a28 92.99%, #fce06b 101.93%)
}

.modal-align-list {
  margin: auto !important;
}

.modal-content-list {
  display: flex;
  justify-content: space-around;
}

.parentbody-list {
  justify-content: space-evenly;
  overflow-y: scroll;
}

.storylist-list {
  display: flex;
  flex-wrap: wrap;
  height: 150px;
  justify-content: space-evenly;
  margin-top: 1%;
}

.cardparent-list {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  background: linear-gradient(180deg, #ffe570, #fffb95 13.74%, #be9a38 26.34%, #ebce65 47.87%, #b38a29 67.57%, #421800 78.56%, #b48a28 92.99%, #fce06b 101.93%);
  height: 100%;
  padding: 5px;
  max-width: 170px;
  min-width: 150px;
  max-height: 170px;
  border-radius: 0.25rem;
  cursor: pointer;
  margin-bottom: 2%;
}

.card-start-list {
  background: linear-gradient(90deg, #d39339, #ecca70 20%, #faf8bd 50%, #daa74c 80%, #cf8c31);
  height: 97%;
  width: 100%;
  margin: auto;
  word-wrap: break-word;
  -webkit-background-clip: border-box;
  background-clip: border-box;
}

.cardBorder-list {
  height: 100%;
}

.cardData-list {
  height: 50%;
}

.cardTitle-list {
  font-size: 20px;
  align-items: center;
  position: relative;
  height: 50%;
}

.cardTitleImage-list {
  position: relative;
  height: 70px;
  width: auto;
  background: url("../../assets/images/shape-add-ads-top.png");
  background-position: bottom;
  background-size: cover;
}

.cardTitleText-list {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  /* margin-left: 24px;  */
}

.continue-btn-group-list .btn-continue-list {
  width: 50px !important;
  height: 50px !important
}

.continue-btn-group-list {
  text-align: center;
}

.continue-btn-group-list .btn-continue-list {
  height: 50px;
  width: 50px;
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  display: inline-block;
  position: relative
}

.continue-btn-group-list .btn-continue-list:focus {
  outline: 0
}

.continue-btn-group-list .btn-continue-list i {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  background: rgba(0, 0, 0, .2);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px
}

.ads-add-btn-back-arrw.continue-btn-group-list .btn-continue-list {
  height: 50px;
  width: 50px
}

.arrow-bounce-right-list {
  -webkit-animation: bounceRight 2s infinite;
  animation: bounceRight 2s infinite
}

.continue-btn-group-list .btn-continue-list {
  width: 50px !important;
  height: 50px !important
}

.readastory-modal-dialog-list.modal-dialog-list .modal-body-list::-webkit-scrollbar-track {
  background: linear-gradient(180deg, #4e4e4e, #000);
  border-radius: 5px;
}

.readastory-modal-dialog-list.modal-dialog-list .modal-body-list::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 2px rgb(255, 255, 0, 30%);
  background: linear-gradient(180deg, #ffe570, #fffe99 9.18%, #bb9432 25.31%, #efd269 48.35%, #c09937 69.22%, #9e781a 83.69%, #f1d361);
}

.border-style-8-list {
  border-radius: 5px;
  padding: 8px !important;
  background: linear-gradient(180deg, #ffe570, #fffb95 13.74%, #be9a38 26.34%, #ebce65 47.87%, #b38a29 67.57%, #421800 78.56%, #b48a28 92.99%, #fce06b 101.93%)
}

.modal-align-list {
  margin: auto !important;
}

.modal-content-list {
  display: flex;
  justify-content: space-around;
}

.parentbody-list {
  justify-content: space-evenly;
  overflow-y: scroll;
}

.storylist-list {
  display: flex;
  flex-wrap: wrap;
  height: 150px;
  justify-content: space-evenly;
  margin-top: 1%;
}

.cardparent-list {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  background: linear-gradient(180deg, #ffe570, #fffb95 13.74%, #be9a38 26.34%, #ebce65 47.87%, #b38a29 67.57%, #421800 78.56%, #b48a28 92.99%, #fce06b 101.93%);
  height: 100%;
  padding: 5px;
  max-width: 170px;
  min-width: 150px;
  max-height: 170px;
  border-radius: 0.25rem;
  cursor: pointer;
  margin-bottom: 2%;
}

.cardparent-list {
  transition-duration: 2s;
}

.cardparent-list:hover {
  transform: scale(1.2);
  z-index: 0;
}

.card-start-list {
  background: linear-gradient(90deg, #d39339, #ecca70 20%, #faf8bd 50%, #daa74c 80%, #cf8c31);
  height: 97%;
  width: 100%;
  margin: auto;
  word-wrap: break-word;
  -webkit-background-clip: border-box;
  background-clip: border-box;
}

.cardBorder-list {
  height: 100%;
}

.cardData-list {
  height: 50%;
}

.cardTitle-list {
  font-size: 20px;
  align-items: center;
  position: relative;
  height: 50%;
}

.cardTitleImage-list {
  position: relative;
  height: 70px;
  width: auto;
  background: url("../../assets/images/shape-add-ads-top.png");
  background-position: bottom;
  background-size: cover;
}

.cardTitleText-list {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.continue-btn-group-list .btn-continue-list {
  width: 50px !important;
  height: 50px !important
}

.continue-btn-group-list {
  text-align: center;
}

.continue-btn-group-list .btn-continue-list {
  height: 50px;
  width: 50px;
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  display: inline-block;
  position: relative
}

.continue-btn-group-list .btn-continue-list:focus {
  outline: 0
}

.continue-btn-group-list .btn-continue-list i {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 00px;
  background: rgba(0, 0, 0, .2);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px
}

.ads-add-btn-back-arrw.continue-btn-group-list .btn-continue-list {
  height: 50px;
  width: 50px
}

.arrow-bounce-right-list {
  -webkit-animation: bounceRight 2s infinite;
  animation: bounceRight 2s infinite
}

.continue-btn-group-list .btn-continue-list {
  width: 50px !important;
  height: 50px !important
}

.continue-btn-group-list .btn-continue-list {
  height: 50px;
  width: 50px;
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  display: inline-block;
  position: relative
}

.continue-btn-group-list .btn-continue-list:focus {
  outline: 0
}

.continue-btn-group-list .btn-continue-list i {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 40px;
  background: rgba(0, 0, 0, .2);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px
}

.ads-add-btn-back-arrw.continue-btn-group-list .btn-continue-list {
  height: 50px;
  width: 50px
}

.arrow-bounce-right-list {
  -webkit-animation: bounceRight 2s infinite;
  animation: bounceRight 2s infinite
}

@media only screen and (max-width:1680px) {

  .home-logo-bx-list a {
    max-width: 250px;
    max-height: 90px;
  }

  .continue-btn-group-list .btn-continue-list {
    width: 50px !important;
    height: 50px !important
  }

  .continue-btn-group-list .btn-continue-list {
    height: 50px;
    width: 50px
  }
}

@media screen and (min-width: 1401px) {
  .readastory-modal-dialog-list.modal-dialog-list .modal-body-list {
    max-height: 350px !important;
    height: 500px;
    overflow: auto;
  }
}

@media only screen and (max-width:1400px) {
  .home-logo-bx-list a .large-logo-list {
    display: none;
    max-height: 90px;
  }

  .readastory-modal-dialog-list .form-group-list {
    margin-bottom: 8px;
  }

  .home-logo-bx-list a .small-logo-list {
    display: block;
    max-height: 90px;
  }
}

@media screen and (min-width: 1024px) and (max-height: 768px) {
  .readastory-modal-dialog-list.modal-dialog-list .modal-body-list {
    max-height: 450px;
    overflow: auto;
  }

  .continue-btn-group-list {
    margin-bottom: 0px;
  }

  .continue-btn-group-list .btn-continue-list {
    height: 50px !important;
    width: 50px !important;
  }



}

@media screen and (max-width: 1400px) {
  .readastory-modal-dialog-list.modal-dialog-list .modal-body-list {
    max-height: 350px !important;
    overflow: auto;
  }
}

.readastory-modal-dialog-list {
  margin-top: 0;
  margin-bottom: 0;
}

@media only screen and (min-width:1200px) {
  .readastory-modal-dialog-list.modal-dialog-list {
    max-width: 940px;
    height: 87%;
  }
}

@media only screen and (max-width:1200px) {
  .bg-read-a-story-list {
    background-size: 1200px
  }

  .modal-dialog-list {
    max-width: 700px
  }
}

@media (min-width:2000px) {
  .bg-read-a-story-list .input-custom-field-2-list .form-control {
    height: 68px
  }
}

@media only screen and (max-width:991px) {
  .modal-dialog-list {
    max-width: 95%;
    margin: auto
  }

  .readastory-modal-dialog-list.modal-dialog-list .modal-body-list {
    padding-left: 35px;
    padding-right: 35px
  }
}

@media screen and (min-width: 1024px) and (max-height: 768px) {
  .readastory-modal-dialog-list.modal-dialog-list .modal-body-list {
    max-height: 410px;
    overflow: auto;
  }


}

@media screen and (max-width: 1400px) {
  .readastory-modal-dialog-list.modal-dialog-list .modal-body-list {
    max-height: 345px !important;
    overflow: auto;
  }
}

@media screen and (min-width: 1024px) and (max-height: 768px) {
  .readastory-modal-dialog-list.modal-dialog-list .modal-body-list::-webkit-scrollbar {
    width: 5px;
  }
}

@media screen and (min-width: 1024px) and (max-height: 768px) {
  .readastory-modal-dialog-list.modal-dialog-list .modal-body-list::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 2px rgb(255, 255, 0, 30%);
    background: linear-gradient(180deg, #ffe570, #fffe99 9.18%, #bb9432 25.31%, #efd269 48.35%, #c09937 69.22%, #9e781a 83.69%, #f1d361);
  }
}

@media screen and (min-width: 1024px) and (max-height: 768px) {
  .readastory-modal-dialog-list.modal-dialog-list .modal-body-list::-webkit-scrollbar-track {
    background: linear-gradient(180deg, #4e4e4e, #000);
    border-radius: 5px;
  }
}

@media only screen and (min-width:768px) {
  .readastory-modal-dialog-list.modal-dialog-list {
    max-width: 705px;
    height: calc(108vh - 62vh);
  }

  .cardTitleImage-list {
    width: 100%;

  }
}

@media only screen and(max-width:767px) {

  .home-top-box-list {
    display: block;
    margin-bottom: 10px;
    padding-left: 35px;
    padding-right: 35px
  }

  .bg-welcome-list .home-top-box-list {
    display: flex
  }

  .home-logo-bx-list {
    text-align: center;
    margin-bottom: 20px
  }

  .bg-welcome-list .home-logo-bx-list {
    margin-bottom: 0
  }

  .home-middle-top-icon-list a {
    margin-left: 8px;
    margin-right: 8px;
    width: 52px;
    height: 52px
  }

  .home-middle-top-icon-list a span {
    width: 41px;
    height: 41px
  }

  .home-middle-top-icon-list a span.questions-span-list:after,
  .home-middle-top-icon-list a span.questions-span-list:before,
  .home-middle-top-icon-list a span i {
    width: 30px;
    height: 30px
  }

  .home-middle-top-icon-list a span.questions-span-list:before {
    left: 5px
  }

  .home-middle-top-icon-list a:hover span.questions-span-list:after {
    right: 5px
  }

  .readastory-modal-dialog-list.modal-dialog-list .modal-body-list {
    padding: 40px 25px 25px
  }

  .readastory-modal-dialog-list.modal-dialog-list.chat-modal-list .modal-body-list {
    padding-top: 40px
  }

  .btn-apply {
    width: 76px
  }

  .readastory-modal-dialog-list.modal-dialog-list.write-a-story-modal-list .modal-body-list .custom-field-heading-list {
    margin-bottom: 0
  }

  .continue-btn-group-list .btn-continue-list {
    height: 50px;
    width: 50px;
    margin-top: 0
  }

  .bottom-wrapper-initial-mobile {
    position: fixed;
    bottom: 40px;
    left: 0;
    right: 0;
  }
}

@media (min-width: 576px) {
  .modal-dialog-list {
    max-width: 500px;
    margin: -0.25rem auto;
  }

  .modal-dialog-centered-list {
    min-height: calc(100% - 12.5rem) !important
  }

}


@media only screen and (max-width:575px) {
  .readastory-modal-dialog-list.modal-dialog-list .modal-body-list {
    padding: 20px
  }
}

@media only screen and (max-width : 1000px) {
  .readastory-modal-dialog-list.modal-dialog-list .modal-content-list {
    margin-top: 0px;
  }
}

@media screen and (max-width: 1050px) and (min-width: 601px) {
  .readastory-modal-dialog-list.modal-dialog-list .modal-content-list {
    margin-top: 0px;
  }
}

@media screen and (max-width: 1050px) and (min-width: 601px) {
  .cardTitleImage-list {
    width: 100%;
  }
}

@media only screen and (max-width: 400) {
  .cardTitleImage-list {
    width: 100% !important;
  }
}

@media screen and (max-width: 1000px) {
  .cardTitleImage-list {
    width: 100%;
  }
}




.footer_bar {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 100px;
  left: 0;
}


/* 
.deteil_htmlparse img {
  height: 100px;
  width: auto !important;
  bottom: 60px !important;
  left: 50%;
  transform: translate(-50%, 0px);
  position: absolute;
} */

.headertop121 .deteil_htmlparse {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 16px;
}

.help-icon.content_Boxes1 {
  width: 100%;
}

.likeCount1.like_Content2 {
  display: block;
  text-align: center;
}

.coverpage_storylist2 {
  height: 100px;
  width: auto !important;
  bottom: 27px !important;
  left: 50%;
  transform: translate(-50%, 0px);
  position: absolute;
}

.coverpage_storylist22 img {
  height: 90px !important;
  width: 120px !important;
  /* height: auto !important; */
  /* bottom: 20px !important; */
  bottom: 50px !important;
  left: 50%;
  transform: translate(-50%, 0px);
  position: absolute;
}

.welcome_item_book {
  bottom: 14px !important;
}

.storylist_loader {
  margin-top: 17%;
}


.help-icon.content_Boxes1 {
  overflow-y: scroll;
  /* height: 200px; */
}

button.btn-btn-danger {
  padding: 8px 20px;
  border: none;
  background: #000000;
  border-radius: 5px;
  color: #fff;
}

.storylist_bool_img {
  width: 46px !important;
}

/* .individual_stories:hover {
  background-color: #d99a4d;
  border-radius: 10px;
  margin: 4px;
} */

.devider_line {
  height: 3px;
}



.shareIconss a {
  gap: 0 !important;
}

.shareIconss .sLShare {
  position: unset;
}

.ReportIconss .sLReport {
  position: unset;
}

.ReportIconss a {
  gap: 0 !important;
}

.help-icon.content_Boxes1::-webkit-scrollbar {
  width: 15px !important;
  background-color: #eee !important;
}

p.fixed_foot_text {
  z-index: 3;
  color: #68edd9;
}

#DisclaimerModal {
  background-color: rgb(0, 0, 0, 0.8);
  position: fixed;
  height: 106vh !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  display: none;
  outline: 0;
}

.likeshare_icon {
  display: grid;
  justify-content: space-around;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
}

.read_bookIcon span {
  width: 100%;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  color: red;
  font-family: unset;
}

img.icon_size {
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.likeshare_icon .sLShare {
  color: blue;
  font-weight: bold;
  -webkit-text-stroke: 1px #00000000;
  text-shadow: 0 0 black;
  font-size: 14px;
}

.likeshare_icon .sLReport {
  color: blue;
  font-weight: bold;
  -webkit-text-stroke: 1px #00000000;
  text-shadow: 0 0 black;
  font-size: 14px;
}

/* 16-01-2023 */


@media only screen and (min-width : 768px) and (max-width : 992px) {
  .B2.cards.search_readstory {
    width: calc(100vw - 0px) !important;
  }

  .B2.cards.search_readstory .modal-dialog {
    max-width: 100vw !important;
  }
}

@media only screen and (min-width : 993px) and (max-width : 1024px) {
  .B2.cards.search_readstory {
    width: calc(100vw - 0px) !important;
  }

  .B2.cards.search_readstory .modal-dialog {
    max-width: 100vw !important;
  }
}

@media only screen and (min-width : 320px) and (max-width : 567px) {
  .post_reviewed {
    font-size: 12px;
    line-height: 16px;
  }

  .B2.C.readstorybook_secone_div {
    bottom: auto;
  }

  .B2.cards.list_cards {
    margin: 0 !important;
    display: inline-block;
  }

  .continue-btn-group-list.continue-btn-group-list-story-list.button-position-list {
    margin: 20px 0 0;
    position: unset;
  }
}


/* new */


.middle.mobile_middle .B3 {
  height: auto;
}

.middle.mobile_middle .B2 {
  height: auto;
}

.middle.mobile_middle {
  justify-content: center;
}

.home-img-div {
  margin: auto;
}

.post_reviewed {
  color: #0099F6;
}

.bottom-wrapper-initial-mobile {
  position: relative;
  bottom: 40px;
  left: 0;
  right: 0;
}

.toast-success {
  background-color: green !important;
  opacity: 0.9 !important;
  box-shadow: none !important;
}

.toast-error {
  background-color: red !important;
  opacity: 0.9 !important;
  box-shadow: none !important;
}