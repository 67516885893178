.close-button-style-read {
  height: 50px;
  width: 50px;
  border: 0;
  cursor: pointer;
  display: block;
  position: absolute;
  /* right: -25px; */
  right: -16px;
  top: -25px;
  border-radius: 50%;
  padding: 4px;
  z-index: 3;
  background: linear-gradient(180deg,
      #ffe773,
      #fffa93 12.81%,
      #c09b37 31.25%,
      #e7c75f 56.25%,
      #512704 82.72%,
      #efd160);
  box-shadow: 0 4px 4px rgb(0 0 0, 68%);
}

.close-button-style-read span {
  background: radial-gradient(50% 50% at 50% 50%, #f31f1f 0, #270505 100%);
  border-radius: 50%;
  height: 42px;
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.close-button-style-read span:before {
  content: "";
  height: 26px;
  width: 26px;
  display: inline-block;
  background: url("../../assets/images/cross-icon-gradient.svg") 50% no-repeat;
  background-size: contain;
  position: absolute;
  opacity: 1;
  transition: 0.5s;
}

.close-button-style-read span:after {
  content: "";
  height: 25px;
  width: 25px;
  display: inline-block;
  background: url("../../assets/images/back-arrow-gradient-small.png") 50% no-repeat;
  background-size: contain;
  position: absolute;
  opacity: 0;
  top: 10px;
  right: -20px;
  transition: 0.5s;
}

.check {
  font-size: 16px;
}

.main {
  width: 100%;
  height: 100%;
}

.main_container_home_read {
  background-image: linear-gradient(89.94deg,
      #4b4251 1.56%,
      #5e6174 7.01%,
      #5f6276 12.37%,
      #707378 18.07%,
      #85afc4 26.74%,
      #9fd0e7 39.42%,
      #6a757f 49.94%,
      #5a5961 60.29%,
      #626a7f 64.62%,
      #59525a 74.69%,
      #5a5d66 81.31%,
      #6d9ba9 90.15%,
      #666669 95.71%,
      #55505c 99.14%);
  min-height: 100%;
  background-size: 100% !important;
  background-repeat: no-repeat !important;
  background-position: top !important;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.main_logo_home {
  height: 130px;
}

.logo_container_home {
  display: flex;
  justify-content: left;
  top: 3%;
  left: 1%;
}

.header_icon_container {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 40%;
  top: 5%;
}

.header_icon_settings {
  margin: 0 15px;
  width: 56px;
  height: 56px;

  background-position: 50%;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/header-icons-frame.svg");
  background-size: contain;
  position: relative;
}

.settingicon {
  align-items: center;
  margin: 6px 6px 6px 6px;
  width: 44px;
  height: 44px;

  background-repeat: no-repeat;
  background-image: url("../../assets/images/header-setting-icon.svg");
}

.settingicon:hover {
  -webkit-animation: spin 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.header_icon_ppc {
  margin: 0 15px;
  width: 56px;
  height: 56px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/header-icons-frame.svg");
  background-size: contain;
  position: relative;
}

.ppcicon {
  align-items: center;
  margin: 6px 6px 6px 6px;
  width: 44px;
  height: 44px;

  background-repeat: no-repeat;
  background-image: url("../../assets/images/header-ppc-icon.svg");
}

.ppcicon:hover {
  animation: zoom-in-zoom-out 1s ease infinite;
}

.header_icon_question {
  margin: 0 15px;
  width: 56px;
  height: 56px;

  background-position: 50%;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/header-icons-frame.svg");
  background-size: contain;
  position: relative;
}

.question {
  align-items: center;
  margin: 6px 6px 6px 6px;
  width: 44px;
  height: 44px;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/header-questions-icon.svg");
}

.question:hover {
  align-items: center;
  margin: 6px 6px 6px 6px;
  height: 44px;
  width: 44px;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/header-questions-icon.svg");
  width: 44px;
  animation: bounceRight 1s ease infinite;
}

.ads-wraper {
  border-radius: 5px;
  padding: 8px;
  max-width: 946px;
  max-height: 400px;
  margin: 0 auto;
  background: linear-gradient(180deg,
      #ffe570,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #421800 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%);
}

.ads-content {
  background: rgb(225, 215, 215);
  background: -moz-linear-gradient(left,
      rgba(225, 215, 215, 1) 0%,
      rgba(255, 251, 251, 1) 50%,
      rgba(209, 207, 207, 1) 75%,
      rgba(119, 118, 118, 1) 100%);
  background: -webkit-linear-gradient(left,
      rgba(225, 215, 215, 1) 0%,
      rgba(255, 251, 251, 1) 50%,
      rgba(209, 207, 207, 1) 75%,
      rgba(119, 118, 118, 1) 100%);
  background: linear-gradient(to right,
      rgba(225, 215, 215, 1) 0%,
      rgba(255, 251, 251, 1) 50%,
      rgba(209, 207, 207, 1) 75%,
      rgba(119, 118, 118, 1) 100%);
  padding: 17px 34px;
}

.ads-col-row {
  margin-left: -14px;
  margin-right: -14px;
  display: flex;
}

.ads-col-50 {
  position: relative;
  padding-left: 14px;
  padding-right: 14px;

  width: 30vw;
  margin-bottom: 10px;
}

.ads-item-box {
  border-radius: 5px;
  padding: 8px;
  background: linear-gradient(180deg,
      #ffe570,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #421800 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%);
}

.ads-add-box {
  background: #d39339;
  background: -moz-linear-gradient(left,
      #d39339 0%,
      #ecca70 20%,
      #faf8bd 50%,
      #daa74c 80%,
      #cf8c31 100%);
  background: -webkit-linear-gradient(left,
      #d39339 0%,
      #ecca70 20%,
      #faf8bd 50%,
      #daa74c 80%,
      #cf8c31 100%);
  background: linear-gradient(to right,
      #d39339 0%,
      #ecca70 20%,
      #faf8bd 50%,
      #daa74c 80%,
      #cf8c31 100%);
  box-shadow: 0px 8px 17px rgb(0, 0, 0, 25%);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  max-height: 270px;
}

.ads-top-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 128px;
  position: relative;
  margin-bottom: -40px;
}

.ads-bottom-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 18vh;
  padding-top: 50px;
  background: url("../../assets/images/shape-add-ads-top.png") center top no-repeat;
}

.ads-top-box h5 {
  font-weight: 700;
  font-size: 22px;
  text-transform: uppercase;
  color: #041241;
  font-family: "droid_serifbold";
  margin-top: 20px;
  margin-bottom: 20px;
}

.ads-banner-box {
  border-radius: 5px;
  padding: 8px;
  background: linear-gradient(180deg,
      #ffe570 0%,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #421800 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%);
}

.ads-banner-box-in {
  background: linear-gradient(90.06deg,
      #6b0101 0.04%,
      #d10606 8.1%,
      #5c0000 50.18%,
      #d10606 94.35%,
      #6b0101 99.95%),
    linear-gradient(0deg, #ffffff, #ffffff);
  text-align: center;
}

.ads-banner-info {
  padding: 16px;
}

.storyPara {
  font-size: 12px;
}

#popupAds2 {
  position: fixed;
  width: 100%;
  height: 100%;

  z-index: 99999999;

  opacity: 1;
  transition: 0.5s;

  display: flex;
  justify-content: center;
  vertical-align: middle;
  transition: all 0.5s ease-in-out;
  animation: pop-swirl 1s ease forwards;
  visibility: visible;
  z-index: 200;

  opacity: 1;
}

.modal-dialog1 {
  border-radius: 8px;
  width: 100%;
  max-width: 978px;
  transform: translateY(10%);
  max-height: 600px;
  border: 5px solid #512704;
}

.modal-analytics {
  border-radius: 5px;
  width: calc(100% - 30px);
  padding: 8px;
  background: linear-gradient(180deg,
      #ffe570,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #421800 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%);
}

.modal-finance-inner {
  background: linear-gradient(90deg,
      #050c39 4.32%,
      #04145c 22.71%,
      #0d2ca0 32.64%,
      #1747e9 46.64%,
      #071963 71%,
      #040d41 85.26%,
      #020a37);
  padding: 15px 25px;
  height: 100%;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(0.75, 0.75);
  }

  100% {
    transform: scale(1, 1);
  }
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.exit_icon {
  height: 56px;
  width: 56px;

  background-position: 50%;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/header-icons-frame.svg");
  background-size: contain;
  position: relative;
  position: absolute;

  float: right;
}

.exit_background {
  background: linear-gradient(0deg,
      rgb(55, 0, 0) -0.33%,
      red 99.96%) !important;
  height: 44px;
  width: 44px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;

  float: right;
  top: 10%;
  left: -9%;
}

.exit_background:hover {
  background: linear-gradient(0deg,
      red -0.33%,
      rgb(55, 0, 0) 99.96%) !important;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.exit {
  align-items: center;
  height: 40px;
  width: 40px;
  margin-left: 2px;
  margin-top: 3px;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/header-exit-icon.svg");
}

.exit:hover {
  align-items: center;
  height: 40px;
  width: 40px;
  margin-left: 2px;
  margin-top: 3px;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/header-exit-icon.svg");
  animation: exit 2s 2;
  animation-direction: alternate;
}

@keyframes exit {
  0% {
    transform: translateX(-10px);
  }

  25% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(-10px);
  }

  75% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(-10px);
  }
}

@-webkit-keyframes bounceLeft {

  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  40% {
    -webkit-transform: translateX(15px);
    transform: translateX(15px);
  }

  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
}

@-moz-keyframes bounceLeft {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }

  40% {
    transform: translateX(15px);
  }

  60% {
    transform: translateX(10px);
  }
}

@keyframes bounceLeft {

  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  40% {
    -ms-transform: translateX(15px);
    transform: translateX(15px);
  }

  60% {
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }
}

@-webkit-keyframes bounceRight {

  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  40% {
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px);
  }

  60% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
}

@-moz-keyframes bounceRight {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }

  40% {
    transform: translateX(-15px);
  }

  60% {
    transform: translateX(-10px);
  }
}

@keyframes bounceRight {

  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  40% {
    -ms-transform: translateX(-15px);
    transform: translateX(-15px);
  }

  60% {
    -ms-transform: translateX(-10px);
    transform: translateX(-10px);
  }
}

.parentreadstory {
  margin-bottom: 16% !important;
  width: 80%;
  max-width: 940px;
  margin-bottom: 0;

  border-radius: 5px;
  padding: 8px;
  background: linear-gradient(180deg,
      #ffe570,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #421800 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%);
  min-height: calc(100% - 3.5rem);
  margin: 1.75rem auto;
  display: flex;
  align-items: center;
  position: relative;
  width: auto;
  pointer-events: none;
}

.childreadstory {
  background: linear-gradient(89.95deg,
      #b56a29 1.56%,
      #ca872e 7.01%,
      #d08f33 12.37%,
      #d39339 18.07%,
      #ecca70 26.75%,
      #faf8bd 39.42%,
      #daa74c 49.94%,
      #cf8c31 60.3%,
      #da8b2c 64.63%,
      #d48b2c 74.69%,
      #ce842b 81.32%,
      #f0b348 90.16%,
      #d58e34 94.34%,
      #c1752a 99.15%);
  border: 0;
  border-radius: 0;
  position: relative;
  box-shadow: 0 8px 17px rgb(0, 0, 0, 25%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  pointer-events: auto;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  max-height: 400px;
}

.headerreadstory {
  text-align: center;
  height: 5%;
  width: 95%;
}

.select {
  font-family: sanitarium_bbregular;
  color: linear-gradient(180deg,
      #f3d666 0,
      #fffe99 10%,
      #be9a37 20%,
      #eed168 30%,
      #bf9734 70%,
      #a8742e 85%,
      #f7da66);
}

.textfield {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2%;
  margin-left: 5%;
}

.textcolumn {
  display: flex;
  flex-direction: column;
}

.textrow {
  position: relative;
  display: block;
  border: 3px solid #eade79;
  border-radius: 5px;
  margin-bottom: 35px;
  box-sizing: border-box;
}

.text_2 {
  border: 3px solid #eade79;
  background-color: #4d2717;
  margin-bottom: 10px;
  height: 45px;
  border-radius: 6px;
}

.text_1 {
  background-color: #041241;
  height: 39px;
  margin-left: 0.25px;
  margin-top: 3px;
  border-radius: 6px;
  padding: 3px;
}

.text_style_1 {
  color: #fff;
  background-color: #041241;
  border: 0;
  padding-right: 20px;
  padding-left: 20px;
  height: 56px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 300;
}

.bgc {
  background: linear-gradient(180deg,
      #ffe570,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #421800 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%);
  height: 45px;
  position: relative;
  text-align: center;
  border: none;
  border-radius: 6px;
  margin-bottom: 10px;
}

.btn_grad_read {
  width: 54px;
  height: 54px;
  background: linear-gradient(180deg,
      #ffe773,
      #fffa93 12.81%,
      #c09b37 31.25%,
      #e7c75f 56.25%,
      #512704 82.72%,
      #efd160);
  box-shadow: 0 4px 4px rgb(0, 0, 0, 68%);
  border-radius: 50%;
  padding: 4px;
  position: relative;
  border: 0;
  cursor: pointer;
  -webkit-appearance: button;
  margin-bottom: 2%;
}

.btn_grad_read:hover {
  width: 54px;
  height: 54px;
  background: linear-gradient(180deg,
      #efd160,
      #512704 12.81%,
      #e7c75f 31.25%,
      #c09b37 56.25%,
      #fffa93 82.72%,
      #ffe773);
  box-shadow: 0 4px 4px rgb(0, 0, 0, 68%);
  border-radius: 50%;
  padding: 4px;
  position: relative;
  border: 0;
  cursor: pointer;
  -webkit-appearance: button;
  margin-bottom: 2%;
}

.btn_ba_read {
  background: radial-gradient(50% 50% at 50% 50%, #082174 0, #031038 100%);
  width: 46px;
  height: 46px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.btn_ba_read:hover {
  background: radial-gradient(50% 50% at 50% 50%, #0be313 0, #012b03 100%);
  width: 46px;
  height: 46px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.sub_btn_read {
  background-image: url("../../assets/images/tick-icon.svg");
  width: 24px;
  height: 20px;
  opacity: 1;
  content: "";
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  position: absolute;
  top: 5px;
  bottom: 0;
  margin: auto;
}

.sub_btn_read:hover {
  background-image: url("../../assets/images/tick-icon.svg");
  width: 24px;
  height: 20px;
  opacity: 1;
  content: "";
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  position: absolute;
  top: 5px;
  bottom: 0;
  margin: auto;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-name: slideout;
  animation-duration: 0.5s;
  animation-name: slideout;
}

@-webkit-keyframes slideout {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

@keyframes slideout {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

.bgc-select {
  color: #fff;
  background-color: #041241;
  border: 0;
  padding-right: 40px;
  padding-left: 20px;
  height: 40px !important;
  margin-top: 1%;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  width: 98%;
  border-radius: 0.25rem;
  font-weight: 300;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position: center right 20px;
  background-image: url("../../assets/images/arrow-down-icon.svg");
}

.datepicker-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  box-sizing: border-box;
  background-color: #041241;
}

.bgcForeground {
  background-color: #041241;
  height: 90%;
  width: 97%;
  margin-left: 1.5%;
  margin-top: 1%;
  border-radius: 5px;
}

.dateImage {
  background-repeat: no-repeat;
  height: 25px;
  width: 25px;
  margin-left: 84%;
}

.datepicker-input::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.calenderImage {
  margin-top: 40%;
}

.btn_grad1111 {
  height: 52px;
  width: 52px;
  border: 0;
  cursor: pointer;
  display: block;
  position: absolute;
  left: 84%;
  top: 21%;
  border-radius: 50%;
  padding: 4px;
  z-index: 4;
  background: linear-gradient(180deg,
      #ffe773,
      #fffa93 12.81%,
      #c09b37 31.25%,
      #e7c75f 56.25%,
      #512704 82.72%,
      #efd160);
  box-shadow: 0 4px 4px rgb(0, 0, 0, 68%);
  transition: inherit;
}

.btn_ba1111 {
  background: radial-gradient(50% 50% at 50% 50%, #f31f1f 0, #270505 100%);
  border-radius: 50%;
  height: 52px;
  width: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.sub_btn_11111 {
  background-image: url("../../assets/images/cross-icon-gradient.svg");
  display: inline-block;
  height: 30px;
  width: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: 0.5s;
}

.crosscontainerRead {
  width: 66px;
  height: 66px;
  border: 0;
  cursor: pointer;
  background: linear-gradient(180deg,
      #ffe773,
      #fffa93 12.81%,
      #c09b37 31.25%,
      #e7c75f 56.25%,
      #512704 82.72%,
      #efd160);
  box-shadow: 0 4px 4px rgb(0, 0, 0, 68%);
  border-radius: 50%;
  position: relative;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.crossbtn {
  background: radial-gradient(50% 50% at 50% 50%, #f34444 0, #7b0000 100%);
  margin-top: 4%;
  margin-left: 4%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.next_icon_container {
  display: flex;
  justify-content: center;
  margin-bottom: 2%;
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  display: inline-block;
  position: relative;
}

.next_icon {
  height: 50px;
}

.next_icon:hover {
  animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  -moz-animation: bounce 2s infinite;
  -o-animation: bounce 2s infinite;
}

.social_container_read {
  position: absolute;
  display: flex;
  top: 25%;
  right: 2%;
  flex-direction: column;
}

.social_icon_read {
  height: 50px;
  width: 50px;
  margin-left: 18px;
  margin-bottom: 10px;
  transition: transform 0.7s ease-in-out;
}

.social_icon_read:hover {
  transform: rotate(360deg);
}

.followParaRead {
  font-size: 16px;
  color: rgb(227, 160, 3);
  font-weight: 800;
  font-family: Poppins, sans-serif;
}

@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap);

.ng-select.ng-select-opened>.ng-select-container {
  background: #fff;
  border-color: #b3b3b3 #ccc #d9d9d9;
}

.ng-select.ng-select-opened>.ng-select-container:hover {
  box-shadow: none;
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow {
  top: -2px;
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow:hover {
  border-color: transparent transparent #333;
}

.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.ng-select.ng-select-opened.ng-select-top>.ng-select-container {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px rgba(0, 126, 255, 0.1);
}

.ng-select.ng-select-disabled>.ng-select-container {
  background-color: #f9f9f9;
}

.ng-select .ng-has-value .ng-placeholder {
  display: none;
}

.ng-select .ng-select-container {
  color: #333;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  min-height: 36px;
  align-items: center;
}

.ng-select .ng-select-container:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.ng-select .ng-select-container .ng-value-container {
  align-items: center;
  padding-left: 10px;
}

[dir="rtl"] .ng-select .ng-select-container .ng-value-container {
  padding-right: 10px;
  padding-left: 0;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #999;
}

.ng-select.ng-select-single .ng-select-container {
  height: 36px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 5px;
  left: 0;
  padding-left: 10px;
  padding-right: 50px;
}

[dir="rtl"] .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding-right: 10px;
  padding-left: 50px;
}

.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value {
  background-color: #f9f9f9;
  border: 1px solid #e6e6e6;
}

.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value .ng-value-label {
  padding: 0 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-top: 5px;
  padding-left: 7px;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-right: 7px;
  padding-left: 0;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  font-size: 0.9em;
  margin-bottom: 5px;
  background-color: #ebf5ff;
  border-radius: 2px;
  margin-right: 5px;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  margin-right: 0;
  margin-left: 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
  background-color: #f9f9f9;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
  padding-left: 5px;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
  padding-left: 0;
  padding-right: 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon,
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  display: inline-block;
  padding: 1px 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  background-color: #d1e8ff;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: 1px solid #b8dbff;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-left: 1px solid #b8dbff;
  border-right: none;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
  border-left: 1px solid #b8dbff;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
  border-left: 0;
  border-right: 1px solid #b8dbff;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0 0 3px 3px;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0 3px 3px 0;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 5px;
  padding-bottom: 5px;
  padding-left: 3px;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  padding-right: 3px;
  padding-left: 0;
}

.ng-select .ng-clear-wrapper {
  color: #999;
}

.ng-select .ng-clear-wrapper:hover .ng-clear {
  color: #d0021b;
}

.ng-select .ng-spinner-zone {
  padding: 5px 5px 0 0;
}

[dir="rtl"] .ng-select .ng-spinner-zone {
  padding: 5px 0 0 5px;
}

.ng-select .ng-arrow-wrapper {
  width: 25px;
  padding-right: 5px;
}

[dir="rtl"] .ng-select .ng-arrow-wrapper {
  padding-left: 5px;
  padding-right: 0;
}

.ng-select .ng-arrow-wrapper:hover .ng-arrow {
  border-top-color: #666;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
}

.ng-dropdown-panel {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  left: 0;
}

.ng-dropdown-panel.ng-select-bottom {
  top: 100%;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-color: #e6e6e6;
  margin-top: -1px;
}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.ng-dropdown-panel.ng-select-top {
  bottom: 100%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-color: #e6e6e6;
  margin-bottom: -1px;
}

.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.ng-dropdown-panel .ng-dropdown-header {
  border-bottom: 1px solid #ccc;
  padding: 5px 7px;
}

.ng-dropdown-panel .ng-dropdown-footer {
  border-top: 1px solid #ccc;
  padding: 5px 7px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  padding: 8px 10px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
  cursor: default;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background-color: #f5faff;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
  background-color: #ebf5ff;
  font-weight: 600;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  padding: 8px 10px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: #333;
  background-color: #ebf5ff;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-weight: 600;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #f5faff;
  color: #333;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: #ccc;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-left: 22px;
}

[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-right: 22px;
  padding-left: 0;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  font-size: 80%;
  font-weight: 400;
  padding-right: 5px;
}

[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  padding-left: 5px;
  padding-right: 0;
}

[dir="rtl"] .ng-dropdown-panel {
  direction: rtl;
  text-align: right;
}

.tox .tox-notification--in {
  display: none !important;
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

*,
:after,
:before {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;

  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  font-style: normal;
  line-height: inherit;
}

address,
dl,
ol,
ul {
  margin-bottom: 1rem;
}

dl,
ol,
ul {
  margin-top: 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]),
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="time"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

.h1,
h1 {
  font-size: 2.5rem;
}

.h2,
h2 {
  font-size: 2rem;
}

.h3,
h3 {
  font-size: 1.75rem;
}

.h4,
h4 {
  font-size: 1.5rem;
}

.h5,
h5 {
  font-size: 1.25rem;
}

.h6,
h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
}

.display-1,
.display-2 {
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
}

.display-3 {
  font-size: 4.5rem;
}

.display-3,
.display-4 {
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.small,
small {
  font-size: 80%;
  font-weight: 400;
}

.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-inline,
.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer:before {
  content: "\2014 \00A0";
}

.img-fluid,
.img-thumbnail {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono,
    Courier New, monospace;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a>code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm td,
.table-sm th {
  padding: 0.3rem;
}

.table-bordered,
.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary>td,
.table-primary>th {
  background-color: #b8daff;
}

.table-hover .table-primary:hover,
.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary>td,
.table-secondary>th {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover,
.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
  background-color: #c8cbcf;
}

.table-success,
.table-success>td,
.table-success>th {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover,
.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
  background-color: #b1dfbb;
}

.table-info,
.table-info>td,
.table-info>th {
  background-color: #bee5eb;
}

.table-hover .table-info:hover,
.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
  background-color: #abdde5;
}

.table-warning,
.table-warning>td,
.table-warning>th {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover,
.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger>td,
.table-danger>th {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover,
.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
  background-color: #f1b0b7;
}

.table-light,
.table-light>td,
.table-light>th {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover,
.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
  background-color: #ececf6;
}

.table-dark,
.table-dark>td,
.table-dark>th {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover,
.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
  background-color: #b9bbbe;
}

.table-active,
.table-active>td,
.table-active>th,
.table-hover .table-active:hover,
.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: hsla(0, 0%, 100%, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: hsla(0, 0%, 100%, 0.075);
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive>.table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm,
.input-group-lg>.form-control-plaintext.form-control,
.input-group-lg>.input-group-append>.form-control-plaintext.btn,
.input-group-lg>.input-group-append>.form-control-plaintext.input-group-text,
.input-group-lg>.input-group-prepend>.form-control-plaintext.btn,
.input-group-lg>.input-group-prepend>.form-control-plaintext.input-group-text,
.input-group-sm>.form-control-plaintext.form-control,
.input-group-sm>.input-group-append>.form-control-plaintext.btn,
.input-group-sm>.input-group-append>.form-control-plaintext.input-group-text,
.input-group-sm>.input-group-prepend>.form-control-plaintext.btn,
.input-group-sm>.input-group-prepend>.form-control-plaintext.input-group-text {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm,
.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-sm>.input-group-append>select.btn:not([size]):not([multiple]),
.input-group-sm>.input-group-append>select.input-group-text:not([size]):not([multiple]),
.input-group-sm>.input-group-prepend>select.btn:not([size]):not([multiple]),
.input-group-sm>.input-group-prepend>select.input-group-text:not([size]):not([multiple]),
.input-group-sm>select.form-control:not([size]):not([multiple]),
select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px);
}

.form-control-lg,
.input-group-lg>.form-control,
.input-group-lg>.input-group-append>.btn,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-prepend>.input-group-text {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-lg>.input-group-append>select.btn:not([size]):not([multiple]),
.input-group-lg>.input-group-append>select.input-group-text:not([size]):not([multiple]),
.input-group-lg>.input-group-prepend>select.btn:not([size]):not([multiple]),
.input-group-lg>.input-group-prepend>select.input-group-text:not([size]):not([multiple]),
.input-group-lg>select.form-control:not([size]):not([multiple]),
select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px);
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row>.col,
.form-row>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled~.form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: 0.2rem;
}

.custom-select.is-valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.was-validated .form-control:valid {
  border-color: #28a745;
}

.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.custom-select.is-valid~.valid-feedback,
.custom-select.is-valid~.valid-tooltip,
.form-control.is-valid~.valid-feedback,
.form-control.is-valid~.valid-tooltip,
.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip,
.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip {
  display: block;
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
  color: #28a745;
}

.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip,
.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip {
  display: block;
}

.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
  color: #28a745;
}

.custom-control-input.is-valid~.custom-control-label:before,
.was-validated .custom-control-input:valid~.custom-control-label:before {
  background-color: #71dd8a;
}

.custom-control-input.is-valid~.valid-feedback,
.custom-control-input.is-valid~.valid-tooltip,
.was-validated .custom-control-input:valid~.valid-feedback,
.was-validated .custom-control-input:valid~.valid-tooltip {
  display: block;
}

.custom-control-input.is-valid:checked~.custom-control-label:before,
.was-validated .custom-control-input:valid:checked~.custom-control-label:before {
  background-color: #34ce57;
}

.custom-control-input.is-valid:focus~.custom-control-label:before,
.was-validated .custom-control-input:valid:focus~.custom-control-label:before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.custom-file-input.is-valid~.custom-file-label,
.was-validated .custom-file-input:valid~.custom-file-label {
  border-color: #28a745;
}

.custom-file-input.is-valid~.custom-file-label:before,
.was-validated .custom-file-input:valid~.custom-file-label:before {
  border-color: inherit;
}

.custom-file-input.is-valid~.valid-feedback,
.custom-file-input.is-valid~.valid-tooltip,
.was-validated .custom-file-input:valid~.valid-feedback,
.was-validated .custom-file-input:valid~.valid-tooltip {
  display: block;
}

.custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .custom-file-input:valid:focus~.custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: 0.2rem;
}

.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545;
}

.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.custom-select.is-invalid~.invalid-feedback,
.custom-select.is-invalid~.invalid-tooltip,
.form-control.is-invalid~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip,
.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip,
.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip {
  display: block;
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
  color: #dc3545;
}

.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip,
.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label {
  color: #dc3545;
}

.custom-control-input.is-invalid~.custom-control-label:before,
.was-validated .custom-control-input:invalid~.custom-control-label:before {
  background-color: #efa2a9;
}

.custom-control-input.is-invalid~.invalid-feedback,
.custom-control-input.is-invalid~.invalid-tooltip,
.was-validated .custom-control-input:invalid~.invalid-feedback,
.was-validated .custom-control-input:invalid~.invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid:checked~.custom-control-label:before,
.was-validated .custom-control-input:invalid:checked~.custom-control-label:before {
  background-color: #e4606d;
}

.custom-control-input.is-invalid:focus~.custom-control-label:before,
.was-validated .custom-control-input:invalid:focus~.custom-control-label:before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.custom-file-input.is-invalid~.custom-file-label,
.was-validated .custom-file-input:invalid~.custom-file-label {
  border-color: #dc3545;
}

.custom-file-input.is-invalid~.custom-file-label:before,
.was-validated .custom-file-input:invalid~.custom-file-label:before {
  border-color: inherit;
}

.custom-file-input.is-invalid~.invalid-feedback,
.custom-file-input.is-invalid~.invalid-tooltip,
.was-validated .custom-file-input:invalid~.invalid-feedback,
.was-validated .custom-file-input:invalid~.invalid-tooltip {
  display: block;
}

.custom-file-input.is-invalid:focus~.custom-file-label,
.was-validated .custom-file-input:invalid:focus~.custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -ms-user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:focus,
.btn:hover {
  text-decoration: none;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
  background-image: none;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success.focus,
.btn-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info.focus,
.btn-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning.focus,
.btn-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger.focus,
.btn-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.manualpage {
  height: 100% !important;
  width: 50% !important;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #fdfaf7;
  color: #785e3a;
  border: solid 1px #c2b5a3;
  overflow: hidden;
}

.manualpage .page-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.manualpage .page-content .page-header {
  height: 30px;
  font-size: 100%;
  text-transform: uppercase;
  text-align: center;
}

.manualpage .page-content .page-image {
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.manualpage .page-content .page-text {
  height: 100%;
  flex-grow: 1;
  font-size: 80%;
  text-align: justify;
  margin-top: 10px;
  padding-top: 10px;
  box-sizing: border-box;
  border-top: solid 1px #f4e8d7;
}

.manualpage .page-content .page-footer {
  height: 30px;
  border-top: solid 1px #f4e8d7;
  font-size: 80%;
  color: #998466;
}

.manualpage.--left {
  border-right: 0;
  box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, 0.4);
}

.manualpage.--right {
  border-left: 0;
  box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4);
}

.manualpage.--right .page-footer {
  text-align: right;
}

.manualpage.hard {
  background-color: #f2e8d9;
  border: solid 1px #998466;
}

.manualpage.page-cover {
  background-color: #e3d0b5;
  color: #785e3a;
  border: solid 1px #998466;
}

.manualpage.page-cover h2 {
  text-align: center;
  padding-top: 50%;
  font-size: 210%;
}

.modal-content-manual {
  position: relative;
  display: flex;
  width: 100%;
  height: 425px;
  pointer-events: auto;
  background-color: #431900;
  -webkit-background-clip: border-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.manualpage.page-cover.page-cover-top {
  box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5),
    -2px 0 5px 2px rgba(0, 0, 0, 0.4);
}

.manualpage.page-cover.page-cover-bottom {
  box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5),
    10px 0 8px 0px rgba(0, 0, 0, 0.4);
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light.focus,
.btn-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark.focus,
.btn-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show>.btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show>.btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show>.btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}

.btn-link:hover {
  color: #0056b3;
  background-color: transparent;
}

.btn-link.focus,
.btn-link:focus,
.btn-link:hover {
  text-decoration: underline;
  border-color: transparent;
}

.btn-link.focus,
.btn-link:focus {
  box-shadow: none;
}

.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d;
}

.btn-group-lg>.btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-group-sm>.btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block+.btn-block {
  margin-top: 0.5rem;
}

input[type="button"].btn-block,
input[type="reset"].btn-block,
input[type="submit"].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}

.fade.show {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.show {
  display: block;
}

tr.collapse.show {
  display: table-row;
}

tbody.collapse.show {
  display: table-row-group;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

.collapsing,
.dropdown,
.dropup {
  position: relative;
}

.dropdown-toggle:after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle:after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle:after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-toggle:after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle:after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  display: none;
}

.dropleft .dropdown-toggle:before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group-vertical>.btn,
.btn-group>.btn {
  position: relative;
  flex: 0 1 auto;
}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover {
  z-index: 1;
}

.btn-group-vertical .btn+.btn,
.btn-group-vertical .btn+.btn-group,
.btn-group-vertical .btn-group+.btn,
.btn-group-vertical .btn-group+.btn-group,
.btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group>.btn:first-child {
  margin-left: 0;
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split:after {
  margin-left: 0;
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical>.btn+.btn,
.btn-group-vertical>.btn+.btn-group,
.btn-group-vertical>.btn-group+.btn,
.btn-group-vertical>.btn-group+.btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
  margin-bottom: 0;
}

.btn-group-toggle>.btn-group>.btn input[type="checkbox"],
.btn-group-toggle>.btn-group>.btn input[type="radio"],
.btn-group-toggle>.btn input[type="checkbox"],
.btn-group-toggle>.btn input[type="radio"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control {
  margin-bottom: 0;
}

.input-group>.custom-file:focus,
.input-group>.custom-select:focus,
.input-group>.form-control:focus {
  z-index: 3;
}

.input-group>.custom-file+.custom-file,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.form-control,
.input-group>.custom-select+.custom-file,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.form-control,
.input-group>.form-control+.custom-file,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.form-control {
  margin-left: -1px;
}

.input-group>.custom-select:not(:last-child),
.input-group>.form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>.custom-select:not(:first-child),
.input-group>.form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group>.custom-file {
  display: flex;
  align-items: center;
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label:before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>.custom-file:not(:first-child) .custom-file-label,
.input-group>.custom-file:not(:first-child) .custom-file-label:before {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-append,
.input-group-prepend {
  display: flex;
}

.input-group-append .btn,
.input-group-prepend .btn {
  position: relative;
  z-index: 2;
}

.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.btn,
.input-group-append .input-group-text+.input-group-text,
.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-prepend .input-group-text+.input-group-text {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="checkbox"],
.input-group-text input[type="radio"] {
  margin-top: 0;
}

/* 
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label:before {
  color: #fff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:active ~ .custom-control-label:before {
  color: #fff;
  background-color: #b3d7ff;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label:before {
  background-color: #e9ecef;
}

.custom-control-label {
  margin-bottom: 0;
}

.custom-control-label:before {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -ms-user-select: none;
  background-color: #dee2e6;
}

.custom-control-label:after,
.custom-control-label:before {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
}

.custom-control-label:after {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label:before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label:before {
  background-color: #007bff;
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label:before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label:before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label:before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  background-color: #007bff;
}

.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label:before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075),
    0 0 5px rgba(128, 189, 255, 0.5);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  font-size: 75%;
}

.custom-select-lg,
.custom-select-sm {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  font-size: 125%;
}

.custom-file {
  display: inline-block;
  margin-bottom: 0;
}

.custom-file,
.custom-file-input {
  position: relative;
  width: 100%;
  height: calc(2.25rem + 2px);
}

.custom-file-input {
  z-index: 2;
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-control {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input:focus ~ .custom-file-control:before {
  border-color: #80bdff;
}

.custom-file-input:lang(en) ~ .custom-file-label:after {
  content: "Browse";
}

.custom-file-label {
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label,
.custom-file-label:after {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
}

.custom-file-label:after {
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  padding: 0.5rem 1rem;
}

.navbar,
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat 50%;
  background-size: 100% 100%;
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .dropdown-menu-right {
  right: 0;
  left: auto;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: hsla(0, 0%, 100%, 0.5);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: hsla(0, 0%, 100%, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: hsla(0, 0%, 100%, 0.25);
}

.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: hsla(0, 0%, 100%, 0.5);
  border-color: hsla(0, 0%, 100%, 0.1);
}

.navbar-dark .navbar-text {
  color: hsla(0, 0%, 100%, 0.5);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  -webkit-background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
}

.card-subtitle,
.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
} */

.card-header-tabs {
  margin-bottom: -0.75rem;
  border-bottom: 0;
}

.card-header-pills,
.card-header-tabs {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group>.card {
  margin-bottom: 15px;
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

.readstory-body-container {
  width: 100%;
  max-height: 480px;
  padding-left: 15px;
  padding-right: 15px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item+.breadcrumb-item:before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item+.breadcrumb-item:hover:before {
  text-decoration: underline;
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.readStory-button-position {
  position: absolute;
  left: 0;
  right: 0;
}

.page-link:hover {
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-primary[href]:focus,
.badge-primary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-secondary[href]:focus,
.badge-secondary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:focus,
.badge-success[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:focus,
.badge-info[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning[href]:focus,
.badge-warning[href]:hover {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:focus,
.badge-danger[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-light[href]:focus,
.badge-light[href]:hover {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:focus,
.badge-dark[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #007bff;
  transition: width 0.6s ease;
}

.progress-bar-striped {
  background-image: linear-gradient(45deg,
      hsla(0, 0%, 100%, 0.15) 25%,
      transparent 0,
      transparent 50%,
      hsla(0, 0%, 100%, 0.15) 0,
      hsla(0, 0%, 100%, 0.15) 75%,
      transparent 0,
      transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:focus,
.list-group-item-action:hover {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item:focus,
.list-group-item:hover {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:focus,
.close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal,
.modal-open {
  overflow: hidden;
}

.modal {
  background-color: rgb(23 9 9 / 81%);
  position: fixed;
  height: 106vh !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  outline: 0;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

#DisclaimerModal.modal {
  background-color: rgb(0, 0, 0, 0.8);
  position: fixed;
  height: 106vh !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  outline: 0;
}

#LanguageModal.modal {
  background-color: rgb(0, 0, 0, 0.8);
  position: fixed;
  height: 106vh !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1055;
  display: none;
  outline: 0;
}

#AttentionModal.modal {
  background-color: rgb(0, 0, 0, 0.8);
  position: fixed;
  height: 106vh !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  outline: 0;
}

#TermsModal {
  background-color: rgb(0, 0, 0, 0.5);
  position: fixed;
  height: 106vh !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0rem auto;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translateY(-25%);
}

.modal.show .modal-dialog {
  transform: translate(0);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0rem !important;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border: none !important;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow:before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-auto[x-placement^="top"],
.bs-tooltip-top {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^="top"] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0;
}

.bs-tooltip-auto[x-placement^="top"] .arrow:before,
.bs-tooltip-top .arrow:before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-auto[x-placement^="right"],
.bs-tooltip-right {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^="right"] .arrow,
.bs-tooltip-right .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[x-placement^="right"] .arrow:before,
.bs-tooltip-right .arrow:before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-auto[x-placement^="bottom"],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow:before,
.bs-tooltip-bottom .arrow:before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-auto[x-placement^="left"],
.bs-tooltip-left {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^="left"] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[x-placement^="left"] .arrow:before,
.bs-tooltip-left .arrow:before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  top: 0;
  left: 0;
  z-index: 1060;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover,
.popover .arrow {
  position: absolute;
  display: block;
}

.demoPage {
  background-color: white;
}

.popover .arrow {
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow:after,
.popover .arrow:before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-auto[x-placement^="top"],
.bs-popover-top {
  margin-bottom: 0.5rem;
}

.bs-popover-auto[x-placement^="top"] .arrow,
.bs-popover-top .arrow {
  bottom: calc(-0.5rem + -1px);
}

.bs-popover-auto[x-placement^="top"] .arrow:after,
.bs-popover-auto[x-placement^="top"] .arrow:before,
.bs-popover-top .arrow:after,
.bs-popover-top .arrow:before {
  border-width: 0.5rem 0.5rem 0;
}

.bs-popover-auto[x-placement^="top"] .arrow:before,
.bs-popover-top .arrow:before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^="top"] .arrow:after,
.bs-popover-top .arrow:after {
  bottom: 1px;
  border-top-color: #fff;
}

.bs-popover-auto[x-placement^="right"],
.bs-popover-right {
  margin-left: 0.5rem;
}

.bs-popover-auto[x-placement^="right"] .arrow,
.bs-popover-right .arrow {
  left: calc(-0.5rem + -1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-auto[x-placement^="right"] .arrow:after,
.bs-popover-auto[x-placement^="right"] .arrow:before,
.bs-popover-right .arrow:after,
.bs-popover-right .arrow:before {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-auto[x-placement^="right"] .arrow:before,
.bs-popover-right .arrow:before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^="right"] .arrow:after,
.bs-popover-right .arrow:after {
  left: 1px;
  border-right-color: #fff;
}

.bs-popover-auto[x-placement^="bottom"],
.bs-popover-bottom {
  margin-top: 0.5rem;
}

.bs-popover-auto[x-placement^="bottom"] .arrow,
.bs-popover-bottom .arrow {
  top: calc(-0.5rem + -1px);
}

.bs-popover-auto[x-placement^="bottom"] .arrow:after,
.bs-popover-auto[x-placement^="bottom"] .arrow:before,
.bs-popover-bottom .arrow:after,
.bs-popover-bottom .arrow:before {
  border-width: 0 0.5rem 0.5rem;
}

.bs-popover-auto[x-placement^="bottom"] .arrow:before,
.bs-popover-bottom .arrow:before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^="bottom"] .arrow:after,
.bs-popover-bottom .arrow:after {
  top: 1px;
  border-bottom-color: #fff;
}

.bs-popover-auto[x-placement^="bottom"] .popover-header:before,
.bs-popover-bottom .popover-header:before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-auto[x-placement^="left"],
.bs-popover-left {
  margin-right: 0.5rem;
}

.bs-popover-auto[x-placement^="left"] .arrow,
.bs-popover-left .arrow {
  right: calc(-0.5rem + -1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-auto[x-placement^="left"] .arrow:after,
.bs-popover-auto[x-placement^="left"] .arrow:before,
.bs-popover-left .arrow:after,
.bs-popover-left .arrow:before {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-popover-auto[x-placement^="left"] .arrow:before,
.bs-popover-left .arrow:before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^="left"] .arrow:after,
.bs-popover-left .arrow:after {
  right: 1px;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 1000px;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {

  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translateZ(0);
  }
}

.active.carousel-item-right,
.carousel-item-next {
  transform: translateX(100%);
}

@supports (transform-style: preserve-3d) {

  .active.carousel-item-right,
  .carousel-item-next {
    transform: translate3d(100%, 0, 0);
  }
}

.active.carousel-item-left,
.carousel-item-prev {
  transform: translateX(-100%);
}

@supports (transform-style: preserve-3d) {

  .active.carousel-item-left,
  .carousel-item-prev {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat 50%;
  background-size: 100% 100%;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  background-color: hsla(0, 0%, 100%, 0.5);
}

.carousel-indicators li:before {
  top: -10px;
}

.carousel-indicators li:after,
.carousel-indicators li:before {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators li:after {
  bottom: -10px;
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
}

.rounded-right,
.rounded-top {
  border-top-right-radius: 0.25rem !important;
}

.rounded-bottom,
.rounded-right {
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom,
.rounded-left {
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix:after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive:before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9:before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.embed-responsive-4by3:before {
  padding-top: 75%;
}

.embed-responsive-1by1:before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
  position: -webkit-sticky;
}

.fixed-top {
  top: 0;
}

.fixed-bottom,
.fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  bottom: 0;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  white-space: normal;
  -webkit-clip-path: none;
  clip-path: none;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: #0062cc !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:focus,
a.text-secondary:hover {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:focus,
a.text-success:hover {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:focus,
a.text-info:hover {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:focus,
a.text-warning:hover {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:focus,
a.text-danger:hover {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:focus,
a.text-light:hover {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:focus,
a.text-dark:hover {
  color: #1d2124 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

.toast-title {
  font-weight: 700;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #fff;
}

.toast-message a:hover {
  color: #ccc;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  text-shadow: 0 1px 0 #fff;
}

.toast-close-button:focus,
.toast-close-button:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999;
  color: #fff;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000;
  opacity: 1;
  cursor: pointer;
}

.toast-container.toast-bottom-center .ngx-toastr,
.toast-container.toast-top-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-bottom-full-width .ngx-toastr,
.toast-container.toast-top-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51a351;
}

.toast-error {
  background-color: #bd362f;
}

.toast-info {
  background-color: #2f96b4;
}

.toast-warning {
  background-color: #f89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000;
  opacity: 0.4;
}

@font-face {
  font-family: sanitarium_bbregular;
  src: url("../../assets/fonts/Sanitarium\ BB\ V2.woff2") format("woff2"),
    url("../../assets/fonts/SanitariumBB.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "droid_serifregular";
  src: url("../../assets/fonts/Droid\ Serif\ V2.woff2") format("woff2"),
    url("../../assets/fonts/Droid\ Serif\ V2.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "droid_serifbold";
  src: url("../../assets/fonts/Droid\ Serif\ V2.woff") format("woff2"),
    url("../../assets/fonts/DroidSerif-Bold.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

body,
html {
  height: 100%;
}

body {
  background-color: #000;
  color: #000;
  font-size: 14px;
}

a {
  color: #0a6caf;
  transition: all 0.3s ease-in-out;
}

a,
a:hover {
  text-decoration: none;
}

a:focus,
button:focus {
  outline: none;
  text-decoration: none;
}

a img {
  border: 0;
}

img {
  max-width: 100%;
}

::-moz-selection {
  color: #fff;
  background: #0a6caf;
}

::selection {
  color: #fff;
  background: #0a6caf;
}

table {
  border-spacing: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: Poppins, sans-serif;
  margin: 0;
  padding: 0;
}

p {
  font-weight: 400;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.hidden-welcome-body {
  overflow: hidden;
}

/* .bg-welcome .continue-btn-group .btn-continue {
  width: 102px !important;
  height: 92px !important;
  background: #b58d4f;
  background-image: linear-gradient( to right, #bf752e 0, #ebda6f 16%, #d9bc76 45%, #cea05a 55%, #e1c395 48%, #cb9b51 78%, #c27a2e 100% );
  border: solid #a27b3a;
} */

.bg-welcome .continue-btn-group .btn-continue {
  width: 140px !important;
  height: 92px !important;
  /* background: #b58d4f;
  background-image: linear-gradient( to right, #bf752e 0, #ebda6f 16%, #d9bc76 45%, #cea05a 55%, #e1c395 48%, #cb9b51 78%, #c27a2e 100% );
  border: solid #a27b3a; */
}

.hvr-float-shadow {
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  position: relative;
  transition-duration: 0.3s;
  transition-property: transform;
}

.hvr-float-shadow:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 15px;
  width: 90%;
  opacity: 0;
  transition-duration: 0.3s;
  transition-property: transform, opacity;
}

.hvr-float-shadow:active,
.hvr-float-shadow:focus,
.hvr-float-shadow:hover {
  transform: translateY(-7px);
}

.hvr-float-shadow:active:before,
.hvr-float-shadow:focus:before,
.hvr-float-shadow:hover:before {
  opacity: 1;
  transform: translateY(7px);
}

.hvr-sweep-to-right {
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
  overflow: hidden;
}

.hvr-sweep-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #10258d;
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.hvr-sweep-to-right:active,
.hvr-sweep-to-right:focus,
.hvr-sweep-to-right:hover {
  color: #fff;
}

.hvr-sweep-to-right:active:before,
.hvr-sweep-to-right:focus:before,
.hvr-sweep-to-right:hover:before {
  transform: scaleX(1);
}

.google-ads-wrap img {
  height: 80px;
  width: 800px;
}

.footer-wrap {
  padding: 15px 0;
  text-align: center;
  background-color: transparent;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.footer-wrap p {
  font-size: 15px;
  color: #fff;
  font-weight: 400;
}

.footer-wrap p span {
  color: #fff;
}

.socials-icon-bar span {
  font-family: "sanitarium_bbregular";
  text-transform: uppercase;
  font-size: 26px;
  color: #fec700;
}

.socials-icon-bar {
  position: absolute;
  top: 52%;
  right: 20px;
  z-index: 1;
  transform: translateY(-48%);
}

.socials-icon-bar a {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  margin-bottom: 10px;
}

.socials-icon-bar a i {
  width: 52px;
  height: 52px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  display: block;
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
}

.socials-icon-bar a:hover i {
  transform: rotate(1turn);
}

.facebook-icon {
  background-image: url("../../assets/images/facebook_new.png");
}

.analytics-icon {
  background-image: url("../../assets/images/analytics-new.png");
}

.linkedin-icon {
  background-image: url("../../assets/images/linkedin-new.png");
}

.twitter-icon {
  background-image: url("../../assets/images/twitter_new.png");
}

.instagram-icon {
  background-image: url("../../assets/images/instragram_new.png");
}

.sociallink-info {
  font-size: 22px;
  font-weight: 600;
  color: #041241;
  margin-top: 30px;
  margin-bottom: 10px;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.menutop-modal-dialog.modal-dialog {
  justify-content: center;
}

.menutop-modal-dialog.modal-dialog .modal-content {
  border: 0;
  background: transparent;
}

.menutop-modal-dialog .welcome-item {
  width: 33.3333333%;
}

@-webkit-keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
}

.home-exit-bx {
  text-align: right;
}

.header-exit-button {
  width: 50px;
  height: 50px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/header-icons-frame.svg");
  background-size: contain;
  padding: 7px;
  position: relative;
}

/* .header-exit-button:active {
  top: 2px;
} */

.header-exit-button span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  background: linear-gradient(0deg, #370000 -0.33%, red 99.96%) !important;
}

.header-exit-button:hover span {
  background: linear-gradient(180deg, #370000 -0.33%, red 99.96%) !important;
}

.header-exit-button span i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../../assets/images/header-exit-icon.svg");
  -webkit-animation: bounceRight 2s infinite;
  animation: exitRight 2s infinite;
}

.header-exit-button:not(:hover) i {
  -webkit-animation: none;
  animation: none;
}

@-webkit-keyframes exitRight {

  0%,
  20%,
  50%,
  80%,
  to {
    transform: translateX(0);
  }

  40% {
    transform: translateX(-10px);
  }

  60% {
    transform: translateX(-7px);
  }
}

@keyframes exitRight {

  0%,
  20%,
  50%,
  80%,
  to {
    transform: translateX(0);
  }

  40% {
    transform: translateX(-10px);
  }

  60% {
    transform: translateX(-7px);
  }
}

.mobile-exit-button {
  display: none !important;
}

.terms-wrapper {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  border-radius: 5px;
  padding: 8px 40px 0px;
  background: linear-gradient(180deg,
      #d2ac4c,
      #896517 12.29%,
      #dbb657 30%,
      #896d26 44.06%,
      #d8ab39 62.29%,
      #441a01 81.56%,
      #c9981e),
    linear-gradient(180deg,
      #ffe570,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #421800 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%),
    linear-gradient(0deg, #fff, #fff) !important;
  box-shadow: 2px 11px 13px rgba(0, 0, 0, 0.31);
}

.terms-wrap {
  border-radius: 5px 5px 5px 5px;
  padding: 9px 0px 19px 0px;
  height: 451px;
}

.terms-info {
  overflow-x: hidden;
  overflow-y: auto;
}

.terms-info::-webkit-scrollbar {
  width: 5px;
}

.terms-info::-webkit-scrollbar-track {
  background: linear-gradient(180deg, #4e4e4e, #000);
  border-radius: 5px;
}

.terms-info::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 2px rgba(255, 255, 0, 0.3);
  background: linear-gradient(180deg,
      #ffe570,
      #fffe99 9.18%,
      #bb9432 25.31%,
      #efd269 48.35%,
      #c09937 69.22%,
      #9e781a 83.69%,
      #f1d361);
}

.terms-info p {
  font-size: 16px;
  font-weight: 600;
  color: #041241;
  margin-bottom: 10px;
}

.terms-logo {
  margin-top: 0;
}

.language-item-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 268px;
  /* scrollbar-width: thin; */
}

.attention-modal-dialog.modal-dialog .modal-content .modal-body::-webkit-scrollbar,
.language-item-row::-webkit-scrollbar,
.opportunity-modal-dialog.modal-dialog .modal-content .modal-body .opportunity-scroll-box::-webkit-scrollbar {
  width: 5px;
}

.attention-modal-dialog.modal-dialog .modal-content .modal-body::-webkit-scrollbar-track,
.language-item-row::-webkit-scrollbar-track,
.opportunity-modal-dialog.modal-dialog .modal-content .modal-body .opportunity-scroll-box::-webkit-scrollbar-track {
  background: linear-gradient(180deg, #4e4e4e, #000);
  border-radius: 5px;
}

.attention-modal-dialog.modal-dialog .modal-content .modal-body::-webkit-scrollbar-thumb,
.language-item-row::-webkit-scrollbar-thumb,
.opportunity-modal-dialog.modal-dialog .modal-content .modal-body .opportunity-scroll-box::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 2px rgba(255, 255, 0, 0.3);
  background: linear-gradient(180deg,
      #ffe570,
      #fffe99 9.18%,
      #bb9432 25.31%,
      #efd269 48.35%,
      #c09937 69.22%,
      #9e781a 83.69%,
      #f1d361);
}

.language-radio-col {
  position: relative;
  padding: 8px;
  width: 25%;
}

.language-radio-col input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.language-radio-col input[type="radio"]:checked+.language-radio-label,
.language-radio-col input[type="radio"]:hover+.language-radio-label {
  border-color: #fd000a;
  box-shadow: 0 5px 10px rgb(253, 0, 10, 50%);
}

.language-radio-col input[type="radio"]:checked+.language-radio-label h5,
.language-radio-col input[type="radio"]:hover+.language-radio-label h5 {
  color: #fd000a;
}

.language-radio-col input[type="radio"]:checked+.language-radio-label .l-quote-bottom:before,
.language-radio-col input[type="radio"]:checked+.language-radio-label .l-quote-top:before,
.language-radio-col input[type="radio"]:hover+.language-radio-label .l-quote-bottom:before,
.language-radio-col input[type="radio"]:hover+.language-radio-label .l-quote-top:before {
  background-color: #fd000a;
}

.language-radio-label {
  display: block;
  border: 4px solid transparent;
  border-radius: 15px;
  text-align: center;
  margin: auto;
  position: relative;
  padding: 16px;
  min-height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.language-radio-label .l-quote-top {
  position: absolute;
  left: 10px;
  top: -12px;
  width: 36px;
  height: 16px;
}

.language-radio-label .l-quote-bottom:after,
.language-radio-label .l-quote-top:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
}

.language-radio-label .l-quote-top:after {
  left: 0;
  bottom: 4px;
}

.language-radio-label .l-quote-top:before {
  content: "";
  position: absolute;
  left: 5px;
  bottom: 0;
  z-index: 1;
  width: 26px;
  height: 16px;
  background-color: #1f1d1e;
  -webkit-mask-size: contain;
  mask-size: contain;
}

.language-radio-label .l-quote-bottom {
  position: absolute;
  right: 10px;
  bottom: -12px;
  width: 36px;
  height: 16px;
}

.language-radio-label .l-quote-bottom:after {
  right: 0;
  top: 4px;
}

.language-radio-label .l-quote-bottom:before {
  content: "";
  position: absolute;
  right: 5px;
  top: 0;
  z-index: 1;
  width: 26px;
  height: 16px;
  background-color: #1f1d1e;
  -webkit-mask-size: contain;
  mask-size: contain;
}

.bor-bg-color-1 .language-radio-label .l-quote-bottom:after,
.bor-bg-color-1 .language-radio-label .l-quote-top:after {
  background-color: #dfa68d;
}

.bor-bg-color-1 .language-radio-label {
  border-color: #1f1d1e;
  background-color: #dfa68d;
}

.bor-bg-color-2 .language-radio-label .l-quote-bottom:after,
.bor-bg-color-2 .language-radio-label .l-quote-top:after {
  background-color: #f8d686;
}

.bor-bg-color-2 .language-radio-label {
  border-color: #0303f4;
  background-color: #f8d686;
}

.bor-bg-color-3 .language-radio-label .l-quote-bottom:after,
.bor-bg-color-3 .language-radio-label .l-quote-top:after {
  background-color: #a7ccd8;
}

.bor-bg-color-3 .language-radio-label {
  border-color: #6abf80;
  background-color: #a7ccd8;
}

.bor-bg-color-4 .language-radio-label .l-quote-bottom:after,
.bor-bg-color-4 .language-radio-label .l-quote-top:after {
  background-color: #bada8b;
}

.bor-bg-color-4 .language-radio-label {
  border-color: #c886ca;
  background-color: #bada8b;
}

.bor-bg-color-5 .language-radio-label .l-quote-bottom:after,
.bor-bg-color-5 .language-radio-label .l-quote-top:after {
  background-color: #db936e;
}

.bor-bg-color-5 .language-radio-label {
  border-color: #c886ca;
  background-color: #db936e;
}

.language-radio-label img {
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  max-height: 63px;
  height: 63px;
  width: 100%;
}

.language-radio-label h5 {
  font-size: 18px;
  color: #000;
  font-weight: 600;
  border-radius: 10px;
}

.language-radio-label p {
  font-size: 12px;
  color: #4b3310;
  font-weight: 600;
  padding: 8px 5px 5px;
}

.border-style-9 {
  border-radius: 5px;
  padding: 8px !important;
  background: linear-gradient(180deg,
      #ffe570,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #421800 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%);
}

.close-modal-button {
  height: 66px;
  width: 66px;
  border: 0;
  padding: 0;
  cursor: pointer;
  display: block;
  position: absolute;
  right: -26px;
  top: -26px;
  z-index: 4;
  background-size: contain;
}

.btn-cmn-group {
  text-align: center;
  margin-top: 0px;
}

.btn-cmn {
  min-width: 150px;
  height: 56px;
  border: 0;
  font-weight: 700;
  font-size: 20px;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  background: linear-gradient(180deg, #20a3ff, #0d1b36);
  border-radius: 300px;
  cursor: pointer;
}

.btn-cmn:hover {
  background: linear-gradient(180deg, #0d1b36, #20a3ff);
}

.btn-cmn:focus {
  outline: 0;
}

.btn-apply {
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.btn-apply:focus {
  outline: 0;
}

.button-gradient-border {
  border-radius: 300px;
  padding: 5px;
  display: inline-block;
  background: linear-gradient(180deg,
      #ffe570,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #421800 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.68);
}

.row-align {
  max-width: 100%;
  margin-left: 0% !important;
  margin-top: 2% !important;
}

.calendar {
  background: url("../../assets/images/calendar-icon.svg") 95% no-repeat;
  cursor: pointer;
  color: white;
}

.input-custom-field,
.select-custom-field {
  position: relative;
  border-radius: 5px;
  padding: 3px;
  display: block;
  background: linear-gradient(180deg,
      #ffe570,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #421800 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%);
}

.select-custom-field-2 .form-control,
.select-custom-field .form-control {
  color: #fff;
  background-color: #233234;
  border: 0;
  padding-right: 40px;
  padding-left: 20px;
  height: 44px !important;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 300;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position: center right 20px;
  background-image: url("../../assets/images/arrow-down-icon.svg");
}

.input-custom-field-2,
.select-custom-field-2 {
  position: relative;
  display: block;
  border: 3px solid #eade79;
  border-radius: 5px;
}

.select-custom-field-2 .form-control {
  background-color: #4d2717;
  height: 46px !important;
}

.input-custom-field-2 .form-control,
.input-custom-field .form-control {
  color: #fff;
  background-color: #041241;
  background-color: #233234;
  border: 0;
  height: 44px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 300;
}

.input-custom-field-2 .form-control {
  height: 44px;
  background-color: #4d2717;
}

.select-custom-field-2 .form-control:focus,
.select-custom-field .form-control:focus {
  box-shadow: none;
}

.input-custom-field-2 .form-control:focus,
.input-custom-field .form-control:focus {
  color: #fff;
  background-color: #041241;
  outline: 0;
  box-shadow: none;
}

.input-custom-field-2 .form-control:focus {
  background-color: #4d2717;
}

.bg-read-a-story .input-custom-field-2 .form-control {
  height: 44px;
}

.input-custom-field-2 .form-control::-moz-placeholder,
.input-custom-field .form-control::-moz-placeholder,
.write-title-fields-top .form-control::-moz-placeholder {
  color: #fff;
}

.input-custom-field-2 .form-control::placeholder,
.input-custom-field .form-control::placeholder,
.write-title-fields-top .form-control::placeholder {
  color: #fff;
}

.read-story-container {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  padding: 8px;
  background: linear-gradient(180deg,
      #ffe570,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #421800 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%);
}

.read-story-scroll-bx {
  background: linear-gradient(89.95deg,
      #0e1b3c 1.56%,
      #213b89 22.2%,
      #3b62d3 39.48%,
      #192e71 49.94%,
      #101f4f 58.28%,
      #142762 71.49%,
      #2343a2 81.15%,
      #0e1b3c 99.14%);
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.25);
  padding: 20px 20px 20px 30px;
}

.story-book-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  padding-right: 10px;
  max-height: 460px;
  overflow: auto;
}

.story-book-row::-webkit-scrollbar {
  width: 5px;
}

.story-book-row::-webkit-scrollbar-track {
  background: linear-gradient(180deg, #4e4e4e, #000);
  border-radius: 5px;
}

.story-book-row::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 2px rgba(255, 255, 0, 0.3);
  background: linear-gradient(180deg,
      #ffe570,
      #fffe99 9.18%,
      #bb9432 25.31%,
      #efd269 48.35%,
      #c09937 69.22%,
      #9e781a 83.69%,
      #f1d361);
}

.story-book-col {
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 20px;
  width: 25%;
}

.story-book-box {
  background: #d39339;
  background: linear-gradient(90deg,
      #d39339 0,
      #ecca70 20%,
      #faf8bd 50%,
      #daa74c 80%,
      #cf8c31);
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.25);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: 195px;
}

.story-book-top-bx {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 125px;
}

.story-book-top-bx p {
  font-size: 14px;
  color: #041241;
  padding: 10px 10px 0;
}

.story-book-item-box {
  border-radius: 5px;
  padding: 8px;
  background: linear-gradient(180deg,
      #ffe570,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #421800 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%);
}

.story-book-btm-bx {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: 80px;
  padding-bottom: 5px;
  background-size: cover;
}

.story-book-btm-bx p {
  font-size: 14px;
  color: #fff;
  font-family: "droid_serifbold";
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  padding: 0 5px;
}

.story-book-btm-bx p.sb-subject-txt {
  color: #ffea82;
}

.chat-modal .date-field .input-group-addon,
.chat-modal .input-custom-field-2 .form-control,
.chat-modal .input-custom-field .form-control,
.chat-modal .select-custom-field-2 .form-control,
.chat-modal .select-custom-field .form-control {
  background-color: #6b0101;
}

.chatroom-title-head {
  text-align: center;
  margin-bottom: 0;
  position: relative;
}

.chatroom-title-head.cmn-title-head h2 {
  -webkit-text-stroke-width: 0;
  -webkit-text-stroke-color: transparent;
}

.cmn-title-head h2:after {
  background: linear-gradient(180deg,
      #f3d666 0,
      #fffe99 10%,
      #be9a37 20%,
      #eed168 30%,
      #bf9734 70%,
      #a8742e 85%,
      #f7da66);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cmn-title-head h2:after,
.cmn-title-head h2:before {
  content: attr(data-content);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.cmn-title-head h2:before {
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.56);
}

.cmn-title-head h2 {
  -webkit-text-fill-color: transparent;
  align-items: center;
  background: -webkit-linear-gradient(269deg,
      #e5cca8,
      #fffe99 10%,
      #785e17 20%,
      #f9ecba 30%,
      #bf9734 70%,
      #f0d5af 85%,
      #f7da66);
  -webkit-background-clip: text;
  display: flex;
  font-family: sanitarium_bbregular;
  font-size: 40px;
  font-weight: 700;
  justify-content: center;
  line-height: inherit;
  min-height: 48px;
  position: relative;
  color: black;
  -webkit-text-stroke-width: 0.1px;
  -webkit-text-stroke-color: #624905;
  /* background-image: linear-gradient(to right,
      #fbb706 0,
      #ebda6f 16%,
      #d9bc76 45%,
      #efe7cb 55%,
      #ffc107 48%,
      #cb9b51 78%,
      #ffeb3b 100%); */
}

.mrl-10p {
  margin-right: 10% !important;
}

.terms-footer {
  text-align: center;
  padding: 24px 0;
}

.terms-footer a {
  display: inline-block;
  margin-left: 0px;
  margin-right: 0px;
}

.terms-footer a:first-child {
  margin-left: 0;
  text-decoration: none;
}

.terms-footer a:last-child {
  margin-right: 0;
}

.spp-dropdown-menu {
  width: 75px;
  min-width: inherit;
  margin: 0;
  padding: 5px;
  border-radius: 5px;
  background: linear-gradient(180deg,
      #ffe570,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #421800 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%);
}

.spp-dropdown-menu a {
  padding: 5px;
  margin: 0;
  background-color: #fff;
}

.sd-top-box {
  text-align: center;
}

.pb-row-flex,
.sd-container-flex {
  display: flex;
  justify-content: center;
}

.pb-row-flex {
  align-items: flex-end;
  margin-top: 5px;
  margin-bottom: 5px;
}

.sd-ref-icon-1 {
  margin-left: 15px;
  margin-right: 15px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.sd-top-header-bx {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 520px;
  margin: auto;
  position: relative;
}

.sd-top-header-bx .sd-ref-icon-1 {
  position: absolute;
  left: 520px;
  white-space: nowrap;
  margin-right: 0;
}

.sd-post-by-name p {
  font-family: "droid_serifregular";
}

.sd-post-by-name h5 {
  font-family: "droid_serifbold";
  color: #ffe670;
  font-size: 22px;
  margin-top: 0;
}

.sd-post-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  width: 19px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
}

.sd-right-width-200 {
  width: 200px;
  position: relative;
  display: flex;
  align-items: flex-end;
}

.sd-post-id-bx {
  display: inline-block;
  border-radius: 300px;
  padding: 4px;
  background: linear-gradient(180deg,
      #ffe570,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #421800 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.68);
  position: absolute;
  right: 0;
  cursor: pointer;
  bottom: 2px;
}

.sd-post-id-bx p {
  min-width: 150px;
  border: 0;
  font-weight: 700;
  font-size: 20px;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  background: linear-gradient(180deg, #061855, #020a25);
  border-radius: 300px;
  padding: 6px 20px;
}

.sd-container-flex-wrap {
  position: relative;
}

.sd-right-icon-row {
  position: absolute;
  left: 15px;
}

.sd-right-icon-row .btn-close {
  display: none;
}

.sd-right-icon {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
}

.sd-right-icon:last-child {
  margin-bottom: 0;
}

.sd-right-icon p {
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  padding-left: 10px;
  text-transform: uppercase;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.symbol-btn {
  border: 0;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  position: relative;
}

.symbol-btn:hover {}

.symbol-btn:active {
  top: 3px;
}

.symbol-btn.active {
  opacity: 0.5;
}

.symbol-btn img {
  transition: transform 0.2s;
}

.symbol-btn:focus img {
  transform: scale(1.2);
}

.notcursor-totalsymbol {
  cursor: inherit;
  padding: 0;
  background-image: none;
  height: auto;
}

.notcursor-totalsymbol:hover {
  cursor: inherit;
}

.notcursor-totalsymbol:active {
  top: 0;
  opacity: 1;
}

.notcursor-totalsymbol:focus img {
  transform: inherit;
}

.sd-book-wrapper {
  max-width: 1021px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.sd-book-wrap-bx {
  background-size: contain;
}

.sd-paddingleft80 {
  padding: 29px 20px 0 64px;
}

.sd-paddingright80 {
  padding: 29px 89px 0 20px;
}

.max-with520 {
  max-width: 520px;
}

.sd-book-wrap-bx p {
  font-size: 16px;
  color: #041241;
  text-align: justify;
}

.max-width1080 {
  max-width: 1080px !important;
}

.conatct-form-box {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

.conatct-checkbox-rows {
  display: flex;
  margin-top: 30px;
}

.conatct-checkbox-rows .form-check .form-check-label {
  font-size: 18px;
}

.conatct-checkbox-rows .form-check {
  display: inline-block;
}

.st-cmn-btn:focus,
.st-cmn-btn:focus span i {
  opacity: 0.8;
}

.gridicons_abc {
  background-image: url("../../assets/images/abc-icon.svg");
}

.tags-btn {
  margin-top: 60px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.68);
}

.tags-btn,
.tags-btn span {
  border-radius: 50%;
}

.tags-btn span i {
  width: 72px;
  height: 72px;
}

.microphone-btn {
  width: 60px;
  height: 60px;
  background: transparent;
  border: 0;
  padding: 0;
}

.padd20tb {
  padding-top: 20px;
  padding-bottom: 20px;
}

.chat-room-box {
  max-width: 944px;
  margin: 0 auto;
  position: relative;
}

.chat-box-body {
  border-radius: 5px;
  padding: 8px;
  background: linear-gradient(180deg,
      #ffe570,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #421800 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%);
}

.chat-box-body,
.chat-box-body-inner {
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.25);
}

.chat-box-body-inner {
  background: linear-gradient(89.95deg,
      #b56a29 1.56%,
      #ca872e 7.01%,
      #d08f33 12.37%,
      #d39339 18.07%,
      #ecca70 26.75%,
      #faf8bd 39.42%,
      #daa74c 49.94%,
      #cf8c31 60.3%,
      #da8b2c 64.63%,
      #d48b2c 74.69%,
      #ce842b 81.32%,
      #f0b348 90.16%,
      #d58e34 94.34%,
      #c1752a 99.15%);
  position: relative;
  padding: 30px 30px 15px;
}

.chat-header-box {
  margin-bottom: 10px;
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 3px;
  background: linear-gradient(180deg,
      #ffe570,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #421800 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%);
}

.chat-header-box-inner {
  background-color: #6b0101;
}

.fi-input-field {
  max-width: 200px;
  margin: 0 auto;
}

.btn-ca-submit {
  padding: 0;
  background-color: transparent;
}

.go-btn {
  width: 100%;
  color: #fff;
}

.demo-user-box {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.demo-user-box .btn {
  margin-top: 15px;
  color: #fff;
}

.chat-cs-icon-rows {
  display: flex;
  justify-content: space-between;
}

.chat-cs-icon-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: #ff2600;
}

.chat-cs-icon-box i {
  font-size: 40px;
  color: #fff;
}

.chat-cs-icon-box span {
  color: #fff;
  font-size: 15px;
  margin-top: 10px;
}

.chat-subject-rows {
  display: flex;
  justify-content: center;
  overflow: auto;
}

.chat-subject-rows::-webkit-scrollbar {
  height: 7px;
  background: linear-gradient(180deg, #7d200f, #000);
  border-radius: 5px;
}

.chat-subject-rows::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: linear-gradient(90deg,
      #ffe570,
      #fffe99 9.18%,
      #bb9432 25.31%,
      #efd269 48.35%,
      #c09937 69.22%,
      #9e781a 83.69%,
      #f1d361);
}

.chat-subject-rows .chat-subject-col {
  text-align: center;
  padding: 10px 5px;
  border-right: 1px solid #3f0000;
  width: 33.333%;
}

.chat-subject-rows .chat-subject-col:last-child {
  border-right: 0;
}

.chat-subject-rows .chat-subject-col p {
  color: #ffe773;
  font-family: "droid_serifbold";
  font-size: 14px;
  white-space: nowrap;
}

.chat-subject-rows .chat-subject-col p.chat-subject-head {
  color: #ffe773;
  font-family: "droid_serifregular";
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  transition: 0.4s;
}

.toggle-switch input:checked+.toggle-slider {
  background-color: #2196f3;
}

.toggle-switch input:focus+.toggle-slider {
  box-shadow: 0 0 1px #2196f3;
}

.toggle-switch input:checked+.toggle-slider:before {
  transform: translateX(26px);
}

.toggle-slider.round {
  border-radius: 34px;
}

.toggle-slider.round:before {
  border-radius: 50%;
}

.demo-user-info {
  background-color: #fff;
  overflow: auto;
  max-height: 130px;
  max-width: 340px;
  padding: 15px;
  margin-top: 15px;
}

.askdiscussion-modal-dialog .modal-content {
  background-color: #072592;
}

.askdiscussion-modal-dialog .modal-content p {
  color: #fff;
}

.askdiscussion-modal-dialog .modal-content i {
  color: #fff;
  font-size: 50px;
  margin-top: 15px;
}

.discussion-user-box {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 5px 5px 5px 0;
  position: relative;
  min-height: 89px;
}

.chat-item-row {
  margin-bottom: 20px;
  padding: 3px;
  background: linear-gradient(180deg,
      #9a7517,
      #441a01 12.29%,
      #d8ab39 30%,
      #6b5318 44.06%,
      #d8ab39 62.29%,
      #441a01 81.56%,
      #826213);
}

.chat-item-row:last-child {
  margin-bottom: 0;
}

.discussion-user {
  width: 118px;
  position: relative;
  text-align: center;
}

.discussion-user img {
  width: 40px;
}

.discussion-user-box {
  padding: 8px;
}

.discussion-user-box .discussion-user-info {
  width: calc(100% - 118px);
  color: #fff;
}

.discussion-user p {
  font-size: 14px;
  color: #041241;
  font-family: "droid_serifbold";
  text-transform: uppercase;
}

.discussion-left-box {
  border-radius: 5px 5px 5px 0;
}

.discussion-right-box {
  border-radius: 5px 5px 0 5px;
}

.discussion-left-box .discussion-user-info {
  padding-left: 15px;
  font-size: 16px;
  color: #041241;
  padding-right: 80px;
  max-height: 75px;
  overflow: auto;
}

.discussion-left-box .discussion-user {
  border-right: 1px solid #c8bb9a;
}

.discussion-right-box .discussion-user-info {
  padding-right: 15px;
  text-align: right;
}

.discussion-right-box .discussion-user {
  border-left: 1px solid #611b35;
}

.discussion-body .chat-box-body-inner {
  padding: 20px;
}

.btn-discussClose {
  right: 36px;
  border: 0;
  padding: 0;
  background: transparent;
  width: 41px;
  height: 41px;
  cursor: pointer;
}

.btn-discussClose,
.knoker-bx {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
}

.knoker-bx {
  left: 0;
  right: 0;
  width: 75px;
  height: 75px;
}

.discussion-user-box .discussion-user-info::-webkit-scrollbar {
  width: 5px;
}

.discussion-user-box .discussion-user-info::-webkit-scrollbar-track {
  background: linear-gradient(180deg, #4e4e4e, #000);
  border-radius: 5px;
}

.discussion-user-box .discussion-user-info::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: linear-gradient(180deg,
      #ffe570,
      #fffe99 9.18%,
      #bb9432 25.31%,
      #efd269 48.35%,
      #c09937 69.22%,
      #9e781a 83.69%,
      #f1d361);
}

.discussion-leftrow-icon {
  position: absolute;
  top: 46%;
  left: 15px;
  transform: translateY(-50%);
}

.discussion-leftrow-icon a {
  display: block;
  width: 95px;
  height: 95px;
}

.chathome-box-body .read-story-scroll-bx {
  background: linear-gradient(89.94deg,
      #920e09 1.57%,
      #d11810 7.02%,
      #a3110a 12.37%,
      #d81511 18.08%,
      #ef0d18 26.75%,
      #b60005 39.42%,
      #ae1510 49.94%,
      #b91714 60.29%,
      #8c0d07 64.62%,
      #7f0a03 74.68%,
      #a61210 81.31%,
      #f50f19 90.14%,
      #eb0d16 94.33%,
      #950c09 99.13%);
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.25);
  padding: 20px 20px 20px 30px;
}

.chat-h-box {
  background: #d39339;
  background: linear-gradient(90deg,
      #d39339 0,
      #ecca70 20%,
      #faf8bd 50%,
      #daa74c 80%,
      #cf8c31);
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.25);
  text-align: center;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 205px;
}

.chat-h-top-bx {
  min-height: 147px;
  padding: 5px;
}

.chat-h-top-bx h5 {
  display: inline-block;
  color: #fff;
  font-size: 16px;
  font-family: "droid_serifbold";
  text-transform: uppercase;
  border-radius: 5px;
  padding: 2px;
  background: linear-gradient(180deg, #fff, #c9c9c9 50.31%, #969696 98.23%);
  margin-bottom: 5px;
}

.chat-h-top-bx h5 span {
  background: linear-gradient(180deg, red, #300303);
  padding: 5px 10px;
  border-radius: 5px;
  display: block;
}

.chat-h-top-bx-info {
  background: linear-gradient(180deg,
      #ffe46f,
      #fffd97 6.56%,
      #ba9331 23.23%,
      #f0d36a 48.23%,
      #ba9230 61.77%,
      #441a01 84.17%,
      #e3c254);
  padding: 3px;
  border-radius: 5px;
  width: 177px;
  margin: 0 auto;
}

.chat-h-top-bx-info .chat-h-top-bx-info-inner {
  background: linear-gradient(0deg, #6b0101, #6b0101), #041241;
  border-radius: 5px;
  padding: 8px;
}

.chat-h-top-bx-info p {
  color: #fff;
  font-size: 16px;
  font-family: "droid_serifregular";
}

.chat-h-item-box {
  border-radius: 5px;
  padding: 8px;
  background: linear-gradient(180deg,
      #ffe570,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #421800 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%);
}

.chat-h-btm-bx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 58px;
  padding: 18px 10px 0;
  background-size: cover;
}

.chat-h-btm-bx p {
  color: #fff;
  font-size: 16px;
  font-family: "droid_serifbold";
}

.avatar-wrapper {
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 8px;
  background: linear-gradient(180deg,
      #ffe570,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #421800 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%);
}

.avatar-wrapper-in {
  background: linear-gradient(180deg, #6b0101 41.61%, #240000 137.25%),
    linear-gradient(0deg, #fff, #fff);
  padding: 20px 20px 10px;
}

.avatar-input-bx {
  max-width: 376px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.avatar-input-bx .input-custom-field .form-control {
  background-color: #6b0101;
}

.avatar-choose-row {
  overflow: auto;
  text-align: center;
  white-space: nowrap;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-choose-row::-webkit-scrollbar {
  height: 11px;
  background: linear-gradient(180deg, #7d200f, #000);
  border: 3px solid transparent;
  border-radius: 5px;
}

.avatar-choose-row::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: linear-gradient(90deg,
      #ffe570,
      #fffe99 9.18%,
      #bb9432 25.31%,
      #efd269 48.35%,
      #c09937 69.22%,
      #9e781a 83.69%,
      #f1d361);
}

.avatar-choose-row .avatar-choose-radio-col {
  display: inline-block;
  width: 101px;
  height: 101px;
  position: relative;
}

.avatar-choose-radio-col input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.avatar-choose-radio-col input[type="radio"]:checked+.avatar-choose-radio-label,
.avatar-choose-radio-col input[type="radio"]:hover+.avatar-choose-radio-label {
  border-color: #ffe46f;
}

.avatar-choose-radio-label {
  width: 101px;
  height: 101px;
  border: 4px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}

.chat-mrt-30 {
  margin-top: 30px;
}

.appear-title-head {
  text-align: center;
  font-size: 24px;
  color: #fff;
  padding: 15px;
  background-color: #ff2600;
}

.ads-appear-item {
  background-color: #fff;
  text-align: center;
  height: 100%;
}

.ads-appear-item-info {
  padding: 30px;
}

.ads-appear-item h3 {
  background-color: #00092b;
  color: #fff;
  font-size: 20px;
  padding: 15px;
  display: block;
}

.ads-appear-item-info p {
  font-size: 16px;
  margin: 10px 0;
}

.ads-appear-item-info .highlight-txt {
  font-size: 18px;
  font-weight: 600;
  color: #00092b;
}

.ads-appear-mt40 {
  margin-top: 40px;
}

.upload-title-head {
  color: #00092b;
  font-size: 24px;
  padding: 15px;
  text-align: center;
}

.upload-img-box {
  height: 300px;
  position: relative;
  margin: 0 auto 15px;
}

.upload-img-box .file {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.upload-img-box .img-thumbnail {
  height: 300px;
  width: 100%;
}

.upload-camera-icon {
  font-weight: 700;
  font-size: 24px;
  margin: 20px 0;
  color: #333;
}

.upload-camera-icon i {
  color: #00092b;
  font-size: 55px;
  vertical-align: middle;
}

.btn-upload-camera,
.btn-upload-next {
  position: relative;
  overflow: hidden;
  color: #fff;
  font-size: 18px;
  min-width: 150px;
}

.btn-upload-camera input {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}

.upload-camera-info {
  color: #00092b;
  font-size: 16px;
  margin: 5px 0 15px;
}

.ads-title-head {
  font-size: 30px;
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
}

.ads-search-card {
  background: #072592;
  align-items: center;
}

.btn-ads-search {
  min-width: 130px;
  height: 48px;
  display: inline-block;
  color: #fff;
  line-height: 33px;
}

.finance-top-bar {
  background-color: #ff2600;
  padding-top: 15px;
}

.flex-finance-box {
  display: flex;
  justify-content: space-between;
}

.flex-finance-box-right {
  display: flex;
  align-items: center;
}

.margin-left15 {
  margin-left: 15px;
}

.opportunities-topbar h2 {
  color: #fff;
}

.opportunities-topbar .select-custom-field select {
  min-width: 185px;
}

.select-custom-field {
  background-color: #233234 !important;
}

.ort-mrt30 {
  margin-top: 30px;
}

.choose-avatar-footer {
  margin-top: 15px;
}

.instruction-modal-dialog.modal-dialog,
.language-modal-dialog.modal-dialog {
  flex-direction: column;
  justify-content: center;
}

.language-modal-dialog.modal-dialog .modal-content {
  background: url("../../assets/images/language-bg.png") top no-repeat !important;
  background-size: cover !important;
  border: 0;
  border-radius: 0;
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.25);
}

.language-modal-dialog.modal-dialog .modal-content .modal-body {
  padding: 25px 30px 20px 30px;
}

.language-title-head {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.width100p {
  width: 100%;
}

.disclaimer-modal-dialog.modal-dialog .modal-content .disclaimer-content-in {
  background: linear-gradient(90.05deg,
      #a8a8a8 -32.01%,
      #747272 -10.03%,
      #999898 3.46%,
      #e1d7d7 19.04%,
      #fffbfb 45.06%,
      #d1cfcf 79.49%,
      #646363 95.38%,
      #adacac 111.41%),
    linear-gradient(89.95deg,
      #b56a29 1.56%,
      #ca872e 7.01%,
      #d08f33 12.37%,
      #d39339 18.07%,
      #ecca70 26.75%,
      #faf8bd 39.42%,
      #daa74c 49.94%,
      #cf8c31 60.3%,
      #da8b2c 64.63%,
      #d48b2c 74.69%,
      #ce842b 81.32%,
      #f0b348 90.16%,
      #d58e34 94.34%,
      #c1752a 99.15%);
  min-height: 524px;
}

.border-style-9-gray {
  border-radius: 5px;
  padding: 8px;
  background: linear-gradient(180deg,
      #5b5b5b,
      #313131 13.74%,
      #121212 26.34%,
      #4d4d4d 47.87%,
      #2b2b2b 67.57%,
      #0b0b0b 78.56%,
      #383838 92.99%,
      #343434 101.93%);
}

.disclaimer-modal-dialog.modal-dialog .modal-content:after,
.disclaimer-modal-dialog.modal-dialog .modal-content:before {
  content: "";
  position: absolute;
  top: 0;
  background-position: 50%;
  background-repeat: no-repeat;
  width: 80px;
  height: 618px;
}

.disclaimer-modal-dialog.modal-dialog .modal-content:after {
  background-image: url("../../assets/images/stand-right.png");
  right: -80px;
}

.disclaimer-modal-dialog.modal-dialog .modal-content:before {
  background-image: url("../../assets/images/stand-left.png");
  left: -80px;
}

.disclaimer-modal-dialog.modal-dialog .modal-content {
  border: 0;
}

.disclaimer-info-scroll {
  max-height: 445px;
  overflow: auto;
  padding: 14px;
}

.disclaimer-info-scroll p {
  color: #041241;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
}

.disclaimer-info-scroll::-webkit-scrollbar {
  width: 5px;
}

.disclaimer-info-scroll::-webkit-scrollbar-track {
  background: linear-gradient(180deg, #4e4e4e, #000);
  border-radius: 5px;
}

.disclaimer-info-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 2px rgba(255, 255, 0, 0.3);
  background: linear-gradient(180deg,
      #ffe570,
      #fffe99 9.18%,
      #bb9432 25.31%,
      #efd269 48.35%,
      #c09937 69.22%,
      #9e781a 83.69%,
      #f1d361);
}

.attention-modal-dialog.modal-dialog {
  flex-direction: column;
}

.attention-modal-dialog .modal-content {
  background-color: #f2deaf;
  border: 0;
  border-radius: 0;
}

.hand-top-box {
  margin-left: auto;
  margin-right: auto;
}

.attention-modal-dialog.modal-dialog .modal-content {
  min-height: 562px;
}

.attention-modal-dialog.modal-dialog .modal-content:after,
.attention-modal-dialog.modal-dialog .modal-content:before {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.modal-align {
  margin: auto !important;
}

.attention-modal-dialog.modal-dialog .modal-content:after {
  background: url("../../assets/images/corner-style-top-left.png") 0 0 no-repeat,
    url("../../assets/images/corner-style-top-right.png") 100% 0 no-repeat;
  top: 0;
}

.attention-modal-dialog.modal-dialog .modal-content:before {
  background: url("../../assets/images/corner-style-bottom-left.png") 0 100% no-repeat,
    url("../../assets/images/corner-style-bottom-right.png") 100% 100% no-repeat;
  bottom: 0;
}

.attention-head-top-bx {
  background-image: url("../../assets/images/attention-men-bg.png");
  background-position: center top 10px;
  background-repeat: no-repeat;
  position: relative;
  z-index: 2;
  text-align: center;
}

.attention-head-top-bx h4 {
  font-size: 30px;
  font-weight: 600;
  color: #143dc5;
}

.attention-head-top-bx .cmn-title-head h2 {
  min-height: 40px;
}

.attention-modal-dialog.modal-dialog .modal-content .modal-body {
  margin: 0 42px;
  overflow: auto;
  position: relative;
  z-index: 3;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 5%;
  border-top: 8px solid #644210;
}

.attention-modal-dialog.modal-dialog .modal-content .modal-body::-webkit-scrollbar {
  width: 15px !important;
  background-color: #eee !important;
}

.attention-modal-dialog.modal-dialog .modal-content .modal-body p {
  color: #041241;
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
}

.attention-modal-dialog.modal-dialog .close-button-style {
  right: -16px;
  z-index: 3;
  top: -16px;
}

.attention-modal-footer {
  border-top: 0;
  justify-content: center;
}

.add-ads-wrapper {
  max-width: 946px;
  margin: 8% auto;
}

.add-ads-content {
  background: #e1d7d7;
  background: linear-gradient(90deg,
      #e1d7d7 0,
      #fffbfb 50%,
      #d1cfcf 75%,
      #777676);
  padding: 17px 34px;
}

.add-ads-col-row {
  margin-left: -14px;
  margin-right: -14px;
  display: flex;
}

.add-ads-col-50 {
  position: relative;
  padding-left: 14px;
  padding-right: 14px;
  width: 50%;
}

.add-ads-box {
  background: #d39339;
  background: linear-gradient(90deg,
      #d39339 0,
      #ecca70 20%,
      #faf8bd 50%,
      #daa74c 80%,
      #cf8c31);
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.25);
  text-align: center;
  justify-content: space-between;
  min-height: 374px;
}

.add-ads-box,
.add-ads-top-bx {
  display: flex;
  flex-direction: column;
  position: relative;
}

.add-ads-top-bx {
  align-items: center;
  justify-content: center;
  min-height: 199px;
  margin-bottom: -40px;
}

.add-ads-top-bx h5 {
  font-weight: 700;
  font-size: 22px;
  text-transform: uppercase;
  color: #041241;
  font-family: "droid_serifbold";
  margin-top: 20px;
  margin-bottom: 20px;
}

.add-ads-top-bx img {
  transition: transform 0.2s;
}

.add-ads-item-box:hover .add-ads-top-bx img {
  transform: scale(1.1);
}

.add-ads-banner-box,
.add-ads-item-box {
  border-radius: 5px;
  padding: 8px;
  background: linear-gradient(180deg,
      #ffe570,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #421800 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%);
}

.add-ads-banner-box:hover,
.add-ads-item-box:hover {
  background: linear-gradient(0deg,
      #ffe570,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #421800 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%);
  cursor: pointer;
}

.add-ads-btm-bx {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 130px;
  padding-top: 50px;

  background: url("../../assets/images/shape-add-ads-top.png") top no-repeat;
  background-size: cover;
}

.add-ads-banner-info p,
.add-ads-btm-bx p {
  line-height: 0px;
  font-size: 14px;
  color: #fff;
  font-family: "droid_serifbold";
  text-transform: uppercase;
  text-shadow: -1px -1px 0 var(--bs-red), 0 -1px 0 #ff9e01;
}

.add-ads-banner-info span,
.add-ads-btm-bx span.add-ads-subject-txt {
  color: #ffea82;
  font-size: 18px;
  font-family: Poppins, sans-serif;
  display: block;
  font-weight: 600;
}

.add-ads-banner-box {
  margin-top: 7px;
}

.add-ads-banner-box-in {
  background: linear-gradient(90.06deg,
      #6b0101 0.04%,
      #d10606 8.1%,
      #5c0000 50.18%,
      #d10606 94.35%,
      #6b0101 99.95%),
    linear-gradient(0deg, #fff, #fff);
  text-align: center;
}

.add-ads-banner-info {
  padding: 16px;
}

.add-ads-banner-info h5 {
  font-size: 22px;
  color: #fff;
  font-family: "droid_serifbold";
  text-transform: uppercase;
  margin-bottom: 15px;
}

.add-ads-banner-info span {
  margin-top: 5px;
}

.ads-ad-title {
  position: relative;
  margin-bottom: 10px;
  margin-top: -10px;
}

.add-advertisement-content .cmn-title-head {
  margin-bottom: 10px;
}

.add-advertisement-content .cmn-title-head h2 {
  font-size: 35px;
}

.add-advertisement-wrapper {
  max-width: 1145px;
  margin: 0 auto;
}

.add-advertisement-content {
  background: linear-gradient(90.05deg,
      #a8a8a8 -32.01%,
      #747272 -10.03%,
      #999898 3.46%,
      #e1d7d7 19.04%,
      #fffbfb 42.85%,
      #d1cfcf 63.76%,
      #646363 86.82%,
      #adacac 111.41%),
    linear-gradient(89.95deg,
      #b56a29 1.56%,
      #ca872e 7.01%,
      #d08f33 12.37%,
      #d39339 18.07%,
      #ecca70 26.75%,
      #faf8bd 39.42%,
      #daa74c 49.94%,
      #cf8c31 60.3%,
      #da8b2c 64.63%,
      #d48b2c 74.69%,
      #ce842b 81.32%,
      #f0b348 90.16%,
      #d58e34 94.34%,
      #c1752a 99.15%);
  padding: 10px 20px;
  overflow-x: hidden;
  overflow-y: auto;
  display: grid;
}

.BlockClass {
  display: block;
}

.language-modal-dialog.modal-dialog .modal-content {
  min-height: 350px;
}

.starting-logo {
  top: 5px;
  position: absolute;
  transform: translateY(-40%) scale(0.23);
  margin: auto;
}

.duration_animation_logo {
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
}

.duration_animation_logo {
  animation-duration: 7s;
  -webkit-animation-duration: 7s;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

.duration_animation_bg {
  animation-duration: 5s;
  -webkit-animation-duration: 5s;
  animation-delay: 7s;
  -webkit-animation-delay: 7s;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

.duration_animation_1 {
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  animation-delay: 7.3s;
  -webkit-animation-delay: 7.3s;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

.duration-welcome-fadeOut {
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

.duration-animation-choice-home {
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  animation-delay: 4s;
  -webkit-animation-delay: 4s;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

.duration-animation-choice {
  animation-duration: 7s;
  -webkit-animation-duration: 7s;
  animation-delay: 4s;
  -webkit-animation-delay: 4s;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

@-webkit-keyframes zoomInlogo {
  0% {
    transform: scale(0.1);
  }

  50% {
    transform: scale(0.9);
  }

  to {
    transform: translateY(-40%) scale(0.23);
    -webkit-backface-visibility: hidden;
  }
}

@keyframes zoomInlogo {
  0% {
    transform: scale(0.1);
  }

  50% {
    transform: scale(0.9);
  }

  to {
    transform: translateY(-40%) scale(0.23);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}

.animate__zoomInlogo {
  -webkit-animation-name: zoomInlogo;
  animation-name: zoomInlogo;
}

@-webkit-keyframes fadeInBg {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInBg {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animation-time-title {
  animation-duration: 5s;
  -webkit-animation-duration: 5s;
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

.animation-time-close,
.animation-time-subtitle {
  animation-duration: 5s;
  -webkit-animation-duration: 5s;
  animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

.animation-time-close2 {
  animation-duration: 5s;
  -webkit-animation-duration: 5s;
  animation-delay: 2s;
  -webkit-animation-delay: 2s;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

.displayNone {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.zindex1 {
  z-index: -1;
}

.add-adv-btngroup .mt-btn:active {
  top: 2px;
}

.add-adv-btngroup .mt-btn:focus i img,
.add-adv-btngroup .mt-btn:hover i img {
  transform: scale(1.2);
}

.camera-icon-ads i img {
  max-width: 30px;
}

.upload-icon-ads i img {
  max-width: 40px;
}

.keyboard-icon-ads i img {
  max-width: 58px;
}

.add-adv-btngroup {
  max-width: 764px;
}

.post-id-modal .sd-post-id-bx {
  position: inherit;
  margin-bottom: 10px;
}

.add-adv-btngroup-middle {
  max-width: 320px;
}

.add-ads-col-1 {
  width: 206px;
}

.add-ads-col-2 {
  width: 342px;
  margin: 0 20px;
}

.add-ads-col-3 {
  width: 150px;
}

.add-ads-button-row {
  display: flex;
}

.btn-bor-0 {
  border: 0;
  background: transparent;
  padding: 0;
  cursor: pointer;
}

.add-ads-button-left button {
  margin: 4px;
  width: 58px;
  height: 58px;
}

.add-ads-button-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.add-ads-button-right button {
  width: 68px;
  height: 68px;
  margin: 4px;
}

.border-style-ads {
  padding: 6px;
  border-radius: 5px;
  background: linear-gradient(180deg,
      #1f9d24,
      #95ff93 12.81%,
      #2fa734 31.25%,
      #4cce36 56.25%,
      #11400d 82.72%,
      #4dba51);
}

.add-ads-banner-editimg {
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 200px;
  background-color: #fff;
}

.segment-div {
  height: 200px;
}

.segment-div3 {
  height: 300px;
}

.add-ads-banner-edit2 {
  position: relative;
  margin-top: 15px;
}

.add-ads-banner-editimg-info {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 8px;
}

.add-ads-transparent-bg {
  background: rgba(225, 215, 215, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.add-ads-banner-editimg-info h1,
.add-ads-banner-editimg-info h2,
.add-ads-banner-editimg-info h3,
.add-ads-banner-editimg-info h4,
.add-ads-banner-editimg-info h5,
.add-ads-banner-editimg-info h6,
.add-ads-banner-editimg-info p {
  font-size: revert;
  word-break: break-all;
}

.second-braket {
  font-size: 156px;
  color: #041241;
  font-family: "droid_serifregular";
  margin-right: 10px;
  display: inline-block;
}

.add-ads-lock {
  display: flex;
  align-items: center;
}

.view-classified-wrapper {
  max-width: 870px;
  margin-left: auto;
  margin-right: auto;
}

.view-classified-content {
  background: linear-gradient(90.05deg,
      #a8a8a8 -32.01%,
      #747272 -10.03%,
      #999898 3.46%,
      #e1d7d7 19.04%,
      #fffbfb 42.85%,
      #d1cfcf 63.76%,
      #646363 86.82%,
      #adacac 111.41%);
  padding: 17px 28px;
}

.view-classified-col-1 {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.view-classified-col-2 {
  width: 342px;
  margin-left: 15px;
  margin-right: 15px;
}

.view-classified-btngroup {
  max-width: 360px;
  margin: 15px auto 0;
  padding: 0;
}

.view-classified-fields .cmn-title-head {
  margin-bottom: 15px;
}

.view-classified-fields .cmn-title-head h2 {
  font-size: 32px;
  min-height: 32px;
}

.vc0mrt-30 {
  margin-top: 15px;
}

.view-classified-bannerimg {
  min-height: 310px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  display: flex;
  align-items: flex-end;
  position: relative;
}

.view-classified-bannerimg:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg,
      hsla(0, 0%, 100%, 0) 0,
      hsla(0, 0%, 100%, 0.6));
}

.view-classified-bannerimg-info {
  padding: 15px;
  position: relative;
  z-index: 2;
}

.view-classified-bannerimg-info h2 {
  font-weight: 700;
  font-size: 22px;
  text-transform: uppercase;
  color: #041241;
}

.view-classified-bannerimg-info p {
  font-weight: 500;
  font-size: 16px;
  color: #041241;
}

.view-book-banner .view-classified-bannerimg {
  min-height: 420px;
}

.add-banner-top-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 590px;
  margin-left: auto;
  margin-right: auto;
}

.add-banner-top-box .input-custom-field {
  width: 302px;
}

.add-banner-top-box .btn-bor-0 {
  width: 58px;
  height: 58px;
}

.add-banner-banner {
  max-width: 752px;
  margin: 15px auto;
}

.add-banner-banner .add-ads-banner-editbnr {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  background-color: #f2e6c8;
  height: 80px;
}

.add-banner-banner .add-ads-banner-editbnr p {
  color: #041241;
  font-size: 22px;
  font-weight: 500;
  padding: 20px;
}

.textarea-form-control {
  border: 0;
  background: transparent;
  width: 100%;
  padding: 15px;
  color: #041241;
  font-size: 16px;
  font-weight: 500;
  height: 80px;
  resize: none;
}

.textarea-form-control:focus {
  outline: 0;
}

.textarea-form-control::-moz-placeholder {
  color: #041241;
}

.textarea-form-control::placeholder {
  color: #041241;
}

.view-banner-wrapper {
  margin-left: auto;
  margin-right: auto;
}

.view-banner-col-2 {
  margin-left: 30px;
  margin-right: 30px;
  width: 540px;
}

.gallary-modal-dialog.modal-dialog .modal-content,
.opportunity-modal-dialog.modal-dialog .modal-content {
  border: 0;
  position: relative;
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.25);
}

.opportunity-modal-dialog.modal-dialog .modal-body {
  padding: 35px 25px;
  background: linear-gradient(90.05deg,
      #a8a8a8 -32.01%,
      #747272 -10.03%,
      #999898 3.46%,
      #e1d7d7 19.04%,
      #fffbfb 42.85%,
      #d1cfcf 63.76%,
      #646363 86.82%,
      #adacac 111.41%);
}

.finance-modal-dialog.modal-dialog .close-button-style,
.gallary-modal-dialog.modal-dialog .close-button-style,
.opportunity-modal-dialog.modal-dialog .close-button-style {
  top: -18px;
  right: -18px;
}

.opportunity-modal-dialog .modal-body .opportunity-scroll-box {
  max-height: 350px;
  min-height: 350px;
  overflow-x: hidden;
  overflow-y: auto;
}

.opportunity-ul-row {
  width: 100%;
  -moz-column-count: 3;
  column-count: 3;
  -moz-column-gap: 1em;
  column-gap: 1em;
  padding: 0;
  -moz-column-gap: 1.5em;
  column-gap: 1.5em;
  font-size: 0.85em;
}

.opportunity-ul-row li {
  width: 100%;
  height: 310px;
  overflow-y: hidden;
  margin-bottom: 14px;
  display: inline-block;
  -webkit-transition: all 1s ease;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.opportunity-ul-row .opportunity-img-block {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.opportunity-ul-row .opportunity-img-block img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.finance-modal-dialog.modal-dialog .modal-content {
  border: 0;
  border-radius: 0;
  background: transparent;
}

.finance-modal-dialog.modal-dialog .modal-body {
  display: flex;
  padding: 0;
}

.finance-left-col {
  width: 245px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.finance-right-col {
  width: calc(100% - 265px);
  margin-left: 20px;
}

.finance-bar-content {
  background: linear-gradient(90deg,
      #050c39 4.32%,
      #04145c 22.71%,
      #0d2ca0 32.64%,
      #1747e9 46.64%,
      #071963 71%,
      #040d41 85.26%,
      #020a37);
  padding: 15px 25px;
  height: 100%;
}

.finance-left-info-border {
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  background: linear-gradient(180deg,
      #ffe570,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #421800 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%);
}

.finance-left-info {
  background: linear-gradient(90deg,
      #050c39 4.32%,
      #04145c 22.71%,
      #0d2ca0 32.64%,
      #1747e9 46.64%,
      #071963 71%,
      #040d41 85.26%,
      #020a37);
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.25);
  padding: 10px 15px;
}

.finance-left-info p {
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  margin: 6px 0;
}

.calendar-read-icon-bx {
  margin: 15px 0;
  position: relative;
}

.calendar-read-icon-bx input {
  position: absolute;
  opacity: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.finance-input-id-left {
  width: 100%;
}

.finance-input-id-left.input-custom-field .form-control {
  background: linear-gradient(183.43deg,
      #050e41 -75.46%,
      #061764 -48.5%,
      #2152f7 4.93%,
      #0d2cab 39.64%,
      #081355 77.13%,
      #040c3d 97.41%);
}

.add-book-col-right .second-braket {
  font-size: 120px;
  line-height: 140px;
}

.add-book-col-right .red-lock .second-braket {
  color: #ed152f;
}

.add-book-banner-edit .add-ads-banner-editimg {
  min-height: 100px;
}

.add-book-banner-edit .add-ads-banner-editimg .tox-tinymce {
  border: 0 !important;
}

.add-book-banner-edit .add-ads-banner-editimg .tox .tox-edit-area__iframe {
  background: none !important;
}

.add-book-banner-edit2 {
  margin-top: 5px;
}

.add-book-banner-edit2 .add-ads-banner-editimg {
  min-height: 200px;
}

.add-book-banner-edit2 .add-ads-banner-editimg-info {
  top: inherit;
}

.modal.fade .modal-dialog.attention-modal-dialog,
.modal.fade .modal-dialog.contact-modal-dialog,
.modal.fade .modal-dialog.disclaimer-modal-dialog,
.modal.fade .modal-dialog.instruction-modal-dialog,
.modal.fade .modal-dialog.language-modal-dialog,
.modal.fade .modal-dialog.menutop-modal-dialog,
.modal.fade .modal-dialog.terms-modal-dialog {
  transform: translate(0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.zoom-in {
  transition: all 2s 0s;
  display: block !important;
}

.zoom-in.show {
  transform: translate(0) scale(1) !important;
}

.zoom-in.zoom-in-left.show {
  left: 0;
}

.zoom-in-left {
  transform: translate(-12%, -5%) scale(0) !important;
  left: -12%;
}

.zoom-in-center {
  transform: translateY(-5%) scale(0) !important;
  opacity: 0;
  transition: all 1.5s 0s;
  display: block !important;
}

.zoom-in-center.show {
  opacity: 1;
  transform: translateY(-5%) scale(1) !important;
}

.language-modal-dialog.modal-dialog .modal-content:after {
  content: "";
  position: absolute;
  left: -8px;
  right: -8px;
  top: -8px;
  bottom: -8px;
  z-index: -1;
  border-radius: 5px;
  background: linear-gradient(180deg,
      #ffe570,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #421800 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%);
}

.disclaimer-modal-dialog.modal-dialog .close-button-style {
  top: -20px;
  right: -7px;
}

.btn-ads-file-uploads {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}

.btn-ads-file-uploads button {
  cursor: pointer;
}

.btn-ads-file-uploads input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.sd-title-md {
  margin-bottom: 10px;
}

.sd-title-md h2 {
  color: #fec700;
  font-size: 40px;
}

.duration_animation_terms {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

.btn-agree,
.btn-dontagree {
  display: inline-block;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.68);
  border-radius: 5px;
  background: linear-gradient(180deg,
      #ffe773,
      #fffa93 12.81%,
      #c09b37 31.25%,
      #e7c75f 56.25%,
      #512704 82.72%,
      #efd160);
  padding: 4px;
  position: relative;
}

.btn-agree span,
.btn-dontagree span {
  background: linear-gradient(180deg, #f34444, #7b0000),
    linear-gradient(180deg, #061855, #020a25), #c4c4c4;
  display: block;
  line-height: 39px;
  padding-left: 15px;
  padding-right: 46px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
  color: #fff;
  letter-spacing: 0.1em;
  position: relative;
  min-width: 153px;
  min-height: 39px;
}

.btn-agree:after,
.btn-dontagree:after {
  content: "";
  width: 74px;
  height: 74px;
  position: absolute;
  top: -12px;
  right: -30px;
  margin: auto;
  background: url("../../assets/images/cross-icon.svg") 50% no-repeat;
  background-size: contain;
  cursor: pointer;
}

.btn-agree span {
  background: linear-gradient(180deg, #061855, #020a25), #c4c4c4;
  cursor: pointer;
}

.btn-agree:after {
  background: url("../../assets/images/agree-icon.svg") 50% no-repeat;
  background-size: contain;
}

.view-book-page-dt .view-classified-col-2,
.view-classified-page-dt .view-classified-col-2 {
  width: 612px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-book-page-dt .view-classified-bannerimg,
.view-classified-page-dt .view-classified-bannerimg {
  min-height: inherit;
}

.add-book-ads-col-2 {
  width: calc(100% - 206px);
  padding-left: 90px;
}

.add-bookpage-banner-edit {
  display: flex;
}

.banner-item-row {
  display: flex;
  align-items: center;
}

.add-ads-banner-edit {
  width: 312px;
}

.add-bookpage-btn-lf {
  margin-right: 15px;
  width: 68px;
}

.banner-cmn-btn {
  border: 0;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: linear-gradient(180deg,
      #ffe773,
      #fffa93 12.81%,
      #c09b37 31.25%,
      #e7c75f 56.25%,
      #512704 82.72%,
      #efd160);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.68);
  padding: 4px;
  cursor: pointer;
  position: relative;
}

.banner-cmn-btn:focus,
.banner-cmn-btn:hover {
  background: linear-gradient(0deg,
      #ffe773,
      #fffa93 12.81%,
      #c09b37 31.25%,
      #e7c75f 56.25%,
      #512704 82.72%,
      #efd160);
}

.banner-cmn-btn:active {
  top: 2px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.68);
}

.banner-cmn-btn span {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(50% 50% at 50% 50%, #f34444 0, #7b0000 100%);
}

.banner-cmn-btn span .banner-cmn-btn-icon {
  width: 30px;
  height: 30px;
  display: block;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform 0.2s;
}

.banner-cmn-btn:focus span .banner-cmn-btn-icon,
.banner-cmn-btn:hover span .banner-cmn-btn-icon {
  transform: scale(1.2);
}

.gridicons_trash {
  background-image: url("../../assets/images/gridicons_trash_red.svg");
}

.gridicons_close {
  background-image: url("../../assets/images/cross-icon-gradient.svg");
  background-size: 22px !important;
}

.gridicons_download {
  background-image: url("../../assets/images/eva_download-outline.svg");
}

.bnr-download-btn span {
  background: radial-gradient(50% 50% at 50% 50%, #082174 0, #031038 100%);
}

.banner-link-box {
  width: 342px;
  float: right;
}

.ab-mrt15 {
  margin-top: 10px;
}

.locks-mrt58 {
  margin-top: 58px;
}

.locks-mrt25 {
  margin-top: 25px;
}

.locks-mrt115 {
  margin-top: 115px;
}

.add-bookpage-right-locks {
  margin-left: 10px;
}

.add-bookpage-right-locks .second-braket {
  font-size: 100px;
  line-height: 100px;
}

.book-flex-column {
  flex-direction: column;
}

.attention-modal-dialog .close-button-style:active,
.disclaimer-modal-dialog .close-button-style:active,
.finance-modal-dialog .close-button-style:active,
.gallary-modal-dialog .close-button-style:active,
.guide-terms-dialog .close-button-style:active,
.instruction-modal-dialog .close-button-style:active,
.language-modal-dialog .close-button-style:active,
.opportunity-modal-dialog .close-button-style:active,
.readastory-modal-dialog .close-button-style:active,
.thankstype-modal-dialog .close-button-style:active {
  margin-top: 2px;
  background-color: transparent;
}

.close-button-style span:before {
  content: "";
  height: 20px;
  width: 20px;
  display: inline-block;
  background: url("../../assets/images/cross-icon-gradient.svg") 50% no-repeat;
  background-size: contain;
  position: absolute;
  opacity: 1;
  transition: 0.5s;
}

.close-button-style:hover span:before {
  opacity: 0;
  left: -20px;
}

.close-button-style span:after {
  content: "";
  height: 25px;
  width: 25px;
  display: inline-block;
  background: url("../../assets/images/back-arrow-gradient-small.png") 50% no-repeat;
  background-size: contain;
  position: absolute;
  opacity: 0;
  top: 10px;
  right: -20px;
  transition: 0.5s;
}

.close-button-style:hover span:after {
  opacity: 1;
  right: 15px;
}

.editor-box-center {
  text-align: center;
}

.edit-text-styles {
  padding: 1rem;
  font-family: inherit;
  overflow: hidden;
}

.edit-text-styles h1 {
  font-size: 2em;
  -webkit-margin-before: 0.67em;
  margin-block-start: 0.67em;
  -webkit-margin-after: 0.67em;
  margin-block-end: 0.67em;
  -webkit-margin-start: 0;
  margin-inline-start: 0;
  -webkit-margin-end: 0;
  margin-inline-end: 0;
  line-height: 1;
}

.edit-text-styles h1,
.edit-text-styles h2 {
  display: block;
  font-weight: 700;
  font-family: inherit;
}

.edit-text-styles h2 {
  font-size: 1.5em;
  -webkit-margin-before: 0.83em;
  margin-block-start: 0.83em;
  -webkit-margin-after: 0.83em;
  margin-block-end: 0.83em;
  -webkit-margin-start: 0;
  margin-inline-start: 0;
  -webkit-margin-end: 0;
  margin-inline-end: 0;
}

.edit-text-styles h3 {
  font-size: 1.17em;
  -webkit-margin-before: 1em;
  margin-block-start: 1em;
  -webkit-margin-after: 1em;
  margin-block-end: 1em;
  -webkit-margin-start: 0;
  margin-inline-start: 0;
  -webkit-margin-end: 0;
  margin-inline-end: 0;
}

.edit-text-styles h3,
.edit-text-styles h4 {
  display: block;
  font-weight: 700;
  font-family: inherit;
}

.edit-text-styles h4 {
  -webkit-margin-before: 1.33em;
  margin-block-start: 1.33em;
  -webkit-margin-after: 1.33em;
  margin-block-end: 1.33em;
  -webkit-margin-start: 0;
  margin-inline-start: 0;
  -webkit-margin-end: 0;
  margin-inline-end: 0;
}

.edit-text-styles h5 {
  font-size: 0.83em;
  -webkit-margin-before: 1.67em;
  margin-block-start: 1.67em;
  -webkit-margin-after: 1.67em;
  margin-block-end: 1.67em;
  -webkit-margin-start: 0;
  margin-inline-start: 0;
  -webkit-margin-end: 0;
  margin-inline-end: 0;
}

.edit-text-styles h5,
.edit-text-styles h6 {
  display: block;
  font-weight: 700;
  font-family: inherit;
}

.edit-text-styles h6 {
  font-size: 0.67em;
  -webkit-margin-before: 2.33em;
  margin-block-start: 2.33em;
  -webkit-margin-after: 2.33em;
  margin-block-end: 2.33em;
}

.edit-text-styles h6,
.edit-text-styles p {
  -webkit-margin-start: 0;
  margin-inline-start: 0;
  -webkit-margin-end: 0;
  margin-inline-end: 0;
}

.edit-text-styles p {
  display: block;
  font-family: inherit;
  font-size: medium;
}

@-webkit-keyframes bounceLeft {

  0%,
  20%,
  50%,
  80%,
  to {
    transform: translateX(0);
  }

  40% {
    transform: translateX(15px);
  }

  60% {
    transform: translateX(10px);
  }
}

@keyframes bounceLeft {

  0%,
  20%,
  50%,
  80%,
  to {
    transform: translateX(0);
  }

  40% {
    transform: translateX(15px);
  }

  60% {
    transform: translateX(10px);
  }
}

@-webkit-keyframes bounceRight {

  0%,
  20%,
  50%,
  80%,
  to {
    transform: translateX(0);
  }

  40% {
    transform: translateX(-15px);
  }

  60% {
    transform: translateX(-10px);
  }
}

@keyframes bounceRight {

  0%,
  20%,
  50%,
  80%,
  to {
    transform: translateX(0);
  }

  40% {
    transform: translateX(-15px);
  }

  60% {
    transform: translateX(-10px);
  }
}

.cmn-submit-button:active {
  top: 2px;
}

.read-btn-bg span {
  background: radial-gradient(50% 50% at 50% 50%, #082174 0, #031038 100%);
}

.write-btn-bg span {
  background: radial-gradient(50% 50% at 50% 50%, #035834 0, #022315 100%);
}

.chatroom-btn-bg span {
  background: radial-gradient(50% 50% at 50% 50%, #9c2813 0, #431007 100%);
}

.cmn-submit-button:hover span:after {
  opacity: 1;
  right: 14px;
}

.editor-box-flex {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #211400;
  border-radius: 5px;
  position: relative;
}

.editor-box-flex .mrrigt-15 {
  margin-right: 10px;
  margin-left: 10px;
  left: 85px;
}

.editor-box-flex .mrlft-15 {
  margin-left: 10px;
  margin-right: 10px;
  right: 85px;
}

.editor-box-flex .banner-cmn-btn {
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  z-index: 9;
}

.editor-box-flex .banner-cmn-btn:active {
  top: calc(60% + 2px);
}

.editor-box-flex .tox .tox-sidebar-wrap {
  max-width: 300px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.editor-box-flex .tox-tinymce {
  border: 0;
}

.terms-modal-dialog.modal-dialog .modal-content {
  border: 0;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  background: transparent;
}

.add-ads-lock button.banner-cmn-btn {
  width: 68px;
  height: 68px;
}

.add-ads-lock button.btn-unlock span {
  background: radial-gradient(50% 50% at 50% 50%, #5bd351 0, #193414 100%);
}

.add-ads-lock button.btn-lock span {
  background: radial-gradient(50% 50% at 50% 50%, #f31f1f 0, #270505 100%);
}

.add-ads-lock button.banner-cmn-btn span {
  width: 60px;
  height: 60px;
}

.add-ads-lock button.banner-cmn-btn span .banner-cmn-btn-icon {
  width: 30px;
  height: 30px;
}

.gridicons_unlock {
  background-image: url("../../assets/images/unlock-icon.svg");
}

.gridicons_lock {
  background-image: url("../../assets/images/unlock-icon.svg");
}

.baket-color {
  color: #ee1530;
}

.btn-color-platte {
  border: 0;
  border-radius: 50%;
  background: linear-gradient(180deg,
      #ffe773,
      #fffa93 12.81%,
      #c09b37 31.25%,
      #e7c75f 56.25%,
      #512704 82.72%,
      #efd160);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.68);
  padding: 4px;
  cursor: pointer;
  position: relative;
}

.btn-color-platte:focus,
.btn-color-platte:hover {
  background: linear-gradient(0deg,
      #ffe773,
      #fffa93 12.81%,
      #c09b37 31.25%,
      #e7c75f 56.25%,
      #512704 82.72%,
      #efd160);
}

.btn-color-platte:active {
  top: 2px;
  box-shadow: 0 4px 6px rgb(0, 0, 0, 68%);
}

.btn-color-platte span {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: block;
}

.pbg-color-1 span {
  background: radial-gradient(83.62% 83.62% at 50% 50%,
      #62ffff 0,
      #3c828b 100%);
}

.pbg-color-2 span {
  background: radial-gradient(81.9% 81.9% at 50% 50%, #ffda17 0, #c09600 100%);
}

.pbg-color-3 span {
  background: radial-gradient(50% 50% at 50% 50%, #47fb2a 0, #13490b 100%);
}

.pbg-color-4 span {
  background: radial-gradient(87.07% 87.07% at 50% 50%,
      #ff8a00 0,
      #562a01 100%);
}

.pbg-color-5 span {
  background: radial-gradient(80.17% 80.17% at 50% 50%,
      #e6e6e6 0,
      #454545 100%);
}

.pbg-color-6 span {
  background: radial-gradient(76.72% 76.72% at 50% 50%,
      #c521ff 0,
      #4b0a62 100%);
}

.pbg-color-7 span {
  background: radial-gradient(87.93% 87.93% at 50% 50%, #fff 0, #cdcdcd 100%);
}

.pbg-color-8 span {
  background: radial-gradient(70.69% 70.69% at 50% 50%,
      #ffa800 0,
      #b87900 100%);
}

.pbg-color-9 span {
  background: radial-gradient(66.38% 66.38% at 50% 50%,
      #f50b0b 0,
      #9a0707 100%);
}

.ab-btn-pls span {
  background: linear-gradient(0deg, #3d290c -0.33%, #724f1b 99.96%);
}

.gridicons_plus {
  background-image: url("../../assets/images/plus-icon.svg");
}

.gridicons_minus {
  background-image: url("../../assets/images/minus-icon.svg");
}

.gallary-modal-dialog.modal-dialog .modal-body {
  padding: 25px;
  background: #fff;
  text-align: center;
}

.banner-item-row .add-bookpage-btn-lf .banner-cmn-btn {
  background: transparent;
  box-shadow: none;
}

.banner-item-row .add-bookpage-btn-lf .banner-cmn-btn span {
  background: transparent;
}

.banner-item-row .add-bookpage-btn-lf .banner-cmn-btn-icon {
  width: 40px;
  height: 40px;
}

.instrution-btn-prev {
  background-image: url("../../assets/images/next-button-icon.svg");
}

.instrution-btn-prev {
  transform: rotate(180deg);
}

.guide-terms-dialog .terms-wrapper {
  position: relative;
}

.guide-terms-dialog .terms-wrapper .close-button-style {
  right: -10px;
  top: -20px;
}

.contact-modal-dialog .modal-content {
  border: 0;
  box-shadow: none;
  background-color: transparent;
}

.contact-modal-dialog .modal-body .contact-body-in {
  background: linear-gradient(90.05deg,
      #a8a8a8 -32.01%,
      #747272 -10.03%,
      #999898 3.46%,
      #e1d7d7 19.04%,
      #fffbfb 45.06%,
      #d1cfcf 79.49%,
      #646363 95.38%,
      #adacac 111.41%);
  padding: 10px 28px;
}

.contact-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact-checkbox-item {
  width: 25%;
  display: flex;
  justify-content: center;
}

.contact-checkbox-item input {
  padding: 0;
  height: auto;
  width: auto;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.contact-checkbox-item label {
  position: relative;
  cursor: pointer;
  font-family: "droid_serifbold";
  font-size: 13px;
  color: #041241;
}

.contact-checkbox-item label:before {
  content: "";
  -webkit-appearance: none;
  background: url("../../assets/images/check-bx-bg.svg") 50% no-repeat;
  background-size: contain;
  width: 36px;
  height: 36px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 8px;
}

.contact-checkbox-item input:checked+span:after {
  content: "";
  width: 24px;
  height: 35px;
  display: block;
  background-image: url("../../assets/images/tick-icon.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 7px;
}

.contact-textarea-bx {
  border-radius: 5px;
  padding: 3px;
  background: linear-gradient(180deg,
      #9a7517,
      #441a01 12.29%,
      #d8ab39 30%,
      #6b5318 44.06%,
      #d8ab39 62.29%,
      #441a01 81.56%,
      #826213);
  line-height: 0;
}

.contact-textarea-bx textarea {
  width: 100%;
  height: 320px;
  border: 0;
  background: url("../../assets/images/contact-paper-bg.png") top repeat-y;
  resize: none;
  line-height: 35px;
  font-size: 16px;
  font-family: Poppins, sans-serif;
  color: #041241;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
}

.contact-textarea-bx textarea:focus {
  outline: 0;
}

.contact-topic-field {
  background: linear-gradient(180deg,
      #ffe46f,
      #fffd97 6.56%,
      #ba9331 23.23%,
      #f0d36a 48.23%,
      #ba9230 61.77%,
      #441a01 84.17%,
      #e3c254);
  margin-bottom: 10px;
}

.contact-topic-field.input-custom-field .form-control {
  background: linear-gradient(90deg,
      #3c3b3b,
      #c5bebe 18.4%,
      #625e5e 31.4%,
      #c6c5c5 46.64%,
      #555151 61.84%,
      #706d6d 75.86%,
      #adacac 89.71%,
      #363434),
    linear-gradient(0deg, #422d0d, #422d0d), #041241;
  text-align: center;
  font-family: "droid_serifbold";
  font-size: 18px;
  color: #041241;
}

.contact-topic-field.input-custom-field .form-control:focus {
  background-color: #4d2717;
}

.contact-topic-field.input-custom-field .form-control::-moz-placeholder {
  color: #041241;
}

.contact-topic-field.input-custom-field .form-control::placeholder {
  color: #041241;
}

.contact-modal-dialog .cmn-submit-button span:before {
  left: 5px;
}

.contact-modal-dialog .cmn-submit-button:hover span:before {
  left: -44px;
}

.contact-modal-dialog .cmn-submit-button:hover span:after {
  right: 8px;
}

.contact-modal-dialog .close-button-style span {
  width: 42px;
  height: 42px;
  top: 2%;
  left: 2%;
}

.contact-modal-dialog .add-adv-btngroup .mt-btn i {
  border-radius: 4px;
  width: 68px;
  height: 68px;
}

.instruction-modal-dialog .close-button-style {
  top: -20px;
  right: -8px;
}

.instruction-modal-dialog .cmn-title-head h2 {
  min-height: 40px;
}

.instruction-modal-dialog .modal-content {
  background-color: transparent;
  border: 0;
  border-radius: 0;
}

.instruction-modal-dialog .modal-content .modal-body {
  background-color: #041241;
  border-radius: 8px;
  padding: 20px;
  position: relative;
}

.instruction-modal-dialog .modal-content .modal-body:before {
  content: "";
  width: 126px;
  background: #00052c;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.cmn-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.cmn-scrollbar::-webkit-scrollbar-track {
  background: linear-gradient(180deg, #4e4e4e, #000);
  border-radius: 5px;
}

.cmn-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 2px rgba(255, 255, 0, 0.3);
  background: linear-gradient(180deg,
      #ffe570,
      #fffe99 9.18%,
      #bb9432 25.31%,
      #efd269 48.35%,
      #c09937 69.22%,
      #9e781a 83.69%,
      #f1d361);
}

bs-datepicker-container .bs-datepicker {
  box-shadow: none;
  background: #041241;
}

bs-datepicker-container .bs-datepicker .bs-datepicker-container {
  padding: 8px;
}

bs-datepicker-container .bs-datepicker .bs-datepicker-head {
  background: linear-gradient(180deg,
      #ffe570,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #ba9331 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%);
}

bs-datepicker-container .bs-datepicker .bs-datepicker-body {
  border: none;
}

bs-datepicker-container .bs-datepicker .bs-datepicker-body table td {
  font-weight: 500;
}

#LanguageModal {
  z-index: 1052;
}

.welcome-exit {
  position: absolute;
  top: 20px;
  right: 20px;
}

.welcome-exit.animate__fadeIn {
  -webkit-animation-delay: 9.5s;
  animation-delay: 9.5s;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-fw {
  width: 1.28571429em;
  text-align: center;
}

.fa-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}

.fa-ul>li {
  position: relative;
}

.fa-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center;
}

.fa-li.fa-lg {
  left: -1.85714286em;
}

.fa-border {
  padding: 0.2em 0.25em 0.15em;
  border: 0.08em solid #eee;
  border-radius: 0.1em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left {
  margin-right: 0.3em;
}

.fa.fa-pull-right {
  margin-left: 0.3em;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.fa.pull-left {
  margin-right: 0.3em;
}

.fa.pull-right {
  margin-left: 0.3em;
}

.fa-spin {
  -webkit-animation: fa-spin 2s linear infinite;
  animation: fa-spin 2s linear infinite;
}

.fa-pulse {
  -webkit-animation: fa-spin 1s steps(8) infinite;
  animation: fa-spin 1s steps(8) infinite;
}

@-webkit-keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scaleX(-1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scaleY(-1);
}

:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270 {
  filter: none;
}

.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}

.fa-stack-1x,
.fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.fa-glass:before {
  content: "\f000";
}

.fa-music:before {
  content: "\f001";
}

.fa-search:before {
  content: "\f002";
}

.fa-envelope-o:before {
  content: "\f003";
}

.fa-heart:before {
  content: "\f004";
}

.fa-star:before {
  content: "\f005";
}

.fa-star-o:before {
  content: "\f006";
}

.fa-user:before {
  content: "\f007";
}

.fa-film:before {
  content: "\f008";
}

.fa-th-large:before {
  content: "\f009";
}

.fa-th:before {
  content: "\f00a";
}

.fa-th-list:before {
  content: "\f00b";
}

.fa-check:before {
  content: "\f00c";
}

.fa-close:before,
.fa-remove:before,
.fa-times:before {
  content: "\f00d";
}

.fa-search-plus:before {
  content: "\f00e";
}

.fa-search-minus:before {
  content: "\f010";
}

.fa-power-off:before {
  content: "\f011";
}

.fa-signal:before {
  content: "\f012";
}

.fa-cog:before,
.fa-gear:before {
  content: "\f013";
}

.fa-trash-o:before {
  content: "\f014";
}

.fa-home:before {
  content: "\f015";
}

.fa-file-o:before {
  content: "\f016";
}

.fa-clock-o:before {
  content: "\f017";
}

.fa-road:before {
  content: "\f018";
}

.fa-download:before {
  content: "\f019";
}

.fa-arrow-circle-o-down:before {
  content: "\f01a";
}

.fa-arrow-circle-o-up:before {
  content: "\f01b";
}

.fa-inbox:before {
  content: "\f01c";
}

.fa-play-circle-o:before {
  content: "\f01d";
}

.fa-repeat:before,
.fa-rotate-right:before {
  content: "\f01e";
}

.fa-refresh:before {
  content: "\f021";
}

.fa-list-alt:before {
  content: "\f022";
}

.fa-lock:before {
  content: "\f023";
}

.fa-flag:before {
  content: "\f024";
}

.fa-headphones:before {
  content: "\f025";
}

.fa-volume-off:before {
  content: "\f026";
}

.fa-volume-down:before {
  content: "\f027";
}

.fa-volume-up:before {
  content: "\f028";
}

.fa-qrcode:before {
  content: "\f029";
}

.fa-barcode:before {
  content: "\f02a";
}

.fa-tag:before {
  content: "\f02b";
}

.fa-tags:before {
  content: "\f02c";
}

.fa-book:before {
  content: "\f02d";
}

.fa-bookmark:before {
  content: "\f02e";
}

.fa-print:before {
  content: "\f02f";
}

.fa-camera:before {
  content: "\f030";
}

.fa-font:before {
  content: "\f031";
}

.fa-bold:before {
  content: "\f032";
}

.fa-italic:before {
  content: "\f033";
}

.fa-text-height:before {
  content: "\f034";
}

.fa-text-width:before {
  content: "\f035";
}

.fa-align-left:before {
  content: "\f036";
}

.fa-align-center:before {
  content: "\f037";
}

.fa-align-right:before {
  content: "\f038";
}

.fa-align-justify:before {
  content: "\f039";
}

.fa-list:before {
  content: "\f03a";
}

.fa-dedent:before,
.fa-outdent:before {
  content: "\f03b";
}

.fa-indent:before {
  content: "\f03c";
}

.fa-video-camera:before {
  content: "\f03d";
}

.fa-image:before,
.fa-photo:before,
.fa-picture-o:before {
  content: "\f03e";
}

.fa-pencil:before {
  content: "\f040";
}

.fa-map-marker:before {
  content: "\f041";
}

.fa-adjust:before {
  content: "\f042";
}

.fa-tint:before {
  content: "\f043";
}

.fa-edit:before,
.fa-pencil-square-o:before {
  content: "\f044";
}

.fa-share-square-o:before {
  content: "\f045";
}

.fa-check-square-o:before {
  content: "\f046";
}

.fa-arrows:before {
  content: "\f047";
}

.fa-step-backward:before {
  content: "\f048";
}

.fa-fast-backward:before {
  content: "\f049";
}

.fa-backward:before {
  content: "\f04a";
}

.fa-play:before {
  content: "\f04b";
}

.fa-pause:before {
  content: "\f04c";
}

.fa-stop:before {
  content: "\f04d";
}

.fa-forward:before {
  content: "\f04e";
}

.fa-fast-forward:before {
  content: "\f050";
}

.fa-step-forward:before {
  content: "\f051";
}

.fa-eject:before {
  content: "\f052";
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-chevron-right:before {
  content: "\f054";
}

.fa-plus-circle:before {
  content: "\f055";
}

.fa-minus-circle:before {
  content: "\f056";
}

.fa-times-circle:before {
  content: "\f057";
}

.fa-check-circle:before {
  content: "\f058";
}

.fa-question-circle:before {
  content: "\f059";
}

.fa-info-circle:before {
  content: "\f05a";
}

.fa-crosshairs:before {
  content: "\f05b";
}

.fa-times-circle-o:before {
  content: "\f05c";
}

.fa-check-circle-o:before {
  content: "\f05d";
}

.fa-ban:before {
  content: "\f05e";
}

.fa-arrow-left:before {
  content: "\f060";
}

.fa-arrow-right:before {
  content: "\f061";
}

.fa-arrow-up:before {
  content: "\f062";
}

.fa-arrow-down:before {
  content: "\f063";
}

.fa-mail-forward:before,
.fa-share:before {
  content: "\f064";
}

.fa-expand:before {
  content: "\f065";
}

.fa-compress:before {
  content: "\f066";
}

.fa-plus:before {
  content: "\f067";
}

.fa-minus:before {
  content: "\f068";
}

.fa-asterisk:before {
  content: "\f069";
}

.fa-exclamation-circle:before {
  content: "\f06a";
}

.fa-gift:before {
  content: "\f06b";
}

.fa-leaf:before {
  content: "\f06c";
}

.fa-fire:before {
  content: "\f06d";
}

.fa-eye:before {
  content: "\f06e";
}

.fa-eye-slash:before {
  content: "\f070";
}

.fa-exclamation-triangle:before,
.fa-warning:before {
  content: "\f071";
}

.fa-plane:before {
  content: "\f072";
}

.fa-calendar:before {
  content: "\f073";
}

.fa-random:before {
  content: "\f074";
}

.fa-comment:before {
  content: "\f075";
}

.fa-magnet:before {
  content: "\f076";
}

.fa-chevron-up:before {
  content: "\f077";
}

.fa-chevron-down:before {
  content: "\f078";
}

.fa-retweet:before {
  content: "\f079";
}

.fa-shopping-cart:before {
  content: "\f07a";
}

.fa-folder:before {
  content: "\f07b";
}

.fa-folder-open:before {
  content: "\f07c";
}

.fa-arrows-v:before {
  content: "\f07d";
}

.fa-arrows-h:before {
  content: "\f07e";
}

.fa-bar-chart-o:before,
.fa-bar-chart:before {
  content: "\f080";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-camera-retro:before {
  content: "\f083";
}

.fa-key:before {
  content: "\f084";
}

.fa-cogs:before,
.fa-gears:before {
  content: "\f085";
}

.fa-comments:before {
  content: "\f086";
}

.fa-thumbs-o-up:before {
  content: "\f087";
}

.fa-thumbs-o-down:before {
  content: "\f088";
}

.fa-star-half:before {
  content: "\f089";
}

.fa-heart-o:before {
  content: "\f08a";
}

.fa-sign-out:before {
  content: "\f08b";
}

.fa-linkedin-square:before {
  content: "\f08c";
}

.fa-thumb-tack:before {
  content: "\f08d";
}

.fa-external-link:before {
  content: "\f08e";
}

.fa-sign-in:before {
  content: "\f090";
}

.fa-trophy:before {
  content: "\f091";
}

.fa-github-square:before {
  content: "\f092";
}

.fa-upload:before {
  content: "\f093";
}

.fa-lemon-o:before {
  content: "\f094";
}

.fa-phone:before {
  content: "\f095";
}

.fa-square-o:before {
  content: "\f096";
}

.fa-bookmark-o:before {
  content: "\f097";
}

.fa-phone-square:before {
  content: "\f098";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-facebook-f:before,
.fa-facebook:before {
  content: "\f09a";
}

.fa-github:before {
  content: "\f09b";
}

.fa-unlock:before {
  content: "\f09c";
}

.fa-credit-card:before {
  content: "\f09d";
}

.fa-feed:before,
.fa-rss:before {
  content: "\f09e";
}

.fa-hdd-o:before {
  content: "\f0a0";
}

.fa-bullhorn:before {
  content: "\f0a1";
}

.fa-bell:before {
  content: "\f0f3";
}

.fa-certificate:before {
  content: "\f0a3";
}

.fa-hand-o-right:before {
  content: "\f0a4";
}

.fa-hand-o-left:before {
  content: "\f0a5";
}

.fa-hand-o-up:before {
  content: "\f0a6";
}

.fa-hand-o-down:before {
  content: "\f0a7";
}

.fa-arrow-circle-left:before {
  content: "\f0a8";
}

.fa-arrow-circle-right:before {
  content: "\f0a9";
}

.fa-arrow-circle-up:before {
  content: "\f0aa";
}

.fa-arrow-circle-down:before {
  content: "\f0ab";
}

.fa-globe:before {
  content: "\f0ac";
}

.fa-wrench:before {
  content: "\f0ad";
}

.fa-tasks:before {
  content: "\f0ae";
}

.fa-filter:before {
  content: "\f0b0";
}

.fa-briefcase:before {
  content: "\f0b1";
}

.fa-arrows-alt:before {
  content: "\f0b2";
}

.fa-group:before,
.fa-users:before {
  content: "\f0c0";
}

.fa-chain:before,
.fa-link:before {
  content: "\f0c1";
}

.fa-cloud:before {
  content: "\f0c2";
}

.fa-flask:before {
  content: "\f0c3";
}

.fa-cut:before,
.fa-scissors:before {
  content: "\f0c4";
}

.fa-copy:before,
.fa-files-o:before {
  content: "\f0c5";
}

.fa-paperclip:before {
  content: "\f0c6";
}

.fa-floppy-o:before,
.fa-save:before {
  content: "\f0c7";
}

.fa-square:before {
  content: "\f0c8";
}

.fa-bars:before,
.fa-navicon:before,
.fa-reorder:before {
  content: "\f0c9";
}

.fa-list-ul:before {
  content: "\f0ca";
}

.fa-list-ol:before {
  content: "\f0cb";
}

.fa-strikethrough:before {
  content: "\f0cc";
}

.fa-underline:before {
  content: "\f0cd";
}

.fa-table:before {
  content: "\f0ce";
}

.fa-magic:before {
  content: "\f0d0";
}

.fa-truck:before {
  content: "\f0d1";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-google-plus-square:before {
  content: "\f0d4";
}

.fa-google-plus:before {
  content: "\f0d5";
}

.fa-money:before {
  content: "\f0d6";
}

.fa-caret-down:before {
  content: "\f0d7";
}

.fa-caret-up:before {
  content: "\f0d8";
}

.fa-caret-left:before {
  content: "\f0d9";
}

.fa-caret-right:before {
  content: "\f0da";
}

.fa-columns:before {
  content: "\f0db";
}

.fa-sort:before,
.fa-unsorted:before {
  content: "\f0dc";
}

.fa-sort-desc:before,
.fa-sort-down:before {
  content: "\f0dd";
}

.fa-sort-asc:before,
.fa-sort-up:before {
  content: "\f0de";
}

.fa-envelope:before {
  content: "\f0e0";
}

.fa-linkedin:before {
  content: "\f0e1";
}

.fa-rotate-left:before,
.fa-undo:before {
  content: "\f0e2";
}

.fa-gavel:before,
.fa-legal:before {
  content: "\f0e3";
}

.fa-dashboard:before,
.fa-tachometer:before {
  content: "\f0e4";
}

.fa-comment-o:before {
  content: "\f0e5";
}

.fa-comments-o:before {
  content: "\f0e6";
}

.fa-bolt:before,
.fa-flash:before {
  content: "\f0e7";
}

.fa-sitemap:before {
  content: "\f0e8";
}

.fa-umbrella:before {
  content: "\f0e9";
}

.fa-clipboard:before,
.fa-paste:before {
  content: "\f0ea";
}

.fa-lightbulb-o:before {
  content: "\f0eb";
}

.fa-exchange:before {
  content: "\f0ec";
}

.fa-cloud-download:before {
  content: "\f0ed";
}

.fa-cloud-upload:before {
  content: "\f0ee";
}

.fa-user-md:before {
  content: "\f0f0";
}

.fa-stethoscope:before {
  content: "\f0f1";
}

.fa-suitcase:before {
  content: "\f0f2";
}

.fa-bell-o:before {
  content: "\f0a2";
}

.fa-coffee:before {
  content: "\f0f4";
}

.fa-cutlery:before {
  content: "\f0f5";
}

.fa-file-text-o:before {
  content: "\f0f6";
}

.fa-building-o:before {
  content: "\f0f7";
}

.fa-hospital-o:before {
  content: "\f0f8";
}

.fa-ambulance:before {
  content: "\f0f9";
}

.fa-medkit:before {
  content: "\f0fa";
}

.fa-fighter-jet:before {
  content: "\f0fb";
}

.fa-beer:before {
  content: "\f0fc";
}

.fa-h-square:before {
  content: "\f0fd";
}

.fa-plus-square:before {
  content: "\f0fe";
}

.fa-angle-double-left:before {
  content: "\f100";
}

.fa-angle-double-right:before {
  content: "\f101";
}

.fa-angle-double-up:before {
  content: "\f102";
}

.fa-angle-double-down:before {
  content: "\f103";
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right:before {
  content: "\f105";
}

.fa-angle-up:before {
  content: "\f106";
}

.fa-angle-down:before {
  content: "\f107";
}

.fa-desktop:before {
  content: "\f108";
}

.fa-laptop:before {
  content: "\f109";
}

.fa-tablet:before {
  content: "\f10a";
}

.fa-mobile-phone:before,
.fa-mobile:before {
  content: "\f10b";
}

.fa-circle-o:before {
  content: "\f10c";
}

.fa-quote-left:before {
  content: "\f10d";
}

.fa-quote-right:before {
  content: "\f10e";
}

.fa-spinner:before {
  content: "\f110";
}

.fa-circle:before {
  content: "\f111";
}

.fa-mail-reply:before,
.fa-reply:before {
  content: "\f112";
}

.fa-github-alt:before {
  content: "\f113";
}

.fa-folder-o:before {
  content: "\f114";
}

.fa-folder-open-o:before {
  content: "\f115";
}

.fa-smile-o:before {
  content: "\f118";
}

.fa-frown-o:before {
  content: "\f119";
}

.fa-meh-o:before {
  content: "\f11a";
}

.fa-gamepad:before {
  content: "\f11b";
}

.fa-keyboard-o:before {
  content: "\f11c";
}

.fa-flag-o:before {
  content: "\f11d";
}

.fa-flag-checkered:before {
  content: "\f11e";
}

.fa-terminal:before {
  content: "\f120";
}

.fa-code:before {
  content: "\f121";
}

.fa-mail-reply-all:before,
.fa-reply-all:before {
  content: "\f122";
}

.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: "\f123";
}

.fa-location-arrow:before {
  content: "\f124";
}

.fa-crop:before {
  content: "\f125";
}

.fa-code-fork:before {
  content: "\f126";
}

.fa-chain-broken:before,
.fa-unlink:before {
  content: "\f127";
}

.fa-question:before {
  content: "\f128";
}

.fa-info:before {
  content: "\f129";
}

.fa-exclamation:before {
  content: "\f12a";
}

.fa-superscript:before {
  content: "\f12b";
}

.fa-subscript:before {
  content: "\f12c";
}

.fa-eraser:before {
  content: "\f12d";
}

.fa-puzzle-piece:before {
  content: "\f12e";
}

.fa-microphone:before {
  content: "\f130";
}

.fa-microphone-slash:before {
  content: "\f131";
}

.fa-shield:before {
  content: "\f132";
}

.fa-calendar-o:before {
  content: "\f133";
}

.fa-fire-extinguisher:before {
  content: "\f134";
}

.fa-rocket:before {
  content: "\f135";
}

.fa-maxcdn:before {
  content: "\f136";
}

.fa-chevron-circle-left:before {
  content: "\f137";
}

.fa-chevron-circle-right:before {
  content: "\f138";
}

.fa-chevron-circle-up:before {
  content: "\f139";
}

.fa-chevron-circle-down:before {
  content: "\f13a";
}

.fa-html5:before {
  content: "\f13b";
}

.fa-css3:before {
  content: "\f13c";
}

.fa-anchor:before {
  content: "\f13d";
}

.fa-unlock-alt:before {
  content: "\f13e";
}

.fa-bullseye:before {
  content: "\f140";
}

.fa-ellipsis-h:before {
  content: "\f141";
}

.fa-ellipsis-v:before {
  content: "\f142";
}

.fa-rss-square:before {
  content: "\f143";
}

.fa-play-circle:before {
  content: "\f144";
}

.fa-ticket:before {
  content: "\f145";
}

.fa-minus-square:before {
  content: "\f146";
}

.fa-minus-square-o:before {
  content: "\f147";
}

.fa-level-up:before {
  content: "\f148";
}

.fa-level-down:before {
  content: "\f149";
}

.fa-check-square:before {
  content: "\f14a";
}

.fa-pencil-square:before {
  content: "\f14b";
}

.fa-external-link-square:before {
  content: "\f14c";
}

.fa-share-square:before {
  content: "\f14d";
}

.fa-compass:before {
  content: "\f14e";
}

.fa-caret-square-o-down:before,
.fa-toggle-down:before {
  content: "\f150";
}

.fa-caret-square-o-up:before,
.fa-toggle-up:before {
  content: "\f151";
}

.fa-caret-square-o-right:before,
.fa-toggle-right:before {
  content: "\f152";
}

.fa-eur:before,
.fa-euro:before {
  content: "\f153";
}

.fa-gbp:before {
  content: "\f154";
}

.fa-dollar:before,
.fa-usd:before {
  content: "\f155";
}

.fa-inr:before,
.fa-rupee:before {
  content: "\f156";
}

.fa-cny:before,
.fa-jpy:before,
.fa-rmb:before,
.fa-yen:before {
  content: "\f157";
}

.fa-rouble:before,
.fa-rub:before,
.fa-ruble:before {
  content: "\f158";
}

.fa-krw:before,
.fa-won:before {
  content: "\f159";
}

.fa-bitcoin:before,
.fa-btc:before {
  content: "\f15a";
}

.fa-file:before {
  content: "\f15b";
}

.fa-file-text:before {
  content: "\f15c";
}

.fa-sort-alpha-asc:before {
  content: "\f15d";
}

.fa-sort-alpha-desc:before {
  content: "\f15e";
}

.fa-sort-amount-asc:before {
  content: "\f160";
}

.fa-sort-amount-desc:before {
  content: "\f161";
}

.fa-sort-numeric-asc:before {
  content: "\f162";
}

.fa-sort-numeric-desc:before {
  content: "\f163";
}

.fa-thumbs-up:before {
  content: "\f164";
}

.fa-thumbs-down:before {
  content: "\f165";
}

.fa-youtube-square:before {
  content: "\f166";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-xing:before {
  content: "\f168";
}

.fa-xing-square:before {
  content: "\f169";
}

.fa-youtube-play:before {
  content: "\f16a";
}

.fa-dropbox:before {
  content: "\f16b";
}

.fa-stack-overflow:before {
  content: "\f16c";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-flickr:before {
  content: "\f16e";
}

.fa-adn:before {
  content: "\f170";
}

.fa-bitbucket:before {
  content: "\f171";
}

.fa-bitbucket-square:before {
  content: "\f172";
}

.fa-tumblr:before {
  content: "\f173";
}

.fa-tumblr-square:before {
  content: "\f174";
}

.fa-long-arrow-down:before {
  content: "\f175";
}

.fa-long-arrow-up:before {
  content: "\f176";
}

.fa-long-arrow-left:before {
  content: "\f177";
}

.fa-long-arrow-right:before {
  content: "\f178";
}

.fa-apple:before {
  content: "\f179";
}

.fa-windows:before {
  content: "\f17a";
}

.fa-android:before {
  content: "\f17b";
}

.fa-linux:before {
  content: "\f17c";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-skype:before {
  content: "\f17e";
}

.fa-foursquare:before {
  content: "\f180";
}

.fa-trello:before {
  content: "\f181";
}

.fa-female:before {
  content: "\f182";
}

.fa-male:before {
  content: "\f183";
}

.fa-gittip:before,
.fa-gratipay:before {
  content: "\f184";
}

.fa-sun-o:before {
  content: "\f185";
}

.fa-moon-o:before {
  content: "\f186";
}

.fa-archive:before {
  content: "\f187";
}

.fa-bug:before {
  content: "\f188";
}

.fa-vk:before {
  content: "\f189";
}

.fa-weibo:before {
  content: "\f18a";
}

.fa-renren:before {
  content: "\f18b";
}

.fa-pagelines:before {
  content: "\f18c";
}

.fa-stack-exchange:before {
  content: "\f18d";
}

.fa-arrow-circle-o-right:before {
  content: "\f18e";
}

.fa-arrow-circle-o-left:before {
  content: "\f190";
}

.fa-caret-square-o-left:before,
.fa-toggle-left:before {
  content: "\f191";
}

.fa-dot-circle-o:before {
  content: "\f192";
}

.fa-wheelchair:before {
  content: "\f193";
}

.fa-vimeo-square:before {
  content: "\f194";
}

.fa-try:before,
.fa-turkish-lira:before {
  content: "\f195";
}

.fa-plus-square-o:before {
  content: "\f196";
}

.fa-space-shuttle:before {
  content: "\f197";
}

.fa-slack:before {
  content: "\f198";
}

.fa-envelope-square:before {
  content: "\f199";
}

.fa-wordpress:before {
  content: "\f19a";
}

.fa-openid:before {
  content: "\f19b";
}

.fa-bank:before,
.fa-institution:before,
.fa-university:before {
  content: "\f19c";
}

.fa-graduation-cap:before,
.fa-mortar-board:before {
  content: "\f19d";
}

.fa-yahoo:before {
  content: "\f19e";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-reddit:before {
  content: "\f1a1";
}

.fa-reddit-square:before {
  content: "\f1a2";
}

.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

.fa-stumbleupon:before {
  content: "\f1a4";
}

.fa-delicious:before {
  content: "\f1a5";
}

.fa-digg:before {
  content: "\f1a6";
}

.fa-pied-piper-pp:before {
  content: "\f1a7";
}

.fa-pied-piper-alt:before {
  content: "\f1a8";
}

.fa-drupal:before {
  content: "\f1a9";
}

.fa-joomla:before {
  content: "\f1aa";
}

.fa-language:before {
  content: "\f1ab";
}

.fa-fax:before {
  content: "\f1ac";
}

.fa-building:before {
  content: "\f1ad";
}

.fa-child:before {
  content: "\f1ae";
}

.fa-paw:before {
  content: "\f1b0";
}

.fa-spoon:before {
  content: "\f1b1";
}

.fa-cube:before {
  content: "\f1b2";
}

.fa-cubes:before {
  content: "\f1b3";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-behance-square:before {
  content: "\f1b5";
}

.fa-steam:before {
  content: "\f1b6";
}

.fa-steam-square:before {
  content: "\f1b7";
}

.fa-recycle:before {
  content: "\f1b8";
}

.fa-automobile:before,
.fa-car:before {
  content: "\f1b9";
}

.fa-cab:before,
.fa-taxi:before {
  content: "\f1ba";
}

.fa-tree:before {
  content: "\f1bb";
}

.fa-spotify:before {
  content: "\f1bc";
}

.fa-deviantart:before {
  content: "\f1bd";
}

.fa-soundcloud:before {
  content: "\f1be";
}

.fa-database:before {
  content: "\f1c0";
}

.fa-file-pdf-o:before {
  content: "\f1c1";
}

.fa-file-word-o:before {
  content: "\f1c2";
}

.fa-file-excel-o:before {
  content: "\f1c3";
}

.fa-file-powerpoint-o:before {
  content: "\f1c4";
}

.fa-file-image-o:before,
.fa-file-photo-o:before,
.fa-file-picture-o:before {
  content: "\f1c5";
}

.fa-file-archive-o:before,
.fa-file-zip-o:before {
  content: "\f1c6";
}

.fa-file-audio-o:before,
.fa-file-sound-o:before {
  content: "\f1c7";
}

.fa-file-movie-o:before,
.fa-file-video-o:before {
  content: "\f1c8";
}

.fa-file-code-o:before {
  content: "\f1c9";
}

.fa-vine:before {
  content: "\f1ca";
}

.fa-codepen:before {
  content: "\f1cb";
}

.fa-jsfiddle:before {
  content: "\f1cc";
}

.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-ring:before,
.fa-life-saver:before,
.fa-support:before {
  content: "\f1cd";
}

.fa-circle-o-notch:before {
  content: "\f1ce";
}

.fa-ra:before,
.fa-rebel:before,
.fa-resistance:before {
  content: "\f1d0";
}

.fa-empire:before,
.fa-ge:before {
  content: "\f1d1";
}

.fa-git-square:before {
  content: "\f1d2";
}

.fa-git:before {
  content: "\f1d3";
}

.fa-hacker-news:before,
.fa-y-combinator-square:before,
.fa-yc-square:before {
  content: "\f1d4";
}

.fa-tencent-weibo:before {
  content: "\f1d5";
}

.fa-qq:before {
  content: "\f1d6";
}

.fa-wechat:before,
.fa-weixin:before {
  content: "\f1d7";
}

.fa-paper-plane:before,
.fa-send:before {
  content: "\f1d8";
}

.fa-paper-plane-o:before,
.fa-send-o:before {
  content: "\f1d9";
}

.fa-history:before {
  content: "\f1da";
}

.fa-circle-thin:before {
  content: "\f1db";
}

.fa-header:before {
  content: "\f1dc";
}

.fa-paragraph:before {
  content: "\f1dd";
}

.fa-sliders:before {
  content: "\f1de";
}

.fa-share-alt:before {
  content: "\f1e0";
}

.fa-share-alt-square:before {
  content: "\f1e1";
}

.fa-bomb:before {
  content: "\f1e2";
}

.fa-futbol-o:before,
.fa-soccer-ball-o:before {
  content: "\f1e3";
}

.fa-tty:before {
  content: "\f1e4";
}

.fa-binoculars:before {
  content: "\f1e5";
}

.fa-plug:before {
  content: "\f1e6";
}

.fa-slideshare:before {
  content: "\f1e7";
}

.fa-twitch:before {
  content: "\f1e8";
}

.fa-yelp:before {
  content: "\f1e9";
}

.fa-newspaper-o:before {
  content: "\f1ea";
}

.fa-wifi:before {
  content: "\f1eb";
}

.fa-calculator:before {
  content: "\f1ec";
}

.fa-paypal:before {
  content: "\f1ed";
}

.fa-google-wallet:before {
  content: "\f1ee";
}

.fa-cc-visa:before {
  content: "\f1f0";
}

.fa-cc-mastercard:before {
  content: "\f1f1";
}

.fa-cc-discover:before {
  content: "\f1f2";
}

.fa-cc-amex:before {
  content: "\f1f3";
}

.fa-cc-paypal:before {
  content: "\f1f4";
}

.fa-cc-stripe:before {
  content: "\f1f5";
}

.fa-bell-slash:before {
  content: "\f1f6";
}

.fa-bell-slash-o:before {
  content: "\f1f7";
}

.fa-trash:before {
  content: "\f1f8";
}

.fa-copyright:before {
  content: "\f1f9";
}

.fa-at:before {
  content: "\f1fa";
}

.fa-eyedropper:before {
  content: "\f1fb";
}

.fa-paint-brush:before {
  content: "\f1fc";
}

.fa-birthday-cake:before {
  content: "\f1fd";
}

.fa-area-chart:before {
  content: "\f1fe";
}

.fa-pie-chart:before {
  content: "\f200";
}

.fa-line-chart:before {
  content: "\f201";
}

.fa-lastfm:before {
  content: "\f202";
}

.fa-lastfm-square:before {
  content: "\f203";
}

.fa-toggle-off:before {
  content: "\f204";
}

.fa-toggle-on:before {
  content: "\f205";
}

.fa-bicycle:before {
  content: "\f206";
}

.fa-bus:before {
  content: "\f207";
}

.fa-ioxhost:before {
  content: "\f208";
}

.fa-angellist:before {
  content: "\f209";
}

.fa-cc:before {
  content: "\f20a";
}

.fa-ils:before,
.fa-shekel:before,
.fa-sheqel:before {
  content: "\f20b";
}

.fa-meanpath:before {
  content: "\f20c";
}

.fa-buysellads:before {
  content: "\f20d";
}

.fa-connectdevelop:before {
  content: "\f20e";
}

.fa-dashcube:before {
  content: "\f210";
}

.fa-forumbee:before {
  content: "\f211";
}

.fa-leanpub:before {
  content: "\f212";
}

.fa-sellsy:before {
  content: "\f213";
}

.fa-shirtsinbulk:before {
  content: "\f214";
}

.fa-simplybuilt:before {
  content: "\f215";
}

.fa-skyatlas:before {
  content: "\f216";
}

.fa-cart-plus:before {
  content: "\f217";
}

.fa-cart-arrow-down:before {
  content: "\f218";
}

.fa-diamond:before {
  content: "\f219";
}

.fa-ship:before {
  content: "\f21a";
}

.fa-user-secret:before {
  content: "\f21b";
}

.fa-motorcycle:before {
  content: "\f21c";
}

.fa-street-view:before {
  content: "\f21d";
}

.fa-heartbeat:before {
  content: "\f21e";
}

.fa-venus:before {
  content: "\f221";
}

.fa-mars:before {
  content: "\f222";
}

.fa-mercury:before {
  content: "\f223";
}

.fa-intersex:before,
.fa-transgender:before {
  content: "\f224";
}

.fa-transgender-alt:before {
  content: "\f225";
}

.fa-venus-double:before {
  content: "\f226";
}

.fa-mars-double:before {
  content: "\f227";
}

.fa-venus-mars:before {
  content: "\f228";
}

.fa-mars-stroke:before {
  content: "\f229";
}

.fa-mars-stroke-v:before {
  content: "\f22a";
}

.fa-mars-stroke-h:before {
  content: "\f22b";
}

.fa-neuter:before {
  content: "\f22c";
}

.fa-genderless:before {
  content: "\f22d";
}

.fa-facebook-official:before {
  content: "\f230";
}

.fa-pinterest-p:before {
  content: "\f231";
}

.fa-whatsapp:before {
  content: "\f232";
}

.fa-server:before {
  content: "\f233";
}

.fa-user-plus:before {
  content: "\f234";
}

.fa-user-times:before {
  content: "\f235";
}

.fa-bed:before,
.fa-hotel:before {
  content: "\f236";
}

.fa-viacoin:before {
  content: "\f237";
}

.fa-train:before {
  content: "\f238";
}

.fa-subway:before {
  content: "\f239";
}

.fa-medium:before {
  content: "\f23a";
}

.fa-y-combinator:before,
.fa-yc:before {
  content: "\f23b";
}

.fa-optin-monster:before {
  content: "\f23c";
}

.fa-opencart:before {
  content: "\f23d";
}

.fa-expeditedssl:before {
  content: "\f23e";
}

.fa-battery-4:before,
.fa-battery-full:before,
.fa-battery:before {
  content: "\f240";
}

.fa-battery-3:before,
.fa-battery-three-quarters:before {
  content: "\f241";
}

.fa-battery-2:before,
.fa-battery-half:before {
  content: "\f242";
}

.fa-battery-1:before,
.fa-battery-quarter:before {
  content: "\f243";
}

.fa-battery-0:before,
.fa-battery-empty:before {
  content: "\f244";
}

.fa-mouse-pointer:before {
  content: "\f245";
}

.fa-i-cursor:before {
  content: "\f246";
}

.fa-object-group:before {
  content: "\f247";
}

.fa-object-ungroup:before {
  content: "\f248";
}

.fa-sticky-note:before {
  content: "\f249";
}

.fa-sticky-note-o:before {
  content: "\f24a";
}

.fa-cc-jcb:before {
  content: "\f24b";
}

.fa-cc-diners-club:before {
  content: "\f24c";
}

.fa-clone:before {
  content: "\f24d";
}

.fa-balance-scale:before {
  content: "\f24e";
}

.fa-hourglass-o:before {
  content: "\f250";
}

.fa-hourglass-1:before,
.fa-hourglass-start:before {
  content: "\f251";
}

.fa-hourglass-2:before,
.fa-hourglass-half:before {
  content: "\f252";
}

.fa-hourglass-3:before,
.fa-hourglass-end:before {
  content: "\f253";
}

.fa-hourglass:before {
  content: "\f254";
}

.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
  content: "\f255";
}

.fa-hand-paper-o:before,
.fa-hand-stop-o:before {
  content: "\f256";
}

.fa-hand-scissors-o:before {
  content: "\f257";
}

.fa-hand-lizard-o:before {
  content: "\f258";
}

.fa-hand-spock-o:before {
  content: "\f259";
}

.fa-hand-pointer-o:before {
  content: "\f25a";
}

.fa-hand-peace-o:before {
  content: "\f25b";
}

.fa-trademark:before {
  content: "\f25c";
}

.fa-registered:before {
  content: "\f25d";
}

.fa-creative-commons:before {
  content: "\f25e";
}

.fa-gg:before {
  content: "\f260";
}

.fa-gg-circle:before {
  content: "\f261";
}

.fa-tripadvisor:before {
  content: "\f262";
}

.fa-odnoklassniki:before {
  content: "\f263";
}

.fa-odnoklassniki-square:before {
  content: "\f264";
}

.fa-get-pocket:before {
  content: "\f265";
}

.fa-wikipedia-w:before {
  content: "\f266";
}

.fa-safari:before {
  content: "\f267";
}

.fa-chrome:before {
  content: "\f268";
}

.fa-firefox:before {
  content: "\f269";
}

.fa-opera:before {
  content: "\f26a";
}

.fa-internet-explorer:before {
  content: "\f26b";
}

.fa-television:before,
.fa-tv:before {
  content: "\f26c";
}

.fa-contao:before {
  content: "\f26d";
}

.fa-500px:before {
  content: "\f26e";
}

.fa-amazon:before {
  content: "\f270";
}

.fa-calendar-plus-o:before {
  content: "\f271";
}

.fa-calendar-minus-o:before {
  content: "\f272";
}

.fa-calendar-times-o:before {
  content: "\f273";
}

.fa-calendar-check-o:before {
  content: "\f274";
}

.fa-industry:before {
  content: "\f275";
}

.fa-map-pin:before {
  content: "\f276";
}

.fa-map-signs:before {
  content: "\f277";
}

.fa-map-o:before {
  content: "\f278";
}

.fa-map:before {
  content: "\f279";
}

.fa-commenting:before {
  content: "\f27a";
}

.fa-commenting-o:before {
  content: "\f27b";
}

.fa-houzz:before {
  content: "\f27c";
}

.fa-vimeo:before {
  content: "\f27d";
}

.fa-black-tie:before {
  content: "\f27e";
}

.fa-fonticons:before {
  content: "\f280";
}

.fa-reddit-alien:before {
  content: "\f281";
}

.fa-edge:before {
  content: "\f282";
}

.fa-credit-card-alt:before {
  content: "\f283";
}

.fa-codiepie:before {
  content: "\f284";
}

.fa-modx:before {
  content: "\f285";
}

.fa-fort-awesome:before {
  content: "\f286";
}

.fa-usb:before {
  content: "\f287";
}

.fa-product-hunt:before {
  content: "\f288";
}

.fa-mixcloud:before {
  content: "\f289";
}

.fa-scribd:before {
  content: "\f28a";
}

.fa-pause-circle:before {
  content: "\f28b";
}

.fa-pause-circle-o:before {
  content: "\f28c";
}

.fa-stop-circle:before {
  content: "\f28d";
}

.fa-stop-circle-o:before {
  content: "\f28e";
}

.fa-shopping-bag:before {
  content: "\f290";
}

.fa-shopping-basket:before {
  content: "\f291";
}

.fa-hashtag:before {
  content: "\f292";
}

.fa-bluetooth:before {
  content: "\f293";
}

.fa-bluetooth-b:before {
  content: "\f294";
}

.fa-percent:before {
  content: "\f295";
}

.fa-gitlab:before {
  content: "\f296";
}

.fa-wpbeginner:before {
  content: "\f297";
}

.fa-wpforms:before {
  content: "\f298";
}

.fa-envira:before {
  content: "\f299";
}

.fa-universal-access:before {
  content: "\f29a";
}

.fa-wheelchair-alt:before {
  content: "\f29b";
}

.fa-question-circle-o:before {
  content: "\f29c";
}

.fa-blind:before {
  content: "\f29d";
}

.fa-audio-description:before {
  content: "\f29e";
}

.fa-volume-control-phone:before {
  content: "\f2a0";
}

.fa-braille:before {
  content: "\f2a1";
}

.fa-assistive-listening-systems:before {
  content: "\f2a2";
}

.fa-american-sign-language-interpreting:before,
.fa-asl-interpreting:before {
  content: "\f2a3";
}

.fa-deaf:before,
.fa-deafness:before,
.fa-hard-of-hearing:before {
  content: "\f2a4";
}

.fa-glide:before {
  content: "\f2a5";
}

.fa-glide-g:before {
  content: "\f2a6";
}

.fa-sign-language:before,
.fa-signing:before {
  content: "\f2a7";
}

.fa-low-vision:before {
  content: "\f2a8";
}

.fa-viadeo:before {
  content: "\f2a9";
}

.fa-viadeo-square:before {
  content: "\f2aa";
}

.fa-snapchat:before {
  content: "\f2ab";
}

.fa-snapchat-ghost:before {
  content: "\f2ac";
}

.fa-snapchat-square:before {
  content: "\f2ad";
}

.fa-pied-piper:before {
  content: "\f2ae";
}

.fa-first-order:before {
  content: "\f2b0";
}

.fa-yoast:before {
  content: "\f2b1";
}

.fa-themeisle:before {
  content: "\f2b2";
}

.fa-google-plus-circle:before,
.fa-google-plus-official:before {
  content: "\f2b3";
}

.fa-fa:before,
.fa-font-awesome:before {
  content: "\f2b4";
}

.fa-handshake-o:before {
  content: "\f2b5";
}

.fa-envelope-open:before {
  content: "\f2b6";
}

.fa-envelope-open-o:before {
  content: "\f2b7";
}

.fa-linode:before {
  content: "\f2b8";
}

.fa-address-book:before {
  content: "\f2b9";
}

.fa-address-book-o:before {
  content: "\f2ba";
}

.fa-address-card:before,
.fa-vcard:before {
  content: "\f2bb";
}

.fa-address-card-o:before,
.fa-vcard-o:before {
  content: "\f2bc";
}

.fa-user-circle:before {
  content: "\f2bd";
}

.fa-user-circle-o:before {
  content: "\f2be";
}

.fa-user-o:before {
  content: "\f2c0";
}

.fa-id-badge:before {
  content: "\f2c1";
}

.fa-drivers-license:before,
.fa-id-card:before {
  content: "\f2c2";
}

.fa-drivers-license-o:before,
.fa-id-card-o:before {
  content: "\f2c3";
}

.fa-quora:before {
  content: "\f2c4";
}

.fa-free-code-camp:before {
  content: "\f2c5";
}

.fa-telegram:before {
  content: "\f2c6";
}

.fa-thermometer-4:before,
.fa-thermometer-full:before,
.fa-thermometer:before {
  content: "\f2c7";
}

.fa-thermometer-3:before,
.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}

.fa-thermometer-2:before,
.fa-thermometer-half:before {
  content: "\f2c9";
}

.fa-thermometer-1:before,
.fa-thermometer-quarter:before {
  content: "\f2ca";
}

.fa-thermometer-0:before,
.fa-thermometer-empty:before {
  content: "\f2cb";
}

.fa-shower:before {
  content: "\f2cc";
}

.fa-bath:before,
.fa-bathtub:before,
.fa-s15:before {
  content: "\f2cd";
}

.fa-podcast:before {
  content: "\f2ce";
}

.fa-window-maximize:before {
  content: "\f2d0";
}

.fa-window-minimize:before {
  content: "\f2d1";
}

.fa-window-restore:before {
  content: "\f2d2";
}

.fa-times-rectangle:before,
.fa-window-close:before {
  content: "\f2d3";
}

.fa-times-rectangle-o:before,
.fa-window-close-o:before {
  content: "\f2d4";
}

.fa-bandcamp:before {
  content: "\f2d5";
}

.fa-grav:before {
  content: "\f2d6";
}

.fa-etsy:before {
  content: "\f2d7";
}

.fa-imdb:before {
  content: "\f2d8";
}

.fa-ravelry:before {
  content: "\f2d9";
}

.fa-eercast:before {
  content: "\f2da";
}

.fa-microchip:before {
  content: "\f2db";
}

.fa-snowflake-o:before {
  content: "\f2dc";
}

.fa-superpowers:before {
  content: "\f2dd";
}

.fa-wpexplorer:before {
  content: "\f2de";
}

.fa-meetup:before {
  content: "\f2e0";
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: Glyphicons Halflings;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon-asterisk:before {
  content: "*";
}

.glyphicon-plus:before {
  content: "+";
}

.glyphicon-eur:before,
.glyphicon-euro:before {
  content: "€";
}

.glyphicon-minus:before {
  content: "−";
}

.glyphicon-cloud:before {
  content: "☁";
}

.glyphicon-envelope:before {
  content: "✉";
}

.glyphicon-pencil:before {
  content: "✏";
}

.glyphicon-glass:before {
  content: "";
}

.glyphicon-music:before {
  content: "";
}

.glyphicon-search:before {
  content: "";
}

.glyphicon-heart:before {
  content: "";
}

.glyphicon-star:before {
  content: "";
}

.glyphicon-star-empty:before {
  content: "";
}

.glyphicon-user:before {
  content: "";
}

.glyphicon-film:before {
  content: "";
}

.glyphicon-th-large:before {
  content: "";
}

.glyphicon-th:before {
  content: "";
}

.glyphicon-th-list:before {
  content: "";
}

.glyphicon-ok:before {
  content: "";
}

.glyphicon-remove:before {
  content: "";
}

.glyphicon-zoom-in:before {
  content: "";
}

.glyphicon-zoom-out:before {
  content: "";
}

.glyphicon-off:before {
  content: "";
}

.glyphicon-signal:before {
  content: "";
}

.glyphicon-cog:before {
  content: "";
}

.glyphicon-trash:before {
  content: "";
}

.glyphicon-home:before {
  content: "";
}

.glyphicon-file:before {
  content: "";
}

.glyphicon-time:before {
  content: "";
}

.glyphicon-road:before {
  content: "";
}

.glyphicon-download-alt:before {
  content: "";
}

.glyphicon-download:before {
  content: "";
}

.glyphicon-upload:before {
  content: "";
}

.glyphicon-inbox:before {
  content: "";
}

.glyphicon-play-circle:before {
  content: "";
}

.glyphicon-repeat:before {
  content: "";
}

.glyphicon-refresh:before {
  content: "";
}

.glyphicon-list-alt:before {
  content: "";
}

.glyphicon-lock:before {
  content: "";
}

.glyphicon-flag:before {
  content: "";
}

.glyphicon-headphones:before {
  content: "";
}

.glyphicon-volume-off:before {
  content: "";
}

.glyphicon-volume-down:before {
  content: "";
}

.glyphicon-volume-up:before {
  content: "";
}

.glyphicon-qrcode:before {
  content: "";
}

.glyphicon-barcode:before {
  content: "";
}

.glyphicon-tag:before {
  content: "";
}

.glyphicon-tags:before {
  content: "";
}

.glyphicon-book:before {
  content: "";
}

.glyphicon-bookmark:before {
  content: "";
}

.glyphicon-print:before {
  content: "";
}

.glyphicon-camera:before {
  content: "";
}

.glyphicon-font:before {
  content: "";
}

.glyphicon-bold:before {
  content: "";
}

.glyphicon-italic:before {
  content: "";
}

.glyphicon-text-height:before {
  content: "";
}

.glyphicon-text-width:before {
  content: "";
}

.glyphicon-align-left:before {
  content: "";
}

.glyphicon-align-center:before {
  content: "";
}

.glyphicon-align-right:before {
  content: "";
}

.glyphicon-align-justify:before {
  content: "";
}

.glyphicon-list:before {
  content: "";
}

.glyphicon-indent-left:before {
  content: "";
}

.glyphicon-indent-right:before {
  content: "";
}

.glyphicon-facetime-video:before {
  content: "";
}

.glyphicon-picture:before {
  content: "";
}

.glyphicon-map-marker:before {
  content: "";
}

.glyphicon-adjust:before {
  content: "";
}

.glyphicon-tint:before {
  content: "";
}

.glyphicon-edit:before {
  content: "";
}

.glyphicon-share:before {
  content: "";
}

.glyphicon-check:before {
  content: "";
}

.glyphicon-move:before {
  content: "";
}

.glyphicon-step-backward:before {
  content: "";
}

.glyphicon-fast-backward:before {
  content: "";
}

.glyphicon-backward:before {
  content: "";
}

.glyphicon-play:before {
  content: "";
}

.glyphicon-pause:before {
  content: "";
}

.glyphicon-stop:before {
  content: "";
}

.glyphicon-forward:before {
  content: "";
}

.glyphicon-fast-forward:before {
  content: "";
}

.glyphicon-step-forward:before {
  content: "";
}

.glyphicon-eject:before {
  content: "";
}

.glyphicon-chevron-left:before {
  content: "";
}

.glyphicon-chevron-right:before {
  content: "";
}

.glyphicon-plus-sign:before {
  content: "";
}

.glyphicon-minus-sign:before {
  content: "";
}

.glyphicon-remove-sign:before {
  content: "";
}

.glyphicon-ok-sign:before {
  content: "";
}

.glyphicon-question-sign:before {
  content: "";
}

.glyphicon-info-sign:before {
  content: "";
}

.glyphicon-screenshot:before {
  content: "";
}

.glyphicon-remove-circle:before {
  content: "";
}

.glyphicon-ok-circle:before {
  content: "";
}

.glyphicon-ban-circle:before {
  content: "";
}

.glyphicon-arrow-left:before {
  content: "";
}

.glyphicon-arrow-right:before {
  content: "";
}

.glyphicon-arrow-up:before {
  content: "";
}

.glyphicon-arrow-down:before {
  content: "";
}

.glyphicon-share-alt:before {
  content: "";
}

.glyphicon-resize-full:before {
  content: "";
}

.glyphicon-resize-small:before {
  content: "";
}

.glyphicon-exclamation-sign:before {
  content: "";
}

.glyphicon-gift:before {
  content: "";
}

.glyphicon-leaf:before {
  content: "";
}

.glyphicon-fire:before {
  content: "";
}

.glyphicon-eye-open:before {
  content: "";
}

.glyphicon-eye-close:before {
  content: "";
}

.glyphicon-warning-sign:before {
  content: "";
}

.glyphicon-plane:before {
  content: "";
}

.glyphicon-calendar:before {
  content: "";
}

.glyphicon-random:before {
  content: "";
}

.glyphicon-comment:before {
  content: "";
}

.glyphicon-magnet:before {
  content: "";
}

.glyphicon-chevron-up:before {
  content: "";
}

.glyphicon-chevron-down:before {
  content: "";
}

.glyphicon-retweet:before {
  content: "";
}

.glyphicon-shopping-cart:before {
  content: "";
}

.glyphicon-folder-close:before {
  content: "";
}

.glyphicon-folder-open:before {
  content: "";
}

.glyphicon-resize-vertical:before {
  content: "";
}

.glyphicon-resize-horizontal:before {
  content: "";
}

.glyphicon-hdd:before {
  content: "";
}

.glyphicon-bullhorn:before {
  content: "";
}

.glyphicon-bell:before {
  content: "";
}

.glyphicon-certificate:before {
  content: "";
}

.glyphicon-thumbs-up:before {
  content: "";
}

.glyphicon-thumbs-down:before {
  content: "";
}

.glyphicon-hand-right:before {
  content: "";
}

.glyphicon-hand-left:before {
  content: "";
}

.glyphicon-hand-up:before {
  content: "";
}

.glyphicon-hand-down:before {
  content: "";
}

.glyphicon-circle-arrow-right:before {
  content: "";
}

.glyphicon-circle-arrow-left:before {
  content: "";
}

.glyphicon-circle-arrow-up:before {
  content: "";
}

.glyphicon-circle-arrow-down:before {
  content: "";
}

.glyphicon-globe:before {
  content: "";
}

.glyphicon-wrench:before {
  content: "";
}

.glyphicon-tasks:before {
  content: "";
}

.glyphicon-filter:before {
  content: "";
}

.glyphicon-briefcase:before {
  content: "";
}

.glyphicon-fullscreen:before {
  content: "";
}

.glyphicon-dashboard:before {
  content: "";
}

.glyphicon-paperclip:before {
  content: "";
}

.glyphicon-heart-empty:before {
  content: "";
}

.glyphicon-link:before {
  content: "";
}

.glyphicon-phone:before {
  content: "";
}

.glyphicon-pushpin:before {
  content: "";
}

.glyphicon-usd:before {
  content: "";
}

.glyphicon-gbp:before {
  content: "";
}

.glyphicon-sort:before {
  content: "";
}

.glyphicon-sort-by-alphabet:before {
  content: "";
}

.glyphicon-sort-by-alphabet-alt:before {
  content: "";
}

.glyphicon-sort-by-order:before {
  content: "";
}

.glyphicon-sort-by-order-alt:before {
  content: "";
}

.glyphicon-sort-by-attributes:before {
  content: "";
}

.glyphicon-sort-by-attributes-alt:before {
  content: "";
}

.glyphicon-unchecked:before {
  content: "";
}

.glyphicon-expand:before {
  content: "";
}

.glyphicon-collapse-down:before {
  content: "";
}

.glyphicon-collapse-up:before {
  content: "";
}

.glyphicon-log-in:before {
  content: "";
}

.glyphicon-flash:before {
  content: "";
}

.glyphicon-log-out:before {
  content: "";
}

.glyphicon-new-window:before {
  content: "";
}

.glyphicon-record:before {
  content: "";
}

.glyphicon-save:before {
  content: "";
}

.glyphicon-open:before {
  content: "";
}

.glyphicon-saved:before {
  content: "";
}

.glyphicon-import:before {
  content: "";
}

.glyphicon-export:before {
  content: "";
}

.glyphicon-send:before {
  content: "";
}

.glyphicon-floppy-disk:before {
  content: "";
}

.glyphicon-floppy-saved:before {
  content: "";
}

.glyphicon-floppy-remove:before {
  content: "";
}

.glyphicon-floppy-save:before {
  content: "";
}

.glyphicon-floppy-open:before {
  content: "";
}

.glyphicon-credit-card:before {
  content: "";
}

.glyphicon-transfer:before {
  content: "";
}

.glyphicon-cutlery:before {
  content: "";
}

.glyphicon-header:before {
  content: "";
}

.glyphicon-compressed:before {
  content: "";
}

.glyphicon-earphone:before {
  content: "";
}

.glyphicon-phone-alt:before {
  content: "";
}

.glyphicon-tower:before {
  content: "";
}

.glyphicon-stats:before {
  content: "";
}

.glyphicon-sd-video:before {
  content: "";
}

.glyphicon-hd-video:before {
  content: "";
}

.glyphicon-subtitles:before {
  content: "";
}

.glyphicon-sound-stereo:before {
  content: "";
}

.glyphicon-sound-dolby:before {
  content: "";
}

.glyphicon-sound-5-1:before {
  content: "";
}

.glyphicon-sound-6-1:before {
  content: "";
}

.glyphicon-sound-7-1:before {
  content: "";
}

.glyphicon-copyright-mark:before {
  content: "";
}

.glyphicon-registration-mark:before {
  content: "";
}

.glyphicon-cloud-download:before {
  content: "";
}

.glyphicon-cloud-upload:before {
  content: "";
}

.glyphicon-tree-conifer:before {
  content: "";
}

.glyphicon-tree-deciduous:before {
  content: "";
}

.glyphicon-cd:before {
  content: "";
}

.glyphicon-save-file:before {
  content: "";
}

.glyphicon-open-file:before {
  content: "";
}

.glyphicon-level-up:before {
  content: "";
}

.glyphicon-copy:before {
  content: "";
}

.glyphicon-paste:before {
  content: "";
}

.glyphicon-alert:before {
  content: "";
}

.glyphicon-equalizer:before {
  content: "";
}

.glyphicon-king:before {
  content: "";
}

.glyphicon-queen:before {
  content: "";
}

.glyphicon-pawn:before {
  content: "";
}

.glyphicon-bishop:before {
  content: "";
}

.glyphicon-knight:before {
  content: "";
}

.glyphicon-baby-formula:before {
  content: "";
}

.glyphicon-tent:before {
  content: "⛺";
}

.glyphicon-blackboard:before {
  content: "";
}

.glyphicon-bed:before {
  content: "";
}

.glyphicon-apple:before {
  content: "";
}

.glyphicon-erase:before {
  content: "";
}

.glyphicon-hourglass:before {
  content: "⌛";
}

.glyphicon-lamp:before {
  content: "";
}

.glyphicon-duplicate:before {
  content: "";
}

.glyphicon-piggy-bank:before {
  content: "";
}

.glyphicon-scissors:before {
  content: "";
}

.glyphicon-bitcoin:before,
.glyphicon-btc:before,
.glyphicon-xbt:before {
  content: "";
}

.glyphicon-jpy:before,
.glyphicon-yen:before {
  content: "¥";
}

.glyphicon-rub:before,
.glyphicon-ruble:before {
  content: "₽";
}

.glyphicon-scale:before {
  content: "";
}

.glyphicon-ice-lolly:before {
  content: "";
}

.glyphicon-ice-lolly-tasted:before {
  content: "";
}

.glyphicon-education:before {
  content: "";
}

.glyphicon-option-horizontal:before {
  content: "";
}

.glyphicon-option-vertical:before {
  content: "";
}

.glyphicon-menu-hamburger:before {
  content: "";
}

.glyphicon-modal-window:before {
  content: "";
}

.glyphicon-oil:before {
  content: "";
}

.glyphicon-grain:before {
  content: "";
}

.glyphicon-sunglasses:before {
  content: "";
}

.glyphicon-text-size:before {
  content: "";
}

.glyphicon-text-color:before {
  content: "";
}

.glyphicon-text-background:before {
  content: "";
}

.glyphicon-object-align-top:before {
  content: "";
}

.glyphicon-object-align-bottom:before {
  content: "";
}

.glyphicon-object-align-horizontal:before {
  content: "";
}

.glyphicon-object-align-left:before {
  content: "";
}

.glyphicon-object-align-vertical:before {
  content: "";
}

.glyphicon-object-align-right:before {
  content: "";
}

.glyphicon-triangle-right:before {
  content: "";
}

.glyphicon-triangle-left:before {
  content: "";
}

.glyphicon-triangle-bottom:before {
  content: "";
}

.glyphicon-triangle-top:before {
  content: "";
}

.glyphicon-console:before {
  content: "";
}

.glyphicon-superscript:before {
  content: "";
}

.glyphicon-subscript:before {
  content: "";
}

.glyphicon-menu-left:before {
  content: "";
}

.glyphicon-menu-right:before {
  content: "";
}

.glyphicon-menu-down:before {
  content: "";
}

.glyphicon-menu-up:before {
  content: "";
}

.bs-datepicker {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  background: #fff;
  box-shadow: 0 0 10px 0 #aaa;
  position: relative;
  z-index: 1;
}

.bs-datepicker:after {
  clear: both;
  content: "";
  display: block;
}

.bs-datepicker bs-day-picker {
  float: left;
}

.bs-datepicker-btns button:active,
.bs-datepicker-btns button:focus,
.bs-datepicker-btns button:hover,
.bs-datepicker-predefined-btns button:active,
.bs-datepicker-predefined-btns button:focus,
.bs-datepicker button:active,
.bs-datepicker button:focus,
.bs-datepicker button:hover,
.bs-datepicker input:active,
.bs-datepicker input:focus,
.bs-datepicker input:hover {
  outline: none;
}

.bs-datepicker-head {
  min-width: 270px;
  height: 50px;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  text-align: justify;
}

.bs-datepicker-head:after {
  content: "";
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.bs-datepicker-head button {
  display: inline-block;
  vertical-align: top;
  padding: 0;
  height: 30px;
  line-height: 30px;
  border: 0;
  background: transparent;
  text-align: center;
  cursor: pointer;
  color: #fff;
  transition: 0.3s;
}

.bs-datepicker-head button[disabled],
.bs-datepicker-head button[disabled]:active,
.bs-datepicker-head button[disabled]:hover {
  background: hsla(0, 0%, 86.7%, 0.3);
  color: #f5f5f5;
  cursor: not-allowed;
}

.bs-datepicker-head button.next,
.bs-datepicker-head button.previous {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.bs-datepicker-head button.next span,
.bs-datepicker-head button.previous span {
  font-size: 28px;
  line-height: 1;
  display: inline-block;
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.bs-datepicker-head button.current {
  border-radius: 15px;
  max-width: 155px;
  padding: 0 13px;
}

.bs-datepicker-head button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.bs-datepicker-head button:active {
  background-color: rgba(0, 0, 0, 0.2);
}

.bs-datepicker-body {
  padding: 10px;
  border-radius: 0 0 3px 3px;
  min-height: 232px;
  min-width: 278px;
  border: 1px solid #e9edf0;
}

.bs-datepicker-body .days.weeks {
  position: relative;
  z-index: 1;
}

.bs-datepicker-body table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.bs-datepicker-body table th {
  font-size: 13px;
  color: #9aaec1;
  font-weight: 400;
  text-align: center;
}

.bs-datepicker-body table td {
  color: #54708b;
  text-align: center;
  position: relative;
  padding: 0;
}

.bs-datepicker-body table td span {
  display: block;
  margin: 0 auto;
  font-size: 13px;
  border-radius: 50%;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.bs-datepicker-body table td:not(.disabled):not(.week) span:not(.disabled):not(.is-other-month) {
  cursor: pointer;
}

.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
  background-color: #e9edf0;
  transition: 0s;
}

.bs-datepicker-body table td.is-active-other-month:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-active-other-month:not(.disabled):not(.selected) {
  background-color: #e9edf0;
  transition: 0s;
  cursor: pointer;
}

.bs-datepicker-body table td.disabled span,
.bs-datepicker-body table td span.disabled {
  color: #9aaec1;
}

.bs-datepicker-body table td.selected span,
.bs-datepicker-body table td span.selected {
  color: #fff;
}

.bs-datepicker-body table td.is-other-month span,
.bs-datepicker-body table td span.is-other-month {
  color: rgba(0, 0, 0, 0.25);
}

.bs-datepicker-body table td.active {
  position: relative;
}

.bs-datepicker-body table td.active.select-start:before {
  left: 35%;
}

.bs-datepicker-body table td.active.select-end:before {
  left: -85%;
}

.bs-datepicker-body table td.active.select-end span:after,
.bs-datepicker-body table td.active.select-start span:after,
.bs-datepicker-body table td span.active.select-end:after,
.bs-datepicker-body table td span.active.select-start:after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  top: 0;
  border-radius: 50%;
}

.bs-datepicker-body table td:before,
.bs-datepicker-body table td span:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 6px;
  bottom: 6px;
  left: -3px;
  right: -2px;
  box-sizing: content-box;
  background: transparent;
}

.bs-datepicker-body table td.active.select-start+td.active:before {
  left: -20%;
}

.bs-datepicker-body table td:last-child.active:before {
  border-radius: 0 3px 3px 0;
  width: 125%;
  left: -25%;
}

.bs-datepicker-body table td[class*="select-"] span,
.bs-datepicker-body table td span[class*="select-"] {
  border-radius: 50%;
  color: #fff;
}

.bs-datepicker-body table.days span.active:not(.select-start):before,
.bs-datepicker-body table.days span.in-range:not(.select-start):before,
.bs-datepicker-body table.days td.active:not(.select-start):before,
.bs-datepicker-body table.days td.in-range:not(.select-start):before {
  background: #e9edf0;
}

.bs-datepicker-body table.days span {
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.bs-datepicker-body table.days span.select-start {
  z-index: 2;
}

.bs-datepicker-body table.days span.is-highlighted.in-range:before {
  right: 3px;
  left: 0;
}

.bs-datepicker-body table.days span.in-range.select-end:before {
  right: 4px;
  left: 0;
}

.bs-datepicker-body table.days td.active+td.is-highlighted:before,
.bs-datepicker-body table.days td.active+td.select-end:before,
.bs-datepicker-body table.days td.in-range+td.is-highlighted:before,
.bs-datepicker-body table.days td.in-range+td.select-end:before,
.bs-datepicker-body table.days td.select-start+td.is-highlighted:before,
.bs-datepicker-body table.days td.select-start+td.select-end:before {
  background: #e9edf0;
  width: 100%;
}

.bs-datepicker-body table.weeks tr td:nth-child(2).active:before {
  border-radius: 3px 0 0 3px;
  left: 0;
  width: 100%;
}

.bs-datepicker-body table:not(.weeks) tr td:first-child:before {
  border-radius: 3px 0 0 3px;
}

.bs-datepicker-body table.years td span {
  width: 46px;
  height: 46px;
  line-height: 45px;
  margin: 0 auto;
}

.bs-datepicker-body table.years tr:not(:last-child) td span {
  margin-bottom: 8px;
}

.bs-datepicker-body table.months td {
  height: 52px;
}

.bs-datepicker-body table.months td span {
  padding: 6px;
  border-radius: 15px;
}

.bs-datepicker .current-timedate {
  color: #54708b;
  font-size: 15px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  border-radius: 20px;
  border: 1px solid #e9edf0;
  margin-bottom: 10px;
  cursor: pointer;
  text-transform: uppercase;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.bs-datepicker .current-timedate span:not(:empty):before {
  content: "";
  width: 15px;
  height: 16px;
  display: inline-block;
  margin-right: 4px;
  vertical-align: text-bottom;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAABMklEQVQoU9VTwW3CQBCcOUgBtEBKSAukAnBKME+wFCAlYIhk8sQlxFABtJAScAsuAPBEewYcxCP8ouxrPDsza61uiVN1o6RNHD4htSCmq49RfO71BvMJqBBkITRf1kmUW49nQRC9h1I5AZlBClaL8aP1fKgOOxCx8aSLs+Q19eZuNO8QmPqJRtDFguy7OAcDbJPs+/BKVPDIPrvD2ZJgWAmVe7O0rI0Vqs1seyWUXpuJoppYCa5L+U++NpNPkr5OE2oMdARsb3gykJT5ydZcL8Z9Ww60nxg2LhjON9li9OwXZzo+xLbp3nC2s9CL2RrueGyVrgwNm8HpsCzZ9EEW6kqXlo1GQe03FzP/7W8Hl0dBtu7Bf7zt6mIwvX1RvzDCm7+q3mAW0Dl/GPdUCeXrZLT9BrDrGkm4qlPvAAAAAElFTkSuQmCC);
}

.bs-datepicker-multiple {
  border-radius: 4px 0 0 4px;
}

.bs-datepicker-multiple+.bs-datepicker-multiple {
  margin-left: 10px;
}

.bs-datepicker-multiple .bs-datepicker {
  box-shadow: none;
  position: relative;
}

.bs-datepicker-multiple .bs-datepicker:not(:last-child) {
  padding-right: 10px;
}

.bs-datepicker-multiple .bs-datepicker+.bs-datepicker:after {
  content: "";
  display: block;
  width: 14px;
  height: 10px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAYAAABrGwT5AAAA1ElEQVQoU42RsQrCUAxF77VuDu7O4oMWW//BURBBpZvgKk4uIrjoqKOTf+DopIO4uYggtFTfw3+pkQqCW1/G5J7kJiFy4m5MxUlxAzgIPHX+lzMPzupRYlYgxiR7vqsOP8YKzsTx0yxFMCUZ+q7aZzlr+OvgoWcAFyAHgat2jLWu48252DdqAihDJGSSJNUUxYmQjs3+hPQBlAh2rG2LCOPnaw3IiGDX99TRCs7ASJsNhUOA7d/LcuHvRG22FIZvsNXw1MX6VZExCilOQKEfeLXr/10+aC9Ho7arh7oAAAAASUVORK5CYII=);
  position: absolute;
  top: 25px;
  left: -8px;
}

.bs-datepicker-multiple .bs-datepicker .left {
  float: left;
}

.bs-datepicker-multiple .bs-datepicker .right {
  float: right;
}

.bs-datepicker-container {
  padding: 15px;
}

.bs-datepicker .bs-media-container {
  display: flex;
}

.bs-datepicker-custom-range {
  padding: 15px;
  background: #eee;
}

.bs-datepicker-predefined-btns button {
  width: 100%;
  display: block;
  height: 30px;
  background-color: #9aaec1;
  border-radius: 4px;
  color: #fff;
  border: 0;
  margin-bottom: 10px;
  padding: 0 18px;
  text-align: left;
  transition: 0.3s;
}

.bs-datepicker-predefined-btns button:hover {
  background-color: #54708b;
}

.bs-datepicker-buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  padding-top: 10px;
  border-top: 1px solid #e9edf0;
}

.bs-datepicker-buttons .btn-default {
  margin-left: 10px;
}

.bs-datepicker-buttons .btn-today-wrapper {
  display: flex;
  flex-flow: row wrap;
}

.bs-datepicker-buttons .clear-right,
.bs-datepicker-buttons .today-right {
  flex-grow: 0;
}

.bs-datepicker-buttons .clear-left,
.bs-datepicker-buttons .today-left {
  flex-grow: 1;
}

.bs-datepicker-buttons .clear-center,
.bs-datepicker-buttons .today-center {
  flex-grow: 0.5;
}

.bs-timepicker-container {
  padding: 10px 0;
}

.bs-timepicker-label {
  color: #54708b;
  margin-bottom: 10px;
}

.bs-timepicker-controls {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.bs-timepicker-controls button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 0;
  background-color: #e9edf0;
  color: #54708b;
  font-size: 16px;
  font-weight: 700;
  vertical-align: middle;
  line-height: 0;
  padding: 0;
  transition: 0.3s;
}

.bs-timepicker-controls button:hover {
  background-color: #d5dadd;
}

.bs-timepicker-controls input {
  width: 35px;
  height: 25px;
  border-radius: 13px;
  text-align: center;
  border: 1px solid #e9edf0;
}

.bs-timepicker .switch-time-format {
  text-transform: uppercase;
  min-width: 54px;
  height: 25px;
  border-radius: 20px;
  border: 1px solid #e9edf0;
  background: #fff;
  color: #54708b;
  font-size: 13px;
}

.bs-timepicker .switch-time-format img {
  vertical-align: initial;
  margin-left: 4px;
}

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 1080;
}

.theme-default .bs-datepicker-head {
  background-color: #777;
}

.theme-default .btn-clear-wrapper .btn-success,
.theme-default .btn-today-wrapper .btn-success {
  background-color: #777;
  border-color: #777;
}

.theme-default .btn-clear-wrapper .btn-success:focus,
.theme-default .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus,
.theme-default .btn-today-wrapper .btn-success:focus,
.theme-default .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.theme-default .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active,
.theme-default .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #616161;
  border-color: #616161;
}

.theme-default .btn-clear-wrapper .btn-success:hover,
.theme-default .btn-today-wrapper .btn-success:hover {
  background-color: #6f6e6e;
  border-color: #6f6e6e;
}

.theme-default .bs-datepicker-body table td.selected span,
.theme-default .bs-datepicker-body table td[class*="select-"] span:after,
.theme-default .bs-datepicker-body table td span.selected,
.theme-default .bs-datepicker-body table td span[class*="select-"]:after,
.theme-default .bs-datepicker-predefined-btns button.selected {
  background-color: #777;
}

.theme-default .bs-datepicker-body table td.week span {
  color: #777;
}

.theme-default .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #777;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-green .bs-datepicker-head {
  background-color: #5cb85c;
}

.theme-green .btn-clear-wrapper .btn-success,
.theme-green .btn-today-wrapper .btn-success {
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.theme-green .btn-clear-wrapper .btn-success:focus,
.theme-green .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus,
.theme-green .btn-today-wrapper .btn-success:focus,
.theme-green .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.theme-green .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active,
.theme-green .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #1e7e34;
  border-color: #1e7e34;
}

.theme-green .btn-clear-wrapper .btn-success:hover,
.theme-green .btn-today-wrapper .btn-success:hover {
  background-color: #218838;
  border-color: #218838;
}

.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td[class*="select-"] span:after,
.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td span[class*="select-"]:after,
.theme-green .bs-datepicker-predefined-btns button.selected {
  background-color: #5cb85c;
}

.theme-green .bs-datepicker-body table td.week span {
  color: #5cb85c;
}

.theme-green .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #5cb85c;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-blue .bs-datepicker-head {
  background-color: #5bc0de;
}

.theme-blue .btn-clear-wrapper .btn-success,
.theme-blue .btn-today-wrapper .btn-success {
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.theme-blue .btn-clear-wrapper .btn-success:focus,
.theme-blue .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus,
.theme-blue .btn-today-wrapper .btn-success:focus,
.theme-blue .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.theme-blue .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active,
.theme-blue .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #2aa8cd;
  border-color: #2aa8cd;
}

.theme-blue .btn-clear-wrapper .btn-success:hover,
.theme-blue .btn-today-wrapper .btn-success:hover {
  background-color: #3ab3d7;
  border-color: #3ab3d7;
}

.theme-blue .bs-datepicker-body table td.selected span,
.theme-blue .bs-datepicker-body table td[class*="select-"] span:after,
.theme-blue .bs-datepicker-body table td span.selected,
.theme-blue .bs-datepicker-body table td span[class*="select-"]:after,
.theme-blue .bs-datepicker-predefined-btns button.selected {
  background-color: #5bc0de;
}

.theme-blue .bs-datepicker-body table td.week span {
  color: #5bc0de;
}

.theme-blue .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #5bc0de;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-dark-blue .bs-datepicker-head {
  background-color: #337ab7;
}

.theme-dark-blue .btn-clear-wrapper .btn-success,
.theme-dark-blue .btn-today-wrapper .btn-success {
  background-color: #337ab7;
  border-color: #337ab7;
}

.theme-dark-blue .btn-clear-wrapper .btn-success:focus,
.theme-dark-blue .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus,
.theme-dark-blue .btn-today-wrapper .btn-success:focus,
.theme-dark-blue .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.theme-dark-blue .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active,
.theme-dark-blue .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #266498;
  border-color: #266498;
}

.theme-dark-blue .btn-clear-wrapper .btn-success:hover,
.theme-dark-blue .btn-today-wrapper .btn-success:hover {
  background-color: #2c6fa9;
  border-color: #2c6fa9;
}

.theme-dark-blue .bs-datepicker-body table td.selected span,
.theme-dark-blue .bs-datepicker-body table td[class*="select-"] span:after,
.theme-dark-blue .bs-datepicker-body table td span.selected,
.theme-dark-blue .bs-datepicker-body table td span[class*="select-"]:after,
.theme-dark-blue .bs-datepicker-predefined-btns button.selected {
  background-color: #337ab7;
}

.theme-dark-blue .bs-datepicker-body table td.week span {
  color: #337ab7;
}

.theme-dark-blue .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #337ab7;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-red .bs-datepicker-head {
  background-color: #d9534f;
}

.theme-red .btn-clear-wrapper .btn-success,
.theme-red .btn-today-wrapper .btn-success {
  background-color: #d9534f;
  border-color: #d9534f;
}

.theme-red .btn-clear-wrapper .btn-success:focus,
.theme-red .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus,
.theme-red .btn-today-wrapper .btn-success:focus,
.theme-red .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.theme-red .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active,
.theme-red .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #d23531;
  border-color: #d23531;
}

.theme-red .btn-clear-wrapper .btn-success:hover,
.theme-red .btn-today-wrapper .btn-success:hover {
  background-color: #e33732;
  border-color: #e33732;
}

.theme-red .bs-datepicker-body table td.selected span,
.theme-red .bs-datepicker-body table td[class*="select-"] span:after,
.theme-red .bs-datepicker-body table td span.selected,
.theme-red .bs-datepicker-body table td span[class*="select-"]:after,
.theme-red .bs-datepicker-predefined-btns button.selected {
  background-color: #d9534f;
}

.theme-red .bs-datepicker-body table td.week span {
  color: #d9534f;
}

.theme-red .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #d9534f;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-orange .bs-datepicker-head {
  background-color: #f0ad4e;
}

.theme-orange .btn-clear-wrapper .btn-success,
.theme-orange .btn-today-wrapper .btn-success {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.theme-orange .btn-clear-wrapper .btn-success:focus,
.theme-orange .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus,
.theme-orange .btn-today-wrapper .btn-success:focus,
.theme-orange .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.theme-orange .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active,
.theme-orange .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #ed9c29;
  border-color: #ed9c29;
}

.theme-orange .btn-clear-wrapper .btn-success:hover,
.theme-orange .btn-today-wrapper .btn-success:hover {
  background-color: #ffac35;
  border-color: #ffac35;
}

.theme-orange .bs-datepicker-body table td.selected span,
.theme-orange .bs-datepicker-body table td[class*="select-"] span:after,
.theme-orange .bs-datepicker-body table td span.selected,
.theme-orange .bs-datepicker-body table td span[class*="select-"]:after,
.theme-orange .bs-datepicker-predefined-btns button.selected {
  background-color: #f0ad4e;
}

.theme-orange .bs-datepicker-body table td.week span {
  color: #f0ad4e;
}

.theme-orange .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #f0ad4e;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate__animated.animate__infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animate__animated.animate__repeat-1 {
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: var(--animate-repeat);
  animation-iteration-count: var(--animate-repeat);
}

.animate__animated.animate__repeat-2 {
  -webkit-animation-iteration-count: 2;
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 2);
  animation-iteration-count: calc(var(--animate-repeat) * 2);
}

.animate__animated.animate__repeat-3 {
  -webkit-animation-iteration-count: 3;
  animation-iteration-count: 3;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 3);
  animation-iteration-count: calc(var(--animate-repeat) * 3);
}

.animate__animated.animate__delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-delay: var(--animate-delay);
  animation-delay: var(--animate-delay);
}

.animate__animated.animate__delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-delay: calc(var(--animate-delay) * 2);
  animation-delay: calc(var(--animate-delay) * 2);
}

.animate__animated.animate__delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  -webkit-animation-delay: calc(var(--animate-delay) * 3);
  animation-delay: calc(var(--animate-delay) * 3);
}

.animate__animated.animate__delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  -webkit-animation-delay: calc(var(--animate-delay) * 4);
  animation-delay: calc(var(--animate-delay) * 4);
}

.animate__animated.animate__delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
  -webkit-animation-delay: calc(var(--animate-delay) * 5);
  animation-delay: calc(var(--animate-delay) * 5);
}

.animate__animated.animate__faster {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-duration: calc(var(--animate-duration) / 2);
  animation-duration: calc(var(--animate-duration) / 2);
}

.animate__animated.animate__fast {
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-duration: calc(var(--animate-duration) * 0.8);
  animation-duration: calc(var(--animate-duration) * 0.8);
}

.animate__animated.animate__slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
}

.animate__animated.animate__slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 3);
  animation-duration: calc(var(--animate-duration) * 3);
}

@-webkit-keyframes bounce {

  0%,
  20%,
  53%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0) scaleY(1.1);
    transform: translate3d(0, -30px, 0) scaleY(1.1);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0) scaleY(1.05);
    transform: translate3d(0, -15px, 0) scaleY(1.05);
  }

  80% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translateZ(0) scaleY(0.95);
    transform: translateZ(0) scaleY(0.95);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
    transform: translate3d(0, -4px, 0) scaleY(1.02);
  }
}

@keyframes bounce {

  0%,
  20%,
  53%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0) scaleY(1.1);
    transform: translate3d(0, -30px, 0) scaleY(1.1);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0) scaleY(1.05);
    transform: translate3d(0, -15px, 0) scaleY(1.05);
  }

  80% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translateZ(0) scaleY(0.95);
    transform: translateZ(0) scaleY(0.95);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
    transform: translate3d(0, -4px, 0) scaleY(1.02);
  }
}

.animate__bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}

@-webkit-keyframes flash {

  0%,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

@keyframes flash {

  0%,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.animate__flash {
  -webkit-animation-name: flash;
  animation-name: flash;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.animate__pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

@-webkit-keyframes rubberBand {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes rubberBand {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.animate__rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}

@-webkit-keyframes shakeX {

  0%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes shakeX {

  0%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

.animate__shakeX {
  -webkit-animation-name: shakeX;
  animation-name: shakeX;
}

@-webkit-keyframes shakeY {

  0%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
}

@keyframes shakeY {

  0%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
}

.animate__shakeY {
  -webkit-animation-name: shakeY;
  animation-name: shakeY;
}

@-webkit-keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.animate__headShake {
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: headShake;
  animation-name: headShake;
}

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  40% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  60% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }

  80% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }

  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes swing {
  20% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  40% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  60% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }

  80% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }

  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.animate__swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes tada {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.animate__tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate(-5deg);
    transform: translate3d(-25%, 0, 0) rotate(-5deg);
  }

  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate(3deg);
    transform: translate3d(20%, 0, 0) rotate(3deg);
  }

  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate(-3deg);
    transform: translate3d(-15%, 0, 0) rotate(-3deg);
  }

  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate(2deg);
    transform: translate3d(10%, 0, 0) rotate(2deg);
  }

  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate(-1deg);
    transform: translate3d(-5%, 0, 0) rotate(-1deg);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes wobble {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate(-5deg);
    transform: translate3d(-25%, 0, 0) rotate(-5deg);
  }

  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate(3deg);
    transform: translate3d(20%, 0, 0) rotate(3deg);
  }

  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate(-3deg);
    transform: translate3d(-15%, 0, 0) rotate(-3deg);
  }

  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate(2deg);
    transform: translate3d(10%, 0, 0) rotate(2deg);
  }

  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate(-1deg);
    transform: translate3d(-5%, 0, 0) rotate(-1deg);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble;
}

@-webkit-keyframes jello {

  0%,
  11.1%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }

  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

@keyframes jello {

  0%,
  11.1%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }

  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

.animate__jello {
  -webkit-animation-name: jello;
  animation-name: jello;
  -webkit-transform-origin: center;
  transform-origin: center;
}

@-webkit-keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.animate__heartBeat {
  -webkit-animation-name: heartBeat;
  animation-name: heartBeat;
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 1.3);
  animation-duration: calc(var(--animate-duration) * 1.3);
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

@-webkit-keyframes backInDown {
  0% {
    -webkit-transform: translateY(-1200px) scale(0.7);
    transform: translateY(-1200px) scale(0.7);
    opacity: 0.7;
  }

  80% {
    -webkit-transform: translateY(0) scale(0.7);
    transform: translateY(0) scale(0.7);
    opacity: 0.7;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes backInDown {
  0% {
    -webkit-transform: translateY(-1200px) scale(0.7);
    transform: translateY(-1200px) scale(0.7);
    opacity: 0.7;
  }

  80% {
    -webkit-transform: translateY(0) scale(0.7);
    transform: translateY(0) scale(0.7);
    opacity: 0.7;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.animate__backInDown {
  -webkit-animation-name: backInDown;
  animation-name: backInDown;
}

@-webkit-keyframes backInLeft {
  0% {
    -webkit-transform: translateX(-2000px) scale(0.7);
    transform: translateX(-2000px) scale(0.7);
    opacity: 0.7;
  }

  80% {
    -webkit-transform: translateX(0) scale(0.7);
    transform: translateX(0) scale(0.7);
    opacity: 0.7;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes backInLeft {
  0% {
    -webkit-transform: translateX(-2000px) scale(0.7);
    transform: translateX(-2000px) scale(0.7);
    opacity: 0.7;
  }

  80% {
    -webkit-transform: translateX(0) scale(0.7);
    transform: translateX(0) scale(0.7);
    opacity: 0.7;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.animate__backInLeft {
  -webkit-animation-name: backInLeft;
  animation-name: backInLeft;
}

@-webkit-keyframes backInRight {
  0% {
    -webkit-transform: translateX(2000px) scale(0.7);
    transform: translateX(2000px) scale(0.7);
    opacity: 0.7;
  }

  80% {
    -webkit-transform: translateX(0) scale(0.7);
    transform: translateX(0) scale(0.7);
    opacity: 0.7;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes backInRight {
  0% {
    -webkit-transform: translateX(2000px) scale(0.7);
    transform: translateX(2000px) scale(0.7);
    opacity: 0.7;
  }

  80% {
    -webkit-transform: translateX(0) scale(0.7);
    transform: translateX(0) scale(0.7);
    opacity: 0.7;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.animate__backInRight {
  -webkit-animation-name: backInRight;
  animation-name: backInRight;
}

@-webkit-keyframes backInUp {
  0% {
    -webkit-transform: translateY(1200px) scale(0.7);
    transform: translateY(1200px) scale(0.7);
    opacity: 0.7;
  }

  80% {
    -webkit-transform: translateY(0) scale(0.7);
    transform: translateY(0) scale(0.7);
    opacity: 0.7;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes backInUp {
  0% {
    -webkit-transform: translateY(1200px) scale(0.7);
    transform: translateY(1200px) scale(0.7);
    opacity: 0.7;
  }

  80% {
    -webkit-transform: translateY(0) scale(0.7);
    transform: translateY(0) scale(0.7);
    opacity: 0.7;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.animate__backInUp {
  -webkit-animation-name: backInUp;
  animation-name: backInUp;
}

@-webkit-keyframes backOutDown {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  20% {
    -webkit-transform: translateY(0) scale(0.7);
    transform: translateY(0) scale(0.7);
    opacity: 0.7;
  }

  to {
    -webkit-transform: translateY(700px) scale(0.7);
    transform: translateY(700px) scale(0.7);
    opacity: 0.7;
  }
}

@keyframes backOutDown {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  20% {
    -webkit-transform: translateY(0) scale(0.7);
    transform: translateY(0) scale(0.7);
    opacity: 0.7;
  }

  to {
    -webkit-transform: translateY(700px) scale(0.7);
    transform: translateY(700px) scale(0.7);
    opacity: 0.7;
  }
}

.animate__backOutDown {
  -webkit-animation-name: backOutDown;
  animation-name: backOutDown;
}

@-webkit-keyframes backOutLeft {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  20% {
    -webkit-transform: translateX(0) scale(0.7);
    transform: translateX(0) scale(0.7);
    opacity: 0.7;
  }

  to {
    -webkit-transform: translateX(-2000px) scale(0.7);
    transform: translateX(-2000px) scale(0.7);
    opacity: 0.7;
  }
}

@keyframes backOutLeft {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  20% {
    -webkit-transform: translateX(0) scale(0.7);
    transform: translateX(0) scale(0.7);
    opacity: 0.7;
  }

  to {
    -webkit-transform: translateX(-2000px) scale(0.7);
    transform: translateX(-2000px) scale(0.7);
    opacity: 0.7;
  }
}

.animate__backOutLeft {
  -webkit-animation-name: backOutLeft;
  animation-name: backOutLeft;
}

@-webkit-keyframes backOutRight {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  20% {
    -webkit-transform: translateX(0) scale(0.7);
    transform: translateX(0) scale(0.7);
    opacity: 0.7;
  }

  to {
    -webkit-transform: translateX(2000px) scale(0.7);
    transform: translateX(2000px) scale(0.7);
    opacity: 0.7;
  }
}

@keyframes backOutRight {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  20% {
    -webkit-transform: translateX(0) scale(0.7);
    transform: translateX(0) scale(0.7);
    opacity: 0.7;
  }

  to {
    -webkit-transform: translateX(2000px) scale(0.7);
    transform: translateX(2000px) scale(0.7);
    opacity: 0.7;
  }
}

.animate__backOutRight {
  -webkit-animation-name: backOutRight;
  animation-name: backOutRight;
}

@-webkit-keyframes backOutUp {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  20% {
    -webkit-transform: translateY(0) scale(0.7);
    transform: translateY(0) scale(0.7);
    opacity: 0.7;
  }

  to {
    -webkit-transform: translateY(-700px) scale(0.7);
    transform: translateY(-700px) scale(0.7);
    opacity: 0.7;
  }
}

@keyframes backOutUp {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  20% {
    -webkit-transform: translateY(0) scale(0.7);
    transform: translateY(0) scale(0.7);
    opacity: 0.7;
  }

  to {
    -webkit-transform: translateY(-700px) scale(0.7);
    transform: translateY(-700px) scale(0.7);
    opacity: 0.7;
  }
}

.animate__backOutUp {
  -webkit-animation-name: backOutUp;
  animation-name: backOutUp;
}

@-webkit-keyframes bounceIn {

  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes bounceIn {

  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.animate__bounceIn {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
  animation-duration: calc(var(--animate-duration) * 0.75);
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}

@-webkit-keyframes bounceInDown {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0) scaleY(3);
    transform: translate3d(0, -3000px, 0) scaleY(3);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0) scaleY(0.9);
    transform: translate3d(0, 25px, 0) scaleY(0.9);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0) scaleY(0.95);
    transform: translate3d(0, -10px, 0) scaleY(0.95);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0) scaleY(0.985);
    transform: translate3d(0, 5px, 0) scaleY(0.985);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes bounceInDown {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0) scaleY(3);
    transform: translate3d(0, -3000px, 0) scaleY(3);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0) scaleY(0.9);
    transform: translate3d(0, 25px, 0) scaleY(0.9);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0) scaleY(0.95);
    transform: translate3d(0, -10px, 0) scaleY(0.95);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0) scaleY(0.985);
    transform: translate3d(0, 5px, 0) scaleY(0.985);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

@-webkit-keyframes bounceInLeft {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0) scaleX(3);
    transform: translate3d(-3000px, 0, 0) scaleX(3);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0) scaleX(1);
    transform: translate3d(25px, 0, 0) scaleX(1);
  }

  75% {
    -webkit-transform: translate3d(-10px, 0, 0) scaleX(0.98);
    transform: translate3d(-10px, 0, 0) scaleX(0.98);
  }

  90% {
    -webkit-transform: translate3d(5px, 0, 0) scaleX(0.995);
    transform: translate3d(5px, 0, 0) scaleX(0.995);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes bounceInLeft {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0) scaleX(3);
    transform: translate3d(-3000px, 0, 0) scaleX(3);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0) scaleX(1);
    transform: translate3d(25px, 0, 0) scaleX(1);
  }

  75% {
    -webkit-transform: translate3d(-10px, 0, 0) scaleX(0.98);
    transform: translate3d(-10px, 0, 0) scaleX(0.98);
  }

  90% {
    -webkit-transform: translate3d(5px, 0, 0) scaleX(0.995);
    transform: translate3d(5px, 0, 0) scaleX(0.995);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}

@-webkit-keyframes bounceInRight {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0) scaleX(3);
    transform: translate3d(3000px, 0, 0) scaleX(3);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0) scaleX(1);
    transform: translate3d(-25px, 0, 0) scaleX(1);
  }

  75% {
    -webkit-transform: translate3d(10px, 0, 0) scaleX(0.98);
    transform: translate3d(10px, 0, 0) scaleX(0.98);
  }

  90% {
    -webkit-transform: translate3d(-5px, 0, 0) scaleX(0.995);
    transform: translate3d(-5px, 0, 0) scaleX(0.995);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes bounceInRight {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0) scaleX(3);
    transform: translate3d(3000px, 0, 0) scaleX(3);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0) scaleX(1);
    transform: translate3d(-25px, 0, 0) scaleX(1);
  }

  75% {
    -webkit-transform: translate3d(10px, 0, 0) scaleX(0.98);
    transform: translate3d(10px, 0, 0) scaleX(0.98);
  }

  90% {
    -webkit-transform: translate3d(-5px, 0, 0) scaleX(0.995);
    transform: translate3d(-5px, 0, 0) scaleX(0.995);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
}

@-webkit-keyframes bounceInUp {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0) scaleY(5);
    transform: translate3d(0, 3000px, 0) scaleY(5);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
    transform: translate3d(0, -20px, 0) scaleY(0.9);
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(0.95);
    transform: translate3d(0, 10px, 0) scaleY(0.95);
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0) scaleY(0.985);
    transform: translate3d(0, -5px, 0) scaleY(0.985);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes bounceInUp {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0) scaleY(5);
    transform: translate3d(0, 3000px, 0) scaleY(5);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
    transform: translate3d(0, -20px, 0) scaleY(0.9);
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(0.95);
    transform: translate3d(0, 10px, 0) scaleY(0.95);
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0) scaleY(0.985);
    transform: translate3d(0, -5px, 0) scaleY(0.985);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
}

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.animate__bounceOut {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
  animation-duration: calc(var(--animate-duration) * 0.75);
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
}

@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(0.985);
    transform: translate3d(0, 10px, 0) scaleY(0.985);
  }

  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
    transform: translate3d(0, -20px, 0) scaleY(0.9);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0) scaleY(3);
    transform: translate3d(0, 2000px, 0) scaleY(3);
  }
}

@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(0.985);
    transform: translate3d(0, 10px, 0) scaleY(0.985);
  }

  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
    transform: translate3d(0, -20px, 0) scaleY(0.9);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0) scaleY(3);
    transform: translate3d(0, 2000px, 0) scaleY(3);
  }
}

.animate__bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown;
}

@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0) scaleX(0.9);
    transform: translate3d(20px, 0, 0) scaleX(0.9);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0) scaleX(2);
    transform: translate3d(-2000px, 0, 0) scaleX(2);
  }
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0) scaleX(0.9);
    transform: translate3d(20px, 0, 0) scaleX(0.9);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0) scaleX(2);
    transform: translate3d(-2000px, 0, 0) scaleX(2);
  }
}

.animate__bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft;
}

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0) scaleX(0.9);
    transform: translate3d(-20px, 0, 0) scaleX(0.9);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0) scaleX(2);
    transform: translate3d(2000px, 0, 0) scaleX(2);
  }
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0) scaleX(0.9);
    transform: translate3d(-20px, 0, 0) scaleX(0.9);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0) scaleX(2);
    transform: translate3d(2000px, 0, 0) scaleX(2);
  }
}

.animate__bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight;
}

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0) scaleY(0.985);
    transform: translate3d(0, -10px, 0) scaleY(0.985);
  }

  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0) scaleY(0.9);
    transform: translate3d(0, 20px, 0) scaleY(0.9);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0) scaleY(3);
    transform: translate3d(0, -2000px, 0) scaleY(3);
  }
}

@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0) scaleY(0.985);
    transform: translate3d(0, -10px, 0) scaleY(0.985);
  }

  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0) scaleY(0.9);
    transform: translate3d(0, 20px, 0) scaleY(0.9);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0) scaleY(3);
    transform: translate3d(0, -2000px, 0) scaleY(3);
  }
}

.animate__bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate__fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@-webkit-keyframes fadeInTopLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, -100%, 0);
    transform: translate3d(-100%, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInTopLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, -100%, 0);
    transform: translate3d(-100%, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInTopLeft {
  -webkit-animation-name: fadeInTopLeft;
  animation-name: fadeInTopLeft;
}

@-webkit-keyframes fadeInTopRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, -100%, 0);
    transform: translate3d(100%, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInTopRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, -100%, 0);
    transform: translate3d(100%, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInTopRight {
  -webkit-animation-name: fadeInTopRight;
  animation-name: fadeInTopRight;
}

@-webkit-keyframes fadeInBottomLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 100%, 0);
    transform: translate3d(-100%, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInBottomLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 100%, 0);
    transform: translate3d(-100%, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInBottomLeft {
  -webkit-animation-name: fadeInBottomLeft;
  animation-name: fadeInBottomLeft;
}

@-webkit-keyframes fadeInBottomRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 100%, 0);
    transform: translate3d(100%, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInBottomRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 100%, 0);
    transform: translate3d(100%, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInBottomRight {
  -webkit-animation-name: fadeInBottomRight;
  animation-name: fadeInBottomRight;
}

.animate__fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.animate__fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

.animate__fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig;
}

@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

.animate__fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig;
}

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.animate__fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

.animate__fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig;
}

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.animate__fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

.animate__fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig;
}

@-webkit-keyframes fadeOutTopLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, -100%, 0);
    transform: translate3d(-100%, -100%, 0);
  }
}

@keyframes fadeOutTopLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, -100%, 0);
    transform: translate3d(-100%, -100%, 0);
  }
}

.animate__fadeOutTopLeft {
  -webkit-animation-name: fadeOutTopLeft;
  animation-name: fadeOutTopLeft;
}

@-webkit-keyframes fadeOutTopRight {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, -100%, 0);
    transform: translate3d(100%, -100%, 0);
  }
}

@keyframes fadeOutTopRight {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, -100%, 0);
    transform: translate3d(100%, -100%, 0);
  }
}

.animate__fadeOutTopRight {
  -webkit-animation-name: fadeOutTopRight;
  animation-name: fadeOutTopRight;
}

@-webkit-keyframes fadeOutBottomRight {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 100%, 0);
    transform: translate3d(100%, 100%, 0);
  }
}

@keyframes fadeOutBottomRight {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 100%, 0);
    transform: translate3d(100%, 100%, 0);
  }
}

.animate__fadeOutBottomRight {
  -webkit-animation-name: fadeOutBottomRight;
  animation-name: fadeOutBottomRight;
}

@-webkit-keyframes fadeOutBottomLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 100%, 0);
    transform: translate3d(-100%, 100%, 0);
  }
}

@keyframes fadeOutBottomLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 100%, 0);
    transform: translate3d(-100%, 100%, 0);
  }
}

.animate__fadeOutBottomLeft {
  -webkit-animation-name: fadeOutBottomLeft;
  animation-name: fadeOutBottomLeft;
}

@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) scaleX(1) translateZ(0) rotateY(-1turn);
    transform: perspective(400px) scaleX(1) translateZ(0) rotateY(-1turn);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  40% {
    -webkit-transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-190deg);
    transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-170deg);
    transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translateZ(0) rotateY(0deg);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translateZ(0) rotateY(0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  to {
    -webkit-transform: perspective(400px) scaleX(1) translateZ(0) rotateY(0deg);
    transform: perspective(400px) scaleX(1) translateZ(0) rotateY(0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

@keyframes flip {
  0% {
    -webkit-transform: perspective(400px) scaleX(1) translateZ(0) rotateY(-1turn);
    transform: perspective(400px) scaleX(1) translateZ(0) rotateY(-1turn);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  40% {
    -webkit-transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-190deg);
    transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-170deg);
    transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translateZ(0) rotateY(0deg);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translateZ(0) rotateY(0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  to {
    -webkit-transform: perspective(400px) scaleX(1) translateZ(0) rotateY(0deg);
    transform: perspective(400px) scaleX(1) translateZ(0) rotateY(0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

.animate__animated.animate__flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip;
}

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotateX(-20deg);
    transform: perspective(400px) rotateX(-20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotateX(-5deg);
    transform: perspective(400px) rotateX(-5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotateX(-20deg);
    transform: perspective(400px) rotateX(-20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotateX(-5deg);
    transform: perspective(400px) rotateX(-5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

.animate__flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
}

@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotateY(-20deg);
    transform: perspective(400px) rotateY(-20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotateY(-5deg);
    transform: perspective(400px) rotateY(-5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotateY(-20deg);
    transform: perspective(400px) rotateY(-20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotateY(-5deg);
    transform: perspective(400px) rotateY(-5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

.animate__flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
}

@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

  30% {
    -webkit-transform: perspective(400px) rotateX(-20deg);
    transform: perspective(400px) rotateX(-20deg);
    opacity: 1;
  }

  to {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}

@keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

  30% {
    -webkit-transform: perspective(400px) rotateX(-20deg);
    transform: perspective(400px) rotateX(-20deg);
    opacity: 1;
  }

  to {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}

.animate__flipOutX {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
  animation-duration: calc(var(--animate-duration) * 0.75);
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

  30% {
    -webkit-transform: perspective(400px) rotateY(-15deg);
    transform: perspective(400px) rotateY(-15deg);
    opacity: 1;
  }

  to {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}

@keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

  30% {
    -webkit-transform: perspective(400px) rotateY(-15deg);
    transform: perspective(400px) rotateY(-15deg);
    opacity: 1;
  }

  to {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}

.animate__flipOutY {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
  animation-duration: calc(var(--animate-duration) * 0.75);
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY;
}

@-webkit-keyframes lightSpeedInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }

  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes lightSpeedInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }

  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__lightSpeedInRight {
  -webkit-animation-name: lightSpeedInRight;
  animation-name: lightSpeedInRight;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

@-webkit-keyframes lightSpeedInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0) skewX(30deg);
    transform: translate3d(-100%, 0, 0) skewX(30deg);
    opacity: 0;
  }

  60% {
    -webkit-transform: skewX(-20deg);
    transform: skewX(-20deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: skewX(5deg);
    transform: skewX(5deg);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes lightSpeedInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0) skewX(30deg);
    transform: translate3d(-100%, 0, 0) skewX(30deg);
    opacity: 0;
  }

  60% {
    -webkit-transform: skewX(-20deg);
    transform: skewX(-20deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: skewX(5deg);
    transform: skewX(5deg);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__lightSpeedInLeft {
  -webkit-animation-name: lightSpeedInLeft;
  animation-name: lightSpeedInLeft;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

@-webkit-keyframes lightSpeedOutRight {
  0% {
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}

@keyframes lightSpeedOutRight {
  0% {
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}

.animate__lightSpeedOutRight {
  -webkit-animation-name: lightSpeedOutRight;
  animation-name: lightSpeedOutRight;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

@-webkit-keyframes lightSpeedOutLeft {
  0% {
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(-100%, 0, 0) skewX(-30deg);
    transform: translate3d(-100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
}

@keyframes lightSpeedOutLeft {
  0% {
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(-100%, 0, 0) skewX(-30deg);
    transform: translate3d(-100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
}

.animate__lightSpeedOutLeft {
  -webkit-animation-name: lightSpeedOutLeft;
  animation-name: lightSpeedOutLeft;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes rotateIn {
  0% {
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.animate__rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn;
  -webkit-transform-origin: center;
  transform-origin: center;
}

@-webkit-keyframes rotateInDownLeft {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes rotateInDownLeft {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@-webkit-keyframes rotateInDownRight {
  0% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes rotateInDownRight {
  0% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@-webkit-keyframes rotateInUpLeft {
  0% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes rotateInUpLeft {
  0% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.animate__rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft;
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom;
}

@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes rotateInUpRight {
  0% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.animate__rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
}

@-webkit-keyframes rotateOut {
  0% {
    opacity: 1;
  }

  to {
    -webkit-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0;
  }
}

@keyframes rotateOut {
  0% {
    opacity: 1;
  }

  to {
    -webkit-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0;
  }
}

.animate__rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut;
  -webkit-transform-origin: center;
  transform-origin: center;
}

@-webkit-keyframes rotateOutDownLeft {
  0% {
    opacity: 1;
  }

  to {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }
}

@keyframes rotateOutDownLeft {
  0% {
    opacity: 1;
  }

  to {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }
}

.animate__rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft;
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom;
}

@-webkit-keyframes rotateOutDownRight {
  0% {
    opacity: 1;
  }

  to {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0;
  }
}

@keyframes rotateOutDownRight {
  0% {
    opacity: 1;
  }

  to {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0;
  }
}

.animate__rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
}

@-webkit-keyframes rotateOutUpLeft {
  0% {
    opacity: 1;
  }

  to {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0;
  }
}

@keyframes rotateOutUpLeft {
  0% {
    opacity: 1;
  }

  to {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0;
  }
}

.animate__rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft;
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom;
}

@-webkit-keyframes rotateOutUpRight {
  0% {
    opacity: 1;
  }

  to {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}

@keyframes rotateOutUpRight {
  0% {
    opacity: 1;
  }

  to {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}

.animate__rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
}

@-webkit-keyframes hinge {
  0% {
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  20%,
  60% {
    -webkit-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  40%,
  80% {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}

@keyframes hinge {
  0% {
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  20%,
  60% {
    -webkit-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  40%,
  80% {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}

.animate__hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
  -webkit-animation-name: hinge;
  animation-name: hinge;
  -webkit-transform-origin: top left;
  transform-origin: top left;
}

@-webkit-keyframes jackInTheBox {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }

  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes jackInTheBox {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }

  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.animate__jackInTheBox {
  -webkit-animation-name: jackInTheBox;
  animation-name: jackInTheBox;
}

@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate(-120deg);
    transform: translate3d(-100%, 0, 0) rotate(-120deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate(-120deg);
    transform: translate3d(-100%, 0, 0) rotate(-120deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn;
}

@-webkit-keyframes rollOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate(120deg);
    transform: translate3d(100%, 0, 0) rotate(120deg);
  }
}

@keyframes rollOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate(120deg);
    transform: translate3d(100%, 0, 0) rotate(120deg);
  }
}

.animate__rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut;
}

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.animate__zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

@-webkit-keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.animate__zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown;
}

@-webkit-keyframes zoomInLeft {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInLeft {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.animate__zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft;
}

@-webkit-keyframes zoomInRight {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInRight {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.animate__zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight;
}

@-webkit-keyframes zoomInUp {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInUp {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.animate__zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp;
}

@-webkit-keyframes zoomOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

@keyframes zoomOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

.animate__zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
}

@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.animate__zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
  }
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
  }
}

.animate__zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
  }
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
  }
}

.animate__zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight;
  -webkit-transform-origin: right center;
  transform-origin: right center;
}

@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.animate__zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}

@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes slideInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes slideInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes slideOutDown {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes slideOutDown {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.animate__slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}

@-webkit-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slideOutLeft {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}

@-webkit-keyframes slideOutRight {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes slideOutRight {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.animate__slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}

@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes slideOutUp {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.animate__slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

.owl-carousel,
.owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.owl-carousel .owl-item.center {
  z-index: 5;
}

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translateZ(0);
}

.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
}

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: none;
}

.no-js .owl-carousel,
.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.owl-height {
  transition: height 0.5s ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
  transition: opacity 0.4s ease;
}

.owl-carousel .owl-item .owl-lazy:not([src]),
.owl-carousel .owl-item .owl-lazy[src^=""] {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 0.1s ease;
}

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.4s ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.owl-theme .owl-dots,
.owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav {
  margin-top: 10px;
}

.owl-theme .owl-nav [class*="owl-"] {
  color: #fff;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #d6d6d6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: #869791;
  color: #fff;
  text-decoration: none;
}

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 10px;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

.btn-check-budge {
  display: inline-block;
  border-radius: 5px;
  padding: 3px;
  background: linear-gradient(180deg,
      #ffe570,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #421800 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%);
  box-shadow: 0 4px 4px rgb(0, 0, 0, 68%);
  border: 0;
  height: 48px;
  margin-top: 35px;
  margin-bottom: 15px;
  cursor: pointer;
}

.btn-check-budget span {
  min-width: 150px;
  font-weight: 700;
  font-size: 14px;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  background: linear-gradient(180deg, #061855, #020a25);
  border-radius: 4px;
  padding: 10px 20px;
  height: 100%;
  display: block;
}

.instruction-modal-dialog .btn-nav button.instrution-btn-next,
.instruction-modal-dialog .btn-nav button.instrution-btn-prev {
  display: inline-block;
  height: 36px;
  width: 36px;
  transition: all 0.2s ease-in-out;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: relative;
}

@media only screen and (max-width: 575px) {
  .contact-checkbox-item {
    display: contents;
  }

  .socials-icon-bar,
  .welcome_container .socials-icon-bar {
    display: flex;
    transform: inherit;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 13% !important;
  }

  .opportunity-ul-row {
    column-count: 1;
  }
}

@media screen and (max-width: 1400px) {
  .add-ads-top-bx img {
    max-width: 14%;
  }
}

@media only screen and (min-width: 768px) and (max-height: 1024px) {
  .add-ads-top-bx img {
    max-width: 18%;
  }
}

@media screen and (max-width: 1400px) {
  .add-ads-btm-bx {
    min-height: 120px;
    padding-top: 40px;
  }
}

@media screen and (max-width: 1400px) {
  .add-ads-top-bx {
    min-height: 88px;
  }
}

@media only screen and (min-width: 768px) and (max-height: 1024px) {
  .add-ads-top-bx {
    min-height: 105px;
    margin-bottom: -25px;
  }
}

@media screen and (max-width: 1400px) {
  .add-ads-box {
    min-height: 180px;
  }
}

@media only screen and (min-width: 768px) and (max-height: 1024px) {
  .add-ads-box {
    min-height: 200px;
    max-height: 210px;
  }
}

@media screen and (max-width: 1400px) {
  .add-ads-top-bx h5 {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-height: 1024px) {

  .add-ads-banner-info h5,
  .add-ads-top-bx h5 {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-height: 1024px) {
  .add-ads-top-bx h5 {
    margin-top: 10px;
  }
}

@media screen and (max-width: 1400px) {

  .input-custom-field-2 .form-control,
  .input-custom-field .form-control .select-custom-field-2 .form-control,
  .select-custom-field.form-control {
    height: 40px !important;
  }
}

@media screen and (max-width: 1400px) {

  .input-custom-field-2 .form-control,
  .input-custom-field .form-control,
  .select-custom-field-2 .form-control,
  .select-custom-field .form-control {
    height: 40px !important;
  }
}

@media screen and (min-width: 1024px) and (max-height: 768px) {
  .readastory-modal-dialog.modal-dialog .modal-body {
    max-height: 450px;
    overflow: auto;
  }
}

.cardChild {
  display: flex;
  width: 12vw;
}

.cardparent {
  margin-bottom: 2%;
}

.cardTitle {
  font-size: 20px;
  align-items: center;
}

.storylist {
  display: flex;
  flex-wrap: wrap;
  height: 150px;
  justify-content: space-evenly;
  margin-top: 1%;
}

.cardparent {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  background: linear-gradient(180deg,
      #ffe570,
      #fffb95 13.74%,
      #be9a38 26.34%,
      #ebce65 47.87%,
      #b38a29 67.57%,
      #421800 78.56%,
      #b48a28 92.99%,
      #fce06b 101.93%);
  height: 100%;
  padding: 5px;
  max-width: 170px;
  min-width: 150px;
  max-height: 170px;
  border-radius: 0.25rem;
  cursor: pointer;
}

.card-start {
  background: linear-gradient(90deg,
      #d39339,
      #ecca70 20%,
      #faf8bd 50%,
      #daa74c 80%,
      #cf8c31);
  height: 97%;
  width: 100%;
  margin: auto;
  word-wrap: break-word;
  -webkit-background-clip: border-box;
  background-clip: border-box;
}

.cardBorder {
  height: 100%;
}

.parentbody {
  justify-content: space-evenly;
  overflow-y: scroll;
}

.cardData {
  height: 50%;
}

.cardTitleImage {
  position: relative;
  height: 70px;
  width: auto;
  background: url("../../assets/images/shape-add-ads-top.png");
  background-position: bottom;
  background-size: cover;
}

.cardTitleText {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.cardTitle {
  position: relative;
  height: 50%;
}

.manualcontent {
  height: 367px;
  overflow-y: scroll;
}

.modal-content-book {
  position: relative;
  display: flex;
  width: 100%;
  height: 425px;
  pointer-events: auto;
  background-color: #431900;
  -webkit-background-clip: border-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.border-style-9-book {
  border-radius: 5px;
  padding: 8px;
  background: #431900;
}

.modal-dialog {
  position: relative;
  width: auto;
  pointer-events: visible !important;
}

.leftPage {
  display: flex;
  flex-direction: column;
  height: 425px;
  width: 48.75%;
  margin-left: 10px;
  background-color: #fff;
}

.rightPage {
  height: 425px;
  width: 48.75%;
  margin-left: 5px;
  background-color: #fff;
}

.left-book-previous {
  margin-top: -3%;
  margin-left: 1%;
  position: relative;
}

.book-previous-border {
  position: absolute;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: linear-gradient(180deg,
      #ffe773,
      #fffa93 12.81%,
      #c09b37 31.25%,
      #e7c75f 56.25%,
      #512704 82.72%,
      #efd160);
}

.book-previous-color {
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-left: -6%;
  margin-top: -43%;
  background: radial-gradient(50% 50% at 50% 50%, #082174 0, #031038 100%);
}

.book-previous-icon {
  background: url("../../assets/images/back-arrow-gradient-small.png");
  height: 30px;
  width: 30px;
  margin-top: 15%;
  margin-left: 5%;
}

.right-book-next {
  display: flex;
  float: right;
  margin-top: -8%;
  margin-right: 12%;
}

.book-next-border {
  position: absolute;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: linear-gradient(180deg,
      #ffe773,
      #fffa93 12.81%,
      #c09b37 31.25%,
      #e7c75f 56.25%,
      #512704 82.72%,
      #efd160);
}

.book-next-color {
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-left: -6%;
  margin-top: -43%;
  background: radial-gradient(50% 50% at 50% 50%, #082174 0, #031038 100%);
}

.book-next-icon {
  background: url("../../assets/images/back-arrow-gradient-small.png");
  transform: rotate(180deg);
  height: 30px;
  width: 30px;
  margin-top: 15%;
  margin-left: 22%;
}

.left-book-content {
  padding: 20px;
}

.right-book-content {
  padding: 20px;
}

@media screen and (max-width: 1400px) {
  .input-custom-field {
    margin-bottom: 2px;
  }
}

@media screen and (max-width: 1050px) and (min-width: 601px) {
  .cardTitleImage {
    width: 119px;
  }
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  z-index: 99;
  position: absolute;
  background-color: #fff;
  width: 100%;
}

::-webkit-scrollbar {
  background: linear-gradient(180deg, #4e4e4e, #000);
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg,
      #ffe570,
      #fffe99 9.18%,
      #bb9432 25.31%,
      #efd269 48.35%,
      #c09937 69.22%,
      #9e781a 83.69%,
      #f1d361);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg,
      #ffe570,
      #fffe99 9.18%,
      #bb9432 25.31%,
      #efd269 48.35%,
      #c09937 69.22%,
      #9e781a 83.69%,
      #f1d361);
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: blue;
  color: #fae042;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

.flip-book {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  display: none;
  background-size: cover;
}

.page {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #fdfaf7;
  color: #785e3a;
  border: solid 1px #c2b5a3;
  overflow: hidden;
}

.pagedatachild {
  height: 90%;
  overflow-y: hidden;
}

input#modified::-webkit-calendar-picker-indicator {
  width: 100%;
  margin: 0;
  background: none;
}

.page .page-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  word-break: break-all;
}

.page .page-content .page-header {
  height: 30px;
  font-size: 100%;
  text-transform: uppercase;
  text-align: center;
}

.page .page-content .page-image {
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.page .page-content .page-text {
  height: 100%;
  flex-grow: 1;
  font-size: 80%;
  text-align: justify;
  margin-top: 10px;
  padding-top: 10px;
  box-sizing: border-box;
  border-top: solid 1px #f4e8d7;
}

.page .page-content .page-footer {
  height: 30px;
  border-top: solid 1px #f4e8d7;
  font-size: 80%;
  color: #998466;
}

.page.--left {
  border-right: 0;
  box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, 0.4);
}

.page.--right {
  border-left: 0;
  box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4);
}

.page.--right .page-footer {
  text-align: right;
}

.page.hard {
  background-color: #f2e8d9;
  border: solid 1px #998466;
}

.page.page-cover {
  background-color: #e3d0b5;
  color: #785e3a;
  border: solid 1px #998466;
}

.page.page-cover h2 {
  text-align: center;
  padding-top: 50%;
  font-size: 210%;
}

.page.page-cover.page-cover-top {
  box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5),
    -2px 0 5px 2px rgba(0, 0, 0, 0.4);
}

.page.page-cover.page-cover-bottom {
  box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5),
    10px 0 8px 0px rgba(0, 0, 0, 0.4);
}

.zoom-in {
  transform: scale(0) !important;
  opacity: 0;
  -webkit-transition: 2s all 0s;
  -moz-transition: 2s all 0s;
  -ms-transition: 2s all 0s;
  -o-transition: 2s all 0s;
  transition: 2s all 0s;
  display: block !important;
}

.zoom-in.show {
  opacity: 1;
  transform: translate(0, 0) scale(1) !important;
}

.zoom-in.zoom-in-left.show {
  left: 0;
}

.zoom-in-left {
  -webkit-transform: translate(-12%, -5%) scale(0) !important;
  transform: translate(-12%, -5%) scale(0) !important;
  left: -12%;
}

.zoom-in-center {
  -webkit-transform: translate(0, -5%) scale(0) !important;
  transform: translate(0, -5%) scale(0) !important;
  opacity: 0;
  -webkit-transition: 1.5s all 0s;
  -moz-transition: 1.5s all 0s;
  -ms-transition: 1.5s all 0s;
  -o-transition: 1.5s all 0s;
  transition: 1.5s all 0s;
  display: block !important;
}

.zoom-in-center.show {
  opacity: 1;
  -webkit-transform: translate(0, -5%) scale(1) !important;
  transform: translate(0, -0%) scale(1) !important;
  transition-delay: 500ms;
  transition-property: margin-right;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  outline: 0;
}

.zoom-in {
  transition: all 5s 10s;
  display: block !important;
}

.zoom-in {
  -webkit-transition: 2s all 0s;
  -moz-transition: 2s all 0s;
  -ms-transition: 2s all 0s;
  -o-transition: 2s all 0s;
  transition: 2s all 0s;
  display: block !important;
}

@media screen and (max-width: 768px) {
  .page {
    width: 97% !important;
    height: 99.5% !important;
    margin-top: -71px;
    /* overflow-y: auto; */
  }

  .cardTitleImage {
    width: 119px;
  }

  .add-advertisement-content {
    padding: 17px 28px;
    overflow-x: auto;
    display: grid;
    z-index: 999999;
  }

  .border-style-9 {
    border-radius: 5px;
    padding: 8px !important;
    height: 42rem;
    background: linear-gradient(180deg,
        #ffe570,
        #fffb95 13.74%,
        #be9a38 26.34%,
        #ebce65 47.87%,
        #b38a29 67.57%,
        #421800 78.56%,
        #b48a28 92.99%,
        #fce06b 101.93%);
  }
}

@media only screen and (max-width: 450) {
  .contact-modal-dialog .close-button-style span {
    right: 10px !important;
    top: -6px !important;
  }

  .cardTitleImage {
    width: 139px !important;
  }

  p {
    font-weight: 400;
    margin-bottom: -9rem;
  }

  .add-advertisement-content {
    padding: 17px 28px;
    overflow-x: auto;
    display: grid;
    z-index: 999999;
  }

  .border-style-9 {
    border-radius: 5px;
    padding: 8px !important;
    background: linear-gradient(180deg,
        #ffe570,
        #fffb95 13.74%,
        #be9a38 26.34%,
        #ebce65 47.87%,
        #b38a29 67.57%,
        #421800 78.56%,
        #b48a28 92.99%,
        #fce06b 101.93%);
  }
}

@media screen and (max-width: 1000px) {
  .write-paper-bg {
    margin-top: 64px;
  }

  .cardTitleImage {
    width: 141px;
  }
}

.add-ads-subject-txt p {
  font-size: 16px;
}

.bookAd {
  height: 600px;
  width: 600px;
  overflow-y: hidden;
}

@media screen and (min-width: 1600px) {}

@media screen and (min-width: 1900px) {}

@media only screen and (max-width: 600px) {
  .logo_container_home {
    display: flex;
    justify-content: center;
  }

  .header_icon_container {
    left: 3%;
    top: 23%;
  }
}

@media only screen and (max-width: 991.5px) {
  .logo_container_home {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }

  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.333333%;
  }

  .offset-lg-2 {
    margin-left: 16.666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.333333%;
  }

  .offset-lg-5 {
    margin-left: 41.666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.333333%;
  }

  .offset-lg-8 {
    margin-left: 66.666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.333333%;
  }

  .offset-lg-11 {
    margin-left: 91.666667%;
  }

  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }

  .modal-lg {
    max-width: 800px;
    min-height: calc(100% - 7.2rem) !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }

  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .language-modal-dialog.modal-dialog {
    max-width: 767px;
  }

  .menutop-modal-dialog.modal-dialog {
    max-width: 680px;
  }

  .contact-modal-dialog.modal-dialog,
  .instruction-modal-dialog.modal-dialog,
  .opportunity-modal-dialog.modal-dialog {
    max-width: 767px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }

  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.333333%;
  }

  .offset-xl-2 {
    margin-left: 16.666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.333333%;
  }

  .offset-xl-5 {
    margin-left: 41.666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.333333%;
  }

  .offset-xl-8 {
    margin-left: 66.666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.333333%;
  }

  .offset-xl-11 {
    margin-left: 91.666667%;
  }

  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }

  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }

  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .attention-modal-dialog.modal-dialog,
  .disclaimer-modal-dialog.modal-dialog,
  .language-modal-dialog.modal-dialog {
    max-width: 940px;
    height: 87%;
  }

  .hand-top-box {
    max-width: 991px;
  }

  .hand-top-box img {
    max-width: 845px;
  }

  .attention-head-top-bx {
    background-size: 670px;
    min-height: 143px;
    padding-top: 80px;
  }

  .contact-modal-dialog.modal-dialog,
  .finance-modal-dialog.modal-dialog,
  .instruction-modal-dialog.modal-dialog,
  .opportunity-modal-dialog.modal-dialog,
  .terms-modal-dialog.modal-dialog,
  .view-banner-wrapper {
    max-width: 940px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }

  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 100%;
  }

  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.333333%;
  }

  .offset-md-2 {
    margin-left: 16.666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.333333%;
  }

  .offset-md-5 {
    margin-left: 41.666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.333333%;
  }

  .offset-md-8 {
    margin-left: 66.666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.333333%;
  }

  .offset-md-11 {
    margin-left: 91.666667%;
  }

  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }

  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }

  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .disclaimer-modal-dialog.modal-dialog,
  .language-modal-dialog.modal-dialog,
  .menutop-modal-dialog.modal-dialog {
    max-width: 600px;
  }

  .attention-modal-dialog.modal-dialog {
    max-width: 650px;
  }

  .contact-modal-dialog.modal-dialog,
  .instruction-modal-dialog.modal-dialog,
  .opportunity-modal-dialog.modal-dialog,
  .view-banner-wrapper {
    max-width: 600px;
  }

  .disclaimer-modal-dialog.modal-dialog,
  .language-modal-dialog.modal-dialog,
  .menutop-modal-dialog.modal-dialog {
    max-width: 600px;
  }

  .attention-modal-dialog.modal-dialog {
    max-width: 650px;
  }

  .contact-modal-dialog.modal-dialog,
  .instruction-modal-dialog.modal-dialog,
  .opportunity-modal-dialog.modal-dialog,
  .view-banner-wrapper {
    max-width: 600px;
  }
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-sm>.table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-md>.table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-lg>.table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-xl>.table-bordered {
    border: 0;
  }
}

@media (min-width: 576px) {
  .form-inline label {
    justify-content: center;
  }

  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group {
    width: auto;
  }

  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }

  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }

  .card-group {
    flex-flow: row wrap;
  }

  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group>.card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group>.card:first-child .card-header,
  .card-group>.card:first-child .card-img-top {
    border-top-right-radius: 0;
  }

  .card-group>.card:first-child .card-footer,
  .card-group>.card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group>.card:last-child .card-header,
  .card-group>.card:last-child .card-img-top {
    border-top-left-radius: 0;
  }

  .card-group>.card:last-child .card-footer,
  .card-group>.card:last-child .card-img-bottom {
    border-bottom-left-radius: 0;
  }

  .card-group>.card:only-child {
    border-radius: 0.25rem;
  }

  .card-group>.card:only-child .card-header,
  .card-group>.card:only-child .card-img-top {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .card-group>.card:only-child .card-footer,
  .card-group>.card:only-child .card-img-bottom {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .card-group>.card:not(:first-child):not(:last-child):not(:only-child),
  .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-footer,
  .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-top {
    border-radius: 0;
  }

  .card-columns {
    -moz-column-count: 3;
    column-count: 3;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }

  .jumbotron {
    padding: 4rem 2rem;
  }

  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-sm {
    max-width: 300px;
  }

  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }

  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .modal-dialog {
    max-width: 500px;
    margin: -0.25rem auto;
  }

  .container {
    max-width: 540px;
  }

  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.333333%;
  }

  .offset-sm-2 {
    margin-left: 16.666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.333333%;
  }

  .offset-sm-5 {
    margin-left: 41.666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.333333%;
  }

  .offset-sm-8 {
    margin-left: 66.666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.333333%;
  }

  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (max-width: 575.98px) {

  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 767.98px) {

  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 991.98px) {

  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 1199.98px) {

  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

@media print {

  *,
  :after,
  :before {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  pre {
    white-space: pre-wrap !important;
  }

  blockquote,
  pre {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  img,
  tr {
    page-break-inside: avoid;
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  .container,
  body {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #fff !important;
  }

  .table-bordered td,
  .table-bordered th {
    border: 1px solid #ddd !important;
  }
}

@media (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}

@media (min-width: 2000px) {
  .footer-wrap {
    position: inherit;
    bottom: inherit;
    top: inherit;
    color: #000;
  }

  .footer-wrap p,
  .footer-wrap p span {
    font-size: 30px;
    color: rgb(52 52 52/60%);
  }

  .story-book-row {
    max-height: 760px;
  }

  .flip-book,
  .page {
    height: 500px;
  }

  .sd-top-box {
    margin-bottom: 20px;
  }

  .locks-mrt58 {
    margin-top: 70px;
  }
}

@media (min-width: 1600px) {

  .attention-modal-dialog.modal-dialog,
  .hand-top-box {
    max-width: 1160px;
  }

  .menutop-modal-dialog.modal-dialog {
    max-width: 954px;
  }

  .hand-top-box img {
    max-width: 100%;
  }

  .attention-head-top-bx {
    background-size: 885px;
    min-height: 193px;
    padding-top: 100px;
  }

  .language-modal-dialog.modal-dialog {
    max-width: 1160px;
  }

  .language-modal-dialog.modal-dialog .modal-content {
    min-height: 435px;
  }

  .language-item-row {
    max-height: 350px;
  }

  .finance-modal-dialog.modal-dialog,
  .opportunity-modal-dialog.modal-dialog,
  .terms-modal-dialog.modal-dialog,
  .view-banner-wrapper {
    max-width: 1160px;
  }

  .contact-modal-dialog.modal-dialog {
    max-width: 990px;
  }

  .instruction-modal-dialog.modal-dialog {
    max-width: 1000px;
  }
}

@media screen and (max-width: 1400px) {
  .add-advertisement-content {
    max-height: 553px !important;
    padding: 10px 16px;
  }

  .post-id-modal .contact-textarea-bx textarea {
    height: 290px;
  }
}

@media only screen and (max-width: 1920px) {
  .welcome-logo a {
    max-width: 320px;
  }
}

@media only screen and (max-width: 1280px) {
  .classified-align-wrapper {
    max-width: 940px;
    min-height: 350px !important;
    width: 100%;
  }

  .classified-align-content {
    min-height: 350px !important;
  }

  .classified-align-button {
    padding: 12px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: -12% !important;
  }

  .read-story-container {
    max-width: 940px;
  }
}

@media only screen and (max-width: 1680px) {
  .sd-right-icon p {
    font-size: 16px;
  }

  .attention-modal-dialog.modal-dialog .modal-content {
    min-height: 480px;
  }

  .socials-icon-bar span {
    font-size: 15px;
  }

  .socials-icon-bar a i {
    width: 44px;
    height: 44px;
  }

  .add-advertisement-wrapper {
    max-width: 940px;
    min-height: 450px;
    width: 100%;
  }

  .add-ads-button-left button {
    width: 48px;
    height: 48px;
  }

  .add-ads-button-right button {
    width: 52px;
    height: 52px;
  }

  .add-ads-col-1 {
    width: 168px;
  }

  .camera-icon-ads i img {
    max-width: 15px;
  }

  .upload-icon-ads i img {
    max-width: 30px;
  }

  .keyboard-icon-ads i img {
    max-width: 50px;
  }

  .second-braket {
    font-size: 126px;
  }

  .add-banner-top-box .btn-bor-0 {
    width: 58px;
    height: 58px;
  }

  .add-banner-top-box {
    max-width: 520px;
  }

  .add-book-col-right .second-braket {
    font-size: 100px;
  }

  .disclaimer-modal-dialog.modal-dialog .modal-content .disclaimer-content-in {
    min-height: 460px;
  }

  .disclaimer-modal-dialog.modal-dialog .modal-content:after,
  .disclaimer-modal-dialog.modal-dialog .modal-content:before {
    width: 70px;
    height: 662px;
    background-size: contain;
  }

  .disclaimer-modal-dialog.modal-dialog .modal-content:before {
    left: -70px;
  }

  .disclaimer-modal-dialog.modal-dialog .modal-content:after {
    right: -70px;
  }

  .disclaimer-info-scroll {
    max-height: 380px;
    /* border-top: 3px solid gold; */
  }

  .terms-info {
    max-height: 385px;
    min-height: 387px;
    background-color: wheat !important;
    border-radius: 10px;
    padding: 15px;
  }

  .terms-footer {
    padding: 12px 0;
  }

  .btn-agree span,
  .btn-dontagree span {
    line-height: 30px;
    font-size: 16px;
    min-width: 150px;
    min-height: 30px;
    padding-left: 10px;
    padding-right: 30px;
    cursor: pointer;
  }

  .btn-agree:after,
  .btn-dontagree:after {
    width: 60px;
    height: 60px;
    top: -9px;
  }

  .add-book-ads-col-2 {
    width: calc(100% - 168px);
    padding-left: 28px;
  }

  .add-adv-btngroup-middle {
    max-width: 260px;
  }

  .banner-cmn-btn {
    width: 54px;
    height: 54px;
  }

  .banner-cmn-btn span {
    width: 46px;
    height: 46px;
  }

  .banner-cmn-btn span .banner-cmn-btn-icon {
    width: 28px;
    height: 28px;
  }

  .banner-link-box {
    width: 264px;
  }

  .btn-color-platte span {
    height: 40px;
    width: 40px;
  }

  .contact-modal-dialog .add-adv-btngroup .mt-btn {
    width: 50px;
    height: 50px;
  }

  .contact-modal-dialog .add-adv-btngroup .mt-btn i {
    width: 38px;
    height: 38px;
  }

  .add-ads-banner-info {
    padding: 5px;
  }

  .banner-link-box {
    width: 250px;
  }
}

@media only screen and (max-width: 768px) {

  .add-ads-content,
  .view-classified-content {
    max-height: 655px;
  }
}

@media only screen and (max-width: 1200px) {
  .hand-top-box {
    top: -52px;
  }

  .hand-top-box img {
    max-width: 530px;
  }

  .attention-modal-dialog.modal-dialog .modal-content:after,
  .attention-modal-dialog.modal-dialog .modal-content:before {
    background-size: 100px !important;
  }

  .attention-head-top-bx {
    background-size: 505px;
    padding-top: 60px;
  }

  .attention-head-top-bx h4 {
    font-size: 24px;
  }

  .attention-modal-dialog.modal-dialog .modal-content .modal-body p {
    font-size: 17px;
  }

  .language-modal-dialog.modal-dialog .modal-content .modal-body {
    padding: 45px 25px 20px;
  }

  .custom-field-col {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media only screen and (max-width: 1024px) {

  .socials-icon-bar,
  .welcome_container .socials-icon-bar {
    top: inherit;
    display: flex;
    transform: inherit;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    left: inherit;
    position: inherit;
    text-align: center;
  }

  .story-book-col {
    width: 33.33333%;
  }

  .discussion-leftrow-icon {
    position: absolute;
    top: inherit;
    bottom: 23px;
    left: -250px;
    right: 0;
    margin: auto;
    transform: inherit;
    width: 75px;
  }

  .chat-subject-rows .chat-subject-col {
    width: auto;
  }

  .language-radio-col {
    width: 33.3333%;
  }

  .disclaimer-modal-dialog.modal-dialog .modal-content:after,
  .disclaimer-modal-dialog.modal-dialog .modal-content:before {
    display: none;
  }

  .disclaimer-modal-dialog.modal-dialog {
    margin-bottom: 15px;
  }

  .add-ads-main {
    display: block;
  }

  .add-ads-col-1,
  .add-ads-col-2,
  .add-ads-col-3 {
    width: inherit;
  }

  .add-ads-col-2 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 15px;
  }

  .add-ads-button-row {
    flex-direction: column;
    align-items: center;
  }

  .add-ads-button-right {
    flex-direction: row;
    width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .modal-dialog {
    max-width: 95%;
    margin: 0.5rem auto;
  }

  .chatroom-title-head {
    margin-top: 0;
  }

  .thankstype-modal-dialog.modal-dialog .modal-content .modal-body h3 {
    font-size: 24px;
  }

  .add-ads-banner-info p,
  .add-ads-banner-info span,
  .add-ads-btm-bx p,
  .add-ads-btm-bx span.add-ads-subject-txt {
    font-size: 13px;
    line-height: 4px;
  }

  .bg-welcome {
    background-size: cover !important;
  }

  .sd-right-icon-row {
    left: -10px;
  }
}

@media only screen and (max-width: 840px) {
  .footer-wrap {
    position: inherit;
    right: inherit;
    top: inherit;
    bottom: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .starting-logo {
    top: 5px;
  }

  .readStory-button-position {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .header-exit-button {
    padding: 6px !important;
  }

  .home-exit-bx {
    display: none;
  }

  .mobile-exit-button {
    display: inline-block !important;
  }

  .bg-welcome .home-exit-bx {
    display: block;
    width: 52px;
  }

  .chat-h-col,
  .story-book-col {
    width: 50%;
  }

  .cmn-title-head h2 {
    margin-bottom: 15px;
    font-size: 32px;
  }

  .close-modal-button {
    right: -20px;
    top: -20px;
    height: 48px;
    width: 48px;
  }

  .btn-apply {
    width: 76px;
  }

  .btn-ca-submit {
    width: 65px;
  }

  .chat-box-body-inner {
    padding: 20px 20px 15px;
  }

  .tags-btn {
    width: 70px;
    height: 70px;
    margin-top: 0;
    margin-left: 15px;
  }

  .sociallink-info {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .discussion-leftrow-icon a {
    width: 60px;
    height: 60px;
  }

  .discussion-leftrow-icon {
    bottom: 20px;
    left: -175px;
    width: 60px;
  }

  .language-modal-dialog.modal-dialog .modal-content .modal-body {
    padding: 35px 20px 20px;
  }

  .language-item-row {
    padding-right: 8px;
  }

  .language-radio-col {
    width: 50%;
  }

  .language-title-head h2 {
    min-height: 30px;
  }

  .disclaimer-modal-dialog.modal-dialog:after,
  .disclaimer-modal-dialog.modal-dialog:before {
    display: none;
  }

  .disclaimer-info-scroll {
    max-height: 270px;
    overflow-x: hidden;
  }

  .disclaimer-modal-dialog.modal-dialog .modal-content {
    min-height: 350px;
  }

  .disclaimer-info-scroll p {
    font-size: 15px;
    margin-top: 10px;
  }

  .hand-top-box img {
    max-width: 360px;
  }

  .attention-modal-dialog.modal-dialog .modal-content:after,
  .attention-modal-dialog.modal-dialog .modal-content:before {
    background-size: 50px !important;
  }

  .attention-head-top-bx {
    background-size: contain;
    margin: 0 15px;
    padding-top: 40px !important;
  }

  .attention-modal-dialog.modal-dialog .modal-content .modal-body {
    margin: 0 20px;
  }

  .attention-modal-data {
    max-height: calc(60vh - 0px) !important;
  }

  .attention-head-top-bx h4 {
    font-size: 20px;
  }

  .attention-modal-dialog.modal-dialog .modal-content .modal-body p {
    font-size: 16px;
  }

  .attention-modal-dialog.modal-dialog .close-modal-button {
    right: -12px;
    top: -12px;
  }

  .terms-footer a {
    margin-left: 15px;
    margin-right: 15px;
  }

  .add-ads-col-row {
    margin-left: 0;
    margin-right: 0;
    display: block;
  }

  .add-ads-col-50 {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    margin-bottom: 15px;
  }

  .add-ads-content {
    padding: 12px 18px;
  }

  .add-ads-item-box {
    max-width: 413px;
    margin: 0 auto;
  }

  .add-banner-top-box .input-custom-field {
    margin: 0 15px;
  }

  .view-classified-main {
    display: block;
  }

  .view-classified-col-1 {
    width: 100%;
  }

  .view-classified-col-2 {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .view-classified-wrapper {
    max-width: 400px;
  }

  .view-classified-content {
    padding: 15px 20px;
  }

  .view-classified-fields .cmn-title-head h2 {
    font-size: 24px;
    min-height: 24px;
  }

  .view-banner-col-2 {
    margin-left: 0;
    margin-right: 0;
  }

  .finance-modal-dialog.modal-dialog .close-modal-button,
  .opportunity-modal-dialog.modal-dialog .close-modal-button {
    top: -10px;
    right: -10px;
  }

  .opportunity-ul-row {
    overflow: auto;
  }

  .disclaimer-modal-dialog.modal-dialog .modal-content .disclaimer-content-in {
    min-height: inherit;
  }

  .btn-agree:after,
  .btn-dontagree:after {
    width: 44px;
    height: 44px;
    top: -3px;
    /* right: -14px; */
    right: -9px;
  }

  .btn-agree span,
  .btn-dontagree span {
    line-height: 28px;
    font-size: 15px;
    min-width: 110px;
  }

  .add-book-ads-col-2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .add-adv-btngroup .mt-btn i {
    width: 38px;
    height: 38px;
  }

  .camera-icon-ads i img {
    max-width: 20px;
  }

  .upload-icon-ads i img {
    max-width: 25px;
  }

  .keyboard-icon-ads i img {
    max-width: 32px;
  }

  .contact-modal-dialog .close-button-style span {
    width: 42px;
    height: 42px;
  }

  .close-button-style span:before {
    height: 26px;
    width: 26px;
  }

  contact-modal-dialog .close-button-style span {
    right: 10px;
    top: -6px;
  }

  .close-button-style:hover span:after {
    right: 9px;
  }

  .close-button-style span:after {
    height: 26px;
    width: 26px;
    top: 8px;
  }

  .socials-icon-bar a {
    margin: 0 0.5%;
  }

  .welcome-exit {
    display: block;
  }

  .sd-right-icon-row {
    display: flex;
    z-index: 1;
    left: -100%;
    background: linear-gradient(45deg, #fff185, #c09a37);
    width: 100%;
    padding: 5px;
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
    border-radius: 4px;
  }

  .sd-right-icon-row.active {
    left: 0;
  }

  .sd-right-icon-row .btn-close {
    position: absolute;
    right: -6px;
    background: linear-gradient(90deg, #da0000, #640000);
    color: #fff;
    border: 3px solid #ffeb79;
    border-radius: 6px;
    font-size: 16px;
    height: 100%;
    padding: 6px;
    display: block;
    top: 0;
  }

  readstory-body-container {
    max-width: 1486px;
    width: 100%;
    max-height: 480px;
    margin: 0 auto;
    margin-top: -8%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .disclaimer-modal-dialog.modal-dialog .close-modal-button {
    top: -10px;
    right: -10px;
  }

  .attention-modal-dialog.modal-dialog,
  .language-modal-dialog.modal-dialog {
    margin-left: 20px;
    margin-right: 20px;
  }

  .btn-cmn-group {
    margin-top: 0;
  }

  .story-book-col {
    width: 100%;
  }

  .story-book-item-box {
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;
  }

  .slider-counter {
    font-size: 16px;
  }

  .chat-h-col {
    width: 100%;
  }

  .discussion-user-box .discussion-user-info {
    width: calc(100% - 70px);
  }

  .discussion-user {
    width: 70px;
  }

  .discussion-left-box .discussion-user-info {
    padding-left: 10px;
    padding-right: 40px;
    font-size: 14px;
  }

  .btn-discussClose {
    right: 25px;
    width: 28px;
    height: 28px;
  }

  .knoker-bx {
    width: 60px;
    height: 60px;
  }

  .terms-wrapper {
    padding: 6px 16px 0;
  }

  .terms-footer {
    display: flex;
    justify-content: space-around;
  }

  .terms-footer a {
    margin-left: 7px;
    margin-right: 7px;
    max-width: inherit;
    width: 45%;
  }

  .hand-top-box {
    top: -24px;
  }

  .hand-top-box img {
    max-width: 240px;
  }

  .attention-modal-dialog.modal-dialog .modal-content {
    min-height: 80vh;
  }

  .starting-logo {
    transform: translateY(-40%) scale(0.5);
  }

  @-webkit-keyframes zoomInlogo {
    0% {
      transform: scale(0.1);
    }

    50% {
      transform: scale(0.9);
    }

    to {
      transform: translateY(-40%) scale(0.5);
    }
  }

  @keyframes zoomInlogo {
    0% {
      transform: scale(0.1);
    }

    50% {
      transform: scale(0.9);
    }

    to {
      transform: translateY(-40%) scale(0.5);
    }
  }

  /* .ng-star-inserted .img-responsive {
    min-width: 650px !important;
    max-width: 250px !important;
} */
  .welcome-item {
    padding: 0;
    /* margin: 0 5px; */
  }

  .opportunity-ul-row {
    overflow: auto;
  }
}

@media only screen and (max-width: 360px) {
  .language-radio-col {
    width: 100%;
  }

  .readStory-button-position {
    position: absolute;
    bottom: 0% !important;
    left: 0;
    right: 0;
  }
}

@media screen and (max-height: 1024px) {
  .ads-align-container {
    max-width: 1486px;
    width: 100%;
    max-height: 380px;
    margin: 0 auto;
    margin-top: 0%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .ads-align {
    max-width: 946px;
    max-height: 280px !important;
    margin: 0% auto;
  }

  .ads-align-content {
    max-height: 58vh !important;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 768px) and (max-height: 1024px) {
  .continue-btn-group-list-story {
    margin-bottom: 18px !important;
    margin-top: -2px !important;
  }

  .continue-btn-group .btn-continue {
    height: 40px !important;
    width: 40px !important;
  }

  .footer-wrap {
    padding: 5px 0;
  }

  .add-ads-content,
  .view-classified-content {
    max-height: 50vh !important;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .ads-align {
    max-width: 70%;
    max-height: 53vh !important;
    margin: 0% auto;
  }

  .view-classified-content {
    max-height: 400px;
  }

  .footer-wrap p {
    font-size: 13px;
  }

  .cmn-title-head h2 {
    font-size: 34px;
    min-height: 38px;
  }

  .story-book-row {
    max-height: 380px;
  }
}

@media (max-width: 1199px) {
  .write-story-add-photo {
    left: -20px;
  }

  .sd-book-wrapper {
    max-width: 995px;
  }
}

.sd-right-icon-row .sd-right-icon {
  flex-direction: column;
  width: 33.33%;
  margin-bottom: 14px;
}

.terms-info p .f1 {
  font-size: 36px !important;
}

.terms-info p .f2 {
  font-size: 16px !important;
}

@media screen and (min-width: 960px) and (max-width: 1280px) and (-webkit-min-pixel-ratio: 1) {
  .story-book-row {
    max-height: 400px;
  }
}

@media (max-width: 768px) {
  .bs-datepicker .bs-media-container {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .bs-datepicker-multiple {
    display: flex;
  }

  .bs-datepicker-multiple+.bs-datepicker-multiple {
    margin-top: 10px;
    margin-left: 0;
  }
}

@media (prefers-reduced-motion: reduce),
print {
  .animate__animated {
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    -webkit-transition-duration: 1ms !important;
    transition-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }

  .animate__animated[class*="Out"] {
    opacity: 0;
  }
}

@media only screen and (max-width: 450px) {
  .readStory-button-position {
    bottom: 3%;
    left: 0;
    right: 0;
  }

  .readstory-body-container {
    max-width: 1486px;
    width: 100%;
    max-height: 505px;
    padding-left: 15px;
    padding-right: 15px;
    top: 25%;
    bottom: 0;
  }

  .socials-icon-bar,
  .welcome_container .socials-icon-bar {
    display: flex;
    transform: inherit;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -17%;
  }

  .cmn-submit-button span:after,
  .cmn-submit-button span:before {
    width: 25px;
    height: 25px;
  }

  .cmn-submit-button span {
    width: 42px;
    height: 42px;
  }

  .cmn-submit-button {
    width: 50px;
    height: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .readStory-button-position {
    bottom: 3%;
    left: 0;
    right: 0;
  }
}

@media only screen and (max-width: 1024px) {
  .readStory-button-position {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@media only screen and (max-width: 1920px) {
  .camera-icon-ads i img {
    max-width: 18px;
  }
}

.emailDiv .welcome-item {
  flex-direction: column;
  padding: 0px;
  width: 50px;
  bottom: 0px;
}

.help-icon.searchIcon {
  top: -85px !important;
  left: -7px !important;
}

.help-icon.shareIcons {
  bottom: -52px !important;
  left: -8px !important;
}

.help-icon.ReportIcons {
  right: -16px !important;
  bottom: -50px !important;
}

.closeBtn.closeBtns1 {
  right: -27px;
  top: -27px;
}

/* width */
.terms-info::-webkit-scrollbar {
  width: 15px !important;
  background-color: #eee !important;
}

.middle.readstory_middle .B2.cards.search_readstory {
  height: auto !important;
}

.terms-footer.acc_ref {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 70px;
  padding-right: 20px;
  padding-top: 18px;
}

.language-title-head h2 {
  /* -webkit-text-stroke: 1px #00000070; */
}


/* new changes title  */
/* .home-img-div span {
  font-size: 30px;
  position: absolute;
  bottom: 25px;
  left: 0;
  text-align: center;     
  right: 0;
  font-weight: 700;
  text-transform: uppercase;
  color: #ccba5e;
  -webkit-text-stroke: 1px #00000070 !important;
} */


.welcome-item12 {
  position: relative;
}

span.manual {
  transform: rotate(-360deg);
  bottom: 23px !important;
  font-family: Poppins, sans-serif !important;
  /* font-size: 26px; */
  font-size: 18px;
  position: absolute;
  left: 0;
  text-align: center;
  right: 0;
  font-weight: 700;
  text-transform: uppercase;
  color: #ccba5e;
  -webkit-text-stroke: 1px #00000070 !important;
}

span.report {
  transform: rotate(-366deg);
  bottom: 42px !important;
  font-family: Poppins, sans-serif !important;
  font-size: 18px;
  position: absolute;
  /* bottom: 25px; */
  left: 0;
  text-align: center;
  right: 0;
  font-weight: 700;
  text-transform: uppercase;
  color: #ccba5e;
  -webkit-text-stroke: 1px #00000070 !important;
}

span.herald {
  transform: rotate(-354deg);
  bottom: 42px !important;
  font-family: Poppins, sans-serif !important;
  font-size: 18px;
  position: absolute;
  left: 0;
  text-align: center;
  right: 0;
  font-weight: 700;
  text-transform: uppercase;
  color: #ccba5e;
  -webkit-text-stroke: 1px #00000070 !important;
}

.heresays_herald_content {
  overflow-y: scroll;
  height: calc(100vh - 350px);
}

.manual-loader-window {
  width: 100%;
  height: 420px;
  text-align: center;
}