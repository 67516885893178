.main {
  width: 100%;
  height: 100%;
}
.addClassified_main_container_home {
  background-image: url("../../../assets/images/welcome-bg.png"),
    linear-gradient(
      89.94deg,
      #b56a29 1.56%,
      #ca872e 7.01%,
      #d08f33 12.37%,
      #d39339 18.07%,
      #ecca70 26.74%,
      #faf8bd 39.42%,
      #daa74c 49.94%,
      #cf8c31 60.29%,
      #da8b2c 64.62%,
      #d48b2c 74.69%,
      #ce842b 81.31%,
      #f0b348 90.15%,
      #d58e34 94.33%,
      #c1752a 99.14%
    );
  min-height: 100%;
  background-size: 100% !important;
  background-repeat: no-repeat !important;
  background-position: top !important;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.classified-align-home {
  max-width: 1486px;
  width: 100%;
  height: calc(100vh - 135px);
  max-height: 555px;
  margin: 0 auto;
  margin-top: 0%;
  padding-left: 15px;
  padding-right: 15px;
}

.main_logo_home {
  height: 130px;
}
.logo_container_home {
  display: flex;
  justify-content: left;
  top: 3%;
  left: 1%;
}

.header_icon_container {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 40%;
  top: 5%;
}
.header_icon_settings {
  margin: 0 15px;
  width: 56px;
  height: 56px;

  background-position: 50%;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/header-icons-frame.svg");
  background-size: contain;
  position: relative;
}
.settingicon {
  align-items: center;
  margin: 6px 6px 6px 6px;
  width: 44px;
  height: 44px;

  background-repeat: no-repeat;
  background-image: url("../../../assets/images/header-setting-icon.svg");
}
.settingicon:hover {
  -webkit-animation: spin 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.header_icon_ppc {
  margin: 0 15px;
  width: 56px;
  height: 56px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/header-icons-frame.svg");
  background-size: contain;
  position: relative;
}
.camera{
  height: 40vh !important;
  width: 65vh !important;
}
.ppcicon {
  align-items: center;
  margin: 6px 6px 6px 6px;
  width: 44px;
  height: 44px;

  background-repeat: no-repeat;
  background-image: url("../../../assets/images/header-ppc-icon.svg");
}

.ppcicon:hover {
  animation: zoom-in-zoom-out 1s ease infinite;
}

.header_icon_question {
  margin: 0 15px;
  width: 56px;
  height: 56px;

  background-position: 50%;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/header-icons-frame.svg");
  background-size: contain;
  position: relative;
}
.question {
  align-items: center;
  margin: 6px 6px 6px 6px;
  width: 44px;
  height: 44px;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/header-questions-icon.svg");
}
.question:hover {
  align-items: center;
  margin: 6px 6px 6px 6px;
  height: 44px;
  width: 44px;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/header-questions-icon.svg");
  width: 44px;
  animation: bounceRight 1s ease infinite;
}
.ads-wraper {
  border-radius: 5px;
  padding: 8px;
  max-width: 946px;
  max-height: 400px;
  margin: 0 auto;
  background: linear-gradient(
    180deg,
    #ffe570,
    #fffb95 13.74%,
    #be9a38 26.34%,
    #ebce65 47.87%,
    #b38a29 67.57%,
    #421800 78.56%,
    #b48a28 92.99%,
    #fce06b 101.93%
  );
}
.ads-content {
  background: rgb(225, 215, 215);
  background: -moz-linear-gradient(
    left,
    rgba(225, 215, 215, 1) 0%,
    rgba(255, 251, 251, 1) 50%,
    rgba(209, 207, 207, 1) 75%,
    rgba(119, 118, 118, 1) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(225, 215, 215, 1) 0%,
    rgba(255, 251, 251, 1) 50%,
    rgba(209, 207, 207, 1) 75%,
    rgba(119, 118, 118, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(225, 215, 215, 1) 0%,
    rgba(255, 251, 251, 1) 50%,
    rgba(209, 207, 207, 1) 75%,
    rgba(119, 118, 118, 1) 100%
  );
  padding: 17px 34px;
}
.ads-col-row {
  margin-left: -14px;
  margin-right: -14px;
  display: flex;
}
.ads-col-50 {
  position: relative;
  padding-left: 14px;
  padding-right: 14px;

  width: 30vw;
  margin-bottom: 10px;
}
.ads-item-box {
  border-radius: 5px;
  padding: 8px;
  background: linear-gradient(
    180deg,
    #ffe570,
    #fffb95 13.74%,
    #be9a38 26.34%,
    #ebce65 47.87%,
    #b38a29 67.57%,
    #421800 78.56%,
    #b48a28 92.99%,
    #fce06b 101.93%
  );
}
.ads-add-box {
  background: #d39339;
  background: -moz-linear-gradient(
    left,
    #d39339 0%,
    #ecca70 20%,
    #faf8bd 50%,
    #daa74c 80%,
    #cf8c31 100%
  );
  background: -webkit-linear-gradient(
    left,
    #d39339 0%,
    #ecca70 20%,
    #faf8bd 50%,
    #daa74c 80%,
    #cf8c31 100%
  );
  background: linear-gradient(
    to right,
    #d39339 0%,
    #ecca70 20%,
    #faf8bd 50%,
    #daa74c 80%,
    #cf8c31 100%
  );
  box-shadow: 0px 8px 17px rgb(0, 0 , 0 , 25%);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  max-height: 270px;
}
.ads-top-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 128px;
  position: relative;
  margin-bottom: -40px;
}
.ads-bottom-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 18vh;
  padding-top: 50px;
  background: url("../../../assets/images/shape-add-ads-top.png") center top
    no-repeat;
}
.ads-top-box h5 {
  font-weight: 700;
  font-size: 22px;
  text-transform: uppercase;
  color: #041241;
  font-family: "droid_serifbold";
  margin-top: 20px;
  margin-bottom: 20px;
}
.ads-banner-box {
  border-radius: 5px;
  padding: 8px;
  background: linear-gradient(
    180deg,
    #ffe570 0%,
    #fffb95 13.74%,
    #be9a38 26.34%,
    #ebce65 47.87%,
    #b38a29 67.57%,
    #421800 78.56%,
    #b48a28 92.99%,
    #fce06b 101.93%
  );
}
.ads-banner-box-in {
  background: linear-gradient(
      90.06deg,
      #6b0101 0.04%,
      #d10606 8.1%,
      #5c0000 50.18%,
      #d10606 94.35%,
      #6b0101 99.95%
    ),
    linear-gradient(0deg, #ffffff, #ffffff);
  text-align: center;
}
.ads-banner-info {
  padding: 16px;
}

#popupAds2 {
  position: fixed;
  width: 100%;
  height: 100%;

  z-index: 99999999;

  opacity: 1;
  transition: 0.5s;

  display: flex;
  justify-content: center;
  vertical-align: middle;
  transition: all 0.5s ease-in-out;
  animation: pop-swirl 1s ease forwards; /*trigger the keyframe*/
  visibility: visible;
  z-index: 200;

  opacity: 1;
}
.modal-dialog1 {
  border-radius: 8px;
  width: 100%;
  max-width: 978px;
  transform: translateY(10%);
  max-height: 600px;
  border: 5px solid #512704;
}
.modal-content {
  display: flex;
  justify-content: space-around;
}
.modal-content-row1 {
  width: 40%;
  margin: 5px;
}
.modal-content-row2 {
  width: 60%;
  margin: 5px;
}
.modal-content-coloumn {
  display: flex;
  flex-direction: column;
}
.modal-analytics {
  border-radius: 5px;
  width: calc(100% - 30px);
  padding: 8px;
  background: linear-gradient(
    180deg,
    #ffe570,
    #fffb95 13.74%,
    #be9a38 26.34%,
    #ebce65 47.87%,
    #b38a29 67.57%,
    #421800 78.56%,
    #b48a28 92.99%,
    #fce06b 101.93%
  );
}
.modal-finance-inner {
  background: linear-gradient(
    90deg,
    #050c39 4.32%,
    #04145c 22.71%,
    #0d2ca0 32.64%,
    #1747e9 46.64%,
    #071963 71%,
    #040d41 85.26%,
    #020a37
  );
  padding: 15px 25px;
  height: 100%;
}

.adspage {
  display: flex;
  flex-direction: column;
}

.buttongroup {
  height: 10vh;
}

.ads-camera-btn {
  height: 40px;
  width: 40px;
}

.ads-cmn-btn {
  border: 0;
  height: 50px;
  width: 50px;
  cursor: pointer;
  border-radius: 8px;
  padding: 7px;
  background: linear-gradient(
    180deg,
    #ffe773,
    #fffa93 12.81%,
    #c09b37 31.25%,
    #e7c75f 56.25%,
    #512704 82.72%,
    #efd160
  );
}

.ads-cmn-btn:focus,
.ads-cmn-btn:hover {
  background: linear-gradient(
    0deg,
    #ffe773,
    #fffa93 12.81%,
    #c09b37 31.25%,
    #e7c75f 56.25%,
    #512704 82.72%,
    #efd160
  );
}

.ads-cmn-btn:focus,
.ads-cmn-btn:focus span i {
  opacity: 0.8;
}

.ads-cmn-btn span {
  background: linear-gradient(180deg, #274b4b, #182e2e);
  height: 42px;
  width: 42px;
  margin-left: -8%;
  margin-top: -8%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.ads-cmn-btn span i {
  width: 30px;
  height: 30px;
  display: block;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform 0.2s;
}

.ads-cmn-btn:focus span i,
.ads-cmn-btn:hover span i {
  transform: scale(1.2);
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.75, 0.75);
  }
  100% {
    transform: scale(1, 1);
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.editorClassName {
  display: flex;
  justify-content: center;
}
.DraftEditor-root {
  margin-top: -5px;
  overflow-y: hidden;
  position: relative;
  width: 360px;
  height: 136px;
  background: white;
}

.exit_icon {
  height: 56px;
  width: 56px;

  background-position: 50%;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/header-icons-frame.svg");
  background-size: contain;
  position: relative;
  position: absolute;

  float: right;
}
.exit_background {
  background: linear-gradient(
    0deg,
    rgb(55, 0, 0) -0.33%,
    red 99.96%
  ) !important;
  height: 44px;
  width: 44px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;

  float: right;
  top: 10%;
  left: -9%;
}
.exit_background:hover {
  background: linear-gradient(
    0deg,
    red -0.33%,
    rgb(55, 0, 0) 99.96%
  ) !important;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}
.exit {
  align-items: center;
  height: 40px;
  width: 40px;
  margin-left: 2px;
  margin-top: 3px;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/header-exit-icon.svg");
}

.exit:hover {
  align-items: center;
  height: 40px;
  width: 40px;
  margin-left: 2px;
  margin-top: 3px;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/header-exit-icon.svg");
  animation: exit 2s 2;
  animation-direction: alternate;
}

@keyframes exit {
  0% {
    transform: translateX(-10px);
  }
  25% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-10px);
  }
}

@-webkit-keyframes bounceLeft {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(15px);
    transform: translateX(15px);
  }
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
}

@-moz-keyframes bounceLeft {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(15px);
  }
  60% {
    transform: translateX(10px);
  }
}

@keyframes bounceLeft {
  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(15px);
    transform: translateX(15px);
  }
  60% {
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }
}

@-webkit-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px);
  }
  60% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
}

@-moz-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-15px);
  }
  60% {
    transform: translateX(-10px);
  }
}

@keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(-15px);
    transform: translateX(-15px);
  }
  60% {
    -ms-transform: translateX(-10px);
    transform: translateX(-10px);
  }
}

.adparent {
  max-width: 940px;
  margin: 0 auto;
  border-radius: 5px;
  padding: 8px;
  background: linear-gradient(
    180deg,
    #ffe570,
    #fffb95 13.74%,
    #be9a38 26.34%,
    #ebce65 47.87%,
    #b38a29 67.57%,
    #421800 78.56%,
    #b48a28 92.99%,
    #fce06b 101.93%
  );
}
.addchild {
  background: linear-gradient(
      90.05deg,
      #a8a8a8 -32.01%,
      #747272 -10.03%,
      #999898 3.46%,
      #e1d7d7 19.04%,
      #fffbfb 42.85%,
      #d1cfcf 63.76%,
      #646363 86.82%,
      #adacac 111.41%
    ),
    linear-gradient(
      89.95deg,
      #b56a29 1.56%,
      #ca872e 7.01%,
      #d08f33 12.37%,
      #d39339 18.07%,
      #ecca70 26.75%,
      #faf8bd 39.42%,
      #daa74c 49.94%,
      #cf8c31 60.3%,
      #da8b2c 64.63%,
      #d48b2c 74.69%,
      #ce842b 81.32%,
      #f0b348 90.16%,
      #d58e34 94.34%,
      #c1752a 99.15%
    );
  padding: 17px 28px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: center;
}
.popupForeads {
  background: linear-gradient(
    180deg,
    #ffe570,
    #fffb95 13.74%,
    #be9a38 26.34%,
    #ebce65 47.87%,
    #b38a29 67.57%,
    #421800 78.56%,
    #b48a28 92.99%,
    #fce06b 101.93%
  );
  width: 60%;
  height: 63%;
  display: flex;
  flex-direction: column;
  border: 0;
  border-radius: 1%;
  position: fixed;
  left: 20%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.3);

  z-index: 99999999;
  top: 13%;
  opacity: 1;
  transition: 0.5s;

  transform: scale(1);
  transition: all 0.5s ease-in-out;
  animation: pop-swirl 1s ease forwards;
  visibility: visible;
  opacity: 1;
}
.modalback {
  display: flex;
  flex-direction: column;
  left: 8px;
  top: 8px;
  position: fixed;
  transform: translate(-50%, -50%);
  width: 98.25%;
  height: 96.5%;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.3);
  background: linear-gradient(
    90.05deg,
    #a8a8a8 -32.01%,
    #747272 -10.03%,
    #999898 3.46%,
    #e1d7d7 19.04%,
    #fffbfb 42.85%,
    #d1cfcf 63.76%,
    #646363 86.82%,
    #adacac 111.41%
  );

  z-index: 99999999;
  opacity: 1;
  transition: 0.5s;

  transform: scale(1);
  transition: all 0.5s ease-in-out;
  animation: pop-swirl 1s ease forwards;
  visibility: visible;
  opacity: 1;
}

.check {
  display: flex;
  height: 30px;
}
.checkbox {
  margin-top: 10px;
  margin-left: 16%;
  height: 36px;
  width: 36px;
  content: "";
  -webkit-appearance: none;
  background: url("../../../assets/images/check-bx-bg.svg") 50% no-repeat;
  z-index: 1;
  background-size: contain;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 8px;
}
.text {
  height: 70px;
  width: 94%;
  background: linear-gradient(
    180deg,
    #ffe46f,
    #fffd97 6.56%,
    #ba9331 23.23%,
    #f0d36a 48.23%,
    #ba9230 61.77%,
    #441a01 84.17%,
    #e3c254
  );
  margin-top: 30px;
  margin-bottom: 8px;
  margin-left: 3%;
  position: relative;
  border-radius: 5px;
  padding: 3px;
  display: block;
  box-sizing: border-box;
  pointer-events: auto;
}
.textbox {
  background: linear-gradient(
      90deg,
      #3c3b3b,
      #c5bebe 18.4%,
      #625e5e 31.4%,
      #c6c5c5 46.64%,
      #555151 61.84%,
      #706d6d 75.86%,
      #adacac 89.71%,
      #363434
    ),
    linear-gradient(0deg, #422d0d, #422d0d), #041241;
  text-align: center;
  font-family: "droid_serifbold";
  font-size: 18px;
  color: #041241;
  width: 99%;
  height: 90%;
}
.textareaparent {
  border-radius: 5px;
  padding: 3px;
  background: linear-gradient(
    180deg,
    #9a7517,
    #441a01 12.29%,
    #d8ab39 30%,
    #6b5318 44.06%,
    #d8ab39 62.29%,
    #441a01 81.56%,
    #826213
  );
  line-height: 0;
  box-sizing: border-box;
  display: block;
  pointer-events: auto;
  margin-top: 6px;
  margin-left: 26px;
  height: 65%;
  width: 94%;
}
.textareachild {
  width: 95.25%;
  height: 100%;
  border: 0;
  background: url("../../../assets/images/contact-paper-bg.png") top repeat-y;
  resize: none;
  line-height: 35px;
  font-size: 16px;
  font-family: Poppins, sans-serif;
  color: #041241;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
}

#textarea {
  background: transparent;
  width: 104.25%;
  height: 98.5%;
  margin-left: -20px;
  border: none;
  line-height: 2.75;
  resize: none;
}
.buttonContainer {
  display: flex;
  justify-content: space-evenly;
  margin-top: 38%;
  margin-left: 7%;
  align-items: center;
  width: 85%;
}
.mailContainer {
  width: 66px;
  height: 66px;
  border: 0;
  cursor: pointer;
  background: linear-gradient(
    180deg,
    #ffe773,
    #fffa93 12.81%,
    #c09b37 31.25%,
    #e7c75f 56.25%,
    #512704 82.72%,
    #efd160
  );
  box-shadow: 0 4px 4px rgb(0 ,0, 0 , 68%);
  border-radius: 50%;
  position: relative;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.mailbtn {
  width: 60px;
  height: 60px;
  margin-left: 4%;
  margin-top: 4%;
  background: radial-gradient(50% 50% at 50% 50%, #274b4b 0, #182e2e 100%);
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mailbtn::before {
  width: 44px;
  height: 44px;
  background-image: url("../../../assets/images/submit-mail-icon.svg");
  background-position: 50%;
  left: 8px;
  opacity: 1;
  content: "";
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: 0.5s;
  box-sizing: border-box;
}
.mailbtn::after {
  width: 44px;
  height: 44px;
  background-image: url("../../../assets/images/submit-mail-icon.svg");
  background-position: 50%;
  opacity: 0;
  right: -44px;
  content: "";
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: 0.5s;
  box-sizing: border-box;
}
.microphonecontainer {
  width: 66px;
  height: 66px;
  border: 0;
  cursor: pointer;
  background: linear-gradient(
    180deg,
    #ffe773,
    #fffa93 12.81%,
    #c09b37 31.25%,
    #e7c75f 56.25%,
    #512704 82.72%,
    #efd160
  );
  box-shadow: 0 4px 4px rgb(0 ,0, 0 , 68%);
  border-radius: 5%;
  position: relative;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.microphonebtn {
  width: 60px;
  height: 60px;
  margin-left: 4%;
  margin-top: 4%;
  background: linear-gradient(0deg, #3d290c -0.33%, #724f1b 99.96%);
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.micro {
  transition: transform 0.2s;
  max-width: 24px;
  vertical-align: middle;
  border-style: none;
  box-sizing: border-box;
}
.keyboardcontainer {
  width: 66px;
  height: 66px;
  border: 0;
  cursor: pointer;
  background: linear-gradient(
    180deg,
    #ffe773,
    #fffa93 12.81%,
    #c09b37 31.25%,
    #e7c75f 56.25%,
    #512704 82.72%,
    #efd160
  );
  box-shadow: 0 4px 4px rgb(0, 0, 0 , 68%);
  border-radius: 5%;
  position: relative;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.keyboardbtn {
  width: 60px;
  height: 60px;
  margin-left: 4%;
  margin-top: 4%;
  background: linear-gradient(0deg, #3d290c -0.33%, #724f1b 99.96%);
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.key {
  width: 50px;
  height: 50px;
  transition: transform 0.2s;
  vertical-align: middle;
  border-style: none;
  box-sizing: border-box;
}
.crosscontainer {
  width: 66px;
  height: 66px;
  border: 0;
  cursor: pointer;
  background: linear-gradient(
    180deg,
    #ffe773,
    #fffa93 12.81%,
    #c09b37 31.25%,
    #e7c75f 56.25%,
    #512704 82.72%,
    #efd160
  );
  box-shadow: 0 4px 4px rgb(0 ,0 ,0 , 68%);
  border-radius: 50%;
  position: relative;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.crossbtn {
  background: radial-gradient(50% 50% at 50% 50%, #f34444 0, #7b0000 100%);
  margin-top: 4%;
  margin-left: 4%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.headerquestion:hover .imgs:nth-child(1) {
  left: 50%;
  opacity: 1;
}
.headerquestion:hover .imgs:nth-child(2) {
  left: -50%;
  opacity: 0;
}
.headerquestion {
  background: url("../../../assets/images/header-icons-frame.svg");
  background-repeat: no-repeat;
  height: 55px;
  width: 55px;
  margin-right: -70px;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.925);
  transition: 0.5s;
}

.classifiedParent {
  background: linear-gradient(
    180deg,
    #ffe570,
    #fffb95 13.74%,
    #be9a38 26.34%,
    #ebce65 47.87%,
    #b38a29 67.57%,
    #421800 78.56%,
    #b48a28 92.99%,
    #fce06b 101.93%
  );
  border-radius: 5px;
  padding: 8px;
  height: 60vh;
  box-sizing: border-box;
  display: block;
}
.classifiedChild {
  max-height: 55vh !important;
  padding: 10px 16px;
  background: linear-gradient(
    90.05deg,
    #a8a8a8 -32.01%,
    #747272 -10.03%,
    #999898 3.46%,
    #e1d7d7 19.04%,
    #fffbfb 42.85%,
    #d1cfcf 63.76%,
    #646363 86.82%,
    #adacac 111.41%
  );
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.classifiedChild::-webkit-scrollbar {
  width: 10px;
}
.classifiedChild::-webkit-scrollbar-track {
  background: linear-gradient(180deg, #4e4e4e, #000);
}
.classifiedChild::-webkit-scrollbar-thumb {
  background: linear-gradient(
    180deg,
    #ffe570,
    #fffb95 13.74%,
    #be9a38 26.34%,
    #ebce65 47.87%,
    #b38a29 67.57%,
    #421800 78.56%,
    #b48a28 92.99%,
    #fce06b 101.93%
  );
  border-radius: 15px;
}
.textInputAd {
  flex-direction: column;
  text-align: center;
  box-sizing: border-box;
  display: block;
  margin-top: -15%;
}
.textStyleAd {
  width: 100%;
  height: 5vh;
  margin-top: 20%;
}
.textInputBoxAd {
  display: flex;

  margin-top: -14.85%;
}
.crossBoxAd {
  width: 30%;
  height: 17.5vh;
  background: #211400;
  border-radius: 0px 0px 0px 5px;
}
.textBoxAd {
  width: 40%;
  height: 20vh;
  background-color: white;
}
.crossBoxBorderAd {
  position: relative;
  top: 45%;
  transform: translateY(-50%);
  z-index: 9;
  margin-right: 10px;
  margin-left: 10px;
  left: 40%;
  width: 54px;
  height: 54px;
  border: 0;
  border-radius: 50%;
  background: linear-gradient(
    180deg,
    #ffe773,
    #fffa93 12.81%,
    #c09b37 31.25%,
    #e7c75f 56.25%,
    #512704 82.72%,
    #efd160
  );
  box-shadow: 0 4px 4px rgb(0, 0, 0 , 68%);
  padding: 4px;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  box-sizing: border-box;
}
.crossBoxContentAd {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(50% 50% at 50% 50%, #f34444 0, #7b0000 100%);
  box-sizing: border-box;
  cursor: pointer;
  text-transform: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.crossAd {
  width: 28px;
  height: 28px;
  display: block;
  background-position: 50%;
  background-repeat: no-repeat;
  transition: transform 0.2s;
  background-image: url("../../../assets/images/cross-icon-gradient.svg");
  background-size: 22px !important;
}
.downloadBoxAd {
  width: 30%;
  height: 17.5vh;
  background: #211400;
  border-radius: 0px 0px 5px 0px;
}
.downloadBoxBorderAd {
  position: relative;
  top: 45%;
  transform: translateY(-50%);
  z-index: 9;
  margin-left: 10px;
  margin-right: 10px;
  right: -40%;
  width: 54px;
  height: 54px;
  border: 0;
  border-radius: 50%;
  background: linear-gradient(
    180deg,
    #ffe773,
    #fffa93 12.81%,
    #c09b37 31.25%,
    #e7c75f 56.25%,
    #512704 82.72%,
    #efd160
  );
  box-shadow: 0 4px 4px rgb(0, 0, 0 , 68%);
  padding: 4px;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  box-sizing: border-box;
}
.downloadBoxContentAd {
  width: 46px;
  height: 46px;
  background: radial-gradient(50% 50% at 50% 50%, #082174 0, #031038 100%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.downloadAd {
  width: 28px;
  height: 28px;
  display: block;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform 0.2s;
  background-image: url("../../../assets/images/eva_download-outline.svg");
  box-sizing: border-box;
  font-style: italic;
  cursor: pointer;
  text-transform: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.textOutputAd {
  display: flex;
  justify-content: space-evenly;
  height: 40vh;
  width: 100%;
  margin-top: 5%;
}
.colorBoxAd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0%;
  width: 20%;
  height: 30vh;
}
.colAd {
  display: flex;
  justify-content: space-evenly;
}
.borderAd {
  width: 48px;
  height: 48px;
  margin: 4px;
  border: 0;
  border-radius: 50%;
  background: linear-gradient(
    180deg,
    #ffe773,
    #fffa93 12.81%,
    #c09b37 31.25%,
    #e7c75f 56.25%,
    #512704 82.72%,
    #efd160
  );
  box-shadow: 0 4px 4px rgb(0, 0, 0 , 68%);
  padding: 4px;
  cursor: pointer;
  position: relative;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  box-sizing: border-box;
}
.aquaMarineAd {
  height: 40px;
  width: 40px;
  background: radial-gradient(
    83.62% 83.62% at 50% 50%,
    #62ffff 0,
    #3c828b 100%
  );
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
}

.yellowAd {
  height: 40px;
  width: 40px;
  background: radial-gradient(81.9% 81.9% at 50% 50%, #ffda17 0, #c09600 100%);
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
}

.greenAd {
  height: 40px;
  width: 40px;
  background: radial-gradient(50% 50% at 50% 50%, #47fb2a 0, #13490b 100%);
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
}
.orangeAd {
  height: 40px;
  width: 40px;
  background: radial-gradient(
    87.07% 87.07% at 50% 50%,
    #ff8a00 0,
    #562a01 100%
  );
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
}
.greyAd {
  height: 40px;
  width: 40px;
  background: radial-gradient(
    80.17% 80.17% at 50% 50%,
    #e6e6e6 0,
    #454545 100%
  );
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
}
.purpleAd {
  height: 40px;
  width: 40px;
  background: radial-gradient(
    76.72% 76.72% at 50% 50%,
    #c521ff 0,
    #4b0a62 100%
  );
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
}
.whiteAd {
  height: 40px;
  width: 40px;
  background: radial-gradient(87.93% 87.93% at 50% 50%, #fff 0, #cdcdcd 100%);
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
}
.darkYellowAd {
  height: 40px;
  width: 40px;
  background: radial-gradient(
    70.69% 70.69% at 50% 50%,
    #ffa800 0,
    #b87900 100%
  );
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
}
.redAd {
  height: 40px;
  width: 40px;
  background: radial-gradient(
    66.38% 66.38% at 50% 50%,
    #f50b0b 0,
    #9a0707 100%
  );
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
}
.deleteSectionAd {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: -3%;
}
.deleteAd {
  width: 40px;
  height: 40px;
  display: block;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform 0.2s;
  background-image: url("../../../assets/images/gridicons_trash_red.svg");
  box-sizing: border-box;
  font-style: italic;
  cursor: pointer;
  text-transform: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.textBoxOutputParentAd {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.textBoxOutputAd {
  height: 15vh;
  width: 20vw;
  display: flex;
  justify-content: center;
  border-radius: 7px;
  margin-bottom: 5%;
}

#red {
  background-color: red;
}
#green {
  background: linear-gradient(
    180deg,
    #1f9d24,
    #95ff93 12.81%,
    #2fa734 31.25%,
    #4cce36 56.25%,
    #11400d 82.72%,
    #4dba51
  );
}
.redBoxAd {
  height: 13vh;
  width: 19vw;
  margin: auto;
}
.functionalitySectionAd {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: -13%;
  margin-left: -1%;
}
.blueBoxBorderAd {
  background: linear-gradient(
    180deg,
    #ffe773,
    #fffa93 12.81%,
    #c09b37 31.25%,
    #e7c75f 56.25%,
    #512704 82.72%,
    #efd160
  );
  height: 9vh;
  width: 17vw;
  border-radius: 5px;
  margin-top: 10%;
  margin-left: 3%;
}
.blueBoxAd {
  background-color: #041241;
  height: 7.8vh;
  width: 16.4vw;
  border-radius: 5px;
  margin-left: 1.8%;
  margin-top: 1.8%;
}
.bracketLockAd {
  display: flex;
  margin-top: -26%;
}
.bracketAdContent {
  font-size: 100px;
  line-height: 100px;
  color: #041241;
  font-family: "droid_serifregular";
  font-weight: 600;
  margin-right: 10px;
  display: inline-block;
  box-sizing: border-box;
  text-align: left;
  margin-top: -42%;
}
.lockParentAd {
  width: 68px;
  height: 68px;
  margin-bottom: 30%;
  border: 0;
  border-radius: 50%;
  background: linear-gradient(
    180deg,
    #ffe773,
    #fffa93 12.81%,
    #c09b37 31.25%,
    #e7c75f 56.25%,
    #512704 82.72%,
    #efd160
  );
  box-shadow: 0 4px 4px rgb(0, 0, 0 , 68%);
  padding: 4px;
  cursor: pointer;
  position: relative;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  box-sizing: border-box;
}
.lockContentAd {
  width: 60px;
  height: 60px;
  background: radial-gradient(50% 50% at 50% 50%, #5bd351 0, #193414 100%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.lockIconAd {
  width: 30px;
  height: 30px;
  display: block;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform 0.2s;
  background-image: url("../../../assets/images/unlock-icon.svg ");
  box-sizing: border-box;
  font-style: italic;
}
.submitSectionAd {
  display: flex;
  justify-content: space-evenly;
}
.tickBorderAd {
  width: 68px;
  height: 68px;
  border: 0;
  border-radius: 50%;
  background: linear-gradient(
    180deg,
    #ffe773,
    #fffa93 12.81%,
    #c09b37 31.25%,
    #e7c75f 56.25%,
    #512704 82.72%,
    #efd160
  );
  box-shadow: 0 4px 4px rgb(0, 0, 0 , 68%);
  padding: 4px;
  cursor: pointer;
  position: relative;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  box-sizing: border-box;
}
.tickContentAd {
  width: 60px;
  height: 60px;
  background: radial-gradient(50% 50% at 50% 50%, #5bd351 0, #193414 100%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.tickAd {
  width: 30px;
  height: 30px;
  display: block;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform 0.2s;
  background-image: url("../../../assets/images/tick-icon.svg");
  box-sizing: border-box;
  font-style: italic;
}
.cameraBorderAd {
  padding: 5px;
  width: 68px;
  height: 68px;
  background: linear-gradient(
    180deg,
    #ffe773,
    #fffd98 10.73%,
    #ba9331 26.49%,
    #f1d56b 53.63%,
    #b68d2b 71.15%,
    #441a01 86.63%,
    #f3d663
  );
  position: relative;
  border: 0;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  box-sizing: border-box;
}
.cameraContentAd {
  width: 58px;
  height: 58px;
  background: linear-gradient(0deg, #3d290c -0.33%, #724f1b 99.96%);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-style: italic;
}
.cameraAd {
  transition: transform 0.2s;
  max-width: 24px;
  vertical-align: middle;
  border-style: none;
  box-sizing: border-box;
  font-style: italic;
  margin-right: 20px;
}
.uploadBorderAd {
  padding: 5px;
  width: 68px;
  height: 68px;
  background: linear-gradient(
    180deg,
    #ffe773,
    #fffd98 10.73%,
    #ba9331 26.49%,
    #f1d56b 53.63%,
    #b68d2b 71.15%,
    #441a01 86.63%,
    #f3d663
  );
  position: relative;
  border: 0;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  box-sizing: border-box;
}
.uploadContentAd {
  width: 58px;
  height: 58px;
  background: linear-gradient(0deg, #3d290c -0.33%, #724f1b 99.96%);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-style: italic;
}
.uploadAd {
  transition: transform 0.2s;
  max-width: 24px;
  vertical-align: middle;
  border-style: none;
  box-sizing: border-box;
  font-style: italic;
  margin-right: 28px;
}
.cancelBorderAd {
  width: 68px;
  height: 68px;
  border: 0;
  border-radius: 50%;
  background: linear-gradient(
    180deg,
    #ffe773,
    #fffa93 12.81%,
    #c09b37 31.25%,
    #e7c75f 56.25%,
    #512704 82.72%,
    #efd160
  );
  box-shadow: 0 4px 4px rgb(0, 0, 0 , 68%);
  padding: 4px;
  cursor: pointer;
  position: relative;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  box-sizing: border-box;
}
.cancelContentAd {
  background: radial-gradient(50% 50% at 50% 50%, #f34444 0, #7b0000 100%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.editorMain {
  height: 100px;
}
.social_container_adv {
  position: absolute;
  display: flex;
  top: 25%;
  right: 2%;
  flex-direction: column;
}

.social_icon_adv {
  height: 50px;
  width: 50px;
  margin-left: 18px;
  margin-bottom: 10px;
  transition: transform 0.7s ease-in-out;
}
.social_icon_adv:hover {
  transform: rotate(360deg);
}
.followParaAdv {
  font-size: 16px;
  color: rgb(227, 160, 3);
  font-weight: 800;
  font-family: Poppins, sans-serif;
}
.tox {
  box-shadow: none;
  box-sizing: content-box;
  color: rgb(34, 47, 62);
  cursor: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  vertical-align: initial;
  white-space: normal;
}
.tox :not(svg):not(rect) {
  box-sizing: inherit;
  color: inherit;
  cursor: inherit;
  direction: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  -webkit-tap-highlight-color: inherit;
  text-align: inherit;
  text-decoration: inherit;
  text-shadow: inherit;
  text-transform: inherit;
  vertical-align: inherit;
  white-space: inherit;
}
.tox :not(svg):not(rect) {
  background: 0px 0px;
  border: 0px;
  box-shadow: none;
  float: none;
  height: auto;
  margin: 0px;
  max-width: none;
  outline: 0px;
  padding: 0px;
  position: static;
  width: auto;
}
.tox:not([dir="rtl"]) {
  direction: ltr;
  text-align: left;
}
.tox[dir="rtl"] {
  direction: rtl;
  text-align: right;
}
.tox-tinymce {
  border: 1px solid rgb(204, 204, 204);
  border-radius: 0px;
  box-shadow: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  overflow: auto !important;
  position: relative;
  visibility: inherit !important;
  height: 635px;
}
.tox-tinymce-inline {
  border: none;
  box-shadow: none;
}
.tox-tinymce-inline .tox-editor-header {
  background-color: transparent;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 0px;
  box-shadow: none;
}
.tox-tinymce-aux {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  z-index: 1300;
}
.tox-tinymce :focus,
.tox-tinymce-aux :focus {
  outline: 0px;
}
.tox[dir="rtl"] .tox-icon--flip svg {
  transform: rotateY(180deg);
}
.tox .accessibility-issue__header {
  align-items: center;
  display: flex;
  margin-bottom: 4px;
}
.tox .accessibility-issue__description {
  align-items: stretch;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
}
.tox .accessibility-issue__description > div {
  padding-bottom: 4px;
}
.tox .accessibility-issue__description > div > div {
  align-items: center;
  display: flex;
  margin-bottom: 4px;
}
.tox .accessibility-issue__description > :last-child:not(:only-child) {
  border-color: rgb(204, 204, 204);
  border-style: solid;
}
.tox .accessibility-issue__repair {
  margin-top: 16px;
}
.tox
  .tox-dialog__body-content
  .accessibility-issue--info
  .accessibility-issue__description {
  background-color: rgba(32, 122, 183, 0.1);
  border-color: rgba(32, 122, 183, 0.4);
  color: rgb(34, 47, 62);
}
.tox
  .tox-dialog__body-content
  .accessibility-issue--info
  .accessibility-issue__description
  > :last-child {
  border-color: rgba(32, 122, 183, 0.4);
}
.tox .tox-dialog__body-content .accessibility-issue--info .tox-form__group h2 {
  color: rgb(32, 122, 183);
}
.tox .tox-dialog__body-content .accessibility-issue--info .tox-icon svg {
  fill: rgb(32, 122, 183);
}
.tox .tox-dialog__body-content .accessibility-issue--info a .tox-icon {
  color: rgb(32, 122, 183);
}
.tox
  .tox-dialog__body-content
  .accessibility-issue--warn
  .accessibility-issue__description {
  background-color: rgba(255, 165, 0, 0.1);
  border-color: rgba(255, 165, 0, 0.5);
  color: rgb(34, 47, 62);
}
.tox
  .tox-dialog__body-content
  .accessibility-issue--warn
  .accessibility-issue__description
  > :last-child {
  border-color: rgba(255, 165, 0, 0.5);
}
.tox .tox-dialog__body-content .accessibility-issue--warn .tox-form__group h2 {
  color: rgb(204, 133, 0);
}
.tox .tox-dialog__body-content .accessibility-issue--warn .tox-icon svg {
  fill: rgb(204, 133, 0);
}
.tox .tox-dialog__body-content .accessibility-issue--warn a .tox-icon {
  color: rgb(204, 133, 0);
}
.tox
  .tox-dialog__body-content
  .accessibility-issue--error
  .accessibility-issue__description {
  background-color: rgba(204, 0, 0, 0.1);
  border-color: rgba(204, 0, 0, 0.4);
  color: rgb(34, 47, 62);
}
.tox
  .tox-dialog__body-content
  .accessibility-issue--error
  .accessibility-issue__description
  > :last-child {
  border-color: rgba(204, 0, 0, 0.4);
}
.tox .tox-dialog__body-content .accessibility-issue--error .tox-form__group h2 {
  color: rgb(204, 0, 0);
}
.tox .tox-dialog__body-content .accessibility-issue--error .tox-icon svg {
  fill: rgb(204, 0, 0);
}
.tox .tox-dialog__body-content .accessibility-issue--error a .tox-icon {
  color: rgb(204, 0, 0);
}
.tox
  .tox-dialog__body-content
  .accessibility-issue--success
  .accessibility-issue__description {
  background-color: rgba(120, 171, 70, 0.1);
  border-color: rgba(120, 171, 70, 0.4);
  color: rgb(34, 47, 62);
}
.tox
  .tox-dialog__body-content
  .accessibility-issue--success
  .accessibility-issue__description
  > :last-child {
  border-color: rgba(120, 171, 70, 0.4);
}
.tox
  .tox-dialog__body-content
  .accessibility-issue--success
  .tox-form__group
  h2 {
  color: rgb(120, 171, 70);
}
.tox .tox-dialog__body-content .accessibility-issue--success .tox-icon svg {
  fill: rgb(120, 171, 70);
}
.tox .tox-dialog__body-content .accessibility-issue--success a .tox-icon {
  color: rgb(120, 171, 70);
}
.tox .tox-dialog__body-content .accessibility-issue__header h1,
.tox
  .tox-dialog__body-content
  .tox-form__group
  .accessibility-issue__description
  h2 {
  margin-top: 0px;
}
.tox:not([dir="rtl"])
  .tox-dialog__body-content
  .accessibility-issue__header
  .tox-button {
  margin-left: 4px;
}
.tox:not([dir="rtl"])
  .tox-dialog__body-content
  .accessibility-issue__header
  > :nth-last-child(2) {
  margin-left: auto;
}
.tox:not([dir="rtl"])
  .tox-dialog__body-content
  .accessibility-issue__description {
  padding: 4px 4px 4px 8px;
}
.tox:not([dir="rtl"])
  .tox-dialog__body-content
  .accessibility-issue__description
  > :last-child {
  border-left-width: 1px;
  padding-left: 4px;
}
.tox[dir="rtl"]
  .tox-dialog__body-content
  .accessibility-issue__header
  .tox-button {
  margin-right: 4px;
}
.tox[dir="rtl"]
  .tox-dialog__body-content
  .accessibility-issue__header
  > :nth-last-child(2) {
  margin-right: auto;
}
.tox[dir="rtl"] .tox-dialog__body-content .accessibility-issue__description {
  padding: 4px 8px 4px 4px;
}
.tox[dir="rtl"]
  .tox-dialog__body-content
  .accessibility-issue__description
  > :last-child {
  border-right-width: 1px;
  padding-right: 4px;
}
.tox .tox-anchorbar {
  display: flex;
  flex: 0 0 auto;
}
.tox .tox-bar {
  display: flex;
  flex: 0 0 auto;
}
.tox .tox-button {
  background-color: rgb(32, 122, 183);
  background-image: none;
  background-position: 0px 0px;
  background-repeat: repeat;
  border-color: rgb(32, 122, 183);
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 24px;
  margin: 0px;
  outline: 0px;
  padding: 4px 16px;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  white-space: nowrap;
}
.tox .tox-button[disabled] {
  background-color: rgb(32, 122, 183);
  background-image: none;
  border-color: rgb(32, 122, 183);
  box-shadow: none;
  color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
}
.tox .tox-button:focus:not(:disabled) {
  background-color: rgb(28, 108, 161);
  background-image: none;
  border-color: rgb(28, 108, 161);
  box-shadow: none;
  color: rgb(255, 255, 255);
}
.tox .tox-button:hover:not(:disabled) {
  background-color: rgb(28, 108, 161);
  background-image: none;
  border-color: rgb(28, 108, 161);
  box-shadow: none;
  color: rgb(255, 255, 255);
}
.tox .tox-button:active:not(:disabled) {
  background-color: rgb(24, 93, 140);
  background-image: none;
  border-color: rgb(24, 93, 140);
  box-shadow: none;
  color: rgb(255, 255, 255);
}
.tox .tox-button--secondary {
  background-color: rgb(240, 240, 240);
  background-image: none;
  background-position: 0px 0px;
  background-repeat: repeat;
  border-color: rgb(240, 240, 240);
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  color: rgb(34, 47, 62);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  outline: 0px;
  padding: 4px 16px;
  text-decoration: none;
  text-transform: none;
}
.tox .tox-button--secondary[disabled] {
  background-color: rgb(240, 240, 240);
  background-image: none;
  border-color: rgb(240, 240, 240);
  box-shadow: none;
  color: rgba(34, 47, 62, 0.5);
}
.tox .tox-button--secondary:focus:not(:disabled) {
  background-color: rgb(227, 227, 227);
  background-image: none;
  border-color: rgb(227, 227, 227);
  box-shadow: none;
  color: rgb(34, 47, 62);
}
.tox .tox-button--secondary:hover:not(:disabled) {
  background-color: rgb(227, 227, 227);
  background-image: none;
  border-color: rgb(227, 227, 227);
  box-shadow: none;
  color: rgb(34, 47, 62);
}
.tox .tox-button--secondary:active:not(:disabled) {
  background-color: rgb(214, 214, 214);
  background-image: none;
  border-color: rgb(214, 214, 214);
  box-shadow: none;
  color: rgb(34, 47, 62);
}
.tox .tox-button--icon,
.tox .tox-button.tox-button--icon,
.tox .tox-button.tox-button--secondary.tox-button--icon {
  padding: 4px;
}
.tox .tox-button--icon .tox-icon svg,
.tox .tox-button.tox-button--icon .tox-icon svg,
.tox .tox-button.tox-button--secondary.tox-button--icon .tox-icon svg {
  display: block;
  fill: currentcolor;
}
.tox .tox-button-link {
  background: 0px center;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  margin: 0px;
  padding: 0px;
  white-space: nowrap;
}
.tox .tox-button-link--sm {
  font-size: 14px;
}
.tox .tox-button--naked {
  background-color: transparent;
  border-color: transparent;
  box-shadow: unset;
  color: rgb(34, 47, 62);
}
.tox .tox-button--naked[disabled] {
  background-color: rgb(240, 240, 240);
  border-color: rgb(240, 240, 240);
  box-shadow: none;
  color: rgba(34, 47, 62, 0.5);
}
.tox .tox-button--naked:hover:not(:disabled) {
  background-color: rgb(227, 227, 227);
  border-color: rgb(227, 227, 227);
  box-shadow: none;
  color: rgb(34, 47, 62);
}
.tox .tox-button--naked:focus:not(:disabled) {
  background-color: rgb(227, 227, 227);
  border-color: rgb(227, 227, 227);
  box-shadow: none;
  color: rgb(34, 47, 62);
}
.tox .tox-button--naked:active:not(:disabled) {
  background-color: rgb(214, 214, 214);
  border-color: rgb(214, 214, 214);
  box-shadow: none;
  color: rgb(34, 47, 62);
}
.tox .tox-button--naked .tox-icon svg {
  fill: currentcolor;
}
.tox .tox-button--naked.tox-button--icon:hover:not(:disabled) {
  color: rgb(34, 47, 62);
}
.tox .tox-checkbox {
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  height: 36px;
  min-width: 36px;
}
.tox .tox-checkbox__input {
  height: 1px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
}
.tox .tox-checkbox__icons {
  align-items: center;
  border-radius: 3px;
  box-shadow: transparent 0px 0px 0px 2px;
  box-sizing: content-box;
  display: flex;
  height: 24px;
  justify-content: center;
  padding: calc(3px);
  width: 24px;
}
.tox .tox-checkbox__icons .tox-checkbox-icon__unchecked svg {
  display: block;
  fill: rgba(34, 47, 62, 0.3);
}
.tox .tox-checkbox__icons .tox-checkbox-icon__indeterminate svg {
  display: none;
  fill: rgb(32, 122, 183);
}
.tox .tox-checkbox__icons .tox-checkbox-icon__checked svg {
  display: none;
  fill: rgb(32, 122, 183);
}
.tox .tox-checkbox--disabled {
  color: rgba(34, 47, 62, 0.5);
  cursor: not-allowed;
}
.tox
  .tox-checkbox--disabled
  .tox-checkbox__icons
  .tox-checkbox-icon__checked
  svg {
  fill: rgba(34, 47, 62, 0.5);
}
.tox
  .tox-checkbox--disabled
  .tox-checkbox__icons
  .tox-checkbox-icon__unchecked
  svg {
  fill: rgba(34, 47, 62, 0.5);
}
.tox
  .tox-checkbox--disabled
  .tox-checkbox__icons
  .tox-checkbox-icon__indeterminate
  svg {
  fill: rgba(34, 47, 62, 0.5);
}
.tox
  input.tox-checkbox__input:checked
  + .tox-checkbox__icons
  .tox-checkbox-icon__unchecked
  svg {
  display: none;
}
.tox
  input.tox-checkbox__input:checked
  + .tox-checkbox__icons
  .tox-checkbox-icon__checked
  svg {
  display: block;
}
.tox
  input.tox-checkbox__input:indeterminate
  + .tox-checkbox__icons
  .tox-checkbox-icon__unchecked
  svg {
  display: none;
}
.tox
  input.tox-checkbox__input:indeterminate
  + .tox-checkbox__icons
  .tox-checkbox-icon__indeterminate
  svg {
  display: block;
}
.tox input.tox-checkbox__input:focus + .tox-checkbox__icons {
  border-radius: 3px;
  box-shadow: rgb(32, 122, 183) 0px 0px 0px 1px inset;
  padding: calc(3px);
}
.tox:not([dir="rtl"]) .tox-checkbox__label {
  margin-left: 4px;
}
.tox:not([dir="rtl"]) .tox-checkbox__input {
  left: -10000px;
}
.tox:not([dir="rtl"]) .tox-bar .tox-checkbox {
  margin-left: 4px;
}
.tox[dir="rtl"] .tox-checkbox__label {
  margin-right: 4px;
}
.tox[dir="rtl"] .tox-checkbox__input {
  right: -10000px;
}
.tox[dir="rtl"] .tox-bar .tox-checkbox {
  margin-right: 4px;
}
.tox .tox-collection--toolbar .tox-collection__group {
  display: flex;
  padding: 0px;
}
.tox .tox-collection--grid .tox-collection__group {
  display: flex;
  flex-wrap: wrap;
  max-height: 208px;
  overflow: hidden auto;
  padding: 0px;
}
.tox .tox-collection--list .tox-collection__group {
  border-width: 1px 0px 0px;
  border-color: rgb(204, 204, 204);
  border-style: solid;
  padding: 4px 0px;
}
.tox .tox-collection--list .tox-collection__group:first-child {
  border-top-width: 0px;
}
.tox .tox-collection__group-heading {
  background-color: rgb(230, 230, 230);
  color: rgba(34, 47, 62, 0.7);
  cursor: default;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 4px;
  margin-top: -4px;
  padding: 4px 8px;
  text-transform: none;
  user-select: none;
}
.tox .tox-collection__item {
  align-items: center;
  color: rgb(34, 47, 62);
  cursor: pointer;
  display: flex;
  user-select: none;
}
.tox .tox-collection--list .tox-collection__item {
  padding: 4px 8px;
}
.tox .tox-collection--toolbar .tox-collection__item {
  border-radius: 3px;
  padding: 4px;
}
.tox .tox-collection--grid .tox-collection__item {
  border-radius: 3px;
  padding: 4px;
}
.tox .tox-collection--list .tox-collection__item--enabled {
  background-color: rgb(255, 255, 255);
  color: rgb(34, 47, 62);
}
.tox .tox-collection--list .tox-collection__item--active {
  background-color: rgb(222, 224, 226);
}
.tox .tox-collection--toolbar .tox-collection__item--enabled {
  background-color: rgb(200, 203, 207);
  color: rgb(34, 47, 62);
}
.tox .tox-collection--toolbar .tox-collection__item--active {
  background-color: rgb(222, 224, 226);
}
.tox .tox-collection--grid .tox-collection__item--enabled {
  background-color: rgb(200, 203, 207);
  color: rgb(34, 47, 62);
}
.tox
  .tox-collection--grid
  .tox-collection__item--active:not(.tox-collection__item--state-disabled) {
  background-color: rgb(222, 224, 226);
  color: rgb(34, 47, 62);
}
.tox
  .tox-collection--list
  .tox-collection__item--active:not(.tox-collection__item--state-disabled) {
  color: rgb(34, 47, 62);
}
.tox
  .tox-collection--toolbar
  .tox-collection__item--active:not(.tox-collection__item--state-disabled) {
  color: rgb(34, 47, 62);
}
.tox .tox-collection__item-checkmark,
.tox .tox-collection__item-icon {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
}
.tox .tox-collection__item-checkmark svg,
.tox .tox-collection__item-icon svg {
  fill: currentcolor;
}
.tox .tox-collection--toolbar-lg .tox-collection__item-icon {
  height: 48px;
  width: 48px;
}
.tox .tox-collection__item-label {
  color: currentcolor;
  display: inline-block;
  flex: 1 1 0%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: none;
  word-break: break-all;
}
.tox .tox-collection__item-accessory {
  color: rgba(34, 47, 62, 0.7);
  display: inline-block;
  font-size: 14px;
  height: 24px;
  line-height: 24px;
  text-transform: none;
}
.tox .tox-collection__item-caret {
  align-items: center;
  display: flex;
  min-height: 24px;
}
.tox .tox-collection__item-caret::after {
  content: "";
  font-size: 0px;
  min-height: inherit;
}
.tox .tox-collection__item-caret svg {
  fill: rgb(34, 47, 62);
}
.tox .tox-collection__item--state-disabled {
  background-color: transparent;
  color: rgba(34, 47, 62, 0.5);
  cursor: not-allowed;
}
.tox .tox-collection__item--state-disabled .tox-collection__item-caret svg {
  fill: rgba(34, 47, 62, 0.5);
}
.tox
  .tox-collection--list
  .tox-collection__item:not(.tox-collection__item--enabled)
  .tox-collection__item-checkmark
  svg {
  display: none;
}
.tox
  .tox-collection--list
  .tox-collection__item:not(.tox-collection__item--enabled)
  .tox-collection__item-accessory
  + .tox-collection__item-checkmark {
  display: none;
}
.tox .tox-collection--horizontal {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 3px;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: nowrap;
  margin-bottom: 0px;
  overflow-x: auto;
  padding: 0px;
}
.tox .tox-collection--horizontal .tox-collection__group {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  margin: 0px;
  padding: 0px 4px;
}
.tox .tox-collection--horizontal .tox-collection__item {
  height: 34px;
  margin: 2px 0px 3px;
  padding: 0px 4px;
}
.tox .tox-collection--horizontal .tox-collection__item-label {
  white-space: nowrap;
}
.tox .tox-collection--horizontal .tox-collection__item-caret {
  margin-left: 4px;
}
.tox .tox-collection__item-container {
  display: flex;
}
.tox .tox-collection__item-container--row {
  align-items: center;
  flex: 1 1 auto;
  flex-direction: row;
}
.tox
  .tox-collection__item-container--row.tox-collection__item-container--align-left {
  margin-right: auto;
}
.tox
  .tox-collection__item-container--row.tox-collection__item-container--align-right {
  justify-content: flex-end;
  margin-left: auto;
}
.tox
  .tox-collection__item-container--row.tox-collection__item-container--valign-top {
  align-items: flex-start;
  margin-bottom: auto;
}
.tox
  .tox-collection__item-container--row.tox-collection__item-container--valign-middle {
  align-items: center;
}
.tox
  .tox-collection__item-container--row.tox-collection__item-container--valign-bottom {
  align-items: flex-end;
  margin-top: auto;
}
.tox .tox-collection__item-container--column {
  align-self: center;
  flex: 1 1 auto;
  flex-direction: column;
}
.tox
  .tox-collection__item-container--column.tox-collection__item-container--align-left {
  align-items: flex-start;
}
.tox
  .tox-collection__item-container--column.tox-collection__item-container--align-right {
  align-items: flex-end;
}
.tox
  .tox-collection__item-container--column.tox-collection__item-container--valign-top {
  align-self: flex-start;
}
.tox
  .tox-collection__item-container--column.tox-collection__item-container--valign-middle {
  align-self: center;
}
.tox
  .tox-collection__item-container--column.tox-collection__item-container--valign-bottom {
  align-self: flex-end;
}
.tox:not([dir="rtl"])
  .tox-collection--horizontal
  .tox-collection__group:not(:last-of-type) {
  border-right: 1px solid rgb(204, 204, 204);
}
.tox:not([dir="rtl"])
  .tox-collection--list
  .tox-collection__item
  > :not(:first-child) {
  margin-left: 8px;
}
.tox:not([dir="rtl"])
  .tox-collection--list
  .tox-collection__item
  > .tox-collection__item-label:first-child {
  margin-left: 4px;
}
.tox:not([dir="rtl"]) .tox-collection__item-accessory {
  margin-left: 16px;
  text-align: right;
}
.tox:not([dir="rtl"]) .tox-collection .tox-collection__item-caret {
  margin-left: 16px;
}
.tox[dir="rtl"]
  .tox-collection--horizontal
  .tox-collection__group:not(:last-of-type) {
  border-left: 1px solid rgb(204, 204, 204);
}
.tox[dir="rtl"]
  .tox-collection--list
  .tox-collection__item
  > :not(:first-child) {
  margin-right: 8px;
}
.tox[dir="rtl"]
  .tox-collection--list
  .tox-collection__item
  > .tox-collection__item-label:first-child {
  margin-right: 4px;
}
.tox[dir="rtl"] .tox-collection__item-accessory {
  margin-right: 16px;
  text-align: left;
}
.tox[dir="rtl"] .tox-collection .tox-collection__item-caret {
  margin-right: 16px;
  transform: rotateY(180deg);
}
.tox[dir="rtl"] .tox-collection--horizontal .tox-collection__item-caret {
  margin-right: 4px;
}
.tox .tox-color-picker-container {
  display: flex;
  flex-direction: row;
  height: 225px;
  margin: 0px;
}
.tox .tox-sv-palette {
  box-sizing: border-box;
  display: flex;
  height: 100%;
}
.tox .tox-sv-palette-spectrum {
  height: 100%;
}
.tox .tox-sv-palette,
.tox .tox-sv-palette-spectrum {
  width: 225px;
}
.tox .tox-sv-palette-thumb {
  background: 0px 0px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 50%;
  box-sizing: content-box;
  height: 12px;
  position: absolute;
  width: 12px;
}
.tox .tox-sv-palette-inner-thumb {
  border: 1px solid rgb(255, 255, 255);
  border-radius: 50%;
  height: 10px;
  position: absolute;
  width: 10px;
}
.tox .tox-hue-slider {
  box-sizing: border-box;
  height: 100%;
  width: 25px;
}
.tox .tox-hue-slider-spectrum {
  background: linear-gradient(
    red,
    rgb(255, 0, 128),
    rgb(255, 0, 255),
    rgb(128, 0, 255),
    rgb(0, 0, 255),
    rgb(0, 128, 255),
    rgb(0, 255, 255),
    rgb(0, 255, 128),
    rgb(0, 255, 0),
    rgb(128, 255, 0),
    rgb(255, 255, 0),
    rgb(255, 128, 0),
    red
  );
  height: 100%;
  width: 100%;
}
.tox .tox-hue-slider,
.tox .tox-hue-slider-spectrum {
  width: 20px;
}
.tox .tox-hue-slider-thumb {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(0, 0, 0);
  box-sizing: content-box;
  height: 4px;
  width: 100%;
}
.tox .tox-rgb-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tox .tox-rgb-form div {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  width: inherit;
}
.tox .tox-rgb-form input {
  width: 6em;
}
.tox .tox-rgb-form input.tox-invalid {
  border: 1px solid red !important;
}
.tox .tox-rgb-form .tox-rgba-preview {
  border: 1px solid rgb(0, 0, 0);
  flex-grow: 2;
  margin-bottom: 0px;
}
.tox:not([dir="rtl"]) .tox-sv-palette {
  margin-right: 15px;
}
.tox:not([dir="rtl"]) .tox-hue-slider {
  margin-right: 15px;
}
.tox:not([dir="rtl"]) .tox-hue-slider-thumb {
  margin-left: -1px;
}
.tox:not([dir="rtl"]) .tox-rgb-form label {
  margin-right: 0.5em;
}
.tox[dir="rtl"] .tox-sv-palette {
  margin-left: 15px;
}
.tox[dir="rtl"] .tox-hue-slider {
  margin-left: 15px;
}
.tox[dir="rtl"] .tox-hue-slider-thumb {
  margin-right: -1px;
}
.tox[dir="rtl"] .tox-rgb-form label {
  margin-left: 0.5em;
}
.tox .tox-toolbar .tox-swatches,
.tox .tox-toolbar__overflow .tox-swatches,
.tox .tox-toolbar__primary .tox-swatches {
  margin: 2px 0px 3px 4px;
}
.tox .tox-collection--list .tox-collection__group .tox-swatches-menu {
  border: 0px;
  margin: -4px 0px;
}
.tox .tox-swatches__row {
  display: flex;
}
.tox .tox-swatch {
  height: 30px;
  transition: transform 0.15s ease 0s, box-shadow 0.15s ease 0s;
  width: 30px;
}
.tox .tox-swatch:focus,
.tox .tox-swatch:hover {
  box-shadow: rgba(127, 127, 127, 0.3) 0px 0px 0px 1px inset;
  transform: scale(0.8);
}
.tox .tox-swatch--remove {
  align-items: center;
  display: flex;
  justify-content: center;
}
.tox .tox-swatch--remove svg path {
  stroke: rgb(231, 76, 60);
}
.tox .tox-swatches__picker-btn {
  align-items: center;
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  outline: 0px;
  padding: 0px;
  width: 30px;
}
.tox .tox-swatches__picker-btn svg {
  height: 24px;
  width: 24px;
}
.tox .tox-swatches__picker-btn:hover {
  background: rgb(222, 224, 226);
}
.tox:not([dir="rtl"]) .tox-swatches__picker-btn {
  margin-left: auto;
}
.tox[dir="rtl"] .tox-swatches__picker-btn {
  margin-right: auto;
}
.tox .tox-comment-thread {
  background: rgb(255, 255, 255);
  position: relative;
}
.tox .tox-comment-thread > :not(:first-child) {
  margin-top: 8px;
}
.tox .tox-comment {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  box-shadow: rgba(34, 47, 62, 0.1) 0px 4px 8px 0px;
  padding: 8px 8px 16px;
  position: relative;
}
.tox .tox-comment__header {
  align-items: center;
  color: rgb(34, 47, 62);
  display: flex;
  justify-content: space-between;
}
.tox .tox-comment__date {
  color: rgba(34, 47, 62, 0.7);
  font-size: 12px;
}
.tox .tox-comment__body {
  color: rgb(34, 47, 62);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  margin-top: 8px;
  position: relative;
  text-transform: initial;
}
.tox .tox-comment__body textarea {
  resize: none;
  white-space: normal;
  width: 100%;
}
.tox .tox-comment__expander {
  padding-top: 8px;
}
.tox .tox-comment__expander p {
  color: rgba(34, 47, 62, 0.7);
  font-size: 14px;
  font-style: normal;
}
.tox .tox-comment__body p {
  margin: 0px;
}
.tox .tox-comment__buttonspacing {
  padding-top: 16px;
  text-align: center;
}
.tox .tox-comment-thread__overlay::after {
  background: rgb(255, 255, 255);
  inset: 0px;
  content: "";
  display: flex;
  opacity: 0.9;
  position: absolute;
  z-index: 5;
}
.tox .tox-comment__reply {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 8px;
}
.tox .tox-comment__reply > :first-child {
  margin-bottom: 8px;
  width: 100%;
}
.tox .tox-comment__edit {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 16px;
}
.tox .tox-comment__gradient::after {
  background: linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255));
  bottom: 0px;
  content: "";
  display: block;
  height: 5em;
  margin-top: -40px;
  position: absolute;
  width: 100%;
}
.tox .tox-comment__overlay {
  background: rgb(255, 255, 255);
  inset: 0px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  opacity: 0.9;
  position: absolute;
  text-align: center;
  z-index: 5;
}
.tox .tox-comment__loading-text {
  align-items: center;
  color: rgb(34, 47, 62);
  display: flex;
  flex-direction: column;
  position: relative;
}
.tox .tox-comment__loading-text > div {
  padding-bottom: 16px;
}
.tox .tox-comment__overlaytext {
  inset: 0px;
  flex-direction: column;
  font-size: 14px;
  padding: 1em;
  position: absolute;
  z-index: 10;
}
.tox .tox-comment__overlaytext p {
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(255, 255, 255) 0px 0px 8px 8px;
  color: rgb(34, 47, 62);
  text-align: center;
}
.tox .tox-comment__overlaytext div:nth-of-type(2) {
  font-size: 0.8em;
}
.tox .tox-comment__busy-spinner {
  align-items: center;
  background-color: rgb(255, 255, 255);
  inset: 0px;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 20;
}
.tox .tox-comment__scroll {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  overflow: auto;
}
.tox .tox-conversations {
  margin: 8px;
}
.tox:not([dir="rtl"]) .tox-comment__edit {
  margin-left: 8px;
}
.tox:not([dir="rtl"]) .tox-comment__buttonspacing > :last-child,
.tox:not([dir="rtl"]) .tox-comment__edit > :last-child,
.tox:not([dir="rtl"]) .tox-comment__reply > :last-child {
  margin-left: 8px;
}
.tox[dir="rtl"] .tox-comment__edit {
  margin-right: 8px;
}
.tox[dir="rtl"] .tox-comment__buttonspacing > :last-child,
.tox[dir="rtl"] .tox-comment__edit > :last-child,
.tox[dir="rtl"] .tox-comment__reply > :last-child {
  margin-right: 8px;
}
.tox .tox-user {
  align-items: center;
  display: flex;
}
.tox .tox-user__avatar svg {
  fill: rgba(34, 47, 62, 0.7);
}
.tox .tox-user__name {
  color: rgba(34, 47, 62, 0.7);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.tox:not([dir="rtl"]) .tox-user__avatar svg {
  margin-right: 8px;
}
.tox:not([dir="rtl"]) .tox-user__avatar + .tox-user__name {
  margin-left: 8px;
}
.tox[dir="rtl"] .tox-user__avatar svg {
  margin-left: 8px;
}
.tox[dir="rtl"] .tox-user__avatar + .tox-user__name {
  margin-right: 8px;
}
.tox .tox-dialog-wrap {
  align-items: center;
  inset: 0px;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 1100;
}
.tox .tox-dialog-wrap__backdrop {
  background-color: rgba(255, 255, 255, 0.75);
  inset: 0px;
  position: absolute;
  z-index: 1;
}
.tox .tox-dialog-wrap__backdrop--opaque {
  background-color: rgb(255, 255, 255);
}
.tox .tox-dialog {
  background-color: rgb(255, 255, 255);
  border-color: rgb(204, 204, 204);
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  box-shadow: rgba(34, 47, 62, 0.15) 0px 16px 16px -10px,
    rgba(34, 47, 62, 0.15) 0px 0px 40px 1px;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  max-width: 480px;
  overflow: hidden;
  position: relative;
  width: 95vw;
  z-index: 2;
}

.tox[dir="rtl"] .tox-dialog__footer .tox-dialog__footer-end > *,
.tox[dir="rtl"] .tox-dialog__footer .tox-dialog__footer-start > * {
  margin-right: 8px;
}
body.tox-dialog__disable-scroll {
  overflow: hidden;
}
.tox .tox-dropzone-container {
  display: flex;
  flex: 1 1 0%;
}
.tox .tox-dropzone {
  align-items: center;
  background: rgb(255, 255, 255);
  border: 2px dashed rgb(204, 204, 204);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  min-height: 100px;
  padding: 10px;
}
.tox .tox-dropzone p {
  color: rgba(34, 47, 62, 0.7);
  margin: 0px 0px 16px;
}
.tox .tox-edit-area {
  display: flex;
  flex: 1 1 0%;
  overflow: hidden;
  position: relative;
}
.tox .tox-edit-area__iframe {
  background-image: url("../../../assets/images/write-paper-bg-bottom.png") ;
  border: 0px;
  box-sizing: border-box;
  flex: 1 1 0%;
  height: 100%;
  position: absolute;
  width: 100%;
}
 .tox.tox-inline-edit-area {
  border: 1px dotted rgb(204, 204, 204);
}
.tox .tox-editor-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
}
.tox .tox-editor-header {
  z-index: 1;
}
.tox:not(.tox-tinymce-inline) .tox-editor-header {
  box-shadow: none;
  transition: box-shadow 0.5s ease 0s;
}
.tox.tox-tinymce--toolbar-bottom .tox-editor-header,
.tox.tox-tinymce-inline .tox-editor-header {
  margin-bottom: -1px;
}
.tox.tox-tinymce--toolbar-sticky-on .tox-editor-header {
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px -3px;
}
.tox-editor-dock-fadeout {
  opacity: 0;
  visibility: hidden;
}
.tox-editor-dock-fadein {
  opacity: 1;
  visibility: visible;
}
.tox-editor-dock-transition {
  transition: visibility 0s linear 0.25s, opacity 0.25s ease 0s;
}
.tox-editor-dock-transition.tox-editor-dock-fadein {
  transition-delay: 0s;
}
.tox .tox-control-wrap {
  flex: 1 1 0%;
  position: relative;
}
.tox
  .tox-control-wrap:not(.tox-control-wrap--status-invalid)
  .tox-control-wrap__status-icon-invalid,
.tox
  .tox-control-wrap:not(.tox-control-wrap--status-unknown)
  .tox-control-wrap__status-icon-unknown,
.tox
  .tox-control-wrap:not(.tox-control-wrap--status-valid)
  .tox-control-wrap__status-icon-valid {
  display: none;
}
.tox .tox-control-wrap svg {
  display: block;
}
.tox .tox-control-wrap__status-icon-wrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.tox .tox-control-wrap__status-icon-invalid svg {
  fill: rgb(204, 0, 0);
}
.tox .tox-control-wrap__status-icon-unknown svg {
  fill: orange;
}
.tox .tox-control-wrap__status-icon-valid svg {
  fill: green;
}
.tox:not([dir="rtl"]) .tox-control-wrap--status-invalid .tox-textfield,
.tox:not([dir="rtl"]) .tox-control-wrap--status-unknown .tox-textfield,
.tox:not([dir="rtl"]) .tox-control-wrap--status-valid .tox-textfield {
  padding-right: 32px;
}
.tox:not([dir="rtl"]) .tox-control-wrap__status-icon-wrap {
  right: 4px;
}
.tox[dir="rtl"] .tox-control-wrap--status-invalid .tox-textfield,
.tox[dir="rtl"] .tox-control-wrap--status-unknown .tox-textfield,
.tox[dir="rtl"] .tox-control-wrap--status-valid .tox-textfield {
  padding-left: 32px;
}
.tox[dir="rtl"] .tox-control-wrap__status-icon-wrap {
  left: 4px;
}
.tox .tox-autocompleter {
  max-width: 25em;
}
.tox .tox-autocompleter .tox-menu {
  max-width: 25em;
}
.tox .tox-autocompleter .tox-autocompleter-highlight {
  font-weight: 700;
}
.tox .tox-color-input {
  display: flex;
  position: relative;
  z-index: 1;
}
.tox .tox-color-input .tox-textfield {
  z-index: -1;
}
.tox .tox-color-input span {
  border-color: rgba(34, 47, 62, 0.2);
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  box-sizing: border-box;
  height: 24px;
  position: absolute;
  top: 6px;
  width: 24px;
}
.tox .tox-color-input span:focus:not([aria-disabled="true"]),
.tox .tox-color-input span:hover:not([aria-disabled="true"]) {
  border-color: rgb(32, 122, 183);
  cursor: pointer;
}
.tox .tox-color-input span::before {
  background-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.25) 25%,
      transparent 25%
    ),
    linear-gradient(-45deg, rgba(0, 0, 0, 0.25) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, rgba(0, 0, 0, 0.25) 75%),
    linear-gradient(-45deg, transparent 75%, rgba(0, 0, 0, 0.25) 75%);
  background-position: 0px 0px, 0px 6px, 6px -6px, -6px 0px;
  background-size: 12px 12px;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 3px;
  box-sizing: border-box;
  content: "";
  height: 24px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 24px;
  z-index: -1;
}
.tox .tox-color-input span[aria-disabled="true"] {
  cursor: not-allowed;
}
.tox:not([dir="rtl"]) .tox-color-input .tox-textfield {
  padding-left: 36px;
}
.tox:not([dir="rtl"]) .tox-color-input span {
  left: 6px;
}
.tox[dir="rtl"] .tox-color-input .tox-textfield {
  padding-right: 36px;
}
.tox[dir="rtl"] .tox-color-input span {
  right: 6px;
}
.tox .tox-label,
.tox .tox-toolbar-label {
  color: rgba(34, 47, 62, 0.7);
  display: block;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  padding: 0px 8px 0px 0px;
  text-transform: none;
  white-space: nowrap;
}
.tox .tox-toolbar-label {
  padding: 0px 8px;
}
.tox[dir="rtl"] .tox-label {
  padding: 0px 0px 0px 8px;
}
.tox .tox-form {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
}
.tox .tox-form__group {
  box-sizing: border-box;
  margin-bottom: 4px;
}
.tox .tox-form-group--maximize {
  flex: 1 1 0%;
}
.tox .tox-form__group--error {
  color: rgb(204, 0, 0);
}
.tox .tox-form__group--collection {
  display: flex;
}
.tox .tox-form__grid {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.tox .tox-form__grid--2col > .tox-form__group {
  width: calc(50% - 4px);
}
.tox .tox-form__grid--3col > .tox-form__group {
  width: calc(33.3333% - 4px);
}
.tox .tox-form__grid--4col > .tox-form__group {
  width: calc(25% - 4px);
}
.tox .tox-form__controls-h-stack {
  align-items: center;
  display: flex;
}
.tox .tox-form__group--inline {
  align-items: center;
  display: flex;
}
.tox .tox-form__group--stretched {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
}
.tox .tox-form__group--stretched .tox-textarea {
  flex: 1 1 0%;
}
.tox .tox-form__group--stretched .tox-navobj {
  display: flex;
  flex: 1 1 0%;
}
.tox .tox-form__group--stretched .tox-navobj :nth-child(2) {
  flex: 1 1 0%;
  height: 100%;
}
.tox:not([dir="rtl"]) .tox-form__controls-h-stack > :not(:first-child) {
  margin-left: 4px;
}
.tox[dir="rtl"] .tox-form__controls-h-stack > :not(:first-child) {
  margin-right: 4px;
}
.tox .tox-lock.tox-locked .tox-lock-icon__unlock,
.tox .tox-lock:not(.tox-locked) .tox-lock-icon__lock {
  display: none;
}
.tox .tox-listboxfield .tox-listbox--select,
.tox .tox-textarea,
.tox .tox-textfield,
.tox .tox-toolbar-textfield {
  appearance: none;
  background-color: rgb(255, 255, 255);
  border-color: rgb(204, 204, 204);
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  box-sizing: border-box;
  color: rgb(34, 47, 62);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
  min-height: 34px;
  outline: 0px;
  padding: 5px 4.75px;
  resize: none;
  width: 100%;
}
.tox .tox-textarea[disabled],
.tox .tox-textfield[disabled] {
  background-color: rgb(242, 242, 242);
  color: rgba(34, 47, 62, 0.85);
  cursor: not-allowed;
}
.tox .tox-listboxfield .tox-listbox--select:focus,
.tox .tox-textarea:focus,
.tox .tox-textfield:focus {
  background-color: rgb(255, 255, 255);
  border-color: rgb(32, 122, 183);
  box-shadow: none;
  outline: 0px;
}
.tox .tox-toolbar-textfield {
  border-width: 0px;
  margin-bottom: 3px;
  margin-top: 2px;
  max-width: 250px;
}
.tox .tox-naked-btn {
  background-color: transparent;
  border: 0px transparent;
  box-shadow: unset;
  color: rgb(32, 122, 183);
  cursor: pointer;
  display: block;
  margin: 0px;
  padding: 0px;
}
.tox .tox-naked-btn svg {
  display: block;
  fill: rgb(34, 47, 62);
}
.tox:not([dir="rtl"]) .tox-toolbar-textfield + * {
  margin-left: 4px;
}
.tox[dir="rtl"] .tox-toolbar-textfield + * {
  margin-right: 4px;
}
.tox .tox-listboxfield {
  cursor: pointer;
  position: relative;
}
.tox .tox-listboxfield .tox-listbox--select[disabled] {
  background-color: rgb(242, 242, 242);
  color: rgba(34, 47, 62, 0.85);
  cursor: not-allowed;
}
.tox .tox-listbox__select-label {
  cursor: default;
  flex: 1 1 0%;
  margin: 0px 4px;
}
.tox .tox-listbox__select-chevron {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 16px;
}
.tox .tox-listbox__select-chevron svg {
  fill: rgb(34, 47, 62);
}
.tox .tox-listboxfield .tox-listbox--select {
  align-items: center;
  display: flex;
}
.tox:not([dir="rtl"]) .tox-listboxfield svg {
  right: 8px;
}
.tox[dir="rtl"] .tox-listboxfield svg {
  left: 8px;
}
.tox .tox-selectfield {
  cursor: pointer;
  position: relative;
}
.tox .tox-selectfield select {
  appearance: none;
  background-color: rgb(255, 255, 255);
  border-color: rgb(204, 204, 204);
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  box-sizing: border-box;
  color: rgb(34, 47, 62);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
  min-height: 34px;
  outline: 0px;
  padding: 5px 4.75px;
  resize: none;
  width: 100%;
}
.tox .tox-selectfield select[disabled] {
  background-color: rgb(242, 242, 242);
  color: rgba(34, 47, 62, 0.85);
  cursor: not-allowed;
}
.tox .tox-selectfield select:focus {
  background-color: rgb(255, 255, 255);
  border-color: rgb(32, 122, 183);
  box-shadow: none;
  outline: 0px;
}
.tox .tox-selectfield svg {
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.tox:not([dir="rtl"]) .tox-selectfield select[size="0"],
.tox:not([dir="rtl"]) .tox-selectfield select[size="1"] {
  padding-right: 24px;
}
.tox:not([dir="rtl"]) .tox-selectfield svg {
  right: 8px;
}
.tox[dir="rtl"] .tox-selectfield select[size="0"],
.tox[dir="rtl"] .tox-selectfield select[size="1"] {
  padding-left: 24px;
}
.tox[dir="rtl"] .tox-selectfield svg {
  left: 8px;
}
.tox .tox-textarea {
  appearance: textarea;
  white-space: pre-wrap;
}
.tox-fullscreen {
  border: 0px;
  height: 100%;
  margin: 0px;
  overflow: hidden;
  overscroll-behavior: none;
  padding: 0px;
  touch-action: pinch-zoom;
  width: 100%;
}
.tox.tox-tinymce.tox-fullscreen .tox-statusbar__resize-handle {
  display: none;
}
.tox-shadowhost.tox-fullscreen,
.tox.tox-tinymce.tox-fullscreen {
  left: 0px;
  position: fixed;
  top: 0px;
  z-index: 1200;
}
.tox.tox-tinymce.tox-fullscreen {
  background-color: transparent;
}
.tox-fullscreen .tox.tox-tinymce-aux,
.tox-fullscreen ~ .tox.tox-tinymce-aux {
  z-index: 1201;
}
.tox .tox-help__more-link {
  list-style: none;
  margin-top: 1em;
}
.tox .tox-image-tools {
  width: 100%;
}
.tox .tox-image-tools__toolbar {
  align-items: center;
  display: flex;
  justify-content: center;
}
.tox .tox-image-tools__image {
  background-color: rgb(102, 102, 102);
  height: 380px;
  overflow: auto;
  position: relative;
  width: 100%;
}
.tox .tox-image-tools__image,
.tox .tox-image-tools__image + .tox-image-tools__toolbar {
  margin-top: 8px;
}
.tox .tox-image-tools__image-bg {
  /* background: url("data:image/gif;base64,R0lGODdhDAAMAIABAMzMzP///ywAAAAADAAMAAACFoQfqYeabNyDMkBQb81Uat85nxguUAEAOw=="); */
}
.tox .tox-image-tools__toolbar > .tox-spacer {
  flex: 1 1 0%;
}
.tox .tox-croprect-block {
  background: rgb(0, 0, 0);
  opacity: 0.5;
  position: absolute;
  zoom: 1;
}
.tox .tox-croprect-handle {
  border: 2px solid rgb(255, 255, 255);
  height: 20px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 20px;
}
.tox .tox-croprect-handle-move {
  border: 0px;
  cursor: move;
  position: absolute;
}
.tox .tox-croprect-handle-nw {
  border-width: 2px 0px 0px 2px;
  cursor: nw-resize;
  left: 100px;
  margin: -2px 0px 0px -2px;
  top: 100px;
}
.tox .tox-croprect-handle-ne {
  border-width: 2px 2px 0px 0px;
  cursor: ne-resize;
  left: 200px;
  margin: -2px 0px 0px -20px;
  top: 100px;
}
.tox .tox-croprect-handle-sw {
  border-width: 0px 0px 2px 2px;
  cursor: sw-resize;
  left: 100px;
  margin: -20px 2px 0px -2px;
  top: 200px;
}
.tox .tox-croprect-handle-se {
  border-width: 0px 2px 2px 0px;
  cursor: se-resize;
  left: 200px;
  margin: -20px 0px 0px -20px;
  top: 200px;
}
.tox:not([dir="rtl"])
  .tox-image-tools__toolbar
  > .tox-slider:not(:first-of-type) {
  margin-left: 8px;
}
.tox:not([dir="rtl"]) .tox-image-tools__toolbar > .tox-button + .tox-slider {
  margin-left: 32px;
}
.tox:not([dir="rtl"]) .tox-image-tools__toolbar > .tox-slider + .tox-button {
  margin-left: 32px;
}
.tox[dir="rtl"] .tox-image-tools__toolbar > .tox-slider:not(:first-of-type) {
  margin-right: 8px;
}
.tox[dir="rtl"] .tox-image-tools__toolbar > .tox-button + .tox-slider {
  margin-right: 32px;
}
.tox[dir="rtl"] .tox-image-tools__toolbar > .tox-slider + .tox-button {
  margin-right: 32px;
}
.tox .tox-insert-table-picker {
  display: flex;
  flex-wrap: wrap;
  width: 170px;
}
.tox .tox-insert-table-picker > div {
  border-color: rgb(204, 204, 204);
  border-style: solid;
  border-width: 0px 1px 1px 0px;
  box-sizing: border-box;
  height: 17px;
  width: 17px;
}
.tox .tox-collection--list .tox-collection__group .tox-insert-table-picker {
  margin: -4px 0px;
}
.tox .tox-insert-table-picker .tox-insert-table-picker__selected {
  background-color: rgba(32, 122, 183, 0.5);
  border-color: rgba(32, 122, 183, 0.5);
}
.tox .tox-insert-table-picker__label {
  color: rgba(34, 47, 62, 0.7);
  display: block;
  font-size: 14px;
  padding: 4px;
  text-align: center;
  width: 100%;
}
.tox:not([dir="rtl"]) .tox-insert-table-picker > div:nth-child(10n) {
  border-right: 0px;
}
.tox[dir="rtl"] .tox-insert-table-picker > div:nth-child(10n + 1) {
  border-right: 0px;
}
.tox .tox-menu {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  box-shadow: rgba(34, 47, 62, 0.1) 0px 4px 8px 0px;
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  z-index: 1150;
}
.tox .tox-menu.tox-collection.tox-collection--list {
  padding: 0px;
}
.tox .tox-menu.tox-collection.tox-collection--toolbar {
  padding: 4px;
}
.tox .tox-menu.tox-collection.tox-collection--grid {
  padding: 4px;
}
.tox .tox-menu__label blockquote,
.tox .tox-menu__label code,
.tox .tox-menu__label h1,
.tox .tox-menu__label h2,
.tox .tox-menu__label h3,
.tox .tox-menu__label h4,
.tox .tox-menu__label h5,
.tox .tox-menu__label h6,
.tox .tox-menu__label p {
  margin: 0px;
}
.tox .tox-menubar {
  background: url("data:image/svg+xml;charset=utf8,%3Csvg height='39px' viewBox='0 0 40 39px' width='40' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0' y='38px' width='100' height='1' fill='%23cccccc'/%3E%3C/svg%3E")
    left 0px top 0px rgb(255, 255, 255);
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  padding: 0px 4px;
}
.tox.tox-tinymce:not(.tox-tinymce-inline)
  .tox-editor-header:not(:first-child)
  .tox-menubar {
  border-top: 1px solid rgb(204, 204, 204);
}
.tox .tox-mbtn {
  align-items: center;
  background: 0px 0px;
  border: 0px;
  border-radius: 3px;
  box-shadow: none;
  color: rgb(34, 47, 62);
  display: flex;
  flex: 0 0 auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 34px;
  justify-content: center;
  margin: 2px 0px 3px;
  outline: 0px;
  overflow: hidden;
  padding: 0px 4px;
  text-transform: none;
  width: auto;
}
.tox .tox-mbtn[disabled] {
  background-color: transparent;
  border: 0px;
  box-shadow: none;
  color: rgba(34, 47, 62, 0.5);
  cursor: not-allowed;
}
.tox .tox-mbtn:focus:not(:disabled) {
  background: rgb(222, 224, 226);
  border: 0px;
  box-shadow: none;
  color: rgb(34, 47, 62);
}
.tox .tox-mbtn--active {
  background: rgb(200, 203, 207);
  border: 0px;
  box-shadow: none;
  color: rgb(34, 47, 62);
}
.tox .tox-mbtn:hover:not(:disabled):not(.tox-mbtn--active) {
  background: rgb(222, 224, 226);
  border: 0px;
  box-shadow: none;
  color: rgb(34, 47, 62);
}
.tox .tox-mbtn__select-label {
  cursor: default;
  font-weight: 400;
  margin: 0px 4px;
}
.tox .tox-mbtn[disabled] .tox-mbtn__select-label {
  cursor: not-allowed;
}
.tox .tox-mbtn__select-chevron {
  align-items: center;
  justify-content: center;
  width: 16px;
  display: none;
}
.tox .tox-notification {
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  box-sizing: border-box;
  display: grid;
  font-size: 14px;
  font-weight: 400;
  grid-template-columns: minmax(40px, 1fr) auto minmax(40px, 1fr);
  margin-top: 4px;
  opacity: 0;
  padding: 4px;
  transition: transform 0.1s ease-in 0s, opacity 150ms ease-in 0s;
}
.tox .tox-notification p {
  font-size: 14px;
  font-weight: 400;
}
.tox .tox-notification a {
  cursor: pointer;
  text-decoration: underline;
}
.tox .tox-notification--in {
  opacity: 1;
}
.tox .tox-notification--success {
  background-color: rgb(228, 238, 218);
  border-color: rgb(215, 230, 200);
  color: rgb(34, 47, 62);
}
.tox .tox-notification--success p {
  color: rgb(34, 47, 62);
}
.tox .tox-notification--success a {
  color: rgb(84, 120, 49);
}
.tox .tox-notification--success svg {
  fill: rgb(34, 47, 62);
}
.tox .tox-notification--error {
  background-color: rgb(248, 222, 222);
  border-color: rgb(242, 191, 191);
  color: rgb(34, 47, 62);
}
.tox .tox-notification--error p {
  color: rgb(34, 47, 62);
}
.tox .tox-notification--error a {
  color: rgb(204, 0, 0);
}
.tox .tox-notification--error svg {
  fill: rgb(34, 47, 62);
}
.tox .tox-notification--warn,
.tox .tox-notification--warning {
  background-color: rgb(255, 250, 234);
  border-color: rgb(255, 232, 157);
  color: rgb(34, 47, 62);
}
.tox .tox-notification--warn p,
.tox .tox-notification--warning p {
  color: rgb(34, 47, 62);
}
.tox .tox-notification--warn a,
.tox .tox-notification--warning a {
  color: rgb(34, 47, 62);
}
.tox .tox-notification--warn svg,
.tox .tox-notification--warning svg {
  fill: rgb(34, 47, 62);
}
.tox .tox-notification--info {
  background-color: rgb(217, 237, 247);
  border-color: rgb(119, 158, 203);
  color: rgb(34, 47, 62);
}
.tox .tox-notification--info p {
  color: rgb(34, 47, 62);
}
.tox .tox-notification--info a {
  color: rgb(34, 47, 62);
}
.tox .tox-notification--info svg {
  fill: rgb(34, 47, 62);
}
.tox .tox-notification__body {
  align-self: center;
  color: rgb(34, 47, 62);
  font-size: 14px;
  grid-area: 1 / 2 / 2 / 3;
  text-align: center;
  white-space: normal;
  word-break: break-word;
}
.tox .tox-notification__body > * {
  margin: 0px;
}
.tox .tox-notification__body > * + * {
  margin-top: 1rem;
}
.tox .tox-notification__icon {
  place-self: center end;
  grid-area: 1 / 1 / 2 / 2;
}
.tox .tox-notification__icon svg {
  display: block;
}
.tox .tox-notification__dismiss {
  place-self: start end;
  grid-area: 1 / 3 / 2 / 4;
}
.tox .tox-notification .tox-progress-bar {
  grid-area: 2 / 1 / 3 / 4;
  justify-self: center;
}
.tox .tox-pop {
  display: inline-block;
  position: relative;
}
.tox .tox-pop--resizing {
  transition: width 0.1s ease 0s;
}
.tox .tox-pop--resizing .tox-toolbar,
.tox .tox-pop--resizing .tox-toolbar__group {
  flex-wrap: nowrap;
}
.tox .tox-pop--transition {
  transition: left 0.15s ease 0s, right, top, bottom;
}
.tox .tox-pop--transition::after,
.tox .tox-pop--transition::before {
  transition: all 0.15s ease 0s, visibility 0s ease 0s, opacity 75ms ease 75ms;
}
.tox .tox-pop__dialog {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 3px;
  min-width: 0px;
  overflow: hidden;
}
.tox .tox-pop__dialog > :not(.tox-toolbar) {
  margin: 4px 4px 4px 8px;
}
.tox .tox-pop__dialog .tox-toolbar {
  background-color: transparent;
  margin-bottom: -1px;
}
.tox .tox-pop::after,
.tox .tox-pop::before {
  border-style: solid;
  content: "";
  display: block;
  height: 0px;
  opacity: 1;
  position: absolute;
  width: 0px;
}
.tox .tox-pop.tox-pop--inset::after,
.tox .tox-pop.tox-pop--inset::before {
  opacity: 0;
  transition: all 0s ease 0.15s, visibility 0s ease 0s, opacity 75ms ease 0s;
}
.tox .tox-pop.tox-pop--bottom::after,
.tox .tox-pop.tox-pop--bottom::before {
  left: 50%;
  top: 100%;
}
.tox .tox-pop.tox-pop--bottom::after {
  border-color: rgb(255, 255, 255) transparent transparent;
  border-width: 8px;
  margin-left: -8px;
  margin-top: -1px;
}
.tox .tox-pop.tox-pop--bottom::before {
  border-color: rgb(204, 204, 204) transparent transparent;
  border-width: 9px;
  margin-left: -9px;
}
.tox .tox-pop.tox-pop--top::after,
.tox .tox-pop.tox-pop--top::before {
  left: 50%;
  top: 0px;
  transform: translateY(-100%);
}
.tox .tox-pop.tox-pop--top::after {
  border-color: transparent transparent rgb(255, 255, 255);
  border-width: 8px;
  margin-left: -8px;
  margin-top: 1px;
}
.tox .tox-pop.tox-pop--top::before {
  border-color: transparent transparent rgb(204, 204, 204);
  border-width: 9px;
  margin-left: -9px;
}
.tox .tox-pop.tox-pop--left::after,
.tox .tox-pop.tox-pop--left::before {
  left: 0px;
  top: calc(50% - 1px);
  transform: translateY(-50%);
}
.tox .tox-pop.tox-pop--left::after {
  border-color: transparent rgb(255, 255, 255) transparent transparent;
  border-width: 8px;
  margin-left: -15px;
}
.tox .tox-pop.tox-pop--left::before {
  border-color: transparent rgb(204, 204, 204) transparent transparent;
  border-width: 10px;
  margin-left: -19px;
}
.tox .tox-pop.tox-pop--right::after,
.tox .tox-pop.tox-pop--right::before {
  left: 100%;
  top: calc(50% + 1px);
  transform: translateY(-50%);
}
.tox .tox-pop.tox-pop--right::after {
  border-color: transparent transparent transparent rgb(255, 255, 255);
  border-width: 8px;
  margin-left: -1px;
}
.tox .tox-pop.tox-pop--right::before {
  border-color: transparent transparent transparent rgb(204, 204, 204);
  border-width: 10px;
  margin-left: -1px;
}
.tox .tox-pop.tox-pop--align-left::after,
.tox .tox-pop.tox-pop--align-left::before {
  left: 20px;
}
.tox .tox-pop.tox-pop--align-right::after,
.tox .tox-pop.tox-pop--align-right::before {
  left: calc(100% - 20px);
}
.tox .tox-sidebar-wrap {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-height: 0px;
}
.tox .tox-sidebar {
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.tox .tox-sidebar__slider {
  display: flex;
  overflow: hidden;
}
.tox .tox-sidebar__pane-container {
  display: flex;
}
.tox .tox-sidebar__pane {
  display: flex;
}
.tox .tox-sidebar--sliding-closed {
  opacity: 0;
}
.tox .tox-sidebar--sliding-open {
  opacity: 1;
}
.tox .tox-sidebar--sliding-growing,
.tox .tox-sidebar--sliding-shrinking {
  transition: width 0.5s ease 0s, opacity 0.5s ease 0s;
}
.tox .tox-selector {
  background-color: rgb(64, 153, 255);
  border-color: rgb(64, 153, 255);
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  display: inline-block;
  height: 10px;
  position: absolute;
  width: 10px;
}
.tox.tox-platform-touch .tox-selector {
  height: 12px;
  width: 12px;
}
.tox .tox-slider {
  align-items: center;
  display: flex;
  flex: 1 1 0%;
  height: 24px;
  justify-content: center;
  position: relative;
}
.tox .tox-slider__rail {
  background-color: transparent;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  height: 10px;
  min-width: 120px;
  width: 100%;
}
.tox .tox-slider__handle {
  background-color: rgb(32, 122, 183);
  border: 2px solid rgb(24, 93, 140);
  border-radius: 3px;
  box-shadow: none;
  height: 24px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 14px;
}
.tox .tox-source-code {
  overflow: auto;
}
.tox .tox-spinner {
  display: flex;
}
.tox .tox-spinner > div {
  animation: 1.5s ease-in-out 0s infinite normal both running tam-bouncing-dots;
  background-color: rgba(34, 47, 62, 0.7);
  border-radius: 100%;
  height: 8px;
  width: 8px;
}
.tox .tox-spinner > div:nth-child(1) {
  animation-delay: -0.32s;
}
.tox .tox-spinner > div:nth-child(2) {
  animation-delay: -0.16s;
}
@keyframes tam-bouncing-dots {
  0%,
  100%,
  80% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.tox:not([dir="rtl"]) .tox-spinner > div:not(:first-child) {
  margin-left: 4px;
}
.tox[dir="rtl"] .tox-spinner > div:not(:first-child) {
  margin-right: 4px;
}
.tox .tox-statusbar {
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-top: 1px solid rgb(204, 204, 204);
  color: rgba(34, 47, 62, 0.7);
  display: flex;
  flex: 0 0 auto;
  font-size: 12px;
  font-weight: 400;
  height: 18px;
  overflow: hidden;
  padding: 0px 8px;
  position: relative;
  text-transform: uppercase;
}
.tox .tox-statusbar__text-container {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  overflow: hidden;
}
.tox .tox-statusbar__path {
  display: flex;
  flex: 1 1 auto;
  margin-right: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tox .tox-statusbar__path > * {
  display: inline;
  white-space: nowrap;
}
.tox .tox-statusbar__wordcount {
  flex: 0 0 auto;
  margin-left: 1ch;
}
.tox .tox-statusbar a,
.tox .tox-statusbar__path-item,
.tox .tox-statusbar__wordcount {
  color: rgba(34, 47, 62, 0.7);
  text-decoration: none;
}
.tox .tox-statusbar a:focus:not(:disabled):not([aria-disabled="true"]),
.tox .tox-statusbar a:hover:not(:disabled):not([aria-disabled="true"]),
.tox .tox-statusbar__path-item:focus:not(:disabled):not([aria-disabled="true"]),
.tox .tox-statusbar__path-item:hover:not(:disabled):not([aria-disabled="true"]),
.tox .tox-statusbar__wordcount:focus:not(:disabled):not([aria-disabled="true"]),
.tox
  .tox-statusbar__wordcount:hover:not(:disabled):not([aria-disabled="true"]) {
  cursor: pointer;
  text-decoration: underline;
}
.tox .tox-statusbar__resize-handle {
  align-items: flex-end;
  align-self: stretch;
  cursor: nwse-resize;
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: -8px;
  padding-left: 1ch;
}
.tox .tox-statusbar__resize-handle svg {
  display: block;
  fill: rgba(34, 47, 62, 0.7);
}
.tox .tox-statusbar__resize-handle:focus svg {
  background-color: rgb(222, 224, 226);
  border-radius: 1px;
  box-shadow: rgb(222, 224, 226) 0px 0px 0px 2px;
}
.tox:not([dir="rtl"]) .tox-statusbar__path > * {
  margin-right: 4px;
}
.tox:not([dir="rtl"]) .tox-statusbar__branding {
  margin-left: 1ch;
}
.tox[dir="rtl"] .tox-statusbar {
  flex-direction: row-reverse;
}
.tox[dir="rtl"] .tox-statusbar__path > * {
  margin-left: 4px;
}
.tox .tox-throbber {
  z-index: 1299;
}
.tox .tox-throbber__busy-spinner {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  inset: 0px;
  display: flex;
  justify-content: center;
  position: absolute;
}
.tox .tox-tbtn {
  align-items: center;
  background: 0px 0px;
  border: 0px;
  border-radius: 3px;
  box-shadow: none;
  color: rgb(34, 47, 62);
  display: flex;
  flex: 0 0 auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 34px;
  justify-content: center;
  margin: 2px 0px 3px;
  outline: 0px;
  overflow: hidden;
  padding: 0px;
  text-transform: none;
  width: 34px;
}
.tox .tox-tbtn svg {
  display: block;
  fill: rgb(34, 47, 62);
}
.tox .tox-tbtn.tox-tbtn-more {
  padding-left: 5px;
  padding-right: 5px;
  width: inherit;
}
.tox .tox-tbtn:focus {
  background: rgb(222, 224, 226);
  border: 0px;
  box-shadow: none;
}
.tox .tox-tbtn:hover {
  background: rgb(222, 224, 226);
  border: 0px;
  box-shadow: none;
  color: rgb(34, 47, 62);
}
.tox .tox-tbtn:hover svg {
  fill: rgb(34, 47, 62);
}
.tox .tox-tbtn:active {
  background: rgb(200, 203, 207);
  border: 0px;
  box-shadow: none;
  color: rgb(34, 47, 62);
}
.tox .tox-tbtn:active svg {
  fill: rgb(34, 47, 62);
}
.tox .tox-tbtn--disabled,
.tox .tox-tbtn--disabled:hover,
.tox .tox-tbtn:disabled,
.tox .tox-tbtn:disabled:hover {
  background: 0px 0px;
  border: 0px;
  box-shadow: none;
  color: rgba(34, 47, 62, 0.5);
  cursor: not-allowed;
}
.tox .tox-tbtn--disabled svg,
.tox .tox-tbtn--disabled:hover svg,
.tox .tox-tbtn:disabled svg,
.tox .tox-tbtn:disabled:hover svg {
  fill: rgba(34, 47, 62, 0.5);
}
.tox .tox-tbtn--enabled,
.tox .tox-tbtn--enabled:hover {
  background: rgb(200, 203, 207);
  border: 0px;
  box-shadow: none;
  color: rgb(34, 47, 62);
}
.tox .tox-tbtn--enabled:hover > *,
.tox .tox-tbtn--enabled > * {
  transform: none;
}
.tox .tox-tbtn--enabled svg,
.tox .tox-tbtn--enabled:hover svg {
  fill: rgb(34, 47, 62);
}
.tox .tox-tbtn:focus:not(.tox-tbtn--disabled) {
  color: rgb(34, 47, 62);
}
.tox .tox-tbtn:focus:not(.tox-tbtn--disabled) svg {
  fill: rgb(34, 47, 62);
}
.tox .tox-tbtn:active > * {
  transform: none;
}
.tox .tox-tbtn--md {
  height: 51px;
  width: 51px;
}
.tox .tox-tbtn--lg {
  flex-direction: column;
  height: 68px;
  width: 68px;
}
.tox .tox-tbtn--return {
  align-self: stretch;
  height: unset;
  width: 16px;
}
.tox .tox-tbtn--labeled {
  padding: 0px 4px;
  width: unset;
}
.tox .tox-tbtn__vlabel {
  display: block;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: -0.025em;
  margin-bottom: 4px;
  white-space: nowrap;
}
.tox .tox-tbtn--select {
  margin: 2px 0px 3px;
  padding: 0px 4px;
  width: auto;
}
.tox .tox-tbtn__select-label {
  cursor: default;
  font-weight: 400;
  margin: 0px 4px;
}
.tox .tox-tbtn__select-chevron {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 16px;
}
.tox .tox-tbtn__select-chevron svg {
  fill: rgba(34, 47, 62, 0.5);
}
.tox .tox-tbtn--bespoke .tox-tbtn__select-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 7em;
}
.tox .tox-split-button {
  border: 0px;
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  margin: 2px 0px 3px;
  overflow: hidden;
}
.tox .tox-split-button:hover {
  box-shadow: rgb(222, 224, 226) 0px 0px 0px 1px inset;
}
.tox .tox-split-button:focus {
  background: rgb(222, 224, 226);
  box-shadow: none;
  color: rgb(34, 47, 62);
}
.tox .tox-split-button > * {
  border-radius: 0px;
}
.tox .tox-split-button__chevron {
  width: 16px;
}
.tox .tox-split-button__chevron svg {
  fill: rgba(34, 47, 62, 0.5);
}
.tox .tox-split-button .tox-tbtn {
  margin: 0px;
}
.tox.tox-platform-touch .tox-split-button .tox-tbtn:first-child {
  width: 30px;
}
.tox.tox-platform-touch .tox-split-button__chevron {
  width: 20px;
}
.tox .tox-split-button.tox-tbtn--disabled .tox-tbtn:focus,
.tox .tox-split-button.tox-tbtn--disabled .tox-tbtn:hover,
.tox .tox-split-button.tox-tbtn--disabled:focus,
.tox .tox-split-button.tox-tbtn--disabled:hover {
  background: 0px 0px;
  box-shadow: none;
  color: rgba(34, 47, 62, 0.5);
}
.tox .tox-toolbar-overlord {
  background-color: rgb(255, 255, 255);
}
.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
  background: url("data:image/svg+xml;charset=utf8,%3Csvg height='39px' viewBox='0 0 40 39px' width='40' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0' y='38px' width='100' height='1' fill='%23cccccc'/%3E%3C/svg%3E")
    left 0px top 0px rgb(255, 255, 255);
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  padding: 0px;
}
.tox .tox-toolbar__overflow.tox-toolbar__overflow--closed {
  height: 0px;
  opacity: 0;
  padding-bottom: 0px;
  padding-top: 0px;
  visibility: hidden;
}
.tox .tox-toolbar__overflow--growing {
  transition: height 0.3s ease 0s, opacity 0.2s linear 0.1s;
}
.tox .tox-toolbar__overflow--shrinking {
  transition: opacity 0.3s ease 0s, height 0.2s linear 0.1s,
    visibility 0s linear 0.3s;
}
.tox .tox-menubar + .tox-toolbar,
.tox .tox-menubar + .tox-toolbar-overlord .tox-toolbar__primary {
  border-top: 1px solid rgb(204, 204, 204);
  margin-top: -1px;
}
.tox .tox-toolbar--scrolling {
  flex-wrap: nowrap;
  overflow-x: auto;
}
.tox .tox-pop .tox-toolbar {
  border-width: 0px;
}
.tox .tox-toolbar--no-divider {
  background-image: none;
}
.tox-tinymce:not(.tox-tinymce-inline)
  .tox-editor-header:not(:first-child)
  .tox-toolbar-overlord:first-child
  .tox-toolbar__primary,
.tox-tinymce:not(.tox-tinymce-inline)
  .tox-editor-header:not(:first-child)
  .tox-toolbar:first-child {
  border-top: 1px solid rgb(204, 204, 204);
}
.tox.tox-tinymce-aux .tox-toolbar__overflow {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 3px;
}
.tox .tox-toolbar__group {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
  padding: 0px 4px;
}
.tox .tox-toolbar__group--pull-right {
  margin-left: auto;
}
.tox .tox-toolbar--scrolling .tox-toolbar__group {
  flex-shrink: 0;
  flex-wrap: nowrap;
}
.tox:not([dir="rtl"]) .tox-toolbar__group:not(:last-of-type) {
  border-right: 1px solid rgb(204, 204, 204);
}
.tox[dir="rtl"] .tox-toolbar__group:not(:last-of-type) {
  border-left: 1px solid rgb(204, 204, 204);
}
.tox .tox-tooltip {
  display: inline-block;
  padding: 8px;
  position: relative;
}
.tox .tox-tooltip__body {
  background-color: rgb(34, 47, 62);
  border-radius: 3px;
  box-shadow: rgba(34, 47, 62, 0.3) 0px 2px 4px;
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 4px 8px;
  text-transform: none;
}
.tox .tox-tooltip__arrow {
  position: absolute;
}
.tox .tox-tooltip--down .tox-tooltip__arrow {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgb(34, 47, 62);
  bottom: 0px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}
.tox .tox-tooltip--up .tox-tooltip__arrow {
  border-bottom: 8px solid rgb(34, 47, 62);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  left: 50%;
  position: absolute;
  top: 0px;
  transform: translateX(-50%);
}
.tox .tox-tooltip--right .tox-tooltip__arrow {
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgb(34, 47, 62);
  border-top: 8px solid transparent;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}
.tox .tox-tooltip--left .tox-tooltip__arrow {
  border-bottom: 8px solid transparent;
  border-right: 8px solid rgb(34, 47, 62);
  border-top: 8px solid transparent;
  left: 0px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.tox .tox-well {
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  padding: 8px;
  width: 100%;
}
.tox .tox-well > :first-child {
  margin-top: 0px;
}
.tox .tox-well > :last-child {
  margin-bottom: 0px;
}
.tox .tox-well > :only-child {
  margin: 0px;
}
.tox .tox-custom-editor {
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  display: flex;
  flex: 1 1 0%;
  position: relative;
}
.tox .tox-dialog-loading::before {
  background-color: rgba(0, 0, 0, 0.5);
  content: "";
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1000;
}
.tox .tox-tab {
  cursor: pointer;
}
.tox .tox-dialog__content-js {
  display: flex;
  flex: 1 1 0%;
}
.tox .tox-dialog__body-content .tox-collection {
  display: flex;
  flex: 1 1 0%;
}
.tox .tox-image-tools-edit-panel {
  height: 60px;
}
.tox .tox-image-tools__sidebar {
  height: 60px;
}

@media screen and (max-width: 400) {
  .tox-tinymce {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 0px;
    box-shadow: none;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    overflow: auto !important;
    position: relative;
    visibility: inherit !important;
    height: 635px !important;
    width: 313px !important;
  }
}

@media screen and (min-width: 1600px) {
}
@media screen and (min-width: 1900px) {
}
@media only screen and (max-width: 600px) {
  .logo_container_home {
    display: flex;
    justify-content: center;
  }
  .header_icon_container {
    left: 3%;
    top: 23%;
  }
}
@media only screen and (max-width: 991.5px) {
  .logo_container_home {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  body:not(.tox-force-desktop) .tox .tox-dialog {
    align-self: flex-start;
    margin: 8px auto;
    width: calc(100vw - 16px);
  }
}
.tox .tox-dialog-inline {
  z-index: 1100;
}
.tox .tox-dialog__header {
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-bottom: none;
  color: rgb(34, 47, 62);
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  padding: 8px 16px 0px;
  position: relative;
}
.tox .tox-dialog__header .tox-button {
  z-index: 1;
}
.tox .tox-dialog__draghandle {
  cursor: grab;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.tox .tox-dialog__draghandle:active {
  cursor: grabbing;
}
.tox .tox-dialog__dismiss {
  margin-left: auto;
}
.tox .tox-dialog__title {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  margin: 0px;
  text-transform: none;
}
.tox .tox-dialog__body {
  color: rgb(34, 47, 62);
  display: flex;
  flex: 1 1 0%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  min-width: 0px;
  text-align: left;
  text-transform: none;
}
@media only screen and (max-width: 767px) {
  body:not(.tox-force-desktop) .tox .tox-dialog__body {
    flex-direction: column;
  }
}
.tox .tox-dialog__body-nav {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 16px;
}
@media only screen and (max-width: 767px) {
  body:not(.tox-force-desktop) .tox .tox-dialog__body-nav {
    flex-direction: row;
    overflow-x: auto;
    padding-bottom: 0px;
  }
}
.tox .tox-dialog__body-nav-item {
  border-bottom: 2px solid transparent;
  color: rgba(34, 47, 62, 0.7);
  display: inline-block;
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 8px;
  text-decoration: none;
  white-space: nowrap;
}
.tox .tox-dialog__body-nav-item:focus {
  background-color: rgba(32, 122, 183, 0.1);
}
.tox .tox-dialog__body-nav-item--active {
  border-bottom: 2px solid rgb(32, 122, 183);
  color: rgb(32, 122, 183);
}
.tox .tox-dialog__body-content {
  box-sizing: border-box;
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  max-height: 650px;
  overflow: auto;
  padding: 16px;
}
.tox .tox-dialog__body-content > * {
  margin-bottom: 0px;
  margin-top: 16px;
}
.tox .tox-dialog__body-content > :first-child {
  margin-top: 0px;
}
.tox .tox-dialog__body-content > :last-child {
  margin-bottom: 0px;
}
.tox .tox-dialog__body-content > :only-child {
  margin-bottom: 0px;
  margin-top: 0px;
}
.tox .tox-dialog__body-content a {
  color: rgb(32, 122, 183);
  cursor: pointer;
  text-decoration: none;
}
.tox .tox-dialog__body-content a:focus,
.tox .tox-dialog__body-content a:hover {
  color: rgb(24, 93, 140);
  text-decoration: none;
}
.tox .tox-dialog__body-content a:active {
  color: rgb(24, 93, 140);
  text-decoration: none;
}
.tox .tox-dialog__body-content svg {
  fill: rgb(34, 47, 62);
}
.tox .tox-dialog__body-content ul {
  display: block;
  list-style-type: disc;
  margin-bottom: 16px;
  margin-inline: 0px;
  padding-inline-start: 2.5rem;
}
.tox .tox-dialog__body-content .tox-form__group h1 {
  color: rgb(34, 47, 62);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  margin-bottom: 16px;
  margin-top: 2rem;
  text-transform: none;
}
.tox .tox-dialog__body-content .tox-form__group h2 {
  color: rgb(34, 47, 62);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  margin-bottom: 16px;
  margin-top: 2rem;
  text-transform: none;
}
.tox .tox-dialog__body-content .tox-form__group p {
  margin-bottom: 16px;
}
.tox .tox-dialog__body-content .tox-form__group h1:first-child,
.tox .tox-dialog__body-content .tox-form__group h2:first-child,
.tox .tox-dialog__body-content .tox-form__group p:first-child {
  margin-top: 0px;
}
.tox .tox-dialog__body-content .tox-form__group h1:last-child,
.tox .tox-dialog__body-content .tox-form__group h2:last-child,
.tox .tox-dialog__body-content .tox-form__group p:last-child {
  margin-bottom: 0px;
}
.tox .tox-dialog__body-content .tox-form__group h1:only-child,
.tox .tox-dialog__body-content .tox-form__group h2:only-child,
.tox .tox-dialog__body-content .tox-form__group p:only-child {
  margin-bottom: 0px;
  margin-top: 0px;
}
.tox .tox-dialog--width-lg {
  height: 650px;
  max-width: 1200px;
}
.tox .tox-dialog--width-md {
  max-width: 800px;
}
.tox .tox-dialog--width-md .tox-dialog__body-content {
  overflow: auto;
}
.tox .tox-dialog__body-content--centered {
  text-align: center;
}
.tox .tox-dialog__footer {
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-top: 1px solid rgb(204, 204, 204);
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
}
.tox .tox-dialog__footer-end,
.tox .tox-dialog__footer-start {
  display: flex;
}
.tox .tox-dialog__busy-spinner {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
  inset: 0px;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 3;
}
.tox .tox-dialog__table {
  border-collapse: collapse;
  width: 100%;
}
.tox .tox-dialog__table thead th {
  font-weight: 700;
  padding-bottom: 8px;
}
.tox .tox-dialog__table tbody tr {
  border-bottom: 1px solid rgb(204, 204, 204);
}
.tox .tox-dialog__table tbody tr:last-child {
  border-bottom: none;
}
.tox .tox-dialog__table td {
  padding-bottom: 8px;
  padding-top: 8px;
}
.tox .tox-dialog__popups {
  position: absolute;
  width: 100%;
  z-index: 1100;
}
.tox .tox-dialog__body-iframe {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
}
.tox .tox-dialog__body-iframe .tox-navobj {
  display: flex;
  flex: 1 1 0%;
}
.tox .tox-dialog__body-iframe .tox-navobj :nth-child(2) {
  flex: 1 1 0%;
  height: 100%;
}
.tox .tox-dialog-dock-fadeout {
  opacity: 0;
  visibility: hidden;
}
.tox .tox-dialog-dock-fadein {
  opacity: 1;
  visibility: visible;
}
.tox .tox-dialog-dock-transition {
  transition: visibility 0s linear 0.3s, opacity 0.3s ease 0s;
}
.tox .tox-dialog-dock-transition.tox-dialog-dock-fadein {
  transition-delay: 0s;
}

@media only screen and (max-width: 767px) {
  body:not(.tox-force-desktop) .tox:not([dir="rtl"]) .tox-dialog__body-nav {
    margin-right: 0px;
  }
}
@media only screen and (max-width: 767px) {
  body:not(.tox-force-desktop)
    .tox:not([dir="rtl"])
    .tox-dialog__body-nav-item:not(:first-child) {
    margin-left: 8px;
  }
}
.tox:not([dir="rtl"]) .tox-dialog__footer .tox-dialog__footer-end > *,
.tox:not([dir="rtl"]) .tox-dialog__footer .tox-dialog__footer-start > * {
  margin-left: 8px;
}
.tox[dir="rtl"] .tox-dialog__body {
  text-align: right;
}
@media only screen and (max-width: 767px) {
  body:not(.tox-force-desktop) .tox[dir="rtl"] .tox-dialog__body-nav {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 767px) {
  body:not(.tox-force-desktop)
    .tox[dir="rtl"]
    .tox-dialog__body-nav-item:not(:first-child) {
    margin-right: 8px;
  }
}

.mceBlackBody {
  background-color: #13490b;
}

#tinymce {
  background-image: url("../../../assets/images/write-paper-bg.png") !important;
}
.mce-content-body {
  background-image: url("../../../assets/images/write-paper-bg.png") !important;
}
